import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./forum.css";
import AskQuestionModal from './ask-question-modal';
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";
import { getForumList, markFavouriteQuestion } from "../../crud/qa-forum.crud";

const defaultImage = require('../../../images/imgAvtr.png');

const Forum = (props) => {
    const [forumList, updateForumList] = useState([]);
    const [displayModal, toggleModal] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingQuestions, toggleLoadingQuestions] = useState(true);
    const [searchedText, updateSearchedText] = useState("");
    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
            authData: auth.user
        }),
        shallowEqual
    );

    const openQuestionModal = () => {
        if (isAuthorized) {
            toggleModal(true);
        } else {
            toast.info("Please login to ask a question");
            setTimeout(() => {
                const loginButton = document.getElementById("login-button");
                if (loginButton) {
                    loginButton.click();
                }
            }, 1000);
        }
    };

    const getHighlightedText = (fullText, searchKey) => {
        let localText = fullText;
        const searchedWords = searchKey.split(" ");
        searchedWords.forEach(each => {
            if (each.length > 1 && localText.toLowerCase().includes(each.toLowerCase())) {
                const index = localText.toLowerCase().indexOf(each.toLowerCase());
                if (index >= 0) {
                    localText = localText.substring(0, index) + "<span class='z'>" + localText.substring(index, index + each.length) + "</span>" + localText.substring(index + each.length);
                }
            }
        });
        return localText;
    };

    const getAllForumList = useCallback(async (searchKey) => {
        toggleDisablePage(true);
        const forumResults = await getForumList(searchKey);
        toggleDisablePage(false);
        toggleLoadingQuestions(false);
        if (forumResults.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.errorCode]);
            return;
        }
        if (forumResults.data.data && forumResults.data.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.data.errorCode]);
            return;
        }
        const forumArr = [];
        if (forumResults.data.data && forumResults.data.data.data && forumResults.data.data.data.length) {
            let forumObj = {};
            forumResults.data.data.data.forEach(each => {
                forumObj = {};
                forumObj.isFavourite = authData && each.favouritedBy && each.favouritedBy.includes(authData._id) ? true : false;
                forumObj._id = each._id;
                if (!searchKey || (searchKey && !searchKey.trim().length)) {
                    forumObj.question = each.question;
                } else {
                    forumObj.question = getHighlightedText(each.question, searchKey);
                }

                forumObj.selected = false;
                forumObj.noOfComments = each.answers.length;
                forumObj.answers = [];
                forumArr.push({ ...forumObj });
            });
        }
        updateForumList([...forumArr]);
    }, [authData]);

    const chooseAnswer = (item) => {
        if (searchedText.trim().length) {
            sessionStorage.setItem("forumFilter", JSON.stringify({
                searchKey: searchedText
            }));
        }

        props.history.push(`/forum-details/${item._id}`);
    };

    useEffect(() => {
        let localSearchKey = undefined;
        if (sessionStorage.getItem("forumFilter") &&
            JSON.parse(sessionStorage.getItem("forumFilter")) &&
            Object.keys(JSON.parse(sessionStorage.getItem("forumFilter"))).length) {
            const localFilterData = JSON.parse(sessionStorage.getItem("forumFilter"));
            localSearchKey = localFilterData.searchKey;
            if (localSearchKey) {
                updateSearchedText(localSearchKey);
            }
        }
        document.title = "Free Consultation with Phycologist / Counsellor";
        getAllForumList(localSearchKey);
    }, [getAllForumList]);

    const openMyQa = () => {
        if (isAuthorized) {
            props.history.push("/myQA");
        } else {
            toast.info("Please login to get access to my QA page");
            setTimeout(() => {
                const loginButton = document.getElementById("login-button");
                if (loginButton) {
                    loginButton.click();
                }
            }, 1000);
        }
    };

    const clickFav = async (profData, ind) => {
        if (!isAuthorized) {
            toast.info("Please login to make the question favourite");
            setTimeout(() => {
                const loginButton = document.getElementById("login-button");
                if (loginButton) {
                    loginButton.click();
                }
            }, 1000);
            return;
        }

        const localProfs = [...forumList];
        toggleDisablePage(true);
        let favProbResult = await markFavouriteQuestion(profData._id, { favourite: !localProfs[ind].isFavourite });
        toggleDisablePage(false);
        if (favProbResult.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.errorCode]);
            return;
        }
        if (favProbResult.data.data && favProbResult.data.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.data.errorCode]);
            return;
        }
        localProfs[ind].isFavourite = !localProfs[ind].isFavourite;
        updateForumList([...localProfs]);
    };

    const search = (isClear) => {
        if (isClear) {
            updateSearchedText("");
            getAllForumList();
            sessionStorage.removeItem("forumFilter");
        } else {
            getAllForumList(searchedText);
        }
    };

    return (
        <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12 pad-0-below-500">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-12 categories-heading`}>
                        <h4 className={`mb-0 page-heading pt-1`}>Forum</h4>
                        <button type="button" className="btn btn-link pt-1" onClick={() => props.history.push("/problem-area?category=60883f8d1069d58676cb0671")}>Back to Problem Area</button>
                        <div className={`xcol-sm-5 pull-right forum-upper-button-wrapper`}>
                            <button type="button" className="btn btn-outline-primary my-qa" onClick={openMyQa}>My Q & A</button>
                            <button type="button" className="btn btn-outline-default ml-3 ask-question" onClick={openQuestionModal}>Ask Question</button>
                        </div>
                    </div>

                </div>
                <div className='row m-0'>
                    <form className='forum-search-input-wrapper' onSubmit={(e) => {
                        e.preventDefault();
                        search(false);}}>
                        <div className="input-group">

                            <input type="text" className={`form-control`} placeholder="Search forum question" aria-label="Search forum question" aria-describedby="basic-addon2" value={searchedText} onChange={(e) => updateSearchedText(e.target.value)} />

                            <div className="input-group-append pos-relative">

                                <button className="btn btn-outline-secondary search-button" type="submit"><i className='fa fa-search'></i></button>
                                {!searchedText.length ? null : <i className='fa fa-times' style={{
                                    position: "absolute",
                                    left: "-20px",
                                    top: "20px",
                                    cursor: "pointer",
                                    zIndex: 3
                                }} onClick={() => search(true)}></i>}
                            </div>
                        </div>
                    </form>
                </div>

                <div className="col-12">
                    <div className="row" style={{ margin: 0 }}>
                        {forumList.length ?
                            forumList.map((professional, index) => {
                                return <div className={`col-12 professional-wrapper ${professional.selected ? 'professional-wrapper-selected' : ''}`} key={index}>
                                    <div className="row professional-wrapper-row p-0" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            <div className={`forum-wrapper ${!professional.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`} onClick={() => chooseAnswer({ ...professional })}>
                                                <div style={{ width: "calc(100% - 70px)", display: "inline-block" }}>
                                                    {professional.selected ?
                                                        professional.askedByProfilePhoto ?
                                                            <div style={{ backgroundImage: "url(" + professional.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                                                            <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                                                    {!professional.selected ? <>
                                                        <span dangerouslySetInnerHTML={{ __html: professional.question }}></span>
                                                        <div className="no-of-comments" style={{ display: "inline-block" }}> (<i className="fas fa-comment"></i> {professional.noOfComments}) </div> </>
                                                        : <div className="question-asked-details">
                                                            <div className="question-commentedby">{professional.askedByName}</div>
                                                            {professional.question} <div className="no-of-comments" style={{ display: "inline-block" }}> (<i className="fas fa-comment"></i> {professional.noOfComments}) </div>
                                                        </div>}
                                                </div>

                                                {professional.isFavourite ?
                                                    <img className="mr-2" src={require('../../../images/loved.png')} alt="sort down" onClick={e => { e.stopPropagation(); clickFav(professional, index) }} />
                                                    : <img className="mr-2" src={require('../../../images/love.png')} alt="sort down" onClick={e => { e.stopPropagation(); clickFav(professional, index) }} />}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingQuestions ? <div className='no-data-found'>
                                    <img src={require("../../../images/no-data.png")} alt="no data" />
                                    <br />
                                    <div className='no-data-header'>No Result Found</div>
                                    <div className='no-data-desc'>Try searching with different <br /> keywords.</div>
                                    <button className='btn btn-primary no-data-button' onClick={() => search(true)}>Retry</button>
                                </div> : 'loading...'}
                            </div>}
                    </div>
                </div>
            </div>
            <AskQuestionModal displayModal={displayModal} toggleModal={toggleModal} getAllForumList={getAllForumList} openMyQa={openMyQa} from={"forum"} />
        </div>
    )
}

export default Forum;