import axios from "axios";
import baseUrl from './api.util';
// import ApiErrorMessage from './api.errorMessage';
import { v4 as uuidv4 } from "uuid";
import { DEVICE_ID } from "../config/local-storage-keys";
import {actions} from './../store/ducks/auth.duck';
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../config/error-message";
const deviceId = () => {
  if (localStorage.getItem(DEVICE_ID)) {
    return localStorage.getItem(DEVICE_ID);
  } else {
    localStorage.setItem(DEVICE_ID, uuidv4());
    return localStorage.getItem(DEVICE_ID);
  }
};

const setNotificationKey = (subValue) => {
  if (localStorage.getItem("CUST_NOT_KEY")) {
    return localStorage.getItem("CUST_NOT_KEY");
  } else {
    if (subValue) {
      localStorage.setItem("CUST_NOT_KEY", JSON.stringify(subValue));
      window.location.reload();
    }
    
    return localStorage.getItem("CUST_NOT_KEY");
  }
};

let errorCode = 0;

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const subscribeUser = async () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(registration) {
      if (!registration.pushManager) {
        return
      }
      registration.pushManager.getSubscription().then(function(existedSubscription) {
        if (existedSubscription === null) {
          registration.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
          }).then(function(newSubscription) {
            setNotificationKey(newSubscription);
          }).catch(function(e) {
            if (Notification.permission !== 'granted') {
              console.log('Permission was not granted.')
            } else {
              console.error('An error ocurred during the subscription process.', e)
            }
          })
        } else {
          setNotificationKey(existedSubscription);
        }
      })
    })
      .catch(function(e) {
        console.error('An error ocurred during Service Worker registration.', e)
      })
  }
}

const getToken = () => {
  let authToken = localStorage.getItem('authTokenPCUserWeb');
  const token = authToken? authToken: "";
  return token;
};

const getHeaders = async () => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["x-auth-deviceid"] = deviceId();
  axios.defaults.headers.common["x-auth-devicetype"] = 3;
  await subscribeUser();
}

getHeaders();

axios.defaults.headers.common["x-auth-notificationkey"] = setNotificationKey();

export const setupAxios = (store) => {

  // Add a request interceptor
  axios.interceptors.request.use(
    function(config) {
      delete config.headers.Authorization;
      const token =getToken();
      if(token){
        config.headers["x-auth-token"] = token;
      }
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function(response) {
      let isStoppable = false;
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (!response.data.success) {
        if (response.data.errorCode === 1000 || response.data.errorCode === 400) {
          if (response.data.errorCode !== errorCode) {
            isStoppable = true;
            errorCode = response.data.errorCode;
            toast.error(API_ERROR[response.data.errorCode]);
            store.dispatch(actions.logout());
            const profModal = document.getElementsByClassName("profile-modal")[0];
            if (profModal) {
              profModal.remove();
            }
            const askQuesModal = document.getElementsByClassName("ask-question-modal")[0];
            if (askQuesModal) {
              askQuesModal.remove();
            }
            setTimeout(() => {
              // window.location.href = '/dashboard';
              const loginButton = document.getElementById("login-button");
              if (loginButton) {
                loginButton.click();
              }
            }, 1000);
          }
        }
        Promise.reject();
        if (!isStoppable) {
          return response;
        } else {
          return {
            data: {}
          }
        }
      } else {
        errorCode = 0;
        return response;
      }
    },
    function(error) {
      console.log('error', error);
      return Promise.reject(error);
    }
  );
};

export default axios;
