import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./forum.css";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";
import { addAnswer, getForumDetails, markFavouriteQuestion, flagAnswer, removeForumQuestion, removeForumAnswer } from "../../crud/qa-forum.crud";
import FlagModal from './flag-modal';
import RemoveModal from './remove-modal';
import EditAnswerModal from './answer-modal';
import { API_SUCCESS } from '../../config/success-message';

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultImage = require('../../../images/imgAvtr.png');
const adminProfileImage = require('../../../images/logo-blue.webp');
const flagSelectedImage = require('../../../images/flag-selected.png');
const flagNotSelectedImage = require('../../../images/flag-not-selected.png');

const Forum = (props) => {
  const [questionDetails, updateQuestionDetails] = useState({});
  const [myAnswer, updateMyAnswer] = useState("");
  const [disablePage, toggleDisablePage] = useState(false);
  const [loadingQuestions, toggleLoadingQuestions] = useState(true);
  const { isAuthorized, authData } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
      authData: auth.user
    }),
    shallowEqual
  );
  const [flagModalOpened, toggleFlagModal] = useState(false);
  const [selectedFlaggedQuestion, updateSelectedFlaggedQuestion] = useState({});
  const [flagAnswerModalOpened, toggleFlagAnswerModal] = useState(false);
  const [isEditable, toggleEditable] = useState(false);
  const [editableAnswer, updateSelectedAnswerForEdit] = useState({});
  const [editableQuestion, updateSelectedQuestionForEdit] = useState({});
  const [editType, updateEditType] = useState("");
  const [removableQuestionId, updateRemoveableQuestionId] = useState("");
  const [removableAnswerId, updateRemoveableAnswerId] = useState("");
  const [openRemoveConfirmationModal, toggleRemoveConfirmationModal] = useState(false);
  const [removeType, updateRemoveType] = useState("");

  const getFullDate = (date) => {
    const d = new Date(date);
    return d.getDate() + ' ' + monthArr[d.getMonth()] + ', ' + d.getFullYear();
  };

  const getQuestionDetails = useCallback(async (id, fromAnswer) => {
    toggleDisablePage(true);
    const localQuestionDetails = await getForumDetails(id);
    toggleDisablePage(false);
    toggleLoadingQuestions(false);
    if (localQuestionDetails.data.errorCode) {
      toast.error(API_ERROR[localQuestionDetails.data.errorCode]);
      return;
    }
    if (localQuestionDetails.data.data && localQuestionDetails.data.data.errorCode) {
      toast.error(API_ERROR[localQuestionDetails.data.data.errorCode]);
      return;
    }
    const localProf = { ...questionDetails };
    let answerObj = {};
    document.title = localQuestionDetails.data.data.question;
    localProf.askedByName = localQuestionDetails.data.data.askedBy.personalInfo.name || '';
    localProf.askedByProfilePhoto = localQuestionDetails.data.data.askedBy.personalInfo.profilePicture || '';
    localProf.isMe = authData && localQuestionDetails.data.data.askedBy._id === authData._id ? true : false;
    localProf.isFavourite = authData && localQuestionDetails.data.data.favouritedBy && localQuestionDetails.data.data.favouritedBy.includes(authData._id) ? true : false;
    localProf._id = localQuestionDetails.data.data._id;
    localProf.question = localQuestionDetails.data.data.question;
    localProf.status = localQuestionDetails.data.data.status;
    localProf.selected = true;
    localProf.date = getFullDate(localQuestionDetails.data.data.createdAt);
    if (!window.location.pathname.includes("forum")) {
      localProf.questionedByMe = false;
      localProf.answerByMe = false;
      if (
        authData &&
        localQuestionDetails.data.data.askedBy._id === authData._id) {
        localProf.questionedByMe = true;
      }
      if (!localProf.questionedByMe) {
        localProf.answerByMe = true;
      }
    }
    localProf.isFlagged = false;
    if (localQuestionDetails.data.data.flaggedBy && localQuestionDetails.data.data.flaggedBy.length) {
      localQuestionDetails.data.data.flaggedBy.forEach(each => {
        if (authData && each.customerId === authData._id) {
          localProf.isFlagged = true;
        }
      })
    }
    localProf.answers = [];
    if (localQuestionDetails.data.data && localQuestionDetails.data.data.answers && localQuestionDetails.data.data.answers.length) {
      localQuestionDetails.data.data.answers.forEach(item => {
        answerObj = {};
        answerObj.answerText = item.answer;
        answerObj._id = item._id;
        answerObj.date = item.time;
        answerObj.name = "";
        answerObj.profilePicture = "";
        answerObj.isMe = authData && item.answeredBy._id === authData._id ? true : false;
        if (item.answeredBy && item.answeredBy.personalInfo) {
          answerObj.name = item.answeredBy.personalInfo.name;
          answerObj.profilePicture = item.answeredBy.personalInfo.profilePicture;
          answerObj.userType = item.answeredBy.userType;
          answerObj.userId = item.answeredBy._id;
          answerObj.profileVisible = item.answeredBy.profileVisible !== undefined ? item.answeredBy.profileVisible : true;
        }
        answerObj.isFlagged = false;
        if (item.flaggedBy && item.flaggedBy.length) {
          item.flaggedBy.forEach(each1 => {
            if (authData && each1.customerId === authData._id) {
              answerObj.isFlagged = true;
            }
          })
        }
        localProf.answers.push({ ...answerObj });
      })
    }
    localProf.noOfComments = localProf.answers.length;
    updateQuestionDetails({ ...localProf });
    if (fromAnswer) {
      setTimeout(() => {
        const el = document.getElementsByClassName("answers-wrapper")[0];
        if (el) {
          el.scrollTop = el.scrollHeight;
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getQuestionDetails(props.match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const giveAnswer = async () => {
    if (myAnswer.trim().length) {
      toggleDisablePage(true);
      const answerResult = await addAnswer(props.match.params.id, { answer: myAnswer.trim() });
      toggleDisablePage(false);
      if (answerResult.data.errorCode) {
        toast.error(API_ERROR[answerResult.data.errorCode]);
        return;
      }
      if (answerResult.data.data && answerResult.data.data.errorCode) {
        toast.error(API_ERROR[answerResult.data.data.errorCode]);
        return;
      }

      updateMyAnswer("");
      getQuestionDetails(props.match.params.id, true);
    }
  };

  const openVendorProfile = (vendorProfile) => {
    if (vendorProfile.profileVisible) {
      props.history.push(`/professional-details/${vendorProfile.vendorId}/${vendorProfile.name.split(" ").join("-")}`, { vendorId: vendorProfile.vendorId });
    }
  };

  const clickFav = async (profData) => {
    if (!isAuthorized) {
      toast.info("Please login to make the question favourite");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }

    const localProfs = { ...questionDetails };
    toggleDisablePage(true);
    let favProbResult = await markFavouriteQuestion(profData._id, { favourite: !localProfs.isFavourite });
    toggleDisablePage(false);
    if (favProbResult.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.errorCode]);
      return;
    }
    if (favProbResult.data.data && favProbResult.data.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.data.errorCode]);
      return;
    }
    localProfs.isFavourite = !localProfs.isFavourite;
    updateQuestionDetails({ ...localProfs });
  };

  const clickUnflagAnswer = async (profData, answer) => {
    toggleDisablePage(true);
    let favProbResult = await flagAnswer(profData._id, answer._id, { flagChoise: false });
    toggleDisablePage(false);
    if (favProbResult.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.errorCode]);
      return;
    }
    if (favProbResult.data.data && favProbResult.data.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.data.errorCode]);
      return;
    }
    getQuestionDetails(props.match.params.id);
    updateSelectedFlaggedQuestion({});
  };

  const openFlagAnswerModal = (profData, answer) => {
    if (!isAuthorized) {
      toast.info("Please login to flag the question");
      setTimeout(() => {
        // window.location.href = '/dashboard';
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }
    let localQuestion = { ...profData };
    localQuestion.selectedAnswer = { ...answer };
    toggleFlagAnswerModal(true);
    updateSelectedFlaggedQuestion({ ...localQuestion });
  };

  const editAnswer = (answerData) => {
    toggleEditable(true);
    const localAnswer = { ...answerData };
    localAnswer.qId = questionDetails._id;
    updateSelectedAnswerForEdit({ ...localAnswer });
  };

  const editQuestion = (answerData) => {
    toggleEditable(true);
    const localAnswer = { ...answerData };
    localAnswer.qId = questionDetails._id;
    updateSelectedQuestionForEdit({ ...localAnswer });
  };

  const removeAnswer = (answerData) => {
    toggleRemoveConfirmationModal(true);
    updateRemoveableQuestionId(questionDetails._id);
    updateRemoveableAnswerId(answerData._id);
    updateRemoveType("answer");
  };

  // const removeQuestion = () => {
  //   toggleRemoveConfirmationModal(true);
  //   updateRemoveableQuestionId(questionDetails._id);
  //   updateRemoveType("question");
  // };

  const remove = async () => {
    toggleDisablePage(true);
    let removeResponse;
    if (removeType === "question") {
      removeResponse = await removeForumQuestion(removableQuestionId);
    } else {
      removeResponse = await removeForumAnswer(removableQuestionId, removableAnswerId);
    }

    toggleDisablePage(false);
    if (removeResponse.data.errorCode) {
      toast.error(API_ERROR[removeResponse.data.errorCode]);
      return;
    }

    toast.success(removeType === "question" ? API_SUCCESS.REMOVE_QUESTION : API_SUCCESS.REMOVE_ANSWER);
    toggleRemoveConfirmationModal(false);

    if (removeType === "question") {
      props.history.goBack();
    } else {
      getQuestionDetails(props.match.params.id);
    }
    
  };

  return (
    <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      {isEditable ?
        <EditAnswerModal
          editType={editType}
          displayModal={isEditable}
          toggleModal={toggleEditable}
          editableAnswer={editableAnswer}
          editableQuestion={editableQuestion}
          updateSelectedAnswerForEdit={updateSelectedAnswerForEdit}
          updateSelectedQuestionForEdit={updateSelectedQuestionForEdit}
          getQuestionDetails={getQuestionDetails}
        /> : null}
      {openRemoveConfirmationModal ?
        <RemoveModal
          removeType={removeType}
          displayModal={openRemoveConfirmationModal}
          toggleModal={toggleRemoveConfirmationModal}
          remove={remove}
        /> : null}
      {flagModalOpened ?
        <FlagModal flagModalOpened={flagModalOpened} toggleFlagModal={toggleFlagModal} type="question" from="details" selectedFlaggedQuestion={selectedFlaggedQuestion} updateSelectedFlaggedQuestion={updateSelectedFlaggedQuestion} getQuestionDetails={getQuestionDetails} /> : null}
      {flagAnswerModalOpened ?
        <FlagModal flagModalOpened={flagAnswerModalOpened} toggleFlagModal={toggleFlagAnswerModal} type="answer" from="details" selectedFlaggedQuestion={selectedFlaggedQuestion} updateSelectedFlaggedQuestion={updateSelectedFlaggedQuestion} getQuestionDetails={getQuestionDetails} /> : null}
      <div className="col-lg-12 pad-0-below-500">
        <div className="row" style={{ margin: 0 }}>
          <div className={`col-md-7 col-xs-12 categories-heading`}>
            <h4 className={`mb-0 page-heading`}>{`${window.location.pathname.includes('forum') ? 'Forum' : 'My Q & A'}`} Details</h4>
            <button type="button" className="btn btn-link pt-0" onClick={() => window.location.pathname.includes('forum') ? props.history.push("/forum") : props.history.push("/myQA")}>{`Back to ${window.location.pathname.includes('forum') ? 'Forum' : 'My Q & A'}`}</button>
          </div>
        </div>

        <div className="col-12">
          <div className="row" style={{ margin: 0 }}>
            {Object.keys(questionDetails).length ?
              <div className={`col-12 professional-wrapper ${questionDetails.selected ? 'professional-wrapper-selected' : ''}`}>
                <div className="row professional-wrapper-row p-0" style={{ margin: 0 }}>
                  <div className="col-12 p-0">
                    <div className={`professional-designation pos-relative row m-0 ${!questionDetails.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`}>
                      <div className="col-12 p-0">
                        {!window.location.pathname.includes("forum") ?
                          <div className="row m-0">
                            <div className="col-12">
                              <div className="qa-date">{questionDetails.date}</div>
                              <div className="qa-by-you">
                                {questionDetails.answerByMe ? "Answered by you" : ""}
                                {questionDetails.questionedByMe ? "Asked by you" : ""}
                              </div>
                            </div>
                          </div> : null}
                      </div>
                      <div className="col-12 p-0">
                        {questionDetails.selected ?
                          questionDetails.askedByProfilePhoto ?
                          questionDetails.askedByProfilePhoto !== "null" ?
                            <div style={{ backgroundImage: "url(" + questionDetails.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> : 
                            <div style={{ backgroundImage: "url(" + adminProfileImage + ")" }} className="background-image-professional mb-0" /> :
                            <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                        {!questionDetails.selected ? <>
                          {questionDetails.question} <span> ({questionDetails.noOfComments} comment{questionDetails.noOfComments > 1 ? "s" : ""}) </span> </>
                          : <div className="question-asked-details">
                            <div className="question-commentedby">{questionDetails.askedByName}</div>
                            {window.location.pathname.includes("forum") ?
                            <div className="qa-date" style={{
                              display: "block",
                              border: 0
                            }}>{questionDetails.date}</div> : null}
                            {questionDetails.question} <span> ({questionDetails.noOfComments} comment{questionDetails.noOfComments > 1 ? "s" : ""}) </span>
                          </div>}
                        {questionDetails.isMe && questionDetails.status !== 2 ?
                          <i className="ml-2 fas fa-pencil-alt pull-right" onClick={() => { updateEditType("question"); editQuestion(questionDetails); }}></i> : null}
                        
                        {window.location.pathname.includes("forum") ?
                          questionDetails.isFavourite ?
                            <img className="mr-2" src={require('../../../images/loved.png')} alt="sort down" onClick={e => { e.stopPropagation(); clickFav(questionDetails) }} />
                            : <img className="mr-2" src={require('../../../images/love.png')} alt="sort down" onClick={e => { e.stopPropagation(); clickFav(questionDetails) }} /> : null}
                      </div>
                      {/* {questionDetails.isMe ?
                          <i class="fa fa-times-circle remove-question-icon" onClick={() => { removeQuestion() }} aria-hidden="true"></i> : null} */}
                    </div>
                    {!questionDetails.selected ?
                      null :
                      <div className="professional-location answer">
                        <div className="row answers-wrapper" style={{ margin: 0 }}>
                          <div className="col-12 pl-0 pr-0 pt-2">
                            {questionDetails.answers.length ?
                              questionDetails.answers.map((answer, index) => {
                                return <div className="row" style={{ margin: 0 }} key={index}>
                                  {answer.userType !== 3 ?
                                    answer.profilePicture ?
                                      <div style={{
                                        backgroundImage: "url(" + answer.profilePicture + ")",
                                        borderColor: answer.userType === 2 ? "#185cd9" : "#ffffff"
                                      }} className="background-image-professional" onClick={answer.userType === 2 ? () => openVendorProfile({ vendorId: answer.userId, name: answer.name, profileVisible: answer.profileVisible }) : () => { }} /> :
                                      <div id="2" style={{
                                        backgroundImage: "url(" + defaultImage + ")",
                                        borderColor: answer.userType === 2 ? "#185cd9" : "#ffffff"
                                      }} className="background-image-professional" onClick={answer.userType === 2 ? () => openVendorProfile({ vendorId: answer.userId, name: answer.name, profileVisible: answer.profileVisible }) : () => { }} /> :
                                    <div style={{
                                      backgroundImage: "url(" + adminProfileImage + ")",
                                      borderColor: answer.userType === 2 ? "#185cd9" : "#ffffff"
                                    }} className="background-image-professional" />}
                                  <div className=" answer-text pos-relative">
                                    <div className="answer-commentedby">{answer.userType !== 3 ? <span className={`${answer.userType === 2 ? `clickable-span ${answer.profileVisible ? '' : 'cursor-default'}` : ''}`} onClick={answer.userType === 2 ? () => openVendorProfile({ vendorId: answer.userId, name: answer.name, profileVisible: answer.profileVisible }) : () => { }}>{answer.name}</span> : 'Parentcraft Admin'}
                                      <span className="answer-date"> {getFullDate(answer.date)}
                                        {window.location.pathname.includes("forum") && !answer.isMe ?
                                          answer.isFlagged ? <img className="ml-2" src={flagSelectedImage} alt="Flagged answer" onClick={e => { e.stopPropagation(); clickUnflagAnswer(questionDetails, answer) }} />
                                            : <img className="ml-2" src={flagNotSelectedImage} alt="Not flagged answer" onClick={e => { e.stopPropagation(); openFlagAnswerModal(questionDetails, answer) }} /> : null}
                                        {answer.isMe ?
                                          <>
                                            <i className="ml-2 fas fa-pencil-alt" style={{fontSize: "18px", marginRight: "7px"}} onClick={() => { updateEditType("answer"); editAnswer(answer); }}></i>
                                            <i class="fa fa-times-circle answer-question-icon" onClick={() => { removeAnswer(answer) }} aria-hidden="true"></i>
                                          </>
                                          : null}
                                      </span>
                                    </div>
                                    <div className="answer-text-span-wrapper">{answer.answerText.split('\n').map((item, key) => {
                                      return <span key={key}>{item}<br /></span>
                                    })}</div>
                                  </div>
                                </div>
                              })
                              : <div className="text-center well col-12 color-black"> 
                              <div className='no-data-found'>
                              <img src={require("../../../images/no-data.png")} alt="no data" />
                              <br />
                              <div className='no-data-header'>No Answer Found</div>
                              <div className='no-data-desc'>Be the first to answer</div>
                          </div>
                          </div>}
                          </div>
                        </div>
                        <div className="row" style={{ margin: 0, paddingTop: "15px" }}>
                          {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                            <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="background-image-professional" /> :
                            <div style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                          <div className="message-input">
                            <textarea className="form-control textarea" placeholder="Type your answer" value={myAnswer} onChange={(e) => updateMyAnswer(e.target.value)} disabled={!isAuthorized} rows="2"></textarea>
                            <button disabled={!myAnswer.trim().length || !isAuthorized} className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={giveAnswer}><img src={require("../../../images/message-send.svg")} alt="send message" /></button>
                            {!isAuthorized ?
                              <div className="col-12 p-0 text-info">
                                Please login to comment
                              </div> : null}
                          </div>

                        </div>
                      </div>}
                  </div>
                </div>
              </div>
              : <div className="text-center well col-12 color-black">
                {!loadingQuestions ? 'No Questions Details Available!' : 'loading...'}
              </div>}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Forum;