import React from 'react';
import { Helmet } from "react-helmet";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    WhatsappShareButton,
    TwitterShareButton
} from "react-share";
import "./problem-area.css";
import { toast } from 'react-toastify';

const defaultImage = require('../../../images/imgAvtr.png');

const ShareToSocialModal = (props) => {
    const copyToClipboard = () => {
        const tempInput = document.createElement("input");
        tempInput.value = window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        toast.success("Link Copied");
    };

    return (
        <div className="row problem-area" style={{ margin: 0 }}>
            <Helmet>
                <meta property="og:description" content={props.bookingDetails.description ? props.bookingDetails.description : ""} />
                <meta property="og:title" content={props.shareType === "profile" ? props.bookingDetails.name : props.bookingDetails.title} />
                <meta property="og:image" content={props.bookingDetails.profilePicture ? props.bookingDetails.profilePicture : defaultImage} />
            </Helmet>
            <div className={`modal login-modal ask-question-modal fade ${props.displayShareModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className={`modal-content ${props.shareType === "appointment" ? 'h-240' : ''}`}>
                        <div className="modal-header">
                            <h5 className="modal-title">{props.shareType === "profile" ? 'Share Profile' : props.shareType === "appointment" ? 'Share Appointment' : 'Share Event'}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: 0 }}>
                                <div className="col-12 p-0 mb-3">
                                    {props.shareType !== "profile" && props.shareType !== "appointment" ?
                                        <>
                                            <FacebookShareButton children={<img src={require('../../../images/fb.png')} alt="fb" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} quote={`${props.bookingDetails.title} - ${props.bookingDetails.description}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <EmailShareButton children={<img src={require('../../../images/mail.png')} alt="mail" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} body={`${props.bookingDetails.title} - ${props.bookingDetails.description}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <LinkedinShareButton children={<img src={require('../../../images/linkedin.png')} alt="linkedin" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.title}`} summary={`${props.bookingDetails.description}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <PinterestShareButton description={`${props.bookingDetails.title} - ${props.bookingDetails.description}`} children={<img src={require('../../../images/pinterest.png')} alt="pinterest" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} media={props.bookingDetails.images[0]} />
                                            <WhatsappShareButton title={`${props.bookingDetails.title} - ${props.bookingDetails.description}`} children={<img src={require('../../../images/whatsapp.png')} alt="whatsapp" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <TwitterShareButton children={<img src={require('../../../images/twitter.png')} alt="twitter" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.title} - ${props.bookingDetails.description}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                        </> :
                                        props.shareType === "profile" ? 
                                        <>
                                            <FacebookShareButton children={<img src={require('../../../images/fb.png')} alt="fb" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} quote={`${props.bookingDetails.name} - ${props.bookingDetails.designation}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <EmailShareButton children={<img src={require('../../../images/mail.png')} alt="mail" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} body={`${props.bookingDetails.name} - ${props.bookingDetails.designation}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <LinkedinShareButton children={<img src={require('../../../images/linkedin.png')} alt="linkedin" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.name}`} summary={`${props.bookingDetails.designation}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <PinterestShareButton description={`${props.bookingDetails.name} - ${props.bookingDetails.designation}`} children={<img src={require('../../../images/pinterest.png')} alt="pinterest" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} media={props.bookingDetails.profilePicture ? props.bookingDetails.profilePicture : defaultImage} />
                                            <WhatsappShareButton title={`${props.bookingDetails.name} - ${props.bookingDetails.designation}`} children={<img src={require('../../../images/whatsapp.png')} alt="whatsapp" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                            <TwitterShareButton children={<img src={require('../../../images/twitter.png')} alt="twitter" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.name} - ${props.bookingDetails.designation}`} url={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} />
                                        </> : 
                                        <>
                                        <FacebookShareButton children={<img src={require('../../../images/fb.png')} alt="fb" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} quote={`${props.bookingDetails.name} - ${props.bookingDetails.description} \n\nAppointment Number: ${props.bookingDetails.appointmentNumber} \n\nPlease join using this link\n`} url={props.bookingDetails.meetingDetails?.link?.trim()} />
                                        <EmailShareButton children={<img src={require('../../../images/mail.png')} alt="mail" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} body={`${props.bookingDetails.name} - ${props.bookingDetails.description} \n\nAppointment Number: ${props.bookingDetails.appointmentNumber} \n\nPlease join using this link\n`} url={props.bookingDetails.meetingDetails?.link?.trim()} />
                                        <LinkedinShareButton children={<img src={require('../../../images/linkedin.png')} alt="linkedin" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.name}`} summary={`${props.bookingDetails.description} \n\nAppointment Number: ${props.bookingDetails.appointmentNumber} \n\nPlease join using this link\n`} url={props.bookingDetails.meetingDetails?.link?.trim()} />
                                        <PinterestShareButton description={`${props.bookingDetails.name} - ${props.bookingDetails.description} \n\nAppointment Number: ${props.bookingDetails.appointmentNumber} \n\nPlease join using this link\n`} children={<img src={require('../../../images/pinterest.png')} alt="pinterest" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={props.bookingDetails.meetingDetails?.link?.trim()} />
                                        <WhatsappShareButton title={`${props.bookingDetails.name} - ${props.bookingDetails.description} \n\nAppointment Number: ${props.bookingDetails.appointmentNumber} \n\n`} children={<img src={require('../../../images/whatsapp.png')} alt="whatsapp" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} url={props.bookingDetails.meetingDetails?.link?.trim()} separator={`Please join using this link\n`} />
                                        <TwitterShareButton children={<img src={require('../../../images/twitter.png')} alt="twitter" style={{ width: "45px", marginRight: "5px", marginBottom: "5px" }} />} title={`${props.bookingDetails.name} - ${props.bookingDetails.description} \n\nAppointment Number: ${props.bookingDetails.appointmentNumber} \n\nPlease join using this link\n`} url={props.bookingDetails.meetingDetails?.link?.trim()} />
                                    </>}
                                </div>
                                {props.shareType !== "appointment" ?
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={window.location.href.includes('workshop-booking') ? `${window.location.origin}/workshop-details/${props.bookingDetails._id}` : window.location.href} disabled />
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-primary" onClick={copyToClipboard}>Copy</button>
                                    </div>
                                </div> : null}
                            </div>

                            <div className="col-12 p-0 text-right mt-3 modal-buttons">
                                <button type="button" className="btn btn-outline-primary" onClick={() => props.toggleShareModal(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareToSocialModal;