import React from 'react';
import "./forum.css";

const AgeInfoModal = (props) => {
    const openProfile = () => {
        props.toggleInfoModal(false);
        const profileButton = document.getElementById("profile-button");
        if (profileButton) {
            profileButton.click();
        }
    };

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0'>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => props.toggleInfoModal(false)}></i>
                                        <h5 className='pre-confirmation-header'>Age Info</h5>
                                        <div className='row m-0'>
                                            Age is calculated from the date of birth provided in profile. Please go to profile to update your date of birth.
                                        </div>
                                    </div>
                                </div>
                                <div className='row m-0 pt-5'>
                                    <div className="col-12 text-center category-arrows">
                                        <button type="button" style={{ float: "inherit" }} className="btn btn-outline-primary details-button" onClick={openProfile}>Update Profile</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AgeInfoModal;