import React, { useEffect, useState, useCallback, useRef } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import { getProfessionalDetails, getAvailability, bookSlot, notification, getSessions, bookSessionApi, followApi, unFollowApi, markFavouriteProfessional, getWorkshopList } from "../../crud/home.crud";
import { getProfile } from "../../crud/auth.crud";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { paytmUrl } from "../../crud/api.util";
import { toast } from 'react-toastify';
import {
  API_ERROR, REQUIRED_ERROR,
  TIME_PASSED
} from "../../config/error-message";
import {
  timeArrConst,
  Config,
  localGlobalConfigData,
  monthArr,
  convertTwelveToZeroAM
} from "../../config/constants";
import PaymentModal from "./payment-modal";
import GalleryModal from "../admins/gallery-modal";
import ShareToSocialModal from '../admins/shareToSocialModal';
import { API_SUCCESS } from "../../config/success-message";
import CustomCalendar from '../admins/custom-calendar';
import SlotSelector from '../admins/slot-selector';

const defaultImage = require('../../../images/imgAvtr.png');

const timeArr = [...timeArrConst];
const dayName = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const bookingAsList = [{
  value: "1",
  text: "Self"
}, {
  value: "2",
  text: "Parent"
}, {
  value: "3",
  text: "Guardian"
}, {
  value: "6",
  text: "Educator"
}, {
  value: "4",
  text: "Other"
}];
let localGD = {};

// let newlyLoggedIn = false;
const Details = (props) => {

  const { isAuthorized, authData } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
      authData: auth.user
    }),
    shallowEqual
  );
  const [selectedProfile, selectProfile] = useState({});
  const [bookingDetails, updateBookingDetails] = useState({});
  const [displayEditableSlot, toggleEditableSlot] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [serviceTimeHours, updateServiceTimeHours] = useState([]);
  const [selectedServiceTime, updateSelectedServiceTime] = useState("");
  const [selectedBookingAs, updateSelectedBookingAs] = useState(bookingAsList[0].value);
  const [pageInitialized, updatePageInitialization] = useState(false);
  const [disablePage, toggleDisablePage] = useState(false);
  // const [datePickerOpened, toggleDatepickerOpened] = useState(false);
  const [bookingFor, updateBookingFor] = useState("");
  const [bookingForError, updateBookingForError] = useState("");
  const [globalConfigData, updateGlobalConfig] = useState({});
  const [paymentInitialized, togglePaymentInitialization] = useState(false);
  const [openPaymentPopup, togglePaymentPopup] = useState(false);
  const [popupBookingDetails, updatePopupBookingDetails] = useState({});
  const [openGalleryModal, toggleGalleryModal] = useState(false);
  const [currentImageIndex, updateCurrentImageIndex] = useState(-1);
  const [showPrevButtons, togglePrevButton] = useState(false);
  const [showNextButtons, toggleNextButton] = useState(true);
  const [currentTab, updateCurrentTab] = useState("services");
  const [displayEditableSession, toggleEditableSession] = useState(false);
  //session
  const [selectedSessionDetails, updateSessionDetails] = useState({});
  const [openPaymentSessionPopup, togglePaymentSessionPopup] = useState(false);
  const [popupSessionDetails, updatePopupSessionDetails] = useState({});
  const [sessionsList, updateUpcomingSessions] = useState([]);
  const [workshopsList, updateUpcomingWorkshops] = useState([]);
  const [displayShareModal, toggleShareModal] = useState(false);
  const [selectedServiceMode, changeServiceMode] = useState(1);
  const [isCancellationChecked, toggleCancellation] = useState(false);
  const [isLastDay, toggleLastDay] = useState(false);

  const bookingForRef = useRef(null);

  const handleNextPrevButtons = () => {
    if (document.getElementsByClassName("categories-wrapper")[0]) {
      if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
        togglePrevButton(true);
        toggleNextButton(true);

        if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
          togglePrevButton(false);
        }
        if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
          toggleNextButton(false);
        }

      } else {
        togglePrevButton(false);
        toggleNextButton(false);
      }
    }
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        handleNextPrevButtons();
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  const nextClick = () => {
    var container = document.getElementsByClassName('categories-wrapper')[0];
    sideScroll(container, 'right', 25, 100, 10);
  };

  const prevClick = () => {
    var container = document.getElementsByClassName('categories-wrapper')[0];
    sideScroll(container, 'left', 25, 100, 10);
  };

  window.onresize = () => {
    handleNextPrevButtons();
  };

  window.onload = () => {
    handleNextPrevButtons();
  };

  const displayNavs = useCallback((bool) => {
    if (bool) {
      handleNextPrevButtons();
    }
  }, []);

  const getFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const getDisplayedFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const year = date.getFullYear();

    return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
  };

  const getTime = (timeStr, date) => {
    const time = timeStr.slice(0, timeStr.length - 3)
    const amPm = timeStr.slice(timeStr.length - 2)
    let d = new Date();
    if (date) {
      d = new Date(date);
    }
    if (amPm === "AM" && time.split(":")[0] === "12") {
      return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    }
    if (amPm === "PM" && time.split(":")[0] !== "12") {
      return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    }
    return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
  };

  const checkCurrentTime = useCallback((times) => {
    const today = new Date().setHours(new Date().getHours() + ((localGD.bufferSlot ? localGD.bufferSlot : 0) * 0.5));
    return getTime(timeArr[times[0] - 1]) > today;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCurrentTimeWithEndTime = useCallback((times) => {
    const today = new Date().getTime();
    const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
    return getTime(endTime) > today;
  }, []);

  const checkCurrentDay = (date) => {
    const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
    const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
    return today === day;
  };

  const getStartTime = (times) => {
    times.sort((a, b) => { return a - b });
    return convertTwelveToZeroAM(timeArr[times[0] - 1]);
  };

  const getEndTime = (times) => {
    times.sort((a, b) => { return a - b });
    return convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
  };

  useEffect(() => {
    if (authData && authData.personalInfo) {
      updateBookingFor(authData.personalInfo.name);
    }
  }, [authData]);

  const getDuration = (times) => {
    times.sort((a, b) => { return a - b });
    const startTime = timeArr[times[0] - 1];
    const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
    return `${startTime} - ${endTime}`;
  };

  const checkStatusExpired = useCallback((localBookingData) => {
    const today = new Date().getTime();
    const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
    const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
    return (startTime <= today && endTime > today);
  }, []);

  const getAllSlots = (sessionTime, slots, date) => {
    let arr = slots;
    let limit = Number(sessionTime);
    let newArr = [];
    let obj = {};
    for (let i = 0; i < arr.length; i++) {

      if (arr[limit + i - 1]) {
        let arr1 = arr.slice(i, limit + i)
        obj = {};
        if (arr1[arr1.length - 1] - arr1[0] === limit - 1) {
          obj.value = arr1;
          if (checkCurrentDay(date)) {
            if (checkCurrentTime(arr1)) {
              newArr.push(obj);
            }
          } else {
            newArr.push(obj);
          }
        }
      }
    }
    return newArr;
  };

  const bookNow = async (serviceDetails) => {
    if (!isAuthorized) {
      toast.info("Please login to book an appointment");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }

    let convenienceFee = 0;
    let gst = 0;
    const availabilityResult = await getAvailability(serviceDetails._id);
    const localBookingData = { ...serviceDetails };
    if (serviceDetails.serviceType === 2) {
      changeServiceMode(2);
    }
    if (globalConfigData) {
      convenienceFee = globalConfigData.convenienceFee ? globalConfigData.convenienceFee : 0;
      gst = globalConfigData.gstPercentage ?
        (convenienceFee) * (globalConfigData.gstPercentage / 100) : 0;
    }
    localBookingData.convenienceFee = parseFloat(convenienceFee);
    localBookingData.gst = parseFloat(gst);
    localBookingData.total = parseFloat((!localBookingData.discountedPrice ? localBookingData.cost : localBookingData.discountedPrice) + localBookingData.convenienceFee + localBookingData.gst);
    localBookingData.slotDuration = "";
    localBookingData.slotDate = "";
    localBookingData.slots = [];
    let localDateTimeObj = {};
    if (availabilityResult.data.data && availabilityResult.data.data.length) {
      availabilityResult.data.data.forEach(each => {
        let localSlots = getAllSlots(serviceDetails.sessionTime, each.availableSlots, each.date);
        if (localSlots.length) {
          localDateTimeObj = {};
          localDateTimeObj.date = each.date;
          localDateTimeObj.availableSlots = localSlots;
          localBookingData.slots.push({ ...localDateTimeObj });
        }
      });
    }
    if (localBookingData.slots.length) {
      const localAvailableSlots = [];
      if (checkCurrentDay(localBookingData.slots[0].date)) {
        localBookingData.slots[0].availableSlots.forEach(each => {
          if (checkCurrentTime(each.value)) {
            let section = 1;
            if (each.value[0] > 24 && each.value[0] <= 32) {
              section = 2;
            } else if (each.value[0] > 32) {
              section = 3;
            }
            localAvailableSlots.push({
              text: getDuration(each.value),
              value: JSON.stringify(each.value),
              selected: false,
              section
            });
          }
        });
        if (localAvailableSlots.length) {
          localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[0].date));
          localBookingData.slotDuration = getDuration(localBookingData.slots[0].availableSlots[0].value);
          handleDateChange(new Date(localBookingData.slots[0].date));
        }
        if (!localAvailableSlots.length && localBookingData.slots.length > 1) {
          localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[1].date));
          localBookingData.slotDuration = getDuration(localBookingData.slots[1].availableSlots[0].value);
          handleDateChange(new Date(localBookingData.slots[1].date));
          localBookingData.slots[1].availableSlots.forEach(each => {
            let section = 1;
            if (each.value[0] > 24 && each.value[0] <= 32) {
              section = 2;
            } else if (each.value[0] > 32) {
              section = 3;
            }
            localAvailableSlots.push({
              text: getDuration(each.value),
              value: JSON.stringify(each.value),
              selected: false,
              section
            });
          });
        }
      } else {
        localBookingData.slotDate = getFullDateFormat(new Date(localBookingData.slots[0].date));
        localBookingData.slotDuration = getDuration(localBookingData.slots[0].availableSlots[0].value);
        handleDateChange(new Date(localBookingData.slots[0].date));
        localBookingData.slots[0].availableSlots.forEach(each => {
          let section = 1;
          if (each.value[0] > 24 && each.value[0] <= 32) {
            section = 2;
          } else if (each.value[0] > 32) {
            section = 3;
          }
          localAvailableSlots.push({
            text: getDuration(each.value),
            value: JSON.stringify(each.value),
            selected: false,
            section
          });
        });
      }

      console.log("localAvailableSlots ", localAvailableSlots)
      if (localAvailableSlots.length) {
        localAvailableSlots[0].selected = true;
        updateSelectedServiceTime(localAvailableSlots[0].value);
      }
      updateServiceTimeHours([...localAvailableSlots]);


    }
    updateBookingDetails({ ...localBookingData });
    if (localBookingData.slotDate) {
      toggleEditableSlot(true);
      handleDateChange(new Date(localBookingData.slotDate));
    }
    const myScript = document.createElement("script");
    myScript.setAttribute("src", paytmUrl);
    myScript.setAttribute("crossorigin", "anonymous");
    myScript.setAttribute("type", "application/javascript");
    document.body.appendChild(myScript);
  };

  const checkListDisplayTime = useCallback((localBookingData) => {
    const today = new Date().getTime();
    const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);

    const lastDateDay = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getDate();
    const lastDateMonth = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getMonth();
    const lastDateYear = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getFullYear();

    const lastDateEndTime = new Date(new Date(new Date(endTime).setDate(lastDateDay)).setMonth(lastDateMonth)).setFullYear(lastDateYear);

    return (lastDateEndTime > today);
  }, []);

  const getWorkshops = useCallback(async (id, globalData) => {
    let localGlobalData = { ...globalData };
    if (globalConfigData && Object.keys(globalConfigData).length) {
      localGlobalData = globalConfigData;
    }
    toggleDisablePage(true);
    const localDate = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
    const reqBody = {
      todaysDate: localDate
    };
    if (isAuthorized) {
      reqBody.customerRef = authData._id;
    }
    const servicesResult = await getWorkshopList(0, undefined, { vendorRef: id });
    toggleDisablePage(false);
    if (servicesResult.data.errorCode) {
      toast.error(API_ERROR[servicesResult.data.errorCode]);
      return;
    }
    if (servicesResult.data.data && servicesResult.data.data.errorCode) {
      toast.error(API_ERROR[servicesResult.data.data.errorCode]);
      return;
    }
    const localServices = [];
    if (servicesResult.data.data && servicesResult.data.data.data && servicesResult.data.data.data.length) {
      let localSingleService = {};
      let convenienceFee = 0;
      let gst = 0;
      servicesResult.data.data.data.forEach(each => {
        convenienceFee = 0;
        gst = 0;
        localSingleService = { ...each };
        localSingleService.selected = false;
        localSingleService.fee = each.fees;
        localSingleService.totalSeats = each.numberOfSeats;
        localSingleService.meetingPlatform = each.meetingPlatform;
        localSingleService.startDate = each.dates[0];
        localSingleService.endDate = each.dates[each.dates.length - 1];
        localSingleService.dates = each.dates;
        localSingleService.slots = each.slots;
        if (localGlobalData) {
          convenienceFee = localGlobalData.convenienceFee ? localGlobalData.convenienceFee : 0;
          gst = localGlobalData.gstPercentage ?
            (convenienceFee) * (localGlobalData.gstPercentage / 100) : 0;
        }
        localSingleService.startTime = getStartTime(each.slots);
        localSingleService.endTime = getEndTime(each.slots);
        localSingleService.convenienceFee = parseFloat(convenienceFee);
        localSingleService.gst = parseFloat(gst);
        localSingleService.total = parseFloat(localSingleService.fee + localSingleService.convenienceFee + localSingleService.gst);
        localSingleService.profilePicture = each.images[0]?.url;

        if (globalData && globalData.promoCodeGeneral && globalData.promoCodeGeneral.discountPercentageOn && globalData.promoCodeGeneral.discountPercentageOn.workshop && each.vendorRef && each.vendorRef.promoCodeInfo && each.vendorRef.promoCodeInfo.allowDiscount && authData && authData.promoCodeInfo && authData.promoCodeInfo.institutionRef) {
          localSingleService.discountedPrice = localSingleService.fee * ((100 - globalData.promoCodeGeneral.discountPercentageOn.workshop) / 100);
        }

        if (checkListDisplayTime(localSingleService)) {
          localServices.push({ ...localSingleService });
        }

        localSingleService = {};
      });
    }
    updateUpcomingWorkshops([...localServices]);
  }, [isAuthorized, authData, globalConfigData, checkListDisplayTime]);

  const getVendorSessions = useCallback(async (id, globalData) => {
    let localGlobalData = { ...globalData };
    if (globalConfigData && Object.keys(globalConfigData).length) {
      localGlobalData = globalConfigData;
    }
    toggleDisablePage(true);
    const localDate = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
    const reqBody = {
      todaysDate: localDate
    };
    if (isAuthorized) {
      reqBody.customerRef = authData._id;
    }
    const servicesResult = await getSessions(id, reqBody);
    toggleDisablePage(false);
    if (servicesResult.data.errorCode) {
      toast.error(API_ERROR[servicesResult.data.errorCode]);
      return;
    }
    if (servicesResult.data.data && servicesResult.data.data.errorCode) {
      toast.error(API_ERROR[servicesResult.data.data.errorCode]);
      return;
    }
    const localServices = [];
    if (servicesResult.data.data && servicesResult.data.data.length) {
      let localSingleService = {};
      let convenienceFee = 0;
      let gst = 0;
      servicesResult.data.data.forEach(each => {
        convenienceFee = 0;
        gst = 0;
        localSingleService = { ...each };
        localSingleService.selected = false;
        localSingleService.fee = each.eventFees;
        localSingleService.totalSeats = each.numberOfSeats;
        localSingleService.meetingPlatform = each.meetingPlatform;
        if (localGlobalData) {
          convenienceFee = localGlobalData.convenienceFee ? localGlobalData.convenienceFee : 0;
          gst = localGlobalData.gstPercentage ?
            (convenienceFee) * (localGlobalData.gstPercentage / 100) : 0;
        }
        localSingleService.startTime = getStartTime(each.slots);
        localSingleService.endTime = getEndTime(each.slots);
        localSingleService.convenienceFee = parseFloat(convenienceFee);
        localSingleService.gst = parseFloat(gst);
        localSingleService.total = parseFloat(localSingleService.fee + localSingleService.convenienceFee + localSingleService.gst);
        if (checkCurrentDay(localSingleService.date)) {
          if (checkCurrentTimeWithEndTime(localSingleService.slots)) {
            localSingleService.isExpired = checkStatusExpired(localSingleService);
            localServices.push({ ...localSingleService });
          }
        } else {
          localSingleService.isExpired = false;
          localServices.push({ ...localSingleService });
        }

        localSingleService = {};
      });
    }
    updateUpcomingSessions([...localServices]);
  }, [isAuthorized, authData, globalConfigData, checkCurrentTimeWithEndTime, checkStatusExpired]);

  const getProfessionalData = useCallback(async (id) => {
    const reqBody = {};
    if (isAuthorized) {
      reqBody.customerRef = authData._id;
    }
    toggleDisablePage(true);
    const globalConfigResult = await notification();
    if (globalConfigResult.data.data) {
      updateGlobalConfig({ ...globalConfigResult.data.data });
      localGD = { ...globalConfigResult.data.data };
    }
    const professionalResult = await getProfessionalDetails(id, reqBody);
    toggleDisablePage(false);
    if (professionalResult.data.errorCode) {
      toast.error(API_ERROR[professionalResult.data.errorCode]);
      return;
    }
    if (professionalResult.data.data && professionalResult.data.data.errorCode) {
      toast.error(API_ERROR[professionalResult.data.data.errorCode]);
      return;
    }
    const resultData = professionalResult.data.data;

    const localProfessionalObj = {
      name: resultData.vendorData.personalInfo.name,
      profilePicture: resultData.vendorData.personalInfo.profilePicture,
      description: resultData.vendorData.personalInfo.description,
      designation: "",
      _id: resultData.vendorData._id,
      experience: "",
      email: resultData.vendorData.personalInfo.email,
      websiteUrl: resultData.vendorData.personalInfo.website,
      rating: resultData.vendorData.ratingDetails && resultData.vendorData.ratingDetails.average ? resultData.vendorData.ratingDetails.average.toFixed(1) : 0,
      descriptionImages: [],
      followedBy: resultData.vendorData.followedBy ? resultData.vendorData.followedBy.length : 0,
      services: [],
      following: resultData.vendorData.following,
      isOnline: resultData.vendorData.personalInfo.isOnline ? resultData.vendorData.personalInfo.isOnline : false,
      isFavourite: authData && resultData.vendorData.favouritedBy && resultData.vendorData.favouritedBy.includes(authData._id) ? true : false,
      isPromoAllowed: resultData.vendorData.promoCodeInfo && resultData.vendorData.promoCodeInfo.allowDiscount ? resultData.vendorData.promoCodeInfo.allowDiscount : false
    }
    if (resultData.vendorData.personalInfo.experience.year) {
      const year = resultData.vendorData.personalInfo.experience.year;
      if (year) {
        localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
      }
    }
    if (resultData.vendorData.photos && resultData.vendorData.photos.length) {
      resultData.vendorData.photos.forEach(each => {
        localProfessionalObj.descriptionImages.push(each.url);
      });
    }
    if (resultData.serviceList && resultData.serviceList.length) {
      let eachService = {};
      resultData.serviceList.forEach(each => {
        if (each.status === Config.Service.Status.Approved) {
          eachService = {
            ...each,
            name: each.title,
            description: each.description,
            addressTitle: each.addressTitle,
            _id: each._id,
            cost: parseFloat(each.fees),
            location: each.location,
            days: ""
          };
          if (each.daysOfWeek && each.daysOfWeek.length) {
            each.daysOfWeek.forEach((item, index) => {
              eachService.days += dayName[item - 1];
              if (index !== each.daysOfWeek.length - 1) {
                eachService.days += ", ";
              }
            });
          }
          if (globalConfigResult.data.data && globalConfigResult.data.data.promoCodeGeneral && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.appointment && resultData && resultData.vendorData && resultData.vendorData.promoCodeInfo && resultData.vendorData.promoCodeInfo.allowDiscount && authData && authData.promoCodeInfo && authData.promoCodeInfo.institutionRef) {
            eachService.discountedPrice = eachService.cost * ((100 - globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.appointment) / 100);
          }
          localProfessionalObj.services.push({
            ...eachService
          });
        }
      });
    }
    if (localProfessionalObj.services.length) {
      localProfessionalObj.addressTitle = localProfessionalObj.services[0].addressTitle;
    }

    if (resultData.vendorData.personalInfo.categories && resultData.vendorData.personalInfo.categories.length) {
      resultData.vendorData.personalInfo.categories.forEach((item, index) => {
        localProfessionalObj.designation += item.title;
        if (index !== resultData.vendorData.personalInfo.categories.length - 1) {
          localProfessionalObj.designation += ", ";
        }
      });
    }
    let localName = localProfessionalObj.name.split("- ").join("");
    if (localProfessionalObj.name.includes("eMindCafe")) {
      localName = "eMindCafe";
    }
    document.title = id !== "653f750ba5adf7a3817d313b" ? localProfessionalObj.addressTitle ? `${localName} Counsellor, Psychologist in ${localProfessionalObj.addressTitle}` : `${localName} Counsellor, Psychologist` : "Naincy Priya Counsellor, Psychologist in New Delhi";
    selectProfile({ ...localProfessionalObj });

    setTimeout(() => {
      displayNavs(true);
    }, 1000);

    setTimeout(() => {
      getVendorSessions(props.match.params.id, { ...globalConfigResult.data.data });
      getWorkshops(props.match.params.id, { ...globalConfigResult.data.data });
    }, 1000);
  }, [displayNavs, getVendorSessions, getWorkshops, props.match.params, authData, isAuthorized]);

  useEffect(() => {
    if (!pageInitialized) {
      updatePageInitialization(true);
      if (props.match.params.id) {
        getProfessionalData(props.match.params.id);
      }
    }
  }, [props.match.params, pageInitialized, getProfessionalData]);

  const selectBookingAs = (e) => {
    updateSelectedBookingAs(e.target.value);
    if (e.target.value === "1") {
      if (authData && authData.personalInfo) {
        updateBookingFor(authData.personalInfo.name);
      }
    } else {
      updateBookingFor("");
    }
    updateBookingForError("");
  };

  useEffect(() => {
    if (selectedBookingAs !== "1" && bookingForRef && bookingForRef.current) {
      bookingForRef.current.focus();
    }
  }, [selectedBookingAs]);

  const openMapLocation = () => {
    const coordinates = selectedProfile.services[0].location.coordinates;
    window.open(`https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`);
  };

  const changeDate = (date) => {
    handleDateChange(new Date(date));
    const localBookingData = { ...bookingDetails };
    const localAvailableSlots = [];
    localBookingData.slots.forEach(each => {
      if (getFullDateFormat(new Date(each.date)) === getFullDateFormat(new Date(date))) {
        if (each.availableSlots.length) {
          each.availableSlots.forEach(each1 => {
            let section = 1;
            if (each1.value[0] > 24 && each1.value[0] <= 32) {
              section = 2;
            } else if (each1.value[0] > 32) {
              section = 3;
            }
            localAvailableSlots.push({
              text: getDuration(each1.value),
              value: JSON.stringify(each1.value),
              selected: false,
              section
            });
          });
        }

      }
    });

    if (localAvailableSlots.length) {
      localAvailableSlots[0].selected = true;
      updateSelectedServiceTime(localAvailableSlots[0].value);
    }
    updateServiceTimeHours([...localAvailableSlots]);

    if (bookingDetails.slots && bookingDetails.slots.length) {
      const currentDay = new Date(new Date(new Date(new Date(new Date(date).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
      if (new Date(bookingDetails.slots[bookingDetails.slots.length - 1].date) <= new Date(currentDay)) {
        toggleLastDay(true);
      } else {
        toggleLastDay(false);
      }
    }

  };

  const validateBookingFor = () => {
    let noError = true;
    if (!bookingFor.trim().length) {
      updateBookingForError(REQUIRED_ERROR);
      noError = false;
    }
    return noError;
  };

  const initiatePayment = (orderId, token, amount) => {
    var config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
        "orderId": orderId, /* update order id */
        "token": token, /* update token value */
        "tokenType": "TXN_TOKEN",
        "amount": amount.toString() /* update amount */
      },
      "handler": {
        "notifyMerchant": function (eventName, data) {
          if (eventName === "APP_CLOSED") {
            if (currentTab === "services") {
              togglePaymentPopup(true);
            } else {
              togglePaymentSessionPopup(true);
            }
          }
        }
      }
    };
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
        // after successfully updating configuration, invoke JS Checkout
        togglePaymentInitialization(false);
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
      });
    }
  }

  const payNow = async (paymentData) => {

    if (authData && !authData.emailVerified) {
      const profileResult = await getProfile();
      if (profileResult.data.data && !profileResult.data.data.emailVerified) {
        toast.error("Please verify your email from profile");
        return;
      }
    }

    let requestBody = {};
    if (!paymentData) {
      if (!validateBookingFor()) {
        return;
      }
      if (checkCurrentDay(selectedDate)) {
        if (!checkCurrentTime(JSON.parse(selectedServiceTime))) {
          toast.error(TIME_PASSED);
          return;
        }
      }
      requestBody = {
        bookingForName: bookingFor,
        bookingForType: Number(selectedBookingAs),
        date: new Date(new Date(new Date(new Date(new Date(getFullDateFormat(new Date(selectedDate))).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString(),
        slots: JSON.parse(selectedServiceTime),
        appointmentBookingType: Number(selectedServiceMode)
      };
    } else {
      requestBody = { ...paymentData };
    }

    togglePaymentInitialization(true);
    // initiatePayment("ORD-60a7a38ed8501ecbe8153336-1621599118809", "e4e63a8798404b13b969fd9aeaca6a821621599119840", "35.4");
    // return;

    toggleDisablePage(true);
    const bookSlotResult = await bookSlot(bookingDetails._id, requestBody);
    toggleDisablePage(false);
    if (bookSlotResult.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }
    if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }
    updatePopupBookingDetails({
      bookingForName: bookSlotResult.data.data.bookingForName,
      bookingForType: bookSlotResult.data.data.bookingForType,
      date: bookSlotResult.data.data.date,
      slots: bookSlotResult.data.data.slots,
      bookingId: bookSlotResult.data.data._id
    });
    const paytmToken = bookSlotResult.data.data.paytmTxnToken;
    const paytmOrderId = bookSlotResult.data.data.orderId;
    const paytmAmount = bookSlotResult.data.data.paymentDetails.total;
    initiatePayment(paytmOrderId, paytmToken, paytmAmount);
    // toast.success(API_SUCCESS.BOOKING_SUCCESSFUL);
    // props.history.push('/booking-list');
  };

  const payForSession = async (paymentData) => {
    let requestBody = {};
    if (!paymentData) {
      requestBody = {
        bookingForName: bookingFor,
        bookingForType: Number(selectedBookingAs),
      };
    } else {
      requestBody = { ...paymentData };
    }

    togglePaymentInitialization(true);

    toggleDisablePage(true);
    const bookSlotResult = await bookSessionApi(selectedSessionDetails._id, requestBody);
    toggleDisablePage(false);
    if (bookSlotResult.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }
    if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }
    updatePopupSessionDetails({
      bookingForName: bookSlotResult.data.data.bookingForName,
      bookingForType: bookSlotResult.data.data.bookingForType,
      bookingId: bookSlotResult.data.data._id
    });
    const paytmToken = bookSlotResult.data.data.paytmTxnToken;
    const paytmOrderId = bookSlotResult.data.data.orderId;
    const paytmAmount = bookSlotResult.data.data.paymentDetails.total;
    initiatePayment(paytmOrderId, paytmToken, paytmAmount);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const cancelPayment = () => {
    togglePaymentPopup(false);
    togglePaymentSessionPopup(false);
    // props.history.push("/book-now");
    window.location.reload();
  };

  const chooseGalleryImage = (index) => {
    updateCurrentImageIndex(index);
    toggleGalleryModal(true);
  };

  const bookSession = (session) => {
    if (!isAuthorized) {
      toast.info("Please login to book a session");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }

    toggleEditableSession(true);
    updateSessionDetails({ ...session });
    const myScript = document.createElement("script");
    myScript.setAttribute("src", paytmUrl);
    myScript.setAttribute("crossorigin", "anonymous");
    myScript.setAttribute("type", "application/javascript");
    document.body.appendChild(myScript);
  };

  const clickSubscribe = async (isSubscribed) => {
    if (!isAuthorized) {
      toast.info("Please login to follow the professional");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }
    let followResult;
    toggleDisablePage(true);
    if (isSubscribed) {
      followResult = await followApi(props.match.params.id);
    } else {
      followResult = await unFollowApi(props.match.params.id);
    }
    toggleDisablePage(false);
    if (followResult.data.errorCode) {
      toast.error(API_ERROR[followResult.data.errorCode]);
      return;
    }
    if (followResult.data.data && followResult.data.data.errorCode) {
      toast.error(API_ERROR[followResult.data.data.errorCode]);
      return;
    }

    toast.success(isSubscribed ? API_SUCCESS.FOLLOW_SUCCESS : API_SUCCESS.UNFOLLOW_SUCCESS);
    const localProfile = { ...selectedProfile };
    localProfile.following = isSubscribed;
    localProfile.followedBy = isSubscribed ? localProfile.followedBy + 1 : localProfile.followedBy - 1;
    selectProfile({ ...localProfile });

  };

  const clickFav = async () => {
    if (!isAuthorized) {
      toast.info("Please login to make the professional favourite");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }
    const localProf = { ...selectedProfile };
    toggleDisablePage(true);
    let favProbResult = await markFavouriteProfessional(props.match.params.id, { favourite: !localProf.isFavourite });
    toggleDisablePage(false);
    if (favProbResult.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.errorCode]);
      return;
    }
    if (favProbResult.data.data && favProbResult.data.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.data.errorCode]);
      return;
    }
    toast.success(!localProf.isFavourite ? API_SUCCESS.FAVOURITE_SUCCESS : API_SUCCESS.UNFAVOURITE_SUCCESS);
    localProf.isFavourite = !localProf.isFavourite;
    selectProfile({ ...localProf });
  };

  const chooseSlot = (timeSlot) => {
    updateSelectedServiceTime(timeSlot.value);
    const localSlots = [...serviceTimeHours];
    localSlots.forEach((each) => {
      if (timeSlot.text !== each.text) {
        each.selected = false;
      } else {
        each.selected = true;
      }
    });
    updateServiceTimeHours([...localSlots]);
  };

  const getNextAvailableDate = () => {
    const currentDay = new Date(new Date(new Date(new Date(new Date(selectedDate).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
    let returnDate = "";
    if (bookingDetails.slots && bookingDetails.slots.length) {
      const nextDay = bookingDetails.slots.find(each => new Date(each.date) > new Date(currentDay));
      if (nextDay) {
        returnDate = getDisplayedFullDateFormat(new Date(nextDay.date));
      }
    }
    return returnDate;
  }

  const openNextAvailableDay = () => {
    const currentDay = new Date(new Date(new Date(new Date(new Date(selectedDate).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
    if (bookingDetails.slots && bookingDetails.slots.length) {
      const nextDay = bookingDetails.slots.find(each => new Date(each.date) > new Date(currentDay));
      if (nextDay) {
        changeDate(nextDay.date);
      }
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {Object.keys(selectedProfile).length ?
        <ShareToSocialModal displayShareModal={displayShareModal}
          toggleShareModal={toggleShareModal} bookingDetails={selectedProfile} shareType="profile" /> : null}
      <div className={`row dashboard ${disablePage || paymentInitialized ? "pointer-none" : ""}`} style={{ margin: 0 }}>
        {selectedProfile.name ?
          <div className="col-lg-12">
            <div className="col-9 categories-heading display-inline-block">
              <h4 className={`mb-0`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Professional's Profile</h4>
            </div>
            <div className="col-3 display-inline-block text-right pl-0 pr-4">
              {selectedProfile.isFavourite ?
                <img src={require('../../../images/loved.png')} className="cursor-pointer profile-fav-share-image" alt="sort down" onClick={e => { e.stopPropagation(); clickFav() }} />
                : <img src={require('../../../images/love.png')} className="cursor-pointer profile-fav-share-image" alt="sort down" onClick={e => { e.stopPropagation(); clickFav() }} />}
            </div>
            <div className="col-12 p-0">
              <div className="row profile-details-wrapper user-profile-details-wrapper" style={{ margin: 0 }}>
                <div className="col-12 p-0">
                  <div className="row" style={{ margin: 0 }}>
                    <div className="profile-image-wrapper p-0">
                      {selectedProfile.profilePicture ?
                        <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className={`profile-image ${selectedProfile.isPromoAllowed ? 'promo-border-professional' : ''}`} /> :
                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className={`profile-image ${selectedProfile.isPromoAllowed ? 'promo-border-professional' : ''}`} />}
                      {selectedProfile.following ? <button type="button" className="btn btn-primary big-book-now-button" onClick={() => clickSubscribe(false)}>Linked ({selectedProfile.followedBy > 999 ? '999+' : selectedProfile.followedBy})</button> :
                        <button type="button" className="btn btn-default subscribe-default-button big-book-now-button" onClick={() => clickSubscribe(true)}>Link ({selectedProfile.followedBy > 999 ? '999+' : selectedProfile.followedBy})</button>}
                    </div>

                    <div className="profile-other-details profile-name-wrapper">
                      <div className="profile-name col-12 p-0">
                        <div className="row m-0">
                          <div className="col-sm-10 col-xs-12 p-0">
                            {selectedProfile.name} {selectedProfile.rating ? <span className="profile-rating"><i className={`ml-3 fa-star fas`}></i> {selectedProfile.rating} </span> : null}
                          </div>
                          <div className="col-sm-2 col-xs-12 pr-0 text-right">
                            <img src={require('../../../images/profile-share.png')} className="cursor-pointer" style={{ width: "60px" }} alt="share" onClick={() => toggleShareModal(true)} />
                          </div>
                        </div>
                      </div>
                      <div className="profile-designation">{selectedProfile.designation}</div>
                      <div className="profile-exp profile-user-details-side-by-side bor-right pl-0">Experience - {selectedProfile.experience}</div>
                      <div className="profile-icon-wrapper profile-user-details-side-by-side bor-right pr-0">
                        {/* {selectedProfile.email ?
                                                    <div className="tooltip">
                                                        <i className="fa fa-envelope" aria-hidden="true" onClick={copyEmailToClipboard} onMouseLeave={emailMouseOut}>

                                                        </i>
                                                        <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                                    </div> : null} */}
                        {selectedProfile.websiteUrl ?
                          <img src={require('../../../images/globe.svg')} alt="website" onClick={() => window.open(selectedProfile.websiteUrl)} /> : null}
                        {selectedProfile && selectedProfile.services && selectedProfile.services.length ?
                          <img src={require('../../../images/location.svg')} alt="location" onClick={openMapLocation} /> : null}
                      </div>
                      <div className="profile-address-title profile-user-details-side-by-side">{selectedProfile.addressTitle}</div>
                      {selectedProfile.description && selectedProfile.description.trim().length ?
                        <>
                          <div className="profile-description-header pb-2 pt-3">
                            Short Bio
                          </div>
                          <div className="profile-description-text">
                            {selectedProfile.description.split('\n').map((item, key) => {
                              return <span key={key}>{item}<br /></span>
                            })}
                          </div> </> : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 p-0">
                  {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                    <div className="row gallery-wrapper ml-0 mr-0">
                      <h4 className="col-7 pl-0 pt-2">Gallery</h4>
                      {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                        <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                          <button className="prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                            <i className="fas fa-arrow-left"></i>
                          </button>
                          <button className="next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                            <i className="fas fa-arrow-right"></i>
                          </button>
                        </div> : null}
                      <div className={`categories-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                        {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                          selectedProfile.descriptionImages.map((image, index) => {
                            return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => chooseGalleryImage(index)}>
                              <div className="profile-image description-image" style={{ backgroundImage: "url(" + image + ")" }} />
                            </div>
                          })
                          : <div className="text-center well col-12 color-black">
                            No Images Available!
                          </div>}
                      </div>
                    </div> : null}
                  <div className="row m-0 services-list-wrapper ">
                    <div className="col-12 pl-0 upcoming-past-button-wrapper pt-3">
                      <button onClick={() => updateCurrentTab("services")} className={`btn btn-primary services-sessions-button ${currentTab === 'services' ? 'active' : ''}`}>Individual Sessions</button>
                      {/* <button onClick={() => updateCurrentTab("sessions")} className={`btn btn-primary services-sessions-button ${currentTab === 'sessions' ? 'active' : ''}`}>Group Sessions</button> */}
                      {localGlobalConfigData.showWorkshop ?
                        <button onClick={() => updateCurrentTab("workshops")} className={`btn btn-primary services-sessions-button ${currentTab === 'workshops' ? 'active' : ''}`}>Workshops</button> : null}
                    </div>
                    <div className="col-sm-8 col-xs-12 pl-0 mb-3"></div>
                    {/* {currentTab === 'services' ?
                                            <h4 className="col-sm-8 col-xs-12 pl-0">{!displayEditableSlot ? 'Services offered' : 'Book Appointment'}</h4> :
                                            currentTab === 'sessions' ?
                                                <h4 className="col-sm-8 col-xs-12 pl-0">{!displayEditableSlot ? 'Sessions' : 'Book Session'}</h4> :
                                                <h4 className="col-sm-8 col-xs-12 pl-0">Workshops</h4>} */}
                    {currentTab === 'services' ?
                      !displayEditableSlot ?
                        selectedProfile.services && selectedProfile.services.length ?
                          selectedProfile.services.map((service, index) => {
                            return <div className={`col-12 professional-wrapper service-wrapper
                                                    `} key={index}>
                              <div className={`row ${index === selectedProfile.services.length - 1 ? 'pb-20' : ''} ${index === 0 ? 'bor-rad-top' : ''} ${index === selectedProfile.services.length - 1 ? 'bor-rad-bot' : ''}`} style={{ margin: 0 }}>
                                <div className='col-md-8 p-0'>
                                  <div className='row m-0 p-0'>
                                    <div className="col-12">
                                      <div className="service-list-name">{service.name} </div>
                                    </div>
                                    <div className="col-12" style={{ margin: "5px 0" }}>
                                      <div className="service-name">{service.description}</div>
                                    </div>
                                    <div className="col-12" style={{ margin: "0 0 5px" }}>
                                      <div style={{ fontSize: "14px", color: "#000" }}>Location: {service.addressTitle}</div>
                                    </div>
                                    <div className="col-12 show-less-768" style={{ margin: "0 0 5px", color: "#000", fontWeight: 400, fontSize: "14px" }}>
                                      Days: {service.days}
                                    </div>
                                    <div className="col-12 show-less-768" style={{ margin: "0 0 5px" }}>
                                      <b>({service.serviceType === 2 ? "Clinic Visit" : service.serviceType === 3 ? "Video Consultation/ Clinic Visit" : "Video Consultation"})</b>
                                    </div>
                                    <div className="col-12">
                                      <div className="service-cost"><i className="fas fa-rupee-sign"></i><span className={`${service.discountedPrice ? 'text-strikethrough' : ''}`}>{service.cost.toFixed(2)}</span></div>
                                      {service.discountedPrice ? <div className="service-cost ml-2"> <i className="fas fa-rupee-sign"></i>{service.discountedPrice.toFixed(2)}</div> : null}
                                      {selectedProfile.isOnline ?
                                        <button type="button" className="btn btn-primary small-book-now-button" onClick={() => bookNow(service)}>Book Appointment</button> : null}
                                    </div>
                                  </div>
                                </div>
                                <div className='col-md-4 pr-0 hide-less-768 desktop-view bor-left-1'>
                                  <div className='row m-0 p-0'>
                                    <div className='col-12 pb-3'>
                                      <div className="service-list-name">Availability </div>
                                    </div>
                                    <div className="col-12">
                                      {service.days.split(", ").map((eachDay) => {
                                        return <img width="30px" style={{ marginRight: "12px", marginBottom: "12px" }} key={eachDay} src={require(`../../../images/${eachDay.toLowerCase()}.png`)} alt={eachDay} />
                                      })}
                                    </div>
                                    <div className="col-12" style={{ margin: "0 0 5px" }}>
                                      {service.serviceType === 1 || service.serviceType === 3 ? <span className="badge badge-pill badge-secondary mr-2"><i className="fa fa-video mr-1"></i> Video Consultation</span> : null}
                                      {service.serviceType === 2 || service.serviceType === 3 ? <span className="badge badge-pill badge-secondary"><i className="fa fa-building mr-1"></i> Clinic Visit</span> : null}
                                    </div>
                                  </div>
                                </div>
                                {index !== selectedProfile.services.length - 1 ?
                                  <hr style={{ width: "100%", margin: "20px 0 0" }} /> : null}
                              </div>
                            </div>
                          })
                          : <div className="text-center well col-12 color-black">
                            No Services Available!
                          </div> :
                        <div className="col-12 slot-wrapper" style={{ margin: "0 auto" }}>
                          
                          {bookingDetails.slotDate ? <div className="row m-0">
                          {displayEditableSlot && currentTab === 'services' ?
                              <div className="col-12 pl-0 pr-0 pb-1 text-right show-less-575 back-button-wrapper">
                                <button type="button" className="btn btn-outline-primary" onClick={() => toggleEditableSlot(false)}>Back to service list</button>
                               </div> 
                              : null}
                            <div className={`${displayEditableSlot && currentTab === 'services' ? "col-sm-8 col-xs-12 pl-0" : "col-12 p-0"}`}>
                              <div className='row m-0'>
                                <div className="col-12 p-0">
                                  <div className="service-list-name">{bookingDetails.name}</div>
                                </div>
                                <div className="col-12 p-0" style={{ margin: "5px 0" }}>
                                  <div className="service-name">{bookingDetails.description}</div>
                                </div>
                              </div>
                            </div>
                            {displayEditableSlot && currentTab === 'services' ?
                              <div className="col-4 pr-0 text-right hide-less-575 back-button-wrapper">
                                <button type="button" className="btn btn-outline-primary" onClick={() => toggleEditableSlot(false)}>Back to service list</button>
                               </div> 
                              : null}
                            
                            <h6 className="col-12 p-0 mb-3">Fill up the following form to complete booking</h6>
                            
                            <div className='col-12 p-0'>
                              <CustomCalendar
                                changeDate={changeDate}
                                selectedDate={selectedDate}
                                openNextAvailableDay={openNextAvailableDay}
                              />
                              <div className="row ml-0 mr-0 slot-selector">
                                {serviceTimeHours.length ?
                                  <>
                                    <SlotSelector serviceTimeHours={serviceTimeHours} chooseSlot={chooseSlot} />
                                    {/* <hr className='w-100' /> */}
                                    {bookingDetails.serviceType === 3 ?
                                      <div className="col-md-6 col-xs-12 mb-3 appointment-type">
                                        <label htmlFor="exampleFormControlInput1" className="form-label fee-label all-label">Appointment type</label>
                                        <br />
                                        <span className={`badge badge-pill badge-${selectedServiceMode === 1 ? 'primary': 'secondary'} mr-2`} onClick={() => changeServiceMode(1)}><i className="fa fa-video mr-1"></i> Video Consultation</span>
                                        <span className={`badge badge-pill badge-${selectedServiceMode === 2 ? 'primary': 'secondary'}`} onClick={() => changeServiceMode(2)}><i className="fa fa-building mr-1"></i> Clinic Visit</span>
                                        {/* <div className="form-check" style={{
                                          display: "inline-block"
                                        }}>
                                          <input className="form-check-input" type="radio" name="serviceMode" id="serviceMode" checked={selectedServiceMode === 1} onChange={() => changeServiceMode(1)} />
                                          <label className="form-check-label" for="serviceMode">
                                            Video Consultation
                                          </label>
                                        </div>
                                        <div className="form-check" style={{
                                          display: "inline-block",
                                          marginLeft: "15px"
                                        }}>
                                          <input className="form-check-input" type="radio" name="serviceMode" id="serviceMode" checked={selectedServiceMode === 2} onChange={() => changeServiceMode(2)} />
                                          <label className="form-check-label" for="serviceMode">
                                            Clinic Visit
                                          </label>
                                        </div> */}
                                      </div>
                                       : null}
                                    <div className={`col-md-6 col-xs-12 mb-3 ${bookingDetails.serviceType !== 3 ? 'm-0-auto' : ''}`}>
                                      <div className='row m-0'>
                                        <div className="col-sm-6 pl-0 mb-3">
                                          <label htmlFor="exampleFormControlInput1" className="form-label fee-label all-label">Booking as</label>
                                          <select className="form-select form-control" aria-label="Service Time" value={selectedBookingAs} onChange={selectBookingAs}>
                                            {bookingAsList.map((loc, index) => {
                                              return <option key={index} value={loc.value}>{loc.text}</option>
                                            })}
                                          </select>

                                        </div>
                                        <div className="col-sm-6 pl-0 mb-3">
                                          <label htmlFor="exampleFormControlInput1" className="form-label fee-label all-label">Booking for</label>
                                          <input ref={bookingForRef} type="text" className={`form-control ${bookingForError ? 'error-border' : ''}`} value={bookingFor} onChange={e => { updateBookingForError(""); updateBookingFor(e.target.value) }} disabled={selectedBookingAs === "1"} />
                                          {bookingForError ?
                                            <div className="input-error">{bookingForError}</div> : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-100 col-12 p-0 text-center mt-2">
                                      <div className="fee-text bor-right pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{!bookingDetails.discountedPrice ? bookingDetails.cost.toFixed(2) : bookingDetails.discountedPrice.toFixed(2)}</div>
                                      <div className="fee-text bor-right">Convenience Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                                      <div className="fee-text">GST: <i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                                    </div>
                                    <div className="profile-amount col-12 p-0 mt-3 text-center">
                                      Total Amount: <i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}
                                    </div>
                                    <div className="col-12 text-center mt-2">
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => toggleCancellation(!isCancellationChecked)} />
                                        <label className="form-check-label mr-1" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                          I have read the <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='anchor-link text-underline'>Cancellation Policy</a>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 booking-details-change-button pay-now-button">
                                      <button disabled={!isCancellationChecked} type="button" className="btn btn-primary" onClick={!paymentInitialized ? () => payNow() : () => { }}>{paymentInitialized ? 'Initialize payment...' : 'Pay Now'}</button>
                                    </div>
                                     </> :
                                  <div className="text-center well col-12 color-black">
                                    <div className='no-data-found'>
                                    <img src={require("../../../images/no-data.png")} alt="no data" />
                                    <br />
                                    <div className='no-data-header'>No Slot Available</div>
                                    {!isLastDay ?
                                    <button className='btn btn-primary no-data-button' onClick={openNextAvailableDay}>Next Available on {getNextAvailableDate()}</button> : null}
                                </div>
                                  </div>}
                              </div>
                            </div>
                            {/* <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label mb-0 display-block">Select date</label>
                              <DatePicker value={selectedDate} onChange={changeDate} format="dd-MM-yyyy" minDate={new Date()} shouldDisableDate={disableRandomDates} onOpen={() => toggleDatepickerOpened(true)} onClose={() => toggleDatepickerOpened(false)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">Select Time</label>
                              {serviceTimeHours.length ?
                                <select className="form-select form-control" aria-label="Service Time" value={selectedServiceTime} onChange={selectServiceTime}>
                                  {serviceTimeHours.map((loc, index) => {
                                    return <option key={index} value={loc.value}>{loc.text}</option>
                                  })}
                                </select> : <div className="text-center well col-12 color-black pt-3">
                                  No Slots Available!
                                </div>}
                            </div> */}

                          </div> : null}

                        </div> :
                      currentTab === 'sessions' ?
                        !displayEditableSession ?
                          sessionsList && sessionsList.length ?
                            sessionsList.map((service, index) => {
                              return <div className={`col-12 professional-wrapper service-wrapper
                                            `} key={index}>
                                <div className={`row ${index === sessionsList.length - 1 ? 'pb-20' : ''} ${index === 0 ? 'bor-rad-top' : ''} ${index === sessionsList.length - 1 ? 'bor-rad-bot' : ''}`} style={{ margin: 0 }}>
                                  <div className="col-12">
                                    <div className="service-list-name">{service.title}</div>
                                  </div>
                                  <div className="col-12" style={{ margin: "5px 0" }}>
                                    <div className="service-name">{service.description}</div>
                                  </div>
                                  <div className="col-12">
                                    <div className="profile-date-time row ml-0 mr-0 pt-2 pl-0">
                                      <div className="col-sm-6 col-md-4 col-xs-12 pl-0">
                                        <div className="label">Date</div>
                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayedFullDateFormat(new Date(service.date))}</div>
                                      </div>
                                      <div className="col-sm-6 col-md-4 col-xs-12 pl-0 time-slot-less-575">
                                        <div className="label">Time</div>
                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {getDuration(service.slots)}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-100 col-12 p-0">
                                    <div className="fee-text mt-3">Meeting Platform: {service.meetingPlatform}</div>
                                  </div>
                                  <div className="w-100 col-12 p-0">
                                    <div className="fee-text seat-count bor-right mt-3">Total Seats: {service.totalSeats}</div>
                                    <div className="fee-text seat-count mt-3">Available Seats: {service.availableSeats}</div>
                                  </div>
                                  <div className="w-100 col-12 p-0">
                                    <div className="fee-text bor-right mt-3">Service Fee: <i className="fas fa-rupee-sign"></i>{service.fee.toFixed(2)}</div>
                                    <div className="fee-text bor-right mt-3">Convenience Fee: <i className="fas fa-rupee-sign"></i>{service.convenienceFee.toFixed(2)}</div>
                                    <div className="fee-text bor-right mt-3">GST: <i className="fas fa-rupee-sign"></i>{service.gst.toFixed(2)}</div>
                                    <div className="fee-text service-cost mt-3">Total: <i className="fas fa-rupee-sign"></i>{service.total.toFixed(2)}</div>
                                    {!service.isExpired ?
                                      <button type="button" disabled={service.availableSeats === 0 || service.isBooked} className="btn btn-primary small-book-now-button float-right mt-2 hide-less-575" onClick={() => bookSession(service)}>{!service.isBooked ? service.availableSeats !== 0 ? 'Book Session' : 'Sold Out' : 'Already Booked'}</button> : null}
                                  </div>
                                  {service.isExpired ?
                                    <div className="w-100 col-12 p-0">
                                      <div className="col-12 fee-text seat-count mt-2">
                                        Session Expired
                                      </div></div> : null}
                                  {!service.isExpired ?
                                    <div className="col-12 p-0 mt-2 text-center">
                                      <button type="button" disabled={service.availableSeats === 0 || service.isBooked} className="btn btn-primary small-book-now-button show-less-575" style={{ margin: "0 auto" }} onClick={() => bookSession(service)}>{!service.isBooked ? service.availableSeats !== 0 ? 'Book Session' : 'Sold Out' : 'Already Booked'}</button>
                                    </div> : null}
                                  {index !== sessionsList.length - 1 ?
                                    <hr style={{ width: "100%", margin: "20px 0 0" }} /> : null}
                                </div>
                              </div>
                            })
                            : <div className="text-center well col-12 color-black">
                              No Sessions Available!
                            </div>
                          :
                          <div className="col-md-6 col-xs-12 slot-wrapper" style={{ margin: "0 auto" }}>
                            <div className="row m-0">
                              <div className="col-12 p-0">
                                <div className="service-list-name">{selectedSessionDetails.title}</div>
                              </div>
                              <div className="col-12 p-0" style={{ margin: "5px 0" }}>
                                <div className="service-name">{selectedSessionDetails.description}</div>
                              </div>
                              <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label display-block">Selected Date</label>
                                <input type="text" className={`form-control`} value={getDisplayedFullDateFormat(new Date(selectedSessionDetails.date))} disabled />
                              </div>
                              <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Selected Time</label>
                                <input type="text" className={`form-control`} value={getDuration(selectedSessionDetails.slots)} disabled />
                              </div>
                              <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label fee-label">Booking as</label>
                                <input type="text" className={`form-control`} value={"Self"} disabled />

                              </div>
                              <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label fee-label">Booking for</label>
                                <input type="text" className={`form-control`} value={bookingFor} disabled />
                              </div>
                              <div className="w-100 col-12 p-0 text-center mt-2">
                                <div className="fee-text bor-right pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{selectedSessionDetails.fee.toFixed(2)}</div>
                                <div className="fee-text bor-right">Convenience Fee: <i className="fas fa-rupee-sign"></i>{selectedSessionDetails.convenienceFee.toFixed(2)}</div>
                                <div className="fee-text">GST: <i className="fas fa-rupee-sign"></i>{selectedSessionDetails.gst.toFixed(2)}</div>
                              </div>
                              <div className="profile-amount col-12 p-0 mt-3 text-center">
                                Total Amount: <i className="fas fa-rupee-sign"></i>{selectedSessionDetails.total.toFixed(2)}
                              </div>
                              <>
                                <div className="col-12 booking-details-change-button pay-now-button">
                                  <button type="button" className="btn btn-primary" disabled={!isCancellationChecked} onClick={!paymentInitialized ? () => payForSession() : () => { }}>{paymentInitialized ? 'Initialize payment...' : 'Pay Now'}</button>
                                </div>
                                <div className="col-12 text-center">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => toggleCancellation(!isCancellationChecked)} />
                                    <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                      I have read the <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='anchor-link text-underline'>Cancellation Policy</a>
                                    </label>
                                  </div>

                                </div> </>
                            </div>

                          </div>
                        :
                        workshopsList.length ?
                          workshopsList.map((professional, index) => {
                            return <div className={`col-12 professional-wrapper profile-workshop-list ${index !== workshopsList.length - 1 ? 'mb-15' : ''}
                                                `} key={index}>
                              <div className={`row booking-item-wrapper`} style={{ margin: 0 }}>
                                <div className="booking-profile-image workshop-image">
                                  {professional.profilePicture ?
                                    <img src={professional.profilePicture} alt="workshop" /> :
                                    null}
                                  <div className="amount-image-wrapper workshop-mobile-view-only" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: professional.discountedPrice ? '160px' : '100px', backgroundSize: 'cover', backgroundPosition: 'right' }}> <i className="fas fa-rupee-sign"></i><span className={`${professional.discountedPrice ? 'text-strikethrough' : ''}`}>{professional.fee.toFixed(2)}</span> {professional.discountedPrice ? <> <i className="fas fa-rupee-sign ml-2"></i>{professional.discountedPrice.toFixed(2)}</> : null} </div>
                                </div>
                                <div className="booking-details-wrapper workshop-details-wrapper">
                                  <div className="col-12 workshop-desktop-view-only">
                                    <div className="professional-name">{professional.title}</div>
                                    <div className="amount-image-wrapper mt-2 mb-2" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: professional.discountedPrice ? '160px' : '100px', backgroundSize: 'cover', backgroundPosition: 'right' }}> <i className="fas fa-rupee-sign"></i><span className={`${professional.discountedPrice ? 'text-strikethrough' : ''}`}>{professional.fee.toFixed(2)}</span> {professional.discountedPrice ? <> <i className="fas fa-rupee-sign ml-2"></i>{professional.discountedPrice.toFixed(2)}</> : null} </div>
                                    <div className="professional-service-desc">{professional.description}</div>
                                    <div className="professional-date-time row m-0 booking-item-side-row pl-0 pb-2 pt-2">
                                      <div className="col-12 p-0">
                                        <div><span className="label mr-3">Dates</span>
                                          <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayedFullDateFormat(new Date(professional.startDate))} {professional.startDate !== professional.endDate ? `- ${getDisplayedFullDateFormat(new Date(professional.endDate))}` : ''}
                                        </div>
                                      </div>
                                      <div className="col-12 p-0 mt-1 mb-1">
                                        <div><span className="label mr-3">Time slot</span><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                      </div>
                                    </div>
                                    <div className="professional-amount row ml-0 mr-0 booking-item-side-row pl-0 pt-2 pb-2">
                                      <button className="btn btn-primary details-button" onClick={() => props.history.push(`/workshop-${window.location.pathname.includes('booking') ? 'booking-' : ''}details/${professional._id}`)}>Details</button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 p-0 workshop-mobile-view-only mt-3">
                                  <div className="professional-name">{professional.title}</div>
                                  <div className="professional-date-time row m-0 booking-item-side-row pl-0 pb-2 pt-2">
                                    <div className="col-12 p-0 mb-2">
                                      <div><span className="label mr-3">Dates</span>
                                        <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayedFullDateFormat(new Date(professional.startDate))} {professional.startDate !== professional.endDate ? `- ${getDisplayedFullDateFormat(new Date(professional.endDate))}` : ''}
                                      </div>
                                    </div>
                                    <div className="col-12 p-0">
                                      <div><span className="label mr-3">Time slot</span><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                    </div>
                                  </div>
                                  <div className="professional-amount row ml-0 mr-0 booking-item-side-row p-0">
                                    <div className='col-6 pl-0'></div>
                                    <div className='col-6 pr-0 text-right'>
                                      <button className="btn btn-primary details-button" onClick={() => props.history.push(`/workshop-${window.location.pathname.includes('booking') ? 'booking-' : ''}details/${professional._id}`)}>Details</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          })
                          : <div className="text-center well col-12 color-black">
                            No Workshops Available
                          </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          : <div className="text-center well col-12 color-black pt-3">
            loading...
          </div>}
      </div>
      <PaymentModal displayModal={openPaymentPopup} paymentInitialized={paymentInitialized} payNow={payNow} popupBookingDetails={popupBookingDetails} cancelBooking={cancelPayment} />
      <PaymentModal displayModal={openPaymentSessionPopup} paymentInitialized={paymentInitialized} payNow={payForSession} popupBookingDetails={popupSessionDetails} cancelBooking={cancelPayment} />
      {selectedProfile.descriptionImages && openGalleryModal ?
        <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
    </MuiPickersUtilsProvider>
  )
};

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Details)
);