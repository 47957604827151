import React from 'react';
import "../admins/forum.css";

const AddressInfoModal = (props) => {

    return (
        <div className={`row forum`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Address</h5>
                        </div>
                        <div className="modal-body">
                            <div className='col-12 pt-3'>
                                <h5 className="col-12 p-0" style={{ color: "#000" }}>
                                    {props.selectedProfile.addressLine1 ? `${props.selectedProfile.addressLine1}, ` : ''}{props.selectedProfile.addressLine2? `${props.selectedProfile.addressLine2} ` : ''}
                                    {props.selectedProfile.addressLine3 ?
                                    <p><b>Direction to reach: </b> {props.selectedProfile.addressLine3}</p> : null}
                                </h5>
                            </div>
                            <div className="col-12 pt-3 pb-3 text-right">
                                <button type="button" className="btn btn-outline-primary" onClick={() => props.closeModal(false)}>Cancel</button>
                                <button type="button" style={{
                                    borderRadius: "10px",
                                    height: "42px"
                                }} className="btn btn-primary ml-3" onClick={props.openMapLocation}>View on map</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddressInfoModal;