import React, { useEffect, useState, useCallback } from 'react';
import "./forum.css";
import { getWorkshopBookingDetails } from "../../crud/booking.crud";
import { bookWorkshop } from "../../crud/home.crud";
import { toast } from 'react-toastify';
import { paytmUrl } from "../../crud/api.util";
import {
    API_ERROR
} from "../../config/error-message";
import {
    Config
} from "../../config/constants";

const FailurePayment = (props) => {
    const [bookingDetails, updateBookingDetails] = useState({});
    const [disablePage, toggleDisablePage] = useState(false);
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [paymentInitialized, togglePaymentInitialization] = useState(false);

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getWorkshopBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            bookingId: resultData._id,
            serviceId: resultData.workshopRef._id,
            transactionStatus: resultData.transactionDetails.status
        };

        updateBookingDetails({ ...localProfessionalObj });

        const myScript = document.createElement("script");
        myScript.setAttribute("src", paytmUrl);
        myScript.setAttribute("crossorigin", "anonymous");
        myScript.setAttribute("type", "application/javascript");
        document.body.appendChild(myScript);

    }, []);

    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            const urlParams = new URLSearchParams(window.location.search);
            const bookingIdParam = urlParams.get('id');
            if (el) {
                el.style.visibility = "hidden";
            }
            if (el1) {
                el1.style.visibility = "hidden";
            }
            if (bookingIdParam) {
                getBookingData(bookingIdParam);
            }
            updatePageInitialization(true);
        }
    }, [getBookingData, pageInitialized]);

    const cancelBooking = () => {
        props.history.push(`/book-now?categoryId=605088be5848fb3bf301e90b`);
    };

    const initiatePayment = (orderId, token, amount) => {
        var config = {
            "root": "",
            "flow": "DEFAULT",
            "data": {
                "orderId": orderId, /* update order id */
                "token": token, /* update token value */
                "tokenType": "TXN_TOKEN",
                "amount": amount.toString() /* update amount */
            },
            "handler": {
                "notifyMerchant": function () {
                    console.log("notifyMerchant handler function called");
                }
            }
        };
        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                togglePaymentInitialization(false);
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
            });
        }
    };

    const retryPayment = async () => {
        const requestBody = {
            bookingId: bookingDetails.bookingId
        };

        togglePaymentInitialization(true);

        toggleDisablePage(true);
        const bookSlotResult = await bookWorkshop(bookingDetails.serviceId, requestBody);
        toggleDisablePage(false);
        if (bookSlotResult.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.errorCode]);
            togglePaymentInitialization(false);
            return;
        }
        if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
            togglePaymentInitialization(false);
            return;
        }
        const paytmToken = bookSlotResult.data.data.paytmTxnToken;
        const paytmOrderId = bookSlotResult.data.data.orderId;
        const paytmAmount = bookSlotResult.data.data.paymentDetails.total;
        initiatePayment(paytmOrderId, paytmToken, paytmAmount);
    };

    return (
        <div className={`forum success-payment-outside ${disablePage || paymentInitialized ? "pointer-none" : ""}`}>
            <div className={`success-payment-wrapper`}>
                {!disablePage ?
                    <>
                        <h3 className="header-1">
                            {bookingDetails.transactionStatus === Config.Transaction.NumberStatus.PaymentPending ? "Sorry! We have not received your payment, if any amount has been deducted from your bank, it will be refunded in three working days." :
                                "Payment Failed"}
                        </h3>
                        <div className="button-wrapper mt-3">
                            <button className="btn btn-outline-primary" onClick={cancelBooking}>Cancel Booking</button>
                            <button className="btn btn-primary" onClick={!paymentInitialized ? retryPayment : () => { }}>{paymentInitialized ? 'Initialize payment...' : 'Retry Payment'}</button>
                        </div>
                    </> : <div>processing...</div>}
            </div>
        </div>
    )
}

export default FailurePayment;