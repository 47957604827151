import React, { useEffect, useState, useCallback } from 'react';
import "./forum.css";

import { submitSingleAnswer, finalSubmitTest } from "../../crud/home.crud";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";
import { testColorScore, monthArr } from "../../config/constants";

const optionSelectedImage = require('../../../images/option-selected.png');
const optionNotSelectedImage = require('../../../images/option-not-selected.png');
const testConfirmationImage = require('../../../images/test-progress.png');
const startTestBg = require('../../../images/start-test-bg.png');
const defaultImage = require('../../../images/imgAvtr.png');
const reportCardBg = require('../../../images/report-card-bg.png');

const TestModal = (props) => {
  const [currentIndex, updateCurrentIndex] = useState(0);
  const [maxIndexReached, updateMaxIndex] = useState(0);
  const [isFiftyDisplayed, updateFifty] = useState(false);
  const [confirmationText, updateConfirmationText] = useState("");
  const [isNinetyDisplayed, updateNinety] = useState(false);
  const [initialConfirmationDisplayed, updateInitialConfirmation] = useState(false);
  const [isSubmitted, updateSubmission] = useState(false);
  const [showPrevButtons, togglePrevButton] = useState(false);
  const [showNextButtons, toggleNextButton] = useState(true);
  const [categories, updateCategory] = useState([]);
  const [currentAnswerIndex, updateCurrentAnswerIndex] = useState(-1);
  const [currentQType2Index, updateCurrentQType2Index] = useState(0);
  const [finalResult, updateFinalResult] = useState({});
  const [set2Timer, updateSet2Timer] = useState(10);

  const chooseAnswer = (ansInd, qInd) => {
    if (props.testDetails.questionSetType !== 2) {
      const localSet = [...props.questionAnswerSet];
      const localAnswers = [...localSet[currentIndex].options];
      localAnswers.forEach((each, ind) => {
        each.selected = false;
        if (ind === ansInd) {
          each.selected = true;
          updateCurrentAnswerIndex(ind);
        }
      });
      localSet[currentIndex].options = [...localAnswers];
      props.updateQuestionAnswerSet([...localSet]);
    } else {
      const localSet = [...props.questionAnswerSet2];
      const localAnswers = [...localSet[currentIndex].questions[qInd].options];
      localAnswers.forEach((each, ind) => {
        each.selected = false;
        if (ind === ansInd) {
          each.selected = true;
          updateCurrentAnswerIndex(ind);
        }
      });
      localSet[currentIndex].questions[qInd].options = [...localAnswers];
      props.updateQuestionAnswerSet2([...localSet]);
    }

  };

  const checkFifty = (indexArg) => {
    const localInd = indexArg;
    if ((((localInd + 1) / props.questionAnswerSet.length) * 100) > 50) {
      updateFifty(true);

      return true;
    }

    return false;
  };

  const checkNinety = (indexArg) => {
    const localInd = indexArg;
    if ((((localInd + 1) / props.questionAnswerSet.length) * 100) > 90) {
      updateNinety(true);

      return true;
    }

    return false;
  };

  const nextClick = async () => {
    let localIndex = currentIndex;
    // if (currentIndex !== props.questionAnswerSet.length - 1) {
    const reqBody = {
      questionIndex: localIndex,
      optionIndex: currentAnswerIndex
    };
    if (props.testDetails.questionSetType === 2) {
      reqBody.questionIndex = currentQType2Index;
      reqBody.questionStoryIndex = localIndex;
    }
    const singleAnswerResponse = await submitSingleAnswer(reqBody, props.testDetails.bookingId);
    if (singleAnswerResponse.data.errorCode) {
      toast.error(API_ERROR[singleAnswerResponse.data.errorCode]);
      return;
    }
    if (singleAnswerResponse.data.data && singleAnswerResponse.data.data.errorCode) {
      toast.error(API_ERROR[singleAnswerResponse.data.data.errorCode]);
      return;
    }
    if (props.testDetails.questionSetType !== 2 && currentIndex !== props.questionAnswerSet.length - 1) {
      ++localIndex;
      if (props.questionAnswerSet.length > 4) {
        if (!isFiftyDisplayed && checkFifty(localIndex)) {
          updateConfirmationText("FIFTY");
        }
        if (!isNinetyDisplayed && checkNinety(localIndex)) {
          updateConfirmationText("NINETY");
        }
      }
      updateCurrentIndex(localIndex);
      updateMaxIndex(localIndex);
    }
    if (props.testDetails.questionSetType === 2) {
      
      if (currentQType2Index !== props.questionAnswerSet2[localIndex].questions.length - 1) {
        updateCurrentQType2Index(prevInd => ++prevInd);
      } else if (currentIndex !== props.questionAnswerSet2.length - 1) {
        ++localIndex;
        updateCurrentIndex(localIndex);
        updateMaxIndex(localIndex);
        updateSet2Timer(props.questionAnswerSet2[localIndex].timeout || 10);
        updateCurrentQType2Index(0);
      }
    }
  };

  const prevClick = () => {
    let localIndex = currentIndex;
    if (currentIndex !== 0) {
      --localIndex;
      updateCurrentIndex(localIndex);
    }
  };

  useEffect(() => {
    if (props.testDetails.questionSetType === 2 && props.questionAnswerSet2 && props.questionAnswerSet2.length) {
      updateSet2Timer(props.questionAnswerSet2[currentIndex].timeout || 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.currentIndex) {
      updateCurrentIndex(props.currentIndex);
      updateMaxIndex(props.currentIndex);
      if (props.testDetails.questionSetType === 2) {
        checkFifty(props.currentIndex);
        checkNinety(props.currentIndex);
      }
      if (props.currentIndex !== 0) {
        updateInitialConfirmation(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentIndex]);

  const handleNextPrevButtons = (type) => {
    if (document.getElementsByClassName(type)[0]) {
      if (document.getElementsByClassName(type)[0].clientWidth < document.getElementsByClassName(type)[0].scrollWidth) {
        if (type === "categories-wrapper") {
          togglePrevButton(true);
          toggleNextButton(true);
        }

        if (document.getElementsByClassName(type)[0].scrollLeft === 0) {
          if (type === "categories-wrapper") {
            togglePrevButton(false);
          }
        }
        if (Math.round(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.round(document.getElementsByClassName(type)[0].scrollLeft) ||
          Math.floor(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.floor(document.getElementsByClassName(type)[0].scrollLeft)) {
          if (type === "categories-wrapper") {
            toggleNextButton(false);
          }
        }

      } else {
        if (type === "categories-wrapper") {
          togglePrevButton(false);
          toggleNextButton(false);
        }
      }
    }
  };

  const sideScroll = (element, direction, speed, distance, step, type) => {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        handleNextPrevButtons(type);
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  const nextProfClick = (type) => {
    var container = document.getElementsByClassName(type)[0];
    sideScroll(container, 'right', 25, 129, 10, type);
  };

  const prevProfClick = (type) => {
    var container = document.getElementsByClassName(type)[0];
    sideScroll(container, 'left', 25, 129, 10, type);
  };

  window.onresize = () => {
    handleNextPrevButtons("categories-wrapper");
  };

  window.onload = () => {
    handleNextPrevButtons("categories-wrapper");
  };

  const displayNavs = useCallback((bool) => {
    if (bool) {
      handleNextPrevButtons("categories-wrapper");
    }
  }, []);

  const getFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const year = date.getFullYear();

    return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
  };

  const submitTest = async () => {
    await nextClick();
    const finalAnswerResponse = await finalSubmitTest(props.testDetails.bookingId);
    if (finalAnswerResponse.data.errorCode) {
      toast.error(API_ERROR[finalAnswerResponse.data.errorCode]);
      return;
    }
    if (finalAnswerResponse.data.data && finalAnswerResponse.data.data.errorCode) {
      toast.error(API_ERROR[finalAnswerResponse.data.data.errorCode]);
      return;
    }
    updateFinalResult({
      title: finalAnswerResponse.data.data.testRef.title,
      state: finalAnswerResponse.data.data.result.state,
      statusText: finalAnswerResponse.data.data.result.title,
      testGivenBy: finalAnswerResponse.data.data.bookingForName,
      testGivenDate: getFullDateFormat(new Date(finalAnswerResponse.data.data.createdAt))
    });
    const localVendorsRef = finalAnswerResponse.data.data.testRef.vendorReferences;
    const localVendors = [];
    if (localVendorsRef.length) {
      localVendorsRef.forEach(each => {
        localVendors.push({
          name: each.vendorId.personalInfo.name,
          profilePhoto: each.vendorId.personalInfo.profilePicture && each.vendorId.personalInfo.profilePicture.length ? each.vendorId.personalInfo.profilePicture : defaultImage,
          _id: each.vendorId._id,
          order: each.order
        });
      });
    }
    updateCategory([...localVendors]);
    updateSubmission(true);
  };

  const decreaseSet2Timer = () => {
    setInterval(() => {
      updateSet2Timer(prevVal => prevVal && --prevVal);
    }, 1000);
  };

  return (
    <div className={`row forum test-modal`} style={{ margin: 0 }}>
      <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            {isSubmitted ? <>
              <div className="modal-body">
                <i className="fas fa-times close-modal pull-right" style={{ marginTop: "15px", marginRight: "15px" }} onClick={() => props.toggleTestModal(maxIndexReached, 'end')}></i>
                <h5 className='pre-confirmation-header' style={{ marginTop: "15px", fontSize: "18px", marginLeft: "15px" }}>Report Card</h5>

                <div className="col-12 pt-3 pb-3 pl-0 pr-0">
                  <div className='row m-0 scrollable-modal'>
                    <div className="col-12 category-arrows pl-0 pr-0">
                      <h5 className='pre-confirmation-header'>{finalResult.title}</h5>
                      <div className='row m-0'>
                        <img src={reportCardBg} alt="Report Card" style={{ width: "200px", margin: "0 auto -10px" }} />
                      </div>
                      <div className='test-completed-booking-wrapper wrapper-2 mt-3'>
                        <div className="row m-0">
                          <h5 className="col-12" style={{ color: "#fff", padding: "20px 20px 0" }}>Self Assessment Report</h5>
                          <div className='col-12 p-0'>
                            <div className='row m-0'>
                              <div className='invoice-labels mb-1 col-7'>Assessment taken</div>
                              <div className='invoice-labels value mb-1 col-5 text-right'>{finalResult.testGivenDate}</div>
                              <div className='invoice-labels mb-1 col-7'>Assessment given by</div>
                              <div className='invoice-labels value mb-1 col-5 text-right'>{finalResult.testGivenBy}</div>
                              <div className='invoice-total col-12 info' style={{ backgroundColor: testColorScore[finalResult.state] }}>{finalResult.statusText}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {categories.length && finalResult.state !== 1 ?
                        <div className='row m-0'>
                          <div className='col-12 p-0'>
                            <div className={`row ml-0 mr-0 categories-heading pb-0 pr-0 pl-0`} onMouseEnter={() => { displayNavs(true) }}>
                              <div className={`col-md-8 col-xs-12 pl-0 pr-0 mb-0 pt-3 home-page-header display-inline-block pre-confirmation-header`}>Recommended Professionals</div>
                              {categories.length ?
                                <div className="col-md-4 col-xs-12 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                  <button className="prev-button-wrapper" style={{ position: "inherit" }} onClick={() => prevProfClick("categories-wrapper")} id="slideprev" disabled={!showPrevButtons}>
                                    <i className="fas fa-arrow-left"></i>
                                  </button>
                                  <button className="next-button-wrapper" onClick={() => nextProfClick("categories-wrapper")} style={{ position: "inherit" }} id="slide" disabled={!showNextButtons}>
                                    <i className="fas fa-arrow-right"></i>
                                  </button>
                                </div> : null}
                            </div>
                            <div className="whole-categories-wrapper p-0">
                              <div className={`categories-wrapper pl-5`} style={{ margin: 0 }}>
                                {categories.length ? categories.map((category, index) => {
                                  return <div className={`custom-card prof-card ${index === categories.length - 1 ? 'mr-0' : ''} ${category.selected ? 'selected-card' : ''}`} key={index} onMouseEnter={() => { displayNavs(true) }} onClick={() => window.open(`${window.location.origin}/professional-details/${category._id}/${category.name.split(" ").join("-")}`)}>
                                    <div className="col-lg-12 p-0">
                                      <div style={{ backgroundImage: "url(" + category.profilePhoto + ")" }} className="background-image-category"></div>
                                    </div>
                                    <div className={`col-lg-12 pl-0 pr-0 category-name ${category.selected ? 'selected-card-name' : ''}`}>
                                      {category.name}
                                    </div>
                                  </div>
                                }) : null}

                              </div>
                            </div>
                          </div>
                        </div> : null}
                      <div className='col-12 pr-0 pl-0 pt-2'>
                        <b>Disclaimer: </b>The following self-assessment is <b>NOT</b> a diagnostic tool. The self-assessment results cannot be substituted for a diagnose or to treat mental health conditions. The results can change over time based on your experience and other factors. Parentcraft India Pvt Ltd,sponsors, partners, and advertisers disclaim any liability, loss, or risk incurred as a consequence, directly or indirectly, from the use of this application / self-assessment.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </> : maxIndexReached === 0 && !initialConfirmationDisplayed ? <>
              <div className="modal-body">
                <div className='col-12 pl-0 text-right'>
                  <i className="fas fa-times close-modal" onClick={() => props.toggleTestModal(maxIndexReached)}></i>
                </div>

                <div className="col-12 pt-3 pb-3">
                  <div className='row m-0'>
                    <div className='scrollable-modal'>
                      <div className="col-12 text-center category-arrows">
                        <img src={startTestBg} alt="Start Test" style={{ width: "150px" }} />
                        <h5 className='pre-confirmation-header'>{props.testDetails.title}</h5>
                        <div className='pre-confirmation-text'>{props.testDetails.description}</div>
                      </div>
                      <div className='col-12 pt-3'>
                        <h6 className='pre-confirmation-disclaimer-header'>Instructions</h6>
                        <ul style={{ marginLeft: "15px" }} className="pre-confirmation-disclaimer-text">
                          <li>Please answer all the questions.</li>
                          <li>Please choose the answer that best describes, based on your feeling, thinking or behaviour over the past 2-4 weeks</li>
                        </ul>
                      </div>
                      <div className='col-12 pt-3 pb-3'>
                        <h6 className='pre-confirmation-disclaimer-header'>Disclaimer</h6>
                        <div className='pre-confirmation-disclaimer-text'>The following self-assessment is <b>NOT</b> a diagnostic tool. The self-assessment results cannot be substituted for a diagnose or to treat mental health conditions. The results can change over time based on your experience and other factors. Parentcraft India Pvt Ltd,sponsors, partners, and advertisers disclaim any liability, loss, or risk incurred as a consequence, directly or indirectly, from the use of this application / self-assessment.</div>
                      </div>
                    </div>
                    <div className="col-12 text-center mt-3">
                      <button type="button" style={{ float: "inherit" }} className="btn btn-primary details-button" onClick={() => { updateInitialConfirmation(true); decreaseSet2Timer(); }}>Start</button>
                    </div>
                  </div>
                </div>
              </div>
            </> :
              isFiftyDisplayed && confirmationText.length ?
                <>
                  <div className="modal-body">
                    {/* <div className='col-12 p-0 text-right'>
                                            <i className="fas fa-times close-modal" onClick={() => props.toggleTestModal(maxIndexReached)}></i>
                                        </div> */}

                    <div className="col-12 pt-3 pb-3">
                      <div className='row m-0'>
                        <div className="col-12 text-center category-arrows">
                          <img src={testConfirmationImage} alt="Confirmation" style={{ width: "150px" }} />
                          <h4 className='confirmation-header'>{confirmationText === "FIFTY" ? 'Congratulations' : 'Almost There'}</h4>
                          <div className='confirmation-text'>You have completed {confirmationText === "FIFTY" ? '50' : '90'}% of the assessment</div>
                          <button type="button" style={{ float: "inherit" }} className="btn btn-primary details-button" onClick={() => updateConfirmationText("")}>Continue</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="modal-header pb-0 row m-0">
                    <div className='col-12 text-center question-count pb-3'>
                    </div>
                  </div>
                  <div className="modal-body">
                    {props.testDetails.questionSetType !== 2 ?
                      <div className="col-12 pt-0 pb-3">
                        <div className='row m-0 pb-5 scrollable-modal' style={{ maxHeight: "200px" }}>
                          <h5 className='text-center col-12 question'>
                            {props.questionAnswerSet ? props.questionAnswerSet[currentIndex].title : null}
                          </h5>
                        </div>
                        <div className='row m-0 '>
                          {props.questionAnswerSet ? props.questionAnswerSet[currentIndex].options.map((eachAns, index) => {
                            return <div className={`col-12 answer-option ${eachAns.selected ? 'answer-selected' : ''}`} key={index} onClick={() => chooseAnswer(index)}>
                              {eachAns.title} <img src={eachAns.selected ? optionSelectedImage : optionNotSelectedImage} alt={'option'} className='option-radio' />
                            </div>
                          }) : null}
                        </div>
                        <div className='row m-0 pt-5'>
                          <div className="col-12 text-center category-arrows">
                            {currentIndex !== props.questionAnswerSet.length - 1 ? <>
                              <button className="prev-button-wrapper" style={{ position: "inherit" }} onClick={prevClick} disabled={currentIndex === 0}>
                                <i className="fas fa-arrow-left"></i>
                              </button>
                              <button className="next-button-wrapper" style={{ position: "inherit" }} onClick={nextClick} disabled={!props.questionAnswerSet[currentIndex].options.some(each => each.selected)}>
                                <i className="fas fa-arrow-right"></i>
                              </button> </> :
                              <button type="button" style={{ float: "inherit" }} className="btn btn-primary details-button" disabled={!props.questionAnswerSet[currentIndex].options.some(each => each.selected)} onClick={submitTest}>Submit</button>}
                          </div>
                        </div>
                      </div> :
                      <div className={`col-12 pt-0 ${!set2Timer ? 'pb-3' : 'pb-70'}`}>
                        {set2Timer ?
                          <>
                            <div className='row m-0 pb-3 scrollable-modal' style={{ maxHeight: "200px" }}>
                              <h5 className='text-center col-12 question'>
                                {props.questionAnswerSet2 ? props.questionAnswerSet2[currentIndex].title : null
                                }
                              </h5>
                            </div>

                            <div className='row m-0 '>
                              {props.questionAnswerSet2 ? props.questionAnswerSet2[currentIndex].contents.map((eachAns, index) => {
                                return <div className={`col-12 question-type-2-content`} key={index}>
                                  {eachAns}
                                </div>
                              }) : null}
                              <div className='timer-wrapper'>
                                <span className="timer-header">Time Remaining</span> <div className="timer-value">00:{set2Timer > 9 ? '' : '0'}{set2Timer} secs</div></div>
                            </div> </> :
                          <div className='row m-0 '>
                            {props.questionAnswerSet2 && props.questionAnswerSet2.length &&
                            props.questionAnswerSet2[currentIndex].questions[currentQType2Index] ? <>
                                <div className='pb-3 scrollable-modal' style={{ maxHeight: "200px" }}>
                                  <h5 className='text-center col-12 question'>
                                    {props.questionAnswerSet2[currentIndex].questions[currentQType2Index].title}
                                  </h5>
                                </div>
                                {props.questionAnswerSet2[currentIndex].questions[currentQType2Index].options.map((eachOtion, ind1) => {
                                  return <div className={`col-12 answer-option ${eachOtion.selected ? 'answer-selected' : ''}`} key={`ans-${ind1}`} onClick={() => chooseAnswer(ind1, currentQType2Index)}>
                                    {eachOtion.title} <img src={eachOtion.selected ? optionSelectedImage : optionNotSelectedImage} alt={'option'} className='option-radio' />
                                  </div>
                                })}

                              </> : null}
                          </div>
                        }
                        {!set2Timer ?
                          <div className='row m-0 pt-5'>
                            <div className="col-12 text-center category-arrows">
                              {props.questionAnswerSet2[currentIndex].questions[currentQType2Index] ?
                              currentIndex !== props.questionAnswerSet2.length - 1 || 
                              currentQType2Index !== props.questionAnswerSet2[currentIndex].questions.length - 1  ? <>
                                <button className="prev-button-wrapper" style={{ position: "inherit" }} onClick={prevClick} disabled={true}>
                                  <i className="fas fa-arrow-left"></i>
                                </button>
                                <button className="next-button-wrapper" style={{ position: "inherit" }} onClick={nextClick} disabled={!props.questionAnswerSet2[currentIndex].questions[currentQType2Index].options.some(each => each.selected)}>
                                  <i className="fas fa-arrow-right"></i>
                                </button> </> :
                                <button type="button" style={{ float: "inherit" }} className="btn btn-primary details-button" disabled={!props.questionAnswerSet2[currentIndex].questions[currentQType2Index].options.some(each => each.selected)} onClick={submitTest}>Submit</button> : null}
                            </div>
                          </div> : null}
                      </div>}
                  </div>
                </>}
          </div>
        </div>
      </div>

    </div>
  )
}

export default TestModal;