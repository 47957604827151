import React, { useState, useEffect } from 'react';
import './home.css';
import {orgMetaData} from "../../config/constants";

const part1Image = require('../../../images/part-1.webp');
const part2Image = require('../../../images/part-2.webp');
const part51Image = require('../../../images/part-5-1.webp');
const part52Image = require('../../../images/part-5-2.webp');
const part53Image = require('../../../images/part-5-3.webp');
const googlePlayStore = require('../../../images/google-play-store.webp');
const appleStore = require('../../../images/app-store.webp');
const fbLogo = require('../../../images/fb-small.webp');
const youtubeLogo = require('../../../images/youtube-small.webp');
const instagramLogo = require('../../../images/instagram-small.webp');
const twitterLogo = require('../../../images/twitter-small.webp');
const isoLogoImage = require('../../../images/iso-logo.webp');
const homeCarousel1 = require('../../../images/home-carousel1.webp');
const homeCarousel2 = require('../../../images/home-carousel2.webp');
const homeCarousel3 = require('../../../images/home-carousel3.webp');
const homeCarousel4 = require('../../../images/home-carousel4.webp');
const homeCarousel5 = require('../../../images/home-carousel5.webp');
const homeCarousel6 = require('../../../images/home-carousel6.webp');
const homeCarousel7 = require('../../../images/home-carousel7.webp');
const homeCarousel8 = require('../../../images/home-carousel8.webp');
const homeCarousel9 = require('../../../images/home-carousel9.webp');
const SupportEmail = require("../../../images/support-email.webp");

const urls = {
    professionalWebUrl: 'https://professional.parentcraftindia.com/',
    customerIos: 'https://apps.apple.com/in/app/parentcraft-india/id1496497624',
    customeAndroid: 'https://play.google.com/store/apps/details?id=com.parentcraftindia.customer',
    professionalIos: 'https://apps.apple.com/in/app/parentcraft-services/id1579359035',
    professionalAndroid: 'https://play.google.com/store/apps/details?id=com.parentcraft.vendor',
    fb: 'https://www.facebook.com/Parentcraft.India',
    twitter: 'https://twitter.com/Parentcraft1',
    youtube: 'https://twitter.com/Parentcraft1',
    instagram: 'https://www.instagram.com/parentcraftindia/'
};

const customersCarousel = [homeCarousel1, homeCarousel2, homeCarousel3, homeCarousel4, homeCarousel5];
const vendorCarousel = [homeCarousel6, homeCarousel7, homeCarousel8, homeCarousel9];

const Home = (props) => {
    const [showPrevCustomerCarousel, togglePrevCustomerButton] = useState(false);
    const [showNextCustomerCarousel, toggleNextCustomerButton] = useState(true);
    const [showPrevVendorCarousel, togglePrevVendorButton] = useState(false);
    const [showNextVendorCarousel, toggleNextVendorButton] = useState(true);

    const handleNextPrevButtons = (type) => {
        if (document.getElementsByClassName(type)[0]) {
            if (document.getElementsByClassName(type)[0].clientWidth < document.getElementsByClassName(type)[0].scrollWidth) {
                if (type === "customers-wrapper") {
                    togglePrevCustomerButton(true);
                    toggleNextCustomerButton(true);
                } else if (type === "vendors-wrapper") {
                    togglePrevVendorButton(true);
                    toggleNextVendorButton(true);
                }

                if (document.getElementsByClassName(type)[0].scrollLeft === 0) {
                    if (type === "customers-wrapper") {
                        togglePrevCustomerButton(false);
                    } else if (type === "vendors-wrapper") {
                        togglePrevVendorButton(false);
                    }
                }
                if (Math.round(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.round(document.getElementsByClassName(type)[0].scrollLeft) ||
                    Math.floor(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.floor(document.getElementsByClassName(type)[0].scrollLeft)) {
                    if (type === "customers-wrapper") {
                        toggleNextCustomerButton(false);
                    } else if (type === "vendors-wrapper") {
                        toggleNextVendorButton(false);
                    }
                }

            } else {
                if (type === "customers-wrapper") {
                    togglePrevCustomerButton(false);
                    toggleNextCustomerButton(false);
                } else if (type === "vendors-wrapper") {
                    togglePrevVendorButton(false);
                    toggleNextVendorButton(false);
                }
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step, type) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons(type);
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'right', 25, 129, 10, type);
    };

    const prevClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'left', 25, 129, 10, type);
    };

    window.onresize = () => {
        if (customersCarousel.length) {
            handleNextPrevButtons("customers-wrapper");
        } else if (vendorCarousel.length) {
            handleNextPrevButtons("vendors-wrapper");
        }
    };

    window.onload = () => {
        if (customersCarousel.length) {
            handleNextPrevButtons("customers-wrapper");
        } else if (vendorCarousel.length) {
            handleNextPrevButtons("vendors-wrapper");
        }
    };

    const displayNavs = (bool) => {
        if (bool) {
            if (customersCarousel.length) {
                handleNextPrevButtons("customers-wrapper");
            } else if (vendorCarousel.length) {
                handleNextPrevButtons("vendors-wrapper");
            }
        }
    };

    useEffect(() => {
        const metaTags = document.getElementsByTagName("meta");
        if (metaTags[1].name ===  "title") {
            metaTags[1].content = orgMetaData.home.title;
        }
        if (metaTags[2].name ===  "description") {
            metaTags[2].content = orgMetaData.home.description;
        }
        if (metaTags[3].name ===  "keywords") {
            metaTags[3].content = orgMetaData.home.keywords;
        }
        if (metaTags[4]) {
            metaTags[4].content = orgMetaData.home.title;
        }
        if (metaTags[5]) {
            metaTags[5].content = orgMetaData.home.description;
        }
        if (metaTags[6]) {
            metaTags[6].content = orgMetaData.image;
        }
        if (metaTags[7]) {
            metaTags[7].content = orgMetaData.url;
        }
        
    }, []);

    return (
        <div className='row m-0 home-container'>
            <script type="application/ld+json">
                {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.parentcraftindia.com/",
                "logo": "https://parentcraftmedia.s3.ap-south-1.amazonaws.com/PCOGImage436x228.png"
                })}
            </script>
            <div className='col-12 p-0'>
                <div className='row m-0 common-top-bottom-padding'>
                    <div className='col-12 p-0 common-width'>
                        <div className={`row m-0 part-1-wrapper`}>
                            <div className='col-md-6 col-xs-12 part-1-left'>
                                <div className='home-header'>Crafted with care for <br />Children everywhere</div>
                                <br />
                                <br />
                                <div className='home-info-1'>Applications available for Customers</div>
                                <div className='link-wrapper'>
                                    <img src={googlePlayStore} alt="android-logo" onClick={() => window.open(urls.customeAndroid)} />
                                    <img src={appleStore} alt="apple-logo" onClick={() => window.open(urls.customerIos)} />
                                </div>
                                <br />
                                <br />
                                <div className='home-info-1'>Applications available for Professionals</div>
                                <div className='link-wrapper'>
                                    <img src={googlePlayStore} alt="android-logo" onClick={() => window.open(urls.professionalAndroid)} />
                                    <img src={appleStore} alt="apple-logo" onClick={() => window.open(urls.professionalIos)} />
                                </div>
                            </div>
                            <div className={`col-md-6 col-xs-12 part-1-image-wrapper`}>
                                <img src={part1Image} className="part-1-image" alt="part-1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 part-2 part-2-wrapper'>
                    <div className='col-12 part-2-1'>
                        <div className='common-width pos-relative'>
                            <div className='part-2-left-wrapper' >
                                <div className='part-2-header'>Problem Today</div>
                                <p className='part-2-info part-2-info-width'>
                                    In today’s times with extensive social and emotional influences and strains, the pressures of parental expectations are collectively leading to a rise in serious cases of emotional and mental disturbances in children of school going age.
                                </p>
                                <p className='part-2-info part-2-info-width'>
                                    At Parentcraft India we dedicate ourselves towards the cause that has largely been ignored and have identified the pertinent need to address this aspect of societal change.
                                </p>

                            </div>
                            <img src={part2Image} alt="part-2" className='part-2-image' />
                        </div>
                    </div>
                    <div className='col-12 part-2-2'>
                        <div className='common-width'>
                            <div className='part-2-left-wrapper'>
                                <div className='part-2-header'>Our Vision</div>
                                <p className='part-2-info part-2-info-width'>
                                    India’s 1st child centric utilities and references platform for parents, guardians and teachers alike to refer to in their times of need with respect to children in their care between the ages of 4-17.
                                </p>
                                <p className='part-2-info part-2-info-width'>
                                    This product has been envisioned around the singular objective of creating a happier, meaningful and secure childhood for children of school going age.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className='below-md-part-2-image-wrapper col-12'>
                        <img src={part2Image} alt="part-2" className='below-md-part-2-image' />
                    </div>

                </div>
                <div className='row part-4 mb-0'>
                    <div className='col-12 p-0 common-width'>
                        <div className='row m-0'>
                            <div className='col-12 full-wrapper'>
                                <div className='part-2-header color-white'>Features for Customers</div>
                                <p className='part-2-info'>
                                    We present you a smooth application with seamless integration in all platforms
                                </p>
                                <div className="whole-categories-wrapper">
                                    <div className={`customers-wrapper m-0`}>
                                        {customersCarousel.length ? customersCarousel.map((category, index) => {
                                            return <div key={index} className="full-event-list-wrapper">
                                                <img src={category} onMouseEnter={() => { displayNavs(true) }} className={`event-custom-card event-background-image-category`} alt={`carousel-customer-${index}`} />
                                            </div>
                                        }) : null}

                                    </div>
                                </div>
                                <div className="col-12 text-center category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                    <button className="prev-button-wrapper" onClick={() => prevClick("customers-wrapper")} id="slideprevCust" disabled={!showPrevCustomerCarousel}>
                                        <span className='sr-only'>Customer Features Left Arrow</span>
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                    <button className="next-button-wrapper" onClick={() => nextClick("customers-wrapper")} id="slideCust" disabled={!showNextCustomerCarousel}>
                                        <span className='sr-only'>Customer Features Right Arrow</span>
                                        <i className="fas fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                            <div className='col-12 full-wrapper'>
                                <div className='part-2-header color-white'>Features for Professionals</div>
                                <p className='part-2-info'>
                                    We present you a smooth application with seamless integration in all platforms
                                </p>
                                <div className="whole-categories-wrapper">
                                    <div className={`vendors-wrapper m-0`}>
                                        {vendorCarousel.length ? vendorCarousel.map((category, index) => {
                                            return <div key={index} className="full-event-list-wrapper">
                                                <img src={category} onMouseEnter={() => { displayNavs(true) }} className={`event-custom-card event-background-image-category`} alt={`carousel-professional-${index}`} />
                                            </div>
                                        }) : null}

                                    </div>
                                </div>
                                <div className="col-12 text-center category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                    <button className="prev-button-wrapper" onClick={() => prevClick("vendors-wrapper")} id="slideprevProf" disabled={!showPrevVendorCarousel}>
                                        <span className='sr-only'>Professional Features Left Arrow</span>
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                    <button className="next-button-wrapper" onClick={() => nextClick("vendors-wrapper")} id="slideProf" disabled={!showNextVendorCarousel}>
                                        <span className='sr-only'>Professional Features Right Arrow</span>
                                        <i className="fas fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 text-center part-5 common-top-bottom-padding'>
                    <div className='col-12 p-0 common-width'>
                        <div className='row m-0'>
                            <div className='col-12 pl-0 pr-0 pb-40px'>
                                <div className='part-2-header'>Our Partners</div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                                <div className='wrapper'>
                                    <img src={part51Image} alt="part-5-1" />
                                    <div className='part-3-header part-5-hrader'>Progressive <br /> Education</div>
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                                <div className='wrapper'>
                                    <img src={part52Image} alt="part-5-2" />
                                    <div className='part-3-header part-5-hrader'>Nancy Ann Miller <br /> Educational Trust</div>

                                </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                                <div className='wrapper'>
                                    <img src={part53Image} alt="part-5-3" />
                                    <div className='part-3-header part-5-hrader'>Friends of <br /> Children Society</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 part-6 common-top-bottom-padding'>
                    <div className='col-12 p-0 common-width'>
                        <div className='row m-0'>
                            <div className='col-sm-7 col-xs-12 part-6-disclaimer'>
                                <div className='part-2-header'>Disclaimer</div>
                                <p className='part-2-info'>
                                    We are not a medical service or suicide prevention helpline. If you are feeling
                                    suicidal, we would suggest you immediately call up a suicide prevention helpline
                                    or seek medical help.
                                </p>
                            </div>
                            <div className='col-sm-5 col-xs-12'>
                                <div className='part-2-header'>Contact Us</div>
                                <p className='part-2-info'>
                                    Registered Office: <span className='registered-office'>Parentcraft India Pvt. Ltd.</span> <br /> 64 Dhar Kothi, Residency Area, Indore, Madhya Pradesh, 452001
                                </p>
                                <div>
                                    <span className='mr-2'>Call us @:</span> <span className='email'>+91-9310831813</span>
                                </div>
                                <div>
                                    <span className='mr-2'>Email:</span> 
                                    <img src={SupportEmail} alt="support-email" className='support-mail-image'/>
                                    {/* <span className='email'>support@parentcraftindia.com</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 part-7'>
                    <div className=' common-width part-7-border common-top-bottom-padding'>
                        <div className='iso-image-wrapper'><img src={isoLogoImage} alt="iso-icon" className='iso-image' /></div>
                        <div className='icons-wrapper'>
                            <div className='social-icon-wrapper' onClick={() => window.open(urls.fb)}>
                                <img src={fbLogo} alt="fb-icon" className='fb-logo' />
                            </div>
                            <div className='social-icon-wrapper' onClick={() => window.open(urls.twitter)}>
                                <img src={twitterLogo} alt="twitter-icon" />
                            </div>
                            <div className='social-icon-wrapper' onClick={() => window.open(urls.youtube)}>
                                <img src={youtubeLogo} alt="youtube-icon" />
                            </div>
                            <div className='social-icon-wrapper' onClick={() => window.open(urls.instagram)}>
                                <img src={instagramLogo} alt="instagram-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;