import React, { useState, useEffect } from 'react';

const closeImage = require('../../images/modal-close-icon.png');
let counterInterval;

const OtpModal = (props) => {

    const [otpText, updateOtp] = useState("");
    const [disableResend, toggleDisableResend] = useState(true);
    const [resendCounter, updateResendCounter] = useState(30);

    const setCounter = () => {
      updateResendCounter(counter => counter - 1);
    };
  
    useEffect(() => {
      if (resendCounter === 0) {
        clearInterval(counterInterval);
        updateResendCounter(30);
        toggleDisableResend(false);
      }
    }, [resendCounter]);

    const resendOtp = () => {
      toggleDisableResend(true);

      counterInterval = setInterval(setCounter, 1000);
      props.resend(true);
    };

    useEffect(() => {
      counterInterval = setInterval(setCounter, 1000);
    }, []);

    return <div style={{zIndex: 1055}} className={`modal custom-login-modal login-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <img src={closeImage} alt="close modal" onClick={() => props.toggleModal(false)} className="login-modal-close" />
              <div className="modal-body">
                <div className="col-12 login-logo-wrapper">
                  <img src={require("../../images/logo-blue.webp")} style={{ width: "70px", height: "70px" }} alt="login logo" />
                </div>
                <div className="col-12 text-center">
                  <div className="welcome-text mt-2 mb-2">{'Verify Email'}</div>
                  <div className="sign-in-text">{'Please submit the 4 digit OTP sent to your email for verfication'}</div>
                </div>
                <div className="col-12 login-phone-input">

                    <div className="row form-group login-otp-input-wrapper" style={{ margin: 0 }}>
                      <input type="text" className="form-control phone-number-input" placeholder="Enter OTP" aria-label="Enter OTP" aria-describedby="basic-addon1" value={otpText} onChange={e => { updateOtp(e.target.value.replace(/\D/, '')) }} maxLength={4} />
                    </div>
                  </div>
                  <div className="col-12 login-button">
                    <button type="button" className="btn btn-primary" onClick={() => props.verifyOtp(otpText)} disabled={!otpText}>Verify</button>
                    <button type="button" className="btn btn-link" onClick={resendOtp} disabled={disableResend}>Resend OTP</button>
                    {disableResend ? <span className="ml-2"><b>in 00:{resendCounter < 10 ? "0": ""}{resendCounter} second{resendCounter > 1 ? "s": ""}</b></span> : null}
                  </div>
              </div>
            </div>
          </div>
        </div>
};

export default OtpModal;