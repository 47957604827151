import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";
import { toast } from 'react-toastify';
import { getProblemAreaList, getProblemCategories, getLanguages, markFavouriteProblem } from "../../crud/qa-forum.crud";
import {
    API_ERROR
} from "../../config/error-message";
import { orgMetaData } from '../../config/constants';
import { problemAreaMetaData } from '../../config/meta';

const dummySelectedLanguage = {
    code: "",
    name: ""
};

const ProblemArea = (props) => {
    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
            authData: auth.user
        }),
        shallowEqual
    );
    const [categories, updateCategory] = useState([]);
    const [problemAreas, updateProblemAreaList] = useState([]);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [menuOpened, toggleMenu] = useState(false);
    const [languages, updateLanguages] = useState([]);
    const [selectedLanguage, changeSelectedLanguage] = useState({ ...dummySelectedLanguage });
    const [pageInitialized, updateInitialization] = useState(false);
    const [selectedCategory, updateSelectedCategory] = useState("");
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingProblems, toggleLoadingProblems] = useState(true);
    const [loadingCategories, toggleLoadingCategories] = useState(true);

    const handleNextPrevButtons = () => {
        if (document.getElementsByClassName("categories-wrapper")[0]) {
            if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
                togglePrevButton(true);
                toggleNextButton(true);

                if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
                    togglePrevButton(false);
                }
                if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
                    toggleNextButton(false);
                }
            } else {
                togglePrevButton(true);
                toggleNextButton(true);
            }
        }
    };

    const openMenu = (bool) => {
        toggleMenu(bool);
    };

    window.onclick = (el) => {
        if (el.target.className.indexOf('dropdown-toggle') <= -1) {
            openMenu(false);
        }
    }

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons();
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'right', 25, 145, 10);
    };

    const prevClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'left', 25, 145, 10);
    };

    window.onresize = () => {
        handleNextPrevButtons();
    };

    window.onload = () => {
        handleNextPrevButtons();
    };

    const selectAnswer = (item) => {
        props.history.push(`/problem-details/${item._id}/${selectedLanguage.code}`);
    };

    const openForum = () => {
        sessionStorage.removeItem("forumFilter");
        props.history.push("/forum");
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons();
        }
    }, []);

    const getClass = (index) => {
        if (document.getElementById(`categoryName${index}`)) {
            if (document.getElementById(`categoryName${index}`).clientHeight > 38) {
                document.getElementById(`categoryName${index}`).style.paddingTop = 17 + "px";
            } else {
                document.getElementById(`categoryName${index}`).style.paddingTop = 23 + "px";
            }
        }
    };

    useEffect(() => {
        if (categories.length) {
            categories.forEach((each, index) => {
                getClass(index);
            });
        }
    }, [categories]);

    const getAllProblemAreaList = useCallback(async (categoryId, localSelectedLang) => {
        let localCatId = categoryId;
        toggleDisablePage(true);
        toggleLoadingProblems(true);
        const problemAreaResults = await getProblemAreaList(localCatId);
        toggleDisablePage(false);
        toggleLoadingProblems(false);
        if (problemAreaResults.data.errorCode) {
            toast.error(API_ERROR[problemAreaResults.data.errorCode]);
            return;
        }
        if (problemAreaResults.data.data && problemAreaResults.data.data.errorCode) {
            toast.error(API_ERROR[problemAreaResults.data.data.errorCode]);
            return;
        }
        const problemAreaArr = [];
        if (problemAreaResults.data.data && problemAreaResults.data.data.data && problemAreaResults.data.data.data.length) {
            let problemAreaObj = {};
            problemAreaResults.data.data.data.forEach(each => {
                problemAreaObj = {};
                each.hasValue = false;
                problemAreaObj.isFavourite = authData && each.favouritedBy && each.favouritedBy.includes(authData._id) ? true : false;
                if (each.languageSet && each.languageSet.length) {
                    each.languageSet.forEach(each1 => {
                        if (localSelectedLang) {
                            if (each1.languageRef.code === localSelectedLang.code) {
                                problemAreaObj._id = each._id;
                                problemAreaObj.question = each1.title;
                                problemAreaObj.answer = each1.description;
                                problemAreaObj.highlightedAnswer = each1.highlightAnswer;
                                problemAreaObj.selected = false;
                                each.hasValue = true;
                            }
                        } else {
                            if (each1.languageRef.code === selectedLanguage.code) {
                                problemAreaObj._id = each._id;
                                problemAreaObj.question = each1.title;
                                problemAreaObj.answer = each1.description;
                                problemAreaObj.highlightedAnswer = each1.highlightAnswer;
                                problemAreaObj.selected = false;
                                each.hasValue = true;
                            }
                        }

                    });
                    // if selected language is not present then display eng
                    if (!each.hasValue) {
                        each.languageSet.forEach(each1 => {
                            if (each1.languageRef.name.trim().toLowerCase() === "english") {
                                problemAreaObj._id = each._id;
                                problemAreaObj.question = each1.title;
                                problemAreaObj.answer = each1.description;
                                problemAreaObj.highlightedAnswer = each1.highlightAnswer;
                                problemAreaObj.selected = false;
                            }
                        });
                    }
                }
                if (each.problemCategoryRef &&
                    each.problemCategoryRef.languageSet &&
                    each.problemCategoryRef.languageSet.length) {
                    each.problemCategoryRef.languageSet.forEach(each1 => {
                        // hasValue is used to if selected language is present or not
                        //  if (each.hasValue) {
                        if (localSelectedLang) {
                            if (each1.languageRef.code === localSelectedLang.code) {
                                problemAreaObj.categoryName = each1.title;
                                each.hasCat = true; // variable to check category lang present
                            }
                        } else {
                            if (each1.languageRef.code === selectedLanguage.code) {
                                problemAreaObj.categoryName = each1.title;
                                each.hasCat = true;
                            }
                        }
                        //  }
                    });
                    // if selected language is not present then display eng
                    if (!each.hasCat) {
                        each.problemCategoryRef.languageSet.forEach(each1 => {
                            if (each1.languageRef.name.trim().toLowerCase() === "english") {
                                problemAreaObj.categoryName = each1.title;
                            }
                        });
                    }
                }

                problemAreaArr.push({ ...problemAreaObj });
            });
        }
        updateProblemAreaList([...problemAreaArr]);
    }, [selectedLanguage, authData]);

    const getAllProblemCategories = useCallback(async (localSelectedLang, selectedCategoryId) => {
        toggleDisablePage(true);
        const categoryResult = await getProblemCategories();
        toggleLoadingCategories(false);
        if (categoryResult.data.errorCode) {
            toast.error(API_ERROR[categoryResult.data.errorCode]);
            toggleDisablePage(false);
            return;
        }

        const localCategories = [];
        let localCatObj = {};

        if (categoryResult.data.data && categoryResult.data.data.length) {
            categoryResult.data.data.forEach((each, index) => {
                each.hasValue = false;
                if (each.languageSet && each.languageSet.length) {
                    each.languageSet.forEach(each1 => {
                        localCatObj = {};
                        if (localSelectedLang) {
                            let matchCheck = false;
                            if (each1.languageRef.code === localSelectedLang.code) {
                                matchCheck = true;
                                localCatObj.name = each1.title;
                                each.hasValue = true;
                            }
                            if (matchCheck) {
                                localCatObj._id = each._id;
                                if (selectedCategoryId && each._id === selectedCategoryId) {
                                    localCatObj.selected = true;
                                    // selectedIndex = index;
                                } else {
                                    if (selectedCategory && each._id === selectedCategory) {
                                        localCatObj.selected = true;
                                        // selectedIndex = index;
                                    } else {
                                        localCatObj.selected = false;
                                    }
                                }
                                localCategories.push({ ...localCatObj });
                            }
                        }
                    });
                    // if selected language is not present then display eng
                    if (!each.hasValue) {
                        each.languageSet.forEach(each2 => {
                            if (each2.languageRef.name.trim().toLowerCase() === "english") {
                                localCatObj.name = each2.title;
                                localCatObj._id = each._id;
                                if (selectedCategoryId && each._id === selectedCategoryId) {
                                    localCatObj.selected = true;
                                    // selectedIndex = index;
                                } else {
                                    if (selectedCategory && each._id === selectedCategory) {
                                        localCatObj.selected = true;
                                        // selectedIndex = index;
                                    } else {
                                        localCatObj.selected = false;
                                    }
                                }
                                localCategories.push({ ...localCatObj });
                            }
                        });
                    }
                }
            });
            if (!selectedCategoryId && !selectedCategory && localCategories && localCategories.length) {
                localCategories[0].selected = true;
                updateSelectedCategory(localCategories[0]._id);
            }
            updateCategory([...localCategories]);
            let choosenCategoryId = localCategories[0]._id;
            const urlParams = new URLSearchParams(window.location.search);
            const catParam = urlParams.get('category');
            const langParam = urlParams.get('language');
            let localUrl = '/problem-area?';

            if (catParam) {
                choosenCategoryId = catParam;
            }

            localUrl += `category=${choosenCategoryId}`;

            if (langParam) {
                localUrl += `&language=${langParam}`;
            }
            props.history.push(localUrl);
            if (localCategories.length) {
                getAllProblemAreaList(selectedCategory ? selectedCategory : choosenCategoryId, localSelectedLang);
            }

            setTimeout(() => {
                displayNavs(true);
                // if (selectedIndex) {
                //     const el = document.getElementsByClassName("categories-wrapper")[0];
                //     el.scrollLeft = 120 * selectedIndex;
                // }
                // setTimeout(() => {
                //     displayNavs(true);
                // }, 1000);
            }, 1000);
        }
    }, [getAllProblemAreaList, selectedCategory, props.history, displayNavs]);

    const getAllLanguages = useCallback(async (localLangCode) => {
        toggleDisablePage(true);
        const languageResult = await getLanguages();
        if (languageResult.data.errorCode) {
            toast.error(API_ERROR[languageResult.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        if (languageResult.data.data && languageResult.data.data.errorCode) {
            toast.error(API_ERROR[languageResult.data.data.errorCode]);
            toggleDisablePage(false);
            return;
        }

        const localLanguages = [];
        let localLangObj = {};
        let localSelectedLang = {};
        if (languageResult.data.data && languageResult.data.data.data && languageResult.data.data.data.length) {
            languageResult.data.data.data.forEach(each => {
                localLangObj = {};
                localLangObj.name = each.name;
                localLangObj._id = each._id;
                localLangObj.code = each.code;
                localLanguages.push({ ...localLangObj });

                if (!localLangCode) {
                    if (each.name.trim().toLowerCase() === "english") {
                        changeSelectedLanguage({ ...each });
                        localSelectedLang = { ...each };
                    }
                } else {
                    if (each.code === localLangCode) {
                        changeSelectedLanguage({ ...each });
                        localSelectedLang = { ...each };
                    }
                }
            });
            updateLanguages([...localLanguages]);
            if (localLanguages.length) {
                let selectedCategoryId = undefined;
                const urlParams = new URLSearchParams(window.location.search);
                const catParam = urlParams.get('category');
                if (catParam) {
                    selectedCategoryId = catParam;
                }
                getAllProblemCategories(localSelectedLang, selectedCategoryId);
            }
        }
    }, [getAllProblemCategories]);

    useEffect(() => {
        if (!pageInitialized) {
            updateInitialization(true);
            let selectedLangCode = undefined;
            const urlParams = new URLSearchParams(window.location.search);
            const langParam = urlParams.get('language');
            if (langParam) {
                selectedLangCode = langParam;
            }
            getAllLanguages(selectedLangCode);
        }
    }, [getAllLanguages, pageInitialized]);

    const changeTags = () => {
        if (window.location.search.split("category=")[1] && problemAreaMetaData[window.location.search.split("category=")[1].split("&language")[0]]) {
            document.title = problemAreaMetaData[window.location.search.split("category=")[1].split("&language")[0]].title;
            const metaTags = document.getElementsByTagName("meta");
            if (metaTags[1].name ===  "title") {
                metaTags[1].content = problemAreaMetaData[window.location.search.split("category=")[1].split("&language")[0]].title;
            }
            if (metaTags[2].name ===  "description") {
                metaTags[2].content = problemAreaMetaData[window.location.search.split("category=")[1].split("&language")[0]].description;
            }
            if (metaTags[3].name ===  "keywords") {
                metaTags[3].content = problemAreaMetaData[window.location.search.split("category=")[1].split("&language")[0]].description;
            }
            if (metaTags[4]) {
                metaTags[4].content = problemAreaMetaData[window.location.search.split("category=")[1].split("&language")[0]].title;
            }
            if (metaTags[5]) {
                metaTags[5].content = problemAreaMetaData[window.location.search.split("category=")[1].split("&language")[0]].description;
            }
            if (metaTags[6]) {
                metaTags[6].content = orgMetaData.image;
            }
            if (metaTags[7]) {
                metaTags[7].content = window.location.href;
            }
        }
    };

    useEffect(() => {
        changeTags();
        
    }, []);

    const selectLanguage = (language) => {
        changeSelectedLanguage({ ...language });
        let selectedCategoryId = undefined;
        const urlParams = new URLSearchParams(window.location.search);
        const catParam = urlParams.get('category');
        let localUrl = '/problem-area?';
        if (catParam) {
            selectedCategoryId = catParam;
            localUrl += `category=${catParam}`;
            localUrl += `&language=${language.code}`;
        } else {
            localUrl += `language=${language.code}`;
        }
        props.history.push(localUrl);
        getAllProblemCategories({ ...language }, selectedCategoryId);
    };

    const selectCategory = (categoryData) => {
        const localCategories = [...categories];
        if (categoryData.selected) {
            // updateSelectedCategory("");
            // getAllProblemAreaList(undefined);
            // const urlParams = new URLSearchParams(window.location.search);
            // const langParam = urlParams.get('language');
            // let localUrl = '/problem-area?';

            // if (langParam) {
            //     localUrl += `language=${langParam}`;
            // }
            // props.history.push(localUrl);

            return;
        } else {
            updateSelectedCategory(categoryData._id);
            getAllProblemAreaList(categoryData._id);
            const urlParams = new URLSearchParams(window.location.search);
            const langParam = urlParams.get('language');
            let localUrl = '/problem-area?';

            if (langParam) {
                localUrl += `category=${categoryData._id}`;
                localUrl += `&language=${langParam}`;
            } else {
                localUrl += `category=${categoryData._id}`;
            }


            props.history.push(localUrl);
            changeTags();
        }
        localCategories.forEach(each => {
            if (each._id === categoryData._id) {
                each.selected = !each.selected;
            } else {
                each.selected = false;
            }
        });
        updateCategory([...localCategories]);
    };

    const clickFav = async (profData, ind) => {
        if (!isAuthorized) {
            toast.info("Please login to make the problem favourite");
            setTimeout(() => {
                const loginButton = document.getElementById("login-button");
                if (loginButton) {
                  loginButton.click();
                }
              }, 1000);
            return;
        }

        const localProfs = [...problemAreas];
        toggleDisablePage(true);
        let favProbResult = await markFavouriteProblem(profData._id, {favourite: !localProfs[ind].isFavourite});
        toggleDisablePage(false);
        if (favProbResult.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.errorCode]);
            return;
        }
        if (favProbResult.data.data && favProbResult.data.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.data.errorCode]);
            return;
        }
        localProfs[ind].isFavourite = !localProfs[ind].isFavourite;
        updateProblemAreaList([...localProfs]);
    };

    return (
        <div className={`row problem-area ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12 pad-0-below-500">
                <div className="row" style={{ margin: 0 }} onMouseEnter={() => { displayNavs(false) }}>
                    <div className={`col-sm-4 col-xs-12 categories-heading pb-0`}>
                        <div className={`mb-0 problem-category-heading`}>Categories</div>
                    </div>
                    <div className={`col-sm-8 col-xs-12 text-right language-dropdown-wrapper pb-0`}>
                        <button type="button" className="btn btn-outline-primary" onClick={openForum}>Forum</button>
                        <div className={`dropdown ${menuOpened ? 'show' : ''}`}>
                            <button className="btn btn-sm btn-secondary dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => openMenu(!menuOpened)}>
                                {selectedLanguage.name}
                            </button>
                            <ul className={`dropdown-menu ${menuOpened ? 'show' : ''}`} aria-labelledby="languageDropdown">
                                {languages.length && languages.map((language, index) => {
                                    return <li key={language.code} onClick={() => selectLanguage(language)}><span className={`dropdown-item ${index !== languages.length - 1 ? 'item-border' : ''}`}>{language.name}</span></li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="whole-categories-wrapper">
                    <div className={`categories-wrapper pl-5`} style={{ margin: 0 }}>
                        {categories.length ? categories.map((category, index) => {
                            return <div className={`custom-card ${index === categories.length - 1 ? 'mr-0' : ''} ${category.selected ? 'selected-card' : ''}`} key={index} onMouseEnter={() => { displayNavs(true) }} onClick={() => selectCategory(category)}>
                                <div id={`categoryName${index}`} className={`col-lg-12 pl-0 pr-0 category-name ${category.selected ? 'selected-card-name' : ''}`}>
                                    {category.name}
                                </div>
                            </div>
                        }) : <div className="text-center well col-12 color-black">
                            {!loadingCategories ? 'No Categories Available!' : 'loading...'}
                        </div>}

                    </div>

                </div>
                {categories.length ?
                    <div className="row ml-0 mr-0 mt-3">
                        <div style={{ margin: "0 auto" }}>
                            <button className="prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                                <span className='sr-only'>Previous Category Button</span>
                                <i className="fas fa-arrow-left"></i>
                            </button>
                            <button className="next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                                <span className='sr-only'>Next Category Button</span>
                                <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>

                    </div> : null}

                <div className="col-12 categories-heading" onMouseEnter={() => { displayNavs(false) }}>
                    <div className={`mb-0 common-problem-heading`}>Common Problems</div>
                </div>
                <div className="col-12" onMouseEnter={() => { displayNavs(false) }}>
                    <div className={`row professional-near-you-wrapper`} style={{ margin: 0 }}>
                        {problemAreas.length ?
                            problemAreas.map((professional, index) => {
                                return <div className={`col-12 professional-wrapper
                                `} key={index}>
                                    <div className="row ml-0 mr-0" onClick={() => selectAnswer({ ...professional })}>
                                        <div className="col-12 p-0">
                                            <div className="professional-designation">{professional.categoryName}
                                                {professional.isFavourite ?
                                                    <img src={require('../../../images/loved.png')} alt="sort down" onClick={e => { e.stopPropagation(); clickFav(professional, index) }} />
                                                    : <img src={require('../../../images/love.png')} alt="sort down" onClick={e => { e.stopPropagation(); clickFav(professional, index) }} />}

                                            </div>
                                            <div className="professional-name">{professional.question}</div>
                                            {!professional.selected ?
                                                <div className="professional-location">{professional.highlightedAnswer}</div> :
                                                null}
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingProblems ? 'No Problems Available!' : 'loading...'}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProblemArea;