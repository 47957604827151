import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_BOOKINGS = `${baseUrl}/account/customer/booking`;
export const JOIN_CALL = `${baseUrl}/account/customer/booking/video/token`;
export const GET_BOOKINGS_WITHOUT_TOKEN = `${baseUrl}/customer/common/slot-details`;
export const RATE_BOOKING = `${baseUrl}/customer/common/rate-appointment`;
export const RATE_EVENT = `${baseUrl}/customer/common/rate-event`;
export const SESSIONS = `${baseUrl}/account/customer/event`;
export const EVENTS = `${baseUrl}/account/customer/online-event`;
export const GET_SESSION_WITHOUT_TOKEN = `${baseUrl}/customer/common/event-booking-details`;
export const GET_EVENT_BOOKING_WITHOUT_TOKEN = `${baseUrl}/customer/common/online-event-booking-details`;
export const RATE_ONLINE_EVENT = `${baseUrl}/customer/common/rate-online-event`;
export const WORKSHOPS = `${baseUrl}/account/customer/workshop`;
export const GET_WORKSHOP_BOOKING_WITHOUT_TOKEN = `${baseUrl}/customer/common/workshop-booking-details`;
export const RATE_WORKSHOP = `${baseUrl}/customer/common/rate-workshop`;
export const APPOINTMENT = `${baseUrl}/account/customer`;

const localDate = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();

export const getUpcomingBookings = async () => {
  const res = await axios.post(`${GET_BOOKINGS}/upcoming?skip=0&limit=0`,{todaysDate: localDate});
  return res;
}

export const getPastBookings = async () => {
  const res = await axios.post(`${GET_BOOKINGS}/past?skip=0&limit=0`, {todaysDate: localDate});
  return res;
}

export const getBookingDetails = async (id) => {
  const res = await axios.get(`${GET_BOOKINGS}/details/${id}`);
  return res;
}

export const getWithoutAuthBookingDetails = async (id) => {
  const res = await axios.get(`${GET_BOOKINGS_WITHOUT_TOKEN}/${id}`);
  return res;
}

export const rateBook = async (id, requestBody) => {
  const res = await axios.put(`${RATE_BOOKING}/${id}`, requestBody);
  return res;
}

export const rateEvent = async (id, requestBody) => {
  const res = await axios.put(`${RATE_EVENT}/${id}`, requestBody);
  return res;
}

export const joinCall = async (requestBody) => {
  const res = await axios.post(`${JOIN_CALL}`, requestBody);
  return res;
}

export const cancelBook = async (id) => {
  const res = await axios.put(`${GET_BOOKINGS}/cancel/${id}`);
  return res;
}

export const rescheduleBook = async (id, requestBody) => {
  const res = await axios.put(`${GET_BOOKINGS}/reschedule/${id}`, requestBody);
  return res;
}

export const getSessionDetails = async (id) => {
  const res = await axios.get(`${SESSIONS}/booking/details/${id}`);
  return res;
}

export const getSessionDetailsWithoutAuth = async (id) => {
  const res = await axios.get(`${GET_SESSION_WITHOUT_TOKEN}/${id}`);
  return res;
}

export const getSessions = async (type) => {
  const res = await axios.post(`${SESSIONS}/bookings/${type}?skip=0&limit=0`, {todaysDate: localDate});
  return res;
}

export const joinSessionCall = async (id) => {
  const res = await axios.post(`${SESSIONS}/booking/join/${id}`);
  return res;
}

export const getEventBookingDetails = async (id) => {
  const res = await axios.get(`${EVENTS}/booking/details/${id}`);
  return res;
}

export const getEventsBookings = async (type) => {
  const res = await axios.post(`${EVENTS}/bookings/${type}?skip=0&limit=0`, {todaysDate: localDate});
  return res;
}

export const getEventBookingDetailsWithoutAuth = async (id) => {
  const res = await axios.get(`${GET_EVENT_BOOKING_WITHOUT_TOKEN}/${id}`);
  return res;
}

export const rateOnlineEvent = async (id, requestBody) => {
  const res = await axios.put(`${RATE_ONLINE_EVENT}/${id}`, requestBody);
  return res;
}

export const joinEventCall = async (id) => {
  const res = await axios.post(`${EVENTS}/booking/join/${id}`);
  return res;
}

export const getWorkshopBookingDetails = async (id) => {
  const res = await axios.get(`${WORKSHOPS}/booking/details/${id}`);
  return res;
}

export const getWorkshopsBookings = async (type) => {
  const res = await axios.post(`${WORKSHOPS}/bookings/${type}?skip=0&limit=0`, {todaysDate: localDate});
  return res;
}

export const joinWorkshopCall = async (id) => {
  const res = await axios.post(`${WORKSHOPS}/booking/join/${id}`);
  return res;
}

export const getWorkshopBookingDetailsWithoutAuth = async (id) => {
  const res = await axios.get(`${GET_WORKSHOP_BOOKING_WITHOUT_TOKEN}/${id}`);
  return res;
}

export const rateWorkshop = async (id, requestBody) => {
  const res = await axios.put(`${RATE_WORKSHOP}/${id}`, requestBody);
  return res;
}

export const joinAppointmentCall = async (id) => {
  const res = await axios.post(`${APPOINTMENT}/booking/join/${id}`);
  return res;
}