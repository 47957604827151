import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { getTestsDetails, bookTest, getTestBookingDetailsAPI } from "../../crud/home.crud";
import { toast } from 'react-toastify';
import {
  API_ERROR,
  REQUIRED_ERROR
} from "../../config/error-message";
import TestModal from './test-modal';
import { testColorScore, monthArr, assessmentMetaData } from "../../config/constants";
import AgeInfoModal from './age-info-modal';
import { getProfile } from "../../crud/auth.crud";

const defaultImage = require('../../../images/imgAvtr.png');
const reportCardBg = require('../../../images/report-card-bg.png');

const bookingAsList = [{
  value: "1",
  text: "Individual"
}, {
  value: "2",
  text: "Parent"
}, {
  value: "3",
  text: "Guardian"
}, {
  value: "6",
  text: "Educator"
}];

const ageList = [{
  value: "1",
  text: "1"
}];

const BookingForTypeConfig = {
  individual: '1',
  parent: '2',
  guardian: '3',
  others: '4',
  partner: '5',
  educator: '6'
};

const TargetUserConfig = {
  normal: 1,
  relationship: 2,
  individual: 3,
  parent: 4
};

const TestDetails = (props) => {
  const { isAuthorized, authData } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
      authData: auth.user
    }),
    shallowEqual
  );
  const [bookingDetails, updateBookingDetails] = useState({});
  const [disablePage, toggleDisablePage] = useState(false);
  const [loadingUpcomingEvents, toggleloadingUpcomingEvents] = useState(true);
  const [bookingFor, updateBookingFor] = useState("");
  const [bookingForError, updateBookingForError] = useState("");

  const [ageError, updateAgeError] = useState("");
  // const [classValue, updateClass] = useState("");
  const [gender, changeGender] = useState(0);
  const [whoAreYouBookingFor, changeBookingFor] = useState(0);
  const [selectedBookingAs, updateSelectedBookingAs] = useState(bookingAsList[0].value);
  const [displayTestModal, toggleTestModal] = useState(false);
  const [questionAnswerSet, updateQuestionAnswerSet] = useState([]);
  const [questionAnswerSet2, updateQuestionAnswerSet2] = useState([]);
  const [testDetails, updateTestDetails] = useState({});
  const [currentIndex, updateCurrentIndex] = useState(0);
  const [showPrevButtons, togglePrevButton] = useState(false);
  const [showNextButtons, toggleNextButton] = useState(true);
  const [categories, updateCategory] = useState([]);
  const [finalResult, updateFinalResult] = useState({});
  const [bookingAsLists, updateBookingList] = useState([...bookingAsList]);
  const [ages, updateAges] = useState([...ageList]);
  const [age, updateAge] = useState(ageList[0].value);
  const [openAgeInfoModal, toggleAgeInfoModal] = useState(false);

  const getTestDetails = useCallback(async (id) => {
    toggleDisablePage(true);
    const reqBody = {};
    if (isAuthorized) {
      reqBody.customerRef = authData._id;
    }
    const upcomingBookingResult = await getTestsDetails(id);

    toggleDisablePage(false);
    toggleloadingUpcomingEvents(false);
    if (upcomingBookingResult.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
      return;
    }
    if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
      return;
    }

    const responseBookingDetails = { ...upcomingBookingResult.data.data };

    const localBookingDetails = {
      image: responseBookingDetails.imageLink,
      title: responseBookingDetails.title,
      description: responseBookingDetails.description,
      _id: props.match.params.testId,
      targetCustomerType: responseBookingDetails.targetCustomerType,
      ageInfo: responseBookingDetails.ageInfo,
      ageRange: responseBookingDetails.ageRange
    };

    if (localBookingDetails.targetCustomerType === TargetUserConfig.relationship) {
      const localBookingListArray = [{
        value: "1",
        text: "Individual"
      }];
      updateBookingList([...localBookingListArray]);
      updateSelectedBookingAs(localBookingListArray[0].value);
      selectBookingFor(1);
      changeBookingFor(1);
    } else if (localBookingDetails.targetCustomerType === TargetUserConfig.individual || localBookingDetails.targetCustomerType === TargetUserConfig.parent) {
      const localBookingListArray = [{
        value: "1",
        text: "Individual"
      }];
      updateBookingList([...localBookingListArray]);
      updateSelectedBookingAs(localBookingListArray[0].value);
      if ((authData && authData.personalInfo)) {
        updateBookingFor(authData.personalInfo.name);
        if (authData.personalInfo.dob) {
          updateAge(getAge(authData.personalInfo.dob));
        }
        if (authData.personalInfo.gender) {
          changeGender(authData.personalInfo.gender);
        }
      }
    }

    updateBookingDetails({ ...localBookingDetails });
    setTimeout(() => {
      if (document.getElementsByClassName("carousel")[1]) {
        document.getElementsByClassName("carousel")[1].remove();
      }
      if (document.getElementsByClassName("carousel-status")[0]) {
        document.getElementsByClassName("carousel-status")[0].remove();
      }
      if (document.getElementsByClassName("control-dots")[0]) {
        document.getElementsByClassName("control-dots")[0].remove();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData, isAuthorized]);

  const getAge = (birthDate) => {
    const dob = new Date(birthDate);
    const month_diff = Date.now() - dob.getTime();
    const age_dt = new Date(month_diff);
    const year = age_dt.getUTCFullYear();
    const age = Math.abs(year - 1970);
    return age !== 0 ? age.toString() : "";
  };

  const getFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const year = date.getFullYear();

    return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
  };

  const getTestBookingDetails = useCallback(async (id) => {
    toggleDisablePage(true);
    const upcomingBookingResult = await getTestBookingDetailsAPI(id);

    toggleDisablePage(false);
    toggleloadingUpcomingEvents(false);
    if (upcomingBookingResult.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
      return;
    }
    if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
      return;
    }

    const responseBookingDetails = { ...upcomingBookingResult.data.data };

    const localBookingDetails = {
      image: responseBookingDetails.testRef.imageLink,
      title: responseBookingDetails.testRef.title,
      description: responseBookingDetails.testRef.description,
      _id: props.match.params.testId,
    };

    updateBookingDetails({ ...localBookingDetails });
    updateFinalResult({
      state: responseBookingDetails.result.state,
      statusText: responseBookingDetails.result.title,
      testGivenBy: responseBookingDetails.bookingForName,
      testGivenDate: getFullDateFormat(new Date(responseBookingDetails.createdAt))
    });
    const localVendorsRef = responseBookingDetails.testRef.vendorReferences;
    const localVendors = [];
    if (localVendorsRef.length) {
      localVendorsRef.forEach(each => {
        localVendors.push({
          name: each.vendorId.personalInfo.name,
          profilePhoto: each.vendorId.personalInfo.profilePicture && each.vendorId.personalInfo.profilePicture.length ? each.vendorId.personalInfo.profilePicture : defaultImage,
          _id: each.vendorId._id,
          order: each.order
        });
      });
    }
    updateCategory([...localVendors]);

    setTimeout(() => {
      if (document.getElementsByClassName("carousel")[1]) {
        document.getElementsByClassName("carousel")[1].remove();
      }
      if (document.getElementsByClassName("carousel-status")[0]) {
        document.getElementsByClassName("carousel-status")[0].remove();
      }
      if (document.getElementsByClassName("control-dots")[0]) {
        document.getElementsByClassName("control-dots")[0].remove();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData, isAuthorized]);

  useEffect(() => {
    if (props.match.params.testId) {
      if (!window.location.pathname.includes("result")) {
        document.title = assessmentMetaData[props.match.params.testId]?.title;
        getTestDetails(props.match.params.testId);
      } else {
        getTestBookingDetails(props.match.params.testId);
      }
    }
  }, [getTestDetails, getTestBookingDetails, props.match.params.testId]);

  useEffect(() => {
    if ((authData && authData.personalInfo) && (selectedBookingAs === BookingForTypeConfig.individual || whoAreYouBookingFor === 1)) {
      updateBookingFor(authData.personalInfo.name);
      if (authData.personalInfo.dob) {
        updateAge(getAge(authData.personalInfo.dob));
      }
      if (authData.personalInfo.gender) {
        changeGender(authData.personalInfo.gender);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  const validateBookingFor = () => {
    let noError = true;
    if (!bookingFor.trim().length) {
      updateBookingForError(REQUIRED_ERROR);
      noError = false;
    }
    if (!age.trim().length) {
      updateAgeError(REQUIRED_ERROR);
      noError = false;
    }
    return noError;
  };

  const saveTest = async (paymentData) => {
    if (!isAuthorized) {
      toast.info("Please login to take this assessment.");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }

    if (authData && !authData.emailVerified) {
      const profileResult = await getProfile();
      if (profileResult.data.data && !profileResult.data.data.emailVerified) {
        toast.error("Please verify your email from profile");
        return;
      }
    }

    let requestBody = {};

    if (!paymentData) {
      let localAge = undefined;
      if (age) {
        localAge = age !== "18+" ? Number(age) : 18;
      }
      if (!validateBookingFor()) {
        return;
      }

      if (!localAge) {
        toast.error("Age cannot be zero");
        return;
      }
      requestBody = {
        bookingForName: bookingFor,
        bookingForType: Number(selectedBookingAs),
        age: localAge
      };

      if (gender) {
        requestBody.gender = gender;
      }

      // if (classValue && classValue.length) {
      //     requestBody.class = classValue;
      // }
    } else {
      requestBody = { ...paymentData };
    }

    if (bookingDetails.targetCustomerType === TargetUserConfig.relationship && whoAreYouBookingFor === 2) {
      requestBody.bookingForType = Number(BookingForTypeConfig.partner);
    }

    toggleDisablePage(true);

    const bookSlotResult = await bookTest(requestBody, bookingDetails._id);
    toggleDisablePage(false);
    if (bookSlotResult.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.errorCode]);
      return;
    }
    if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
      return;
    }

    updateTestDetails({
      title: bookingDetails.title,
      description: bookSlotResult.data.data.questionSetType !== 2 ? bookSlotResult.data.data.questionSet.description : 
      bookSlotResult.data.data.questionSetType2.description,
      bookingId: bookSlotResult.data.data._id,
      questionSetType: bookSlotResult.data.data.questionSetType
    });
    if (bookSlotResult.data.data.questionSetType !== 2) {
      updateQuestionAnswerSet([...bookSlotResult.data.data.questionSet.questions]);
    } else {
      updateQuestionAnswerSet2([...bookSlotResult.data.data.questionSetType2.questionStory]);
    }
    
    toggleTestModal(true);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const selectBookingFor = (type) => {
    if (type === 1) {
      if (authData && authData.personalInfo) {
        updateBookingFor(authData.personalInfo.name);
        if (authData.personalInfo.dob) {
          updateAge(getAge(authData.personalInfo.dob));
        }
        if (authData.personalInfo.gender) {
          changeGender(authData.personalInfo.gender);
        } else {
          changeGender(0);
        }
      }
    } else {
      const minAge = bookingDetails.ageRange.min;
      const maxAge = bookingDetails.ageRange.max;
      const localAges = [];
      if (bookingDetails.ageRange.showMaxRange) {
        for (let i = minAge; i <= maxAge; i++) {
          localAges.push({
            value: i.toString(),
            text: i.toString()
          });
        }
      } else {
        for (let i = minAge; i <= 17; i++) {
          localAges.push({
            value: i.toString(),
            text: i.toString()
          });
        }
        localAges.push({
          value: "18+",
          text: "18+"
        });
      }
      updateAges([...localAges]);
      updateAge(localAges[0].value);
      updateBookingFor("");
      changeGender(0);
    }
    updateBookingForError("");
    updateAgeError("");
  };

  const selectBookingAs = (e) => {
    updateSelectedBookingAs(e.target.value);
    if (e.target.value === BookingForTypeConfig.individual) {
      if (authData && authData.personalInfo) {
        updateBookingFor(authData.personalInfo.name);
        if (authData.personalInfo.dob) {
          updateAge(getAge(authData.personalInfo.dob));
        }
        if (authData.personalInfo.gender) {
          changeGender(authData.personalInfo.gender);
        } else {
          changeGender(0);
        }
      }
    } else {
      selectBookingFor(1);
      changeBookingFor(1);
    }
    updateBookingForError("");
    updateAgeError("");
  };

  const chooseAge = (e) => {
    updateAge(e.target.value);
  };

  const closeTestModal = (closingIndex, endText) => {
    toggleTestModal(false);
    updateCurrentIndex(0);
    if (endText) {
      props.history.push('/assessment-list');
    }
  };

  const handleNextPrevButtons = (type) => {
    if (document.getElementsByClassName(type)[0]) {
      if (document.getElementsByClassName(type)[0].clientWidth < document.getElementsByClassName(type)[0].scrollWidth) {
        if (type === "categories-wrapper") {
          togglePrevButton(true);
          toggleNextButton(true);
        }

        if (document.getElementsByClassName(type)[0].scrollLeft === 0) {
          if (type === "categories-wrapper") {
            togglePrevButton(false);
          }
        }
        if (Math.round(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.round(document.getElementsByClassName(type)[0].scrollLeft) ||
          Math.floor(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.floor(document.getElementsByClassName(type)[0].scrollLeft)) {
          if (type === "categories-wrapper") {
            toggleNextButton(false);
          }
        }

      } else {
        if (type === "categories-wrapper") {
          togglePrevButton(false);
          toggleNextButton(false);
        }
      }
    }
  };

  const sideScroll = (element, direction, speed, distance, step, type) => {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        handleNextPrevButtons(type);
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  const nextClick = (type) => {
    var container = document.getElementsByClassName(type)[0];
    sideScroll(container, 'right', 25, 129, 10, type);
  };

  const prevClick = (type) => {
    var container = document.getElementsByClassName(type)[0];
    sideScroll(container, 'left', 25, 129, 10, type);
  };

  window.onresize = () => {
    handleNextPrevButtons("categories-wrapper");
  };

  window.onload = () => {
    handleNextPrevButtons("categories-wrapper");
  };

  const displayNavs = useCallback((bool) => {
    if (bool) {
      handleNextPrevButtons("categories-wrapper");
    }
  }, []);

  return (
    <div className={`row problem-area booking-list test-details ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      <div className="col-lg-12">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-12 categories-heading">
            <h4 className={`mb-0 problem-category-heading`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>{!window.location.pathname.includes("result") ? 'Self Assessment' : 'Assessment Details'}</h4>
          </div>
        </div>
        <div className="row" style={{ margin: 0 }}>
          <div className="col-12">
            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
              {Object.keys(bookingDetails).length ?
                <div className="w-100 event-list-item-wrapper pos-relative" style={{ margin: 0 }}>
                  {bookingDetails.image ?
                    <div className="pos-relative w-100">
                      <Carousel>
                        <div>
                          <img src={bookingDetails.image} alt={`Test`} />
                        </div>
                      </Carousel>
                    </div> : null}

                  <div className="col-12 p-3 xevent-list-item-description-wrapper">
                    <div className="row m-0 booking-item-side-row">
                      <div className="col-12 pr-0 pl-0">
                        <div className="row m-0">
                          <div className="col-12 professional-name pl-0">
                            {bookingDetails.title}
                          </div>
                          <div className="col-12 pl-0 professional-date-time">{bookingDetails.description.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br /></span>
                          })}</div>
                        </div>
                      </div>
                      {window.location.pathname.includes("result") ? <>
                        <div className='col-md-6 col-xs-12 mt-3' style={{ margin: "0 auto" }}>
                          <div className='row m-0'>
                            <img src={reportCardBg} alt="Report Card" style={{ width: "200px", margin: "0 auto -10px" }} />
                          </div>
                          <div className="row m-0 test-completed-booking-wrapper wrapper-2">
                            <h5 className="col-12" style={{ color: "#fff", padding: "20px 20px 0" }}>Self Assessment Report</h5>
                            <div className='col-12 p-0'>
                              <div className='row m-0'>
                                <div className='invoice-labels mb-1 col-7'>Assessment taken</div>
                                <div className='invoice-labels value mb-1 col-5 text-right'>{finalResult.testGivenDate}</div>
                                <div className='invoice-labels mb-1 col-7'>Assessment given by</div>
                                <div className='invoice-labels value mb-1 col-5 text-right'>{finalResult.testGivenBy}</div>
                                <div className='invoice-total col-12 info' style={{ backgroundColor: testColorScore[finalResult.state] }}>{finalResult.statusText}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {categories.length && finalResult.state !== 1 ?
                          <div className='col-12 p-0'>
                            <div className={`row ml-0 mr-0 categories-heading pb-0 pr-0 pl-0`} onMouseEnter={() => { displayNavs(true) }}>
                              <h4 className={`col-sm-7 col-xs-12 pl-0 mb-0 pt-3 home-page-header display-inline-block professional-name`}>Recommended Professionals</h4>
                              {categories.length ?
                                <div className="col-sm-5 col-xs-12 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                  <button className="prev-button-wrapper" onClick={() => prevClick("categories-wrapper")} id="slideprev" disabled={!showPrevButtons}>
                                    <i className="fas fa-arrow-left"></i>
                                  </button>
                                  <button className="next-button-wrapper" onClick={() => nextClick("categories-wrapper")} id="slide" disabled={!showNextButtons}>
                                    <i className="fas fa-arrow-right"></i>
                                  </button>
                                </div> : null}
                            </div>
                            <div className="whole-categories-wrapper p-0">
                              <div className={`categories-wrapper pl-5`} style={{ margin: 0 }}>
                                {categories.length ? categories.map((category, index) => {
                                  return <div className={`custom-card prof-card ${index === categories.length - 1 ? 'mr-0' : ''} ${category.selected ? 'selected-card' : ''}`} key={index} onMouseEnter={() => { displayNavs(true) }} onClick={() => window.open(`${window.location.origin}/professional-details/${category._id}/${category.name.split(" ").join("-")}`)}>
                                    <div className="col-lg-12 p-0">
                                      <div style={{ backgroundImage: "url(" + category.profilePhoto + ")" }} className="background-image-category"></div>
                                    </div>
                                    <div className={`col-lg-12 pl-0 pr-0 category-name ${category.selected ? 'selected-card-name' : ''}`}>
                                      {category.name}
                                    </div>
                                  </div>
                                }) : null}

                              </div>
                            </div>
                          </div> : null}
                        <div className='col-12 pr-0 pl-0 pt-2'>
                          <b>Disclaimer: </b>The following self-assessment is <b>NOT</b> a diagnostic tool. The self-assessment results cannot be substituted for a diagnose or to treat mental health conditions. The results can change over time based on your experience and other factors. Parentcraft India Pvt Ltd,sponsors, partners, and advertisers disclaim any liability, loss, or risk incurred as a consequence, directly or indirectly, from the use of this application / self-assessment.
                        </div>
                      </> : <>
                        {!window.location.pathname.includes("result") ?
                          <div className='col-md-6 col-xs-12 test-form-wrapper mt-3'>
                            <div className="row m-0">
                              {!window.location.pathname.includes("result") ?
                                <>
                                  <h5 style={{ color: "#000" }}>Personal Details</h5>
                                  <h6 className="col-12 p-0">Please share all the following details and submit in order to appear the self assessment.</h6>
                                  <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label fee-label">Booking as</label>
                                    <select className="form-select form-control" aria-label="Service Time" value={selectedBookingAs} onChange={selectBookingAs}>
                                      {bookingAsLists.map((loc, index) => {
                                        return <option key={index} value={loc.value}>{loc.text}</option>
                                      })}
                                    </select>
                                    {bookingDetails.targetCustomerType === TargetUserConfig.parent ? <label style={{ fontSize: "10px" }} className="mb-0">Only to be taken as individual parents</label> : null}
                                  </div>
                                  {(selectedBookingAs !== BookingForTypeConfig.individual || (selectedBookingAs === BookingForTypeConfig.individual && bookingDetails.targetCustomerType === TargetUserConfig.relationship)) ?
                                    <div className="mb-3 col-sm-6 col-xs-12 pl-0">
                                      <div>
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Booking for</label>
                                      </div>
                                      <div className="form-check mt-2">
                                        <input className="form-check-input" type="radio" name="whoAreYouBookingFor" id="bookingFor1" value="Self" checked={whoAreYouBookingFor === 1} onChange={() => { changeBookingFor(1); selectBookingFor(1) }} />
                                        <label className="form-check-label" htmlFor="bookingFor1">
                                          Self
                                        </label>
                                      </div>
                                      <div className="form-check mt-2">
                                        <input className="form-check-input" type="radio" name="whoAreYouBookingFor" id="bookingFor2" value="A child" checked={whoAreYouBookingFor === TargetUserConfig.relationship} onChange={() => { changeBookingFor(2); selectBookingFor(2) }} />
                                        <label className="form-check-label" htmlFor="bookingFor2">
                                          {bookingDetails.targetCustomerType === TargetUserConfig.relationship ? 'Partner' : 'A child'}
                                        </label>
                                      </div>
                                    </div> : null}
                                  <div className="col-12 pl-0 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label fee-label">Name of person taking self assessment</label>
                                    <input type="text" placeholder='Full Name' className={`form-control ${bookingForError ? 'error-border' : ''}`} value={bookingFor} onChange={e => { updateBookingForError(""); updateBookingFor(e.target.value) }}
                                      disabled={(selectedBookingAs === BookingForTypeConfig.individual && bookingDetails.targetCustomerType !== 2) || whoAreYouBookingFor === 1} />
                                    {bookingForError ?
                                      <div className="input-error">{bookingForError}</div> : null}
                                  </div>
                                  <div className="col-12 pl-0 mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label fee-label">Age of person taking self assessment
                                      {(selectedBookingAs === BookingForTypeConfig.individual && bookingDetails.targetCustomerType !== 2) || whoAreYouBookingFor === 1 ?
                                        <i className="fa fa-info-circle pull-right cursor-pointer ml-2" style={{ fontSize: "16px" }} aria-hidden="true" onClick={() => toggleAgeInfoModal(true)}></i> : null}
                                    </label>
                                    {(selectedBookingAs === BookingForTypeConfig.individual && bookingDetails.targetCustomerType !== 2) || whoAreYouBookingFor === 1 ?
                                      <input type="text" className={`form-control ${ageError ? 'error-border' : ''}`} value={age} placeholder='Age' onChange={e => { updateAgeError(""); updateAge(e.target.value.replace(/\D/, '')) }}
                                        disabled /> :

                                      <select className="form-select form-control" aria-label="Service Time" value={age} onChange={chooseAge}
                                      >
                                        {ages.map((loc, index) => {
                                          return <option key={index} value={loc.value}>{loc.text}</option>
                                        })}
                                      </select>}

                                    {bookingDetails.ageInfo ? <label style={{ fontSize: "10px" }} className="mb-0">{bookingDetails.ageInfo}</label> : null}
                                    {ageError ?
                                      <div className={`input-error ${bookingDetails.ageInfo ? 'mt-0' : ''}`}>{ageError}</div> : null}
                                  </div>
                                  {/* <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                                                                        <label htmlFor="exampleFormControlInput1" className="form-label fee-label">Class <span style={{ fontSize: "10px" }}>(Optional)</span></label>
                                                                        <input type="text" placeholder='Mention Class' className={`form-control`} value={classValue} onChange={e => { updateClass(e.target.value) }} />
                                                                    </div> */}
                                  <div className="mb-3 col-12 pl-0">
                                    <div>
                                      <label htmlFor="exampleFormControlInput1" className="form-label">Gender <span style={{ fontSize: "10px" }}>(Optional)</span></label>
                                    </div>
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="genderForTest" id="help1" value="Male" checked={gender === 1} onChange={() => changeGender(1)} disabled={(selectedBookingAs === BookingForTypeConfig.individual && bookingDetails.targetCustomerType !== 2) || whoAreYouBookingFor === 1} />
                                      <label className="form-check-label" htmlFor="help1">
                                        Male
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="genderForTest" id="help2" value="Female" checked={gender === 2} onChange={() => changeGender(2)} disabled={(selectedBookingAs === BookingForTypeConfig.individual && bookingDetails.targetCustomerType !== 2) || whoAreYouBookingFor === 1} />
                                      <label className="form-check-label" htmlFor="help2">
                                        Female
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="genderForTest" id="help2" value="Other" checked={gender === 3} onChange={() => changeGender(3)} disabled={(selectedBookingAs === BookingForTypeConfig.individual && bookingDetails.targetCustomerType !== 2) || whoAreYouBookingFor === 1} />
                                      <label className="form-check-label" htmlFor="help2">
                                        Other
                                      </label>
                                    </div>
                                  </div>
                                </> : null
                                // <h5 className="col-12 p-0" style={{ color: "#374561" }}>Invoice Details</h5>
                              }
                              {!window.location.pathname.includes("result") ? <>
                                <div className="col-12 booking-details-change-button pay-now-button text-center pt-2 pb-2">
                                  <button type="button" style={{ float: "inherit" }} className="btn btn-primary details-button" onClick={() => saveTest()}>Continue</button>
                                </div> </> :
                                null}
                            </div>
                          </div> : null}
                      </>}
                    </div>
                  </div>
                </div>
                : <div className="text-center well col-12 color-black">
                  {!loadingUpcomingEvents ? 'No Details Available!' : 'loading...'}
                </div>}
            </div>
          </div>
        </div>
      </div>
      {displayTestModal ?
        <TestModal 
        displayModal={displayTestModal} 
        questionAnswerSet={questionAnswerSet} 
        updateQuestionAnswerSet={updateQuestionAnswerSet}
        questionAnswerSet2={questionAnswerSet2} 
        updateQuestionAnswerSet2={updateQuestionAnswerSet2} 
        currentIndex={currentIndex} 
        toggleTestModal={closeTestModal} 
        parentUpdateIndex={updateCurrentIndex} 
        testDetails={testDetails} 
        /> : null}
      {openAgeInfoModal ?
        <AgeInfoModal displayModal={openAgeInfoModal} toggleInfoModal={toggleAgeInfoModal} /> : null}
    </div>
  )
}

export default TestDetails;