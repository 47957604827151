import React from 'react';
import "../admins/problem-area.css";

const NoReviewConfirmation = (props) => {
    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal fade ${props.displayNoReviewModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirmation</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: 0 }}>
                                <h5 className="col-12 p-0 mb-3" style={{ color: "#000" }}>
                                    Are you sure you want to submit the feedback without any review?
                                </h5>
                            </div>

                            <div className="col-12 p-0 text-right mt-3">
                                <button type="button" className="btn btn-outline-primary mr-2" onClick={() => props.toggleNoReview(false)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={() => {props.toggleNoReview(false); props.submitReview(true)}}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoReviewConfirmation;