import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getProfile } from "../../crud/auth.crud";
import { paytmUrl } from "../../crud/api.util";
import { notification, getWorkshopDetails, bookWorkshop } from "../../crud/home.crud";
import { getWorkshopBookingDetails } from "../../crud/booking.crud";
import { joinWorkshopCall } from "../../crud/booking.crud";
import {
  timeArrConst,
  Config,
  monthArr,
  convertTwelveToZeroAM
} from "../../config/constants";
import { toast } from 'react-toastify';
import {
  API_ERROR,
  WORKSHOP_TIME_PASSED,
  CALL_TIME_PASSED
} from "../../config/error-message";
import PaymentModal from "../home/payment-modal";
import ShareToSocialModal from './shareToSocialModal';

const defaultImage = require('../../../images/imgAvtr.png');
const timeArr = [...timeArrConst];

const WorkshopDetails = (props) => {
  const { isAuthorized, authData } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
      authData: auth.user
    }),
    shallowEqual
  );
  const [bookingDetails, updateBookingDetails] = useState({});
  const [disablePage, toggleDisablePage] = useState(false);
  const [loadingUpcomingEvents, toggleloadingUpcomingEvents] = useState(true);
  // const [currentImageIndex, updateCurrentImageIndex] = useState(0);
  const [paymentInitialized, togglePaymentInitialization] = useState(false);
  const [openPaymentPopup, togglePaymentPopup] = useState(false);
  const [popupBookingDetails, updatePopupBookingDetails] = useState({});
  const [bookingFor, updateBookingFor] = useState("");
  const [displayShareModal, toggleShareModal] = useState(false);
  // const [globalConfigData, updateGlobalConfig] = useState({});
  const [isCancellationChecked, toggleCancellation] = useState(false);
  const [isJoinInfoDisplayed, showJoinInfo] = useState(false);
  const [stars, updateRatingStars] = useState([{
    selected: false
  }, {
    selected: false
  }, {
    selected: false
  }, {
    selected: false
  }, {
    selected: false
  }]);

  const getTime = (timeStr, date) => {
    const time = timeStr.slice(0, timeStr.length - 3)
    const amPm = timeStr.slice(timeStr.length - 2)
    let d = new Date();
    if (date) {
      d = new Date(date);
    }
    if (amPm === "AM" && time.split(":")[0] === "12") {
      return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    }
    if (amPm === "PM" && time.split(":")[0] !== "12") {
      return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    }
    return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
  };

  const checkCurrentDay = (dates, type) => {
    const today = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();

    if (dates && dates.length) {
      return dates.includes(today);
    } else {
      return false;
    }
  };

  const checkLastDay = (dates) => {
    const today = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();

    if (dates && dates.length) {
      return dates[dates.length - 1] === today;
    } else {
      return false;
    }
  };

  const checkCurrentTime = useCallback((times) => {
    const today = new Date().getTime();
    return getTime(timeArr[times[0] - 1]) > today;
  }, []);

  const getFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getStartTime = (times) => {
    times.sort((a, b) => { return a - b });
    return convertTwelveToZeroAM(timeArr[times[0] - 1]);
  };

  const getEndTime = (times) => {
    times.sort((a, b) => { return a - b });
    return convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
  };

  const checkJoiningCallTime = useCallback((localBookingData) => {
    if (checkCurrentDay(localBookingData.dates)) {
      const today = new Date().getTime();
      const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
      const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
      return (startTime <= today && endTime > today) && localBookingData.isBooked && !localBookingData.isExpired;
    }
    return false;
  }, []);

  const checkStatusExpired = useCallback((localBookingData) => {
    const today = new Date().getTime();
    const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);

    const lastDateDay = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getDate();
    const lastDateMonth = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getMonth();
    const lastDateYear = new Date(localBookingData.dates[localBookingData.dates.length - 1]).getFullYear();

    const lastDateEndTime = new Date(new Date(new Date(endTime).setDate(lastDateDay)).setMonth(lastDateMonth)).setFullYear(lastDateYear);

    return (today > lastDateEndTime);
  }, []);

  const getDisplayFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const year = date.getFullYear();

    return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
  };

  const getBookingDetails = useCallback(async (id) => {
    toggleDisablePage(true);
    const reqBody = {};
    if (isAuthorized) {
      reqBody.customerRef = authData._id;
    }
    let upcomingBookingResult = null;

    if (!window.location.pathname.includes('booking')) {
      upcomingBookingResult = await getWorkshopDetails(id, reqBody);
    } else {
      upcomingBookingResult = await getWorkshopBookingDetails(id);
    }
    let globalConfigResult = {};
    if (!window.location.pathname.includes('booking')) {
      globalConfigResult = await notification();
    }

    toggleDisablePage(false);
    toggleloadingUpcomingEvents(false);
    if (upcomingBookingResult.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
      return;
    }
    if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
      return;
    }

    let responseBookingDetails = {};
    if (!window.location.pathname.includes('booking')) {
      responseBookingDetails = { ...upcomingBookingResult.data.data };
    } else {
      responseBookingDetails = { ...upcomingBookingResult.data.data.workshopRef };
    }

    document.title = responseBookingDetails.title;

    const localBookingDetails = {
      images: responseBookingDetails.images.map(img => img.url),
      title: responseBookingDetails.title,
      description: responseBookingDetails.description,
      dates: responseBookingDetails.dates,
      "startDate": getFullDateFormat(new Date(responseBookingDetails.dates[0])),
      displayStartDate: getDisplayFullDateFormat(new Date(responseBookingDetails.dates[0])),
      "endDate": getFullDateFormat(new Date(responseBookingDetails.dates[responseBookingDetails.dates.length - 1])),
      displayEndDate: getDisplayFullDateFormat(new Date(responseBookingDetails.dates[responseBookingDetails.dates.length - 1])),
      startTime: getStartTime(responseBookingDetails.slots),
      endTime: getEndTime(responseBookingDetails.slots),
      vendorName: upcomingBookingResult.data.data.vendorRef.personalInfo.name,
      vendorId: upcomingBookingResult.data.data.vendorRef._id,
      profileVisible: upcomingBookingResult.data.data.vendorRef.profileVisible !== undefined ? upcomingBookingResult.data.data.vendorRef.profileVisible : true,
      totalSeats: responseBookingDetails.numberOfSeats,
      availableSeats: responseBookingDetails.availableSeats,
      rating: upcomingBookingResult.data.data.ratingDetails && upcomingBookingResult.data.data.ratingDetails.rating ? upcomingBookingResult.data.data.ratingDetails.rating.toFixed(1) : 0,
      ratingData: upcomingBookingResult.data.data.ratingDetails,
      fee: responseBookingDetails.fees,
      slots: responseBookingDetails.slots,
      _id: responseBookingDetails._id,
      isBooked: upcomingBookingResult.data.data.paymentDetails || upcomingBookingResult.data.data.isBooked ? true : false,
      convenienceFee: 0,
      status: upcomingBookingResult.data.data.status,
      bookingId: responseBookingDetails.bookingId,
      eventLink: responseBookingDetails.meetingLink,
      meetingPlatform: responseBookingDetails.meetingPlatform,
      gst: 0,
      total: 0,
      profilePicture: responseBookingDetails.images[0].url
    };

    if (localBookingDetails.rating) {
      const localRating = [...stars];
      for (let i = 1; i <= 5; i++) {
        if (i <= localBookingDetails.ratingData.rating) {
          localRating[i - 1].selected = true;
        }
      }
      updateRatingStars([...localRating]);
    }

    if (window.location.pathname.includes('booking')) {
      localBookingDetails.bookingId = upcomingBookingResult.data.data._id;
      localBookingDetails.bookingNumber = upcomingBookingResult.data.data.bookingNumber || "";
    }

    if (responseBookingDetails.paymentStatus) {
      localBookingDetails.paymentStatus = responseBookingDetails.paymentStatus;
    }

    let convenienceFee = 0;
    let gst = 0;

    if (globalConfigResult?.data?.data) {
      convenienceFee = globalConfigResult.data.data.convenienceFee ? globalConfigResult.data.data.convenienceFee : 0;
      gst = globalConfigResult.data.data.gstPercentage ?
        (convenienceFee) * (globalConfigResult.data.data.gstPercentage / 100) : 0;
    }

    if (!window.location.pathname.includes('booking') && globalConfigResult.data.data && globalConfigResult.data.data.promoCodeGeneral && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.workshop && responseBookingDetails && responseBookingDetails.vendorRef && responseBookingDetails.vendorRef.promoCodeInfo && responseBookingDetails.vendorRef.promoCodeInfo.allowDiscount && authData && authData.promoCodeInfo && authData.promoCodeInfo.institutionRef) {
      localBookingDetails.discountedPrice = localBookingDetails.fee * ((100 - globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.workshop) / 100);
    }

    if (!window.location.pathname.includes('booking')) {
      localBookingDetails.convenienceFee = parseFloat(convenienceFee);
      localBookingDetails.gst = parseFloat(gst);
      localBookingDetails.total = parseFloat((!localBookingDetails.discountedPrice ? localBookingDetails.fee : localBookingDetails.discountedPrice) + localBookingDetails.convenienceFee + localBookingDetails.gst);
    } else {
      localBookingDetails.fee = upcomingBookingResult.data.data.paymentDetails.serviceFee;
      localBookingDetails.convenienceFee = upcomingBookingResult.data.data.paymentDetails.convenienceFee;
      localBookingDetails.gst = upcomingBookingResult.data.data.paymentDetails.gst;
      localBookingDetails.total = upcomingBookingResult.data.data.paymentDetails.total;
    }

    localBookingDetails.isExpired = false;
    localBookingDetails.isCertificate = responseBookingDetails.isCertificate;

    if (localBookingDetails.isBooked && checkStatusExpired(responseBookingDetails)) {
      localBookingDetails.isExpired = true;
    }

    if (!localBookingDetails.isBooked && checkLastDay(localBookingDetails.dates)) {
      if (!checkCurrentTime(localBookingDetails.slots)) {
        localBookingDetails.isExpired = true;
      }
    }

    localBookingDetails.isCallJoinable = checkJoiningCallTime(localBookingDetails);

    updateBookingDetails({ ...localBookingDetails });
    setTimeout(() => {
      if (document.getElementsByClassName("carousel")[1]) {
        document.getElementsByClassName("carousel")[1].remove();
      }
      if (document.getElementsByClassName("carousel-status")[0]) {
        document.getElementsByClassName("carousel-status")[0].remove();
      }
      if (document.getElementsByClassName("control-dots")[0]) {
        document.getElementsByClassName("control-dots")[0].remove();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStatusExpired, authData, isAuthorized, checkJoiningCallTime]);

  useEffect(() => {
    if (props.match.params.eventId) {
      getBookingDetails(props.match.params.eventId);
    }
  }, [getBookingDetails, props.match.params.eventId]);

  useEffect(() => {
    if (authData && authData.personalInfo) {
      updateBookingFor(authData.personalInfo.name);
    }
    document.addEventListener('click', (e) => {
      if (e.target && e.target.className && !e.target.className.includes("tooltip-info")) {
        showJoinInfo(false);
      }
    });
  }, [authData]);

  const initiatePayment = (orderId, token, amount) => {
    var config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
        "orderId": orderId, /* update order id */
        "token": token, /* update token value */
        "tokenType": "TXN_TOKEN",
        "amount": amount.toString() /* update amount */
      },
      "handler": {
        "notifyMerchant": function () {
          togglePaymentPopup(true);
        }
      }
    };
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
        // after successfully updating configuration, invoke JS Checkout
        togglePaymentInitialization(false);
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
      });
    }
  }

  const payNow = async (paymentData) => {
    if (!isAuthorized) {
      toast.info("Please login to book a workshop");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }

    if (authData && !authData.emailVerified) {
      const profileResult = await getProfile();
      if (profileResult.data.data && !profileResult.data.data.emailVerified) {
        toast.error("Please verify your email from profile");
        return;
      }
    }

    let requestBody = {};
    const myScript = document.createElement("script");
    myScript.setAttribute("src", paytmUrl);
    myScript.setAttribute("crossorigin", "anonymous");
    myScript.setAttribute("type", "application/javascript");
    document.body.appendChild(myScript);

    if (!paymentData) {
      if (checkLastDay(bookingDetails.dates)) {
        if (!checkCurrentTime(bookingDetails.slots)) {
          toast.error(WORKSHOP_TIME_PASSED);
          return;
        }
      }
      requestBody = {
        bookingForName: bookingFor,
        bookingForType: 1
      };
    } else {
      requestBody = { ...paymentData };
    }

    togglePaymentInitialization(true);

    toggleDisablePage(true);
    const bookSlotResult = await bookWorkshop(bookingDetails._id, requestBody);
    toggleDisablePage(false);
    if (bookSlotResult.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }
    if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }

    if (bookSlotResult.data.data) {
      updatePopupBookingDetails({
        bookingForName: bookingFor,
        bookingForType: 1,
        bookingId: bookSlotResult.data.data._id
      });

      setTimeout(() => {
        const paytmToken = bookSlotResult.data.data.paytmTxnToken;
        const paytmOrderId = bookSlotResult.data.data.orderId;
        const paytmAmount = bookSlotResult.data.data.paymentDetails.total;
        initiatePayment(paytmOrderId, paytmToken, paytmAmount);
      }, 2000);
    }
  };

  const cancelPayment = () => {
    togglePaymentPopup(false);
    props.history.push("/book-now?categoryId=605088be5848fb3bf301e90b");
  };

  const goBack = () => {
    props.history.goBack();
  };

  const joinEventCallButtonClick = async () => {
    if (checkJoiningCallTime(bookingDetails)) {
      if (!bookingDetails.eventLink) {
        toast.error('The meeting link is not yet posted by the professional, please contact support.');
        return;
      }
      window.open(bookingDetails.eventLink);
      const resultData = await joinWorkshopCall(bookingDetails.bookingId);
      if (resultData.data.errorCode) {
        toast.error(API_ERROR[resultData.data.errorCode]);
        return;
      }
      if (resultData.data.data && resultData.data.data.errorCode) {
        toast.error(API_ERROR[resultData.data.data.errorCode]);
        return;
      }
    } else {
      toast.error(CALL_TIME_PASSED);
    }
  };

  const openVendorProfile = (vendorProfile) => {
    if (vendorProfile.profileVisible) {
      props.history.push(`/professional-details/${vendorProfile.vendorId}/${vendorProfile.vendorName.split(" ").join("-")}`, { vendorId: vendorProfile.vendorId });
    }
  };

  const openFeedback = () => {
    props.history.push(`/workshop-rating-review/${props.match.params.eventId}`);
  };

  return (
    <div className={`row problem-area booking-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      {Object.keys(bookingDetails).length ?
        <ShareToSocialModal displayShareModal={displayShareModal}
          toggleShareModal={toggleShareModal} bookingDetails={bookingDetails} /> : null}
      <div className="col-lg-12">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-12 categories-heading">
            <h4 className={`mb-0 problem-category-heading`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Workshops {window.location.pathname.includes('booking') ? 'Booking ' : ''}Details</h4>
          </div>
        </div>
        <div className="row workshop-details" style={{ margin: 0 }}>
          <div className="col-12 full-wrapper">
            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
              {Object.keys(bookingDetails).length ?
                <div className="w-100 event-list-item-wrapper pos-relative" style={{ margin: 0 }}>
                  {bookingDetails.images.length ?
                    <div className="pos-relative w-100">
                      {!window.location.pathname.includes('booking') && bookingDetails.discountedPrice ?
                        <div className="amount-image-wrapper" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: '160px', backgroundSize: 'cover', backgroundPosition: 'right', zIndex: 9 }}> <i className="fas fa-rupee-sign"></i><span className={`text-strikethrough`}>{bookingDetails.fee.toFixed(2)}</span> <> <i className="fas fa-rupee-sign ml-2"></i>{bookingDetails.discountedPrice.toFixed(2)}</> </div> : null}
                      <div className='share-button-wrapper' onClick={() => toggleShareModal(true)}>
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </div>
                      <Carousel autoPlay>
                        {bookingDetails.images.map((imgItem, ind) => {
                          return <div key={ind}>
                            <img src={imgItem} alt={`Event ${ind + 1}`} />
                          </div>
                        })}
                      </Carousel>
                      <div className='professional-date-time date-section'>
                        {bookingDetails.displayStartDate} {bookingDetails.startDate !== bookingDetails.endDate ? `- ${bookingDetails.displayEndDate}` : ''}
                      </div>
                    </div> : null}

                  <div className="col-12 description-wrapper">
                    <div className="row m-0 booking-item-side-row">
                      <div className="col-12 pr-0 pl-0">
                        <div className="row m-0">
                          <div className="col-12 professional-name pl-0 pb-2">
                            {bookingDetails.title}
                          </div>
                        </div>
                        <div>Created by <span className={`professional-amount text-underline ${bookingDetails.profileVisible ? 'cursor-pointer': ''}`} onClick={() => openVendorProfile(bookingDetails)}>{bookingDetails.vendorName}</span></div>
                        <hr className='w-100' />
                        <div className='about-header'>About</div>
                        <div className="professional-designation">
                          {bookingDetails.description.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br /></span>
                          })}</div>
                        <hr className='w-100' />

                        <div className='row m-0 professional-date-time xhide-row-less-575'>
                          <div className='col-sm-3 col-6 small-slot text-center bor-right-always'>
                            <div className='about-header mb-2'>Platform</div>
                            <div>
                              <i className="fas fa-video mr-2"></i>
                              {bookingDetails.meetingPlatform} <i className="fa fa-info-circle cursor-pointer tooltip-info" onClick={() => showJoinInfo(!isJoinInfoDisplayed)} aria-hidden="true">
                                {isJoinInfoDisplayed ?
                                  <span class="tooltip-info-text">{bookingDetails.meetingPlatform !== "TBD" && bookingDetails.meetingPlatform !== "Others" ? `This workshop will host on ${bookingDetails.meetingPlatform}` : "Meeting Platform will be shared by professional before the workshop starts"}</span> : null}
                              </i>
                            </div>
                          </div>
                          <div className='col-sm-3 col-6 small-slot text-center bor-right'>
                            <div className='about-header mb-2'>Workshop Time</div>
                            <div>
                              <i className="fas fa-clock mr-2"></i>
                              {bookingDetails.startTime} - {bookingDetails.endTime}
                            </div>
                          </div>
                          <hr className='w-100 show-less-575' />
                          <div className='col-sm-3 col-6 small-slot text-center bor-right-always'>
                            <div className='about-header mb-2'>Total Seats</div>
                            <div>
                              <i className="fas fa-chair mr-2"></i>
                              {bookingDetails.totalSeats}
                            </div>
                          </div>
                          <div className='col-sm-3 col-6 small-slot text-center'>
                            <div className='about-header mb-2'>Available Seats</div>
                            <div>
                              <i className="fas fa-chair mr-2"></i>
                              <span style={{
                                fontWeight: "bold",
                                color: bookingDetails.availableSeats ? '#00A365' : '#E52E40'
                              }}>{bookingDetails.availableSeats ? "Yes" : "No"}</span>
                            </div>
                          </div>
                        </div>
                        <hr className='w-100 hide-less-575' />

                        <div className="professional-date-time row m-0 booking-item-side-row mt-2 show-less-575">
                          {/* <div className='event-details-time-date-wrapper time-slot-less-575 pl-0 w-100'>
              <img src={require('../../../images/video.png')} style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} alt="video" /> Will be held on {bookingDetails.meetingPlatform}
             </div>
             <div className="event-details-time-date-wrapper time-slot-less-575 pl-0 w-100">
              <img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} alt="clock" /> {bookingDetails.startTime} - {bookingDetails.endTime}
             </div> */}
                          {bookingDetails.bookingNumber ?
                            <div className="professional-date-time row m-0 booking-item-side-row my-1">
                              <div className="time-slot-less-575">
                                <div><span className="label mr-3">Booking Number</span> <br />{bookingDetails.bookingNumber}</div>
                              </div>
                            </div> : null}
                        </div>
                        {bookingDetails.bookingNumber ?
                          <div className="col-12 pr-0 pl-0 hide-less-575">
                            <div className="professional-date-time row m-0 booking-item-side-row my-2">
                              <div className="time-slot-less-575">
                                <div><span className="label mr-3">Booking Number</span> {bookingDetails.bookingNumber}</div>
                              </div>
                            </div>
                          </div> : null}
                        {/* <div className="w-100 col-12 p-0 show-row-less-575">
             <div className="fee-text seat-count bor-right pl-0 total-seats-text">Total Seats: {bookingDetails.totalSeats}</div>
             <div className="fee-text seat-count available-seats-text">Available Seats: {bookingDetails.availableSeats}</div>
            </div> */}
                        {bookingDetails.isCertificate ?
                          <div className='about-header show-less-575 mt-4'>Every participant will receive certificate of participation</div> : null}
                        <div className="professional-date-time row m-0 show-less-event-details-invoice mt-4 show-less-575">
                          <div className="col-12 p-0 professional-address-title">Payment Details</div>
                          <div className="col-6 display-inline-block event-details-fees p-0 mt-1 mb-0">Service Fee</div>
                          <div className="col-6 display-inline-block event-details-fees pr-0 mt-1 mb-0 text-right"><i className="fas fa-rupee-sign"></i>{!bookingDetails.discountedPrice ? bookingDetails.fee.toFixed(2) : bookingDetails.discountedPrice.toFixed(2)}</div>
                          <div className="col-6 display-inline-block event-details-fees p-0 mt-1 mb-0">Convenience Fee:</div>
                          <div className="col-6 display-inline-block event-details-fees pr-0 mt-1 mb-0 text-right"><i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                          <div className="col-6 display-inline-block event-details-fees p-0 mt-1 mb-0">GST</div>
                          <div className="col-6 display-inline-block event-details-fees pr-0 mt-1 mb-0 text-right"><i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                          <div className="col-6 pl-0 pr-0 display-inline-block fees-invoice-event-details bor-top">Total</div>
                          <div className="col-6 pr-0 display-inline-block fees-invoice-event-details bor-top text-right"><i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}</div>
                        </div>
                        <div className="w-100 col-12 p-0 hide-less-575 text-center">
                          {bookingDetails.isCertificate ?
                          <div className='about-header'>Every participant will receive certificate of participation</div> : null}
                          <div className="fee-text bor-right margin-top-5 pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{!bookingDetails.discountedPrice ? bookingDetails.fee.toFixed(2) : bookingDetails.discountedPrice.toFixed(2)}</div>
                          <div className="fee-text bor-right margin-top-5">Convenience Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                          <div className="fee-text bor-right margin-top-5">GST: <i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                          <div className="fee-text service-cost margin-top-5">Total: <i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}</div>
                          <br />

                          {!bookingDetails.isExpired && !window.location.pathname.includes('booking') && !bookingDetails.isCallJoinable && new Date(bookingDetails.dates[bookingDetails.dates.length - 1]) >= new Date(new Date().setHours(5, 30, 0, 0)) ?
                            <>
                              {(bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid) || bookingDetails.availableSeats === 0 ? null :
                                <div className="form-check event-details-cancellation-policy-link mt-2">
                                  <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => toggleCancellation(!isCancellationChecked)} />
                                  <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                    I have read the  <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'> Cancellation Policy</a>
                                  </label>
                                </div>}
                              <button type="button" className="btn btn-primary details-button  event-book-now-button" disabled={!isCancellationChecked || bookingDetails.availableSeats === 0 || bookingDetails.isBooked} onClick={!paymentInitialized ? () => payNow() : () => { }}>{paymentInitialized ? 'Initialize payment...' : bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid ? 'Already Booked' : bookingDetails.availableSeats === 0 ? 'Sold Out' : 'Pay Now'}</button>
                              <div className="col-12 pl-0 hide-less-575">
                                {bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Pending ? <span className="mt-2 hide-less-575 color-red">Please try again after 5 minutes</span> : null}
                              </div>
                            </> : null}

                          {bookingDetails.isBooked && bookingDetails.isCallJoinable ? <button type="button" className="btn btn-primary details-button event-book-now-button" onClick={joinEventCallButtonClick}>Join</button> : null}

                          {(bookingDetails.isExpired && bookingDetails.status === Config.Event.Status.Timedout) || (bookingDetails.isBooked && !bookingDetails.isCallJoinable && window.location.pathname.includes('booking') && bookingDetails.status !== Config.Event.Status.Completed) ? <>
                            <button type="button" disabled className="btn btn-primary details-button event-book-now-button">Join</button> </> : null}

                          {bookingDetails.isExpired && bookingDetails.status !== Config.Event.Status.Completed && !window.location.pathname.includes('booking') ? <>
                            <div className="col-12 pl-0 hide-less-575 mt-2">

                              <div className="form-check event-details-cancellation-policy-link">
                                <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => { }} disabled />
                                <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                  I have read the <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'>Cancellation Policy</a>
                                </label>
                              </div>

                            </div>
                            <button type="button" className="btn btn-primary details-button event-book-now-button" disabled>Expired</button>
                          </> : null}
                          {bookingDetails.isExpired && bookingDetails.status !== Config.Event.Status.Completed && window.location.pathname.includes('booking') ?
                            <div className="w-100 fee-text seat-count pl-0 mt-2">
                              Workshop Expired
                            </div> : null}

                          {bookingDetails.status === Config.Event.Status.Completed ?
                            <>
                            <div className='row ml-0 mr-0 mt-2'>
                              <div className={`${!bookingDetails.rating ? "col-12" : "col-md-6 booking-status-wrapper"}  pl-0 booking-details-header fee-text hide-less-575`}>
                                The workshop has been {bookingDetails.rating ? <br /> : null}completed
                              </div>
                              <div className={`${!bookingDetails.rating ? "col-12" : "col-md-6 p-0"} booking-details-header`}>
                              {bookingDetails.rating ?
                                <div className="review-section">
                                  <div className="review-image-wrapper p-0">
                                    {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                                      <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="review-image" /> :
                                      <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="review-image" />}
                                  </div>
                                  <div className="review-other-details profile-name-wrapper">
                                    <div className="professional-name">{authData && authData.personalInfo && authData.personalInfo.name}</div>
                                    <div className="professional-designation">{getDisplayFullDateFormat(new Date(bookingDetails.ratingData.dateTime))}</div>
                                  </div>
                                  <hr />
                                  <div className='review-text-wrapper text-left'>
                                    {bookingDetails.ratingData.rating ?
                                      stars.map((star, starIndex) => {
                                        return <i key={starIndex} className={`fa-star fas`} style={{ color: star.selected ? "#E12936" : "#ccc" }}></i>
                                      }) : null}
                                      {bookingDetails.ratingData.feedback ?
                                    <div>{bookingDetails.ratingData.feedback.split('\n').map((item, key) => {
                                      return <span key={key}>{item}<br /></span>
                                    })}</div> : null}
                                  </div>
                                </div> :
                                <button type="button" style={{ margin: "10px auto 0" }} className="btn btn-primary give-feedback-button hide-less-575" onClick={openFeedback}>Rate & Feedback</button>}
                              </div>
                              </div>
                            </> : null}
                        </div>
                        {!bookingDetails.isExpired && !window.location.pathname.includes('booking') && !bookingDetails.isCallJoinable && new Date(bookingDetails.dates[bookingDetails.dates.length - 1]) >= new Date(new Date().setHours(5, 30, 0, 0)) ? <>

                          <div className="col-12 text-center show-less-575 mt-2">
                            {(bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid) || bookingDetails.availableSeats === 0 ? null :
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => toggleCancellation(!isCancellationChecked)} />
                                <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                  I have read the <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'>Cancellation Policy</a>
                                </label>
                              </div>}
                            <div className="col-12 p-0 mt-2 text-center show-less-575">
                              <button type="button" disabled={!isCancellationChecked || bookingDetails.availableSeats === 0 || bookingDetails.isBooked} className="btn btn-primary details-button float-none" style={{ margin: "0 auto" }} onClick={!paymentInitialized ? () => payNow() : () => { }}>{paymentInitialized ? 'Initialize payment...' : bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid ? 'Already Booked' : bookingDetails.availableSeats === 0 ? 'Sold Out' : 'Pay Now'}</button>
                            </div>
                            {bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Pending ?
                              <div className="col-12 p-0 mt-2 text-center show-less-575 color-red">
                                Please try again after 5 minutes
                              </div>
                              : null}
                          </div> </> : null}
                        {bookingDetails.isBooked && bookingDetails.isCallJoinable ? <div className="col-12 p-0 mt-2 text-center show-less-575">
                          <button type="button" className="btn btn-primary details-button float-none" style={{ margin: "0 auto" }} onClick={joinEventCallButtonClick}>Join</button>
                        </div> : null}
                        {(bookingDetails.isExpired && bookingDetails.status === Config.Event.Status.Timedout) || (bookingDetails.isBooked && !bookingDetails.isCallJoinable && window.location.pathname.includes('booking') && bookingDetails.status !== Config.Event.Status.Completed) ? <>
                          <div className="col-12 p-0 mt-2 text-center show-less-575">
                            <button type="button" className="btn btn-primary details-button float-none" style={{ margin: "0 auto" }} disabled>Join</button>
                          </div> </> : null}
                        {bookingDetails.isExpired && bookingDetails.status !== Config.Event.Status.Completed && !window.location.pathname.includes('booking') ? <>

                          <div className="col-12 text-center show-less-575 mt-2">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => { }} disabled />
                              <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                I have read the <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'>Cancellation Policy</a>
                              </label>
                            </div>

                          </div>
                          <div className="col-12 p-0 mt-2 text-center show-less-575">
                            <button type="button" className="btn btn-primary details-button float-none" style={{ margin: "0 auto" }} disabled>Expired</button>
                          </div>
                        </> : null}

                        {bookingDetails.status === Config.Event.Status.Completed ? <>
                          <div className="col-12 booking-details-change-button  appointment-details-button-wrapper show-less-575 text-center mt-3">
                          <div className="col-12 p-0 booking-details-header fee-text show-less-575 text-center mb-3">
                            The workshop has been completed
                          </div>
                            {!bookingDetails.rating ?
                              <button type="button" className="btn btn-primary give-feedback-button" onClick={openFeedback}>Rate & Feedback</button> :
                              <div className="col-12 p-0 booking-details-header">
                                <div className="review-section">
                                  <div className="review-image-wrapper p-0">
                                    {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                                      <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="review-image" /> :
                                      <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="review-image" />}
                                  </div>
                                  <div className="review-other-details profile-name-wrapper">
                                    <div className="professional-name">{authData && authData.personalInfo && authData.personalInfo.name}</div>
                                    <div className="professional-designation">{getDisplayFullDateFormat(new Date(bookingDetails.ratingData.dateTime))}</div>
                                  </div>
                                  <hr />
                                  <div className='review-text-wrapper text-left'>
                                    {bookingDetails.ratingData.rating ?
                                      stars.map((star, starIndex) => {
                                        return <i key={starIndex} className={`fa-star fas`} style={{ color: star.selected ? "#E12936" : "#ccc" }}></i>
                                      }) : null}
                                      {bookingDetails.ratingData.feedback ?
                                    <div>{bookingDetails.ratingData.feedback.split('\n').map((item, key) => {
                                      return <span key={key}>{item}<br /></span>
                                    })}</div> : null}
                                  </div>
                                </div>
                              </div>}
                          </div> </> : null}
                        
                      </div>
                    </div>
                  </div>
                </div>
                : <div className="text-center well col-12 color-black">
                  {!loadingUpcomingEvents ? 'No Workshops Details Available!' : 'loading...'}
                </div>}
            </div>
          </div>
        </div>
      </div>
      <PaymentModal displayModal={openPaymentPopup} paymentInitialized={paymentInitialized} payNow={payNow} popupBookingDetails={popupBookingDetails} cancelBooking={cancelPayment} />
    </div>
  )
}

export default WorkshopDetails;