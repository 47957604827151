import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_PROFESSIONAL_CATEGORIES = `${baseUrl}/customer/common/vendor-category`;
export const GET_NEARBY_PROFESSIONALS = `${baseUrl}/customer/common/nearby-vendor`;
export const GET_PROFESSIONAL_DETAILS = `${baseUrl}/customer/common/vendor-details`;
export const GET_AVAILABILITY = `${baseUrl}/account/customer/booking/available-slot`;
export const BOOK_SLOT = `${baseUrl}/account/customer/booking/book`;
export const NOTIFICATION = `${baseUrl}/customer/common/global-config`;
export const HELPSUPPORT = `${baseUrl}/account/customer/support`;
export const SESSION = `${baseUrl}/customer/common/event-list/`;
export const BOOK_SESSION = `${baseUrl}/account/customer/event/book/`;
export const FOLLOW = `${baseUrl}/account/customer/event/`;
export const GET_EVENT_LIST = `${baseUrl}/customer/common/online-event-list`;
export const GET_EVENT_DETAILS = `${baseUrl}/customer/common/online-event-details/`;
export const EVENT_BOOK = `${baseUrl}/account/customer/online-event/book/`;
export const NOTIFICATION_LIST = `${baseUrl}/account/customer/notification/`;
export const FAV_PROFESSIONAL = `${baseUrl}/account/customer/favourite/mark-unmark-favourite-vendor/`;
export const TEST_CATEGORIES = `${baseUrl}/customer/common/test-categories`;
export const TEST = `${baseUrl}/customer/common/test-list`;
export const TEST_DETAILS = `${baseUrl}/customer/common/test-details`;
export const BOOK_TEST = `${baseUrl}/account/customer/test`;
export const TEST_LIST_ACCOUNT = `${baseUrl}/account/customer/test/completed-tests`;
export const TEST_DETAILS_ACCOUNT = `${baseUrl}/account/customer/test/booking-details`;
export const ATTACH_TEST_APPOINTMENT = `${baseUrl}/account/customer/booking/attach-test-booking/`;
export const APPLY_PROMO = `${baseUrl}/account/customer/promo/apply`;
export const ALL_TEST = `${baseUrl}/customer/common/all-test-list`;
export const GET_WORKSHOP_LIST = `${baseUrl}/customer/common/workshop-list`;
export const GET_WORKSHOP_DETAILS = `${baseUrl}/customer/common/workshop-details/`;
export const WORKSHOP_BOOK = `${baseUrl}/account/customer/workshop/book/`;
export const TUTORIAL = `${baseUrl}/customer/common/tutorials`;

const localDate = new Date(new Date(new Date(new Date(new Date().setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();

export const getProfessionalCategories = async () => {
  const res = await axios.get(`${GET_PROFESSIONAL_CATEGORIES}?skip=0&limit=0`);
  return res;
}

export const getNearbyProfessionals = async (requestBody) => {
  const res = await axios.post(`${GET_NEARBY_PROFESSIONALS}?skip=0&limit=0`, requestBody);
  return res;
}

export const getProfessionalDetails = async (id, requestBody) => {
  const res = await axios.post(`${GET_PROFESSIONAL_DETAILS}/${id}`, requestBody);
  return res;
}

export const getAvailability = async (id) => {
  const res = await axios.get(`${GET_AVAILABILITY}/${id}`);
  return res;
}

export const bookSlot = async (id, requestBody) => {
  const res = await axios.post(`${BOOK_SLOT}/${id}`, requestBody);
  return res;
}

export const notification = async () => {
  const res = await axios.get(`${NOTIFICATION}`);
  return res;
}

export const helpSupport = async (requestBody, imageFile) => {
  const formData = new FormData();
  if (imageFile) {
    formData.append('image', imageFile);
  }
  if (requestBody) {
    for (let i in requestBody) {
      formData.append(i, requestBody[i]);
    };
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const res = await axios.post(`${HELPSUPPORT}`, formData, config);
  return res;
}

export const getSessions = async (id, requestBody) => {
  const res = await axios.post(`${SESSION}${id}`, requestBody);
  return res;
}

export const bookSessionApi = async (id, requestBody) => {
  const res = await axios.post(`${BOOK_SESSION}${id}`, requestBody);
  return res;
}

export const followApi = async (id) => {
  const res = await axios.put(`${FOLLOW}/follow/${id}`);
  return res;
}

export const unFollowApi = async (id) => {
  const res = await axios.put(`${FOLLOW}/unfollow/${id}`);
  return res;
}

export const getEventList = async (limit, sortConfig, requestBody) => {
  const reqBody = { todaysDate: localDate };
  if (sortConfig) {
    reqBody.sortConfig = sortConfig;
  }
  if (requestBody && requestBody.customerRef) {
    reqBody.customerRef = requestBody.customerRef;
  }
  const res = await axios.post(`${GET_EVENT_LIST}?skip=0&limit=${limit}`, reqBody);
  return res;
}

export const getEventDetails = async (id, requestBody) => {
  const res = await axios.post(`${GET_EVENT_DETAILS}${id}`, requestBody);
  return res;
}

export const bookEvent = async (id, requestBody) => {
  const res = await axios.post(`${EVENT_BOOK}${id}`, requestBody);
  return res;
}

export const getNotificationList = async () => {
  const res = await axios.get(`${NOTIFICATION_LIST}list?skip=0&limit=100`);
  return res;
}

export const notificationMarkSeen = async (id) => {
  const res = await axios.put(`${NOTIFICATION_LIST}seen/${id}`);
  return res;
}

export const markFavouriteProfessional = async (id, requestBody) => {
  const res = await axios.put(`${FAV_PROFESSIONAL}${id}`, requestBody);
  return res;
}

export const getTestCategories = async () => {
  const res = await axios.get(`${TEST_CATEGORIES}`);
  return res;
}

export const getTests = async () => {
  const res = await axios.get(`${ALL_TEST}`);
  return res;
}

export const getTestsDetails = async (id) => {
  const res = await axios.get(`${TEST_DETAILS}/${id}`);
  return res;
}

export const bookTest = async (requestBody, id) => {
  const res = await axios.post(`${BOOK_TEST}/book/${id}`, requestBody);
  return res;
}

export const submitSingleAnswer = async (requestBody, id) => {
  const res = await axios.put(`${BOOK_TEST}/submit-answer/${id}`, requestBody);
  return res;
}

export const finalSubmitTest = async (id) => {
  const res = await axios.put(`${BOOK_TEST}/final-submit/${id}`);
  return res;
}

export const getTestBookingDetailsAPI = async (id) => {
  const res = await axios.get(`${BOOK_TEST}/booking-details/${id}`);
  return res;
}

export const getTestsAccount = async () => {
  const res = await axios.get(`${TEST_LIST_ACCOUNT}`);
  return res;
}

export const getTestsDetailsAccount = async (id) => {
  const res = await axios.get(`${TEST_DETAILS_ACCOUNT}/${id}`);
  return res;
}

export const attachAppointmentTest = async (requestBody, id) => {
  const res = await axios.put(`${ATTACH_TEST_APPOINTMENT}/${id}`, requestBody);
  return res;
}

export const applyPromoCode = async (requestBody) => {
  const res = await axios.put(`${APPLY_PROMO}`, requestBody);
  return res;
}

export const getWorkshopList = async (limit, sortConfig, requestBody) => {
  const reqBody = { todaysDate: localDate };
  if (sortConfig) {
    reqBody.sortConfig = sortConfig;
  }
  if (requestBody && requestBody.customerRef) {
    reqBody.customerRef = requestBody.customerRef;
  }
  if (requestBody && requestBody.vendorRef) {
    reqBody.vendorRef = requestBody.vendorRef;
  }
  const res = await axios.post(`${GET_WORKSHOP_LIST}?skip=0&limit=${limit}`, reqBody);
  return res;
}

export const getWorkshopDetails = async (id, requestBody) => {
  const res = await axios.post(`${GET_WORKSHOP_DETAILS}${id}`, requestBody);
  return res;
}

export const bookWorkshop = async (id, requestBody) => {
  const res = await axios.post(`${WORKSHOP_BOOK}${id}`, requestBody);
  return res;
}

export const getTutorial = async (id) => {
  const res = await axios.get(`${TUTORIAL}`);
  return res;
}