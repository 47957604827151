import React, { useState } from 'react';
import "./forum.css";

const TutorialModal = (props) => {
    const [currentIndex, updateCurrentIndex] = useState(0);

    const nextClick = async () => {
        let localIndex = currentIndex;
        
        if (currentIndex !== props.tutorials.length - 1) {
            ++localIndex;
            updateCurrentIndex(localIndex);
        }
    };

    const prevClick = () => {
        let localIndex = currentIndex;
        if (currentIndex !== 0) {
            --localIndex;
            updateCurrentIndex(localIndex);
        }
    };

    return (
        <div className={`row forum test-modal tutorial-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0'>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => props.toggleInfoModal(false)}></i>
                                        <h5 className='pre-confirmation-header'>{props.tutorials[currentIndex].title}</h5>
                                        <div className='row m-0 pre-confirmation-description'>
                                            {props.tutorials[currentIndex].description}
                                        </div>
                                        <div className='row m-0 pt-5'>
                                            <div className="col-12 text-center category-arrows">
                                                <button className="prev-button-wrapper" style={{ position: "inherit" }} onClick={prevClick} disabled={currentIndex === 0}>
                                                    <i className="fas fa-arrow-left"></i>
                                                </button>
                                                <button className="next-button-wrapper" style={{ position: "inherit" }} onClick={nextClick} disabled={currentIndex === props.tutorials.length - 1}>
                                                    <i className="fas fa-arrow-right"></i>
                                                </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div >
    )
}

export default TutorialModal;