import React, { useEffect } from 'react';
import "./forum.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';

const TestCategoryModal = (props) => {

    useEffect(() => {
        if (props.displayModal) {
            document.getElementsByClassName("scrollable-modal")[0].scrollTop = 0;
        }
    }, [props.displayModal]);

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0 '>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => props.toggleTestModal(false)}></i>
                                        <h5 className='pre-confirmation-header'>Self Assessments</h5>
                                        <div className='row m-0 scrollable-modal'>
                                            {props.tests.map((category, index) => {
                                                return (<Card key={index} className={`test-category-item`}>
                                                    <CardContent>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                            <div> <Checkbox color="primary" checked={!!category.selected} onChange={() => { props.chooseTest(category) }} inputProps={{ 'aria-label': 'controlled' }} /></div>
                                                            <div>
                                                                <p style={{ fontSize: 16, fontWeight: 600, margin: 0, paddingTop: 6 }}>{category.title}</p>
                                                                <p style={{ fontSize: 14, fontWeight: 600, margin: 0, marginTop: 8, marginBottom: 8 }}>For: {category.bookingForName}</p>
                                                                <p style={{ marginBottom: 0 }}>Score: {category.score}, Date: {category.updatedAt}</p>
                                                            </div>
                                                        </div>

                                                    </CardContent>
                                                </Card>);
                                            })}
                                        </div>
                                        <div className='row m-0'>
                                            <div className="col-12 text-center category-arrows">
                                                <button type="button" style={{ float: "inherit" }} className="btn btn-primary details-button mt-2" onClick={props.submitTests}>Done</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TestCategoryModal;