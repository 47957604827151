import React, { useState } from 'react';
import "./forum.css";

const FontModal = (props) => {
    const [fontSize, updateFont] = useState(props.currentFontSize);

    const changeFont = (event) => {
        updateFont(event.target.value);
        props.toggleInfoModal(false);
        props.updateCurrentFontSize(event.target.value);
        sessionStorage.setItem("currentFont", event.target.value);
    };

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0'>
                                    <div className="col-12 category-arrows">
                                        <h5 className='pre-confirmation-header'>Choose the font size
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => props.toggleInfoModal(false)}></i></h5>
                                        
                                        <div className='row ml-0 mr-0 mt-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="fontSize" onChange={changeFont} value={"1"} checked={fontSize === "1"} id="fontSize1" />
                                                <label class="form-check-label" for="fontSize1">
                                                    Default
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="fontSize" onChange={changeFont} value={"2"} checked={fontSize === "2"} id="fontSize2" />
                                                <label class="form-check-label" for="fontSize2">
                                                    Medium
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="fontSize" onChange={changeFont} value={"3"} checked={fontSize === "3"} id="fontSize3" />
                                                <label class="form-check-label" for="fontSize3">
                                                    Big
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FontModal;