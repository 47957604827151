import React, { useEffect, useState } from 'react';
import OtherHeader from './other-page-header';
import './home.css';

const TermsCondition = () => {
    const [pageInitialized, updatePageInitialization] = useState(false);
    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            if (el) {
                el.style.display = "none";
            }
            if (el1) {
                el1.style.display = "none";
            }
            updatePageInitialization(true);
        }
    }, [pageInitialized]);
    return <>
        <OtherHeader header="Terms and Conditions" />
        <section
            class="elementor-section elementor-top-section elementor-element elementor-element-1daa4020 elementor-section-boxed elementor-section-height-default elementor-section-height-default other-page-wrapper"
            data-id="1daa4020" data-element_type="section">
            <h3>TERMS AND CONDITIONS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India Private Limited (“we”, “us”, “our” or “Parentcraft India”) owns the website
                www.parentcraftindia.com (“Website”) and the mobile application ‘Parentcraft India’ (“App”)
                (together, “Platform”). Parentcraft India owns and operates the services provided through the
                Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>By clicking the &quot;I accept&quot; and &quot;sign in&quot; button, registering for an account or using any of the
                services you (“you”, “your” or “User”) represent that (1) you have read, understand, and agree to
                be bound by these terms and conditions (“Terms and Conditions”), (2) you are of legal age to
                form a binding contract with Parentcraft India, and (3) you have the authority to enter into these
                Terms and Conditions personally or on behalf of the company you have named as the user, and
                to bind that company to the Terms and Conditions. If you do not agree with these Terms and
                Conditions or do not have the authority to agree to them you must not register for an account
                with us and must not use the services.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>For the purpose of these Terms and Conditions, wherever the context so requires you shall
                mean any natural or legal person who has agreed to access the Platform by providing Personal
                Data (as defined in the Privacy Policy) while registering as a registered User. Parentcraft India
                allows the User to surf through Platform after registering on the Platform and Parentcraft India
                reserves the right to restrict the access of non-registered users of the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India reserves the right to change the Terms and Conditions and notices under
                which the services are offered through the Platform, including but not limited to the charges for
                the services provided through the Platform. The User shall be responsible for regularly
                reviewing these Terms and Conditions.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>When you access any of the information of Psychologist/Counselors (herein referred to as
                “Psychologist/Counsellor”) listed by us through the Platform, including but not limited to, like
                professional services e.g., Psychoanalyst, Child Counsellor, Aptitude Service, Sexologist etc.;
                listed by us or organically self-listed through the Platform, you will be subject to the rules,
                guidelines, policies, terms, and conditions applicable to such services, and they shall be
                deemed to be incorporated into this Terms and Conditions and shall be considered as part and
                parcel of this Terms and Conditions.</p>

            <h3>ELIGIBILITY FOR REGISTRATION</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You must be competent to enter into a contract under the Indian Contract Act, 1872 to register,
                use the Services, or visit or use the Platform in any manner.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You must be 18 years of age or older to register, use the Services, or visit or use the Platform in
                any manner. By registering, visiting and using the Platform or accepting this Terms and
                Conditions, you represent and warrant to Parentcraft India that you are 18 years of age or older,
                and that you have the right, authority and capacity to use the Platform and the Services
                available through the Platform, and agree to and abide by this Terms and Conditions.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If you are a minor i.e. under the age of 18 years and wishes to use the Platform it should be in
                presence or knowledge of a legal guardian. In breach of any provisions of the Indian Contract
                Act, 1872, Parentcraft India reserves the right to suspend or terminate your registration or block
                you from accessing the services provided by Parentcraft India.</p>

            <h3>USER ACCOUNT AND REGISTRATION</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The User has the option to login in through an one time password (OTP) verification, which will
                be done by sending a Short Message Service (SMS). The User shall be fully responsible for all
                activities that occur by the use of the OTP. After the User logs in to the Platform through mobile
                phone number User shall receive a registration form. The User hereby understands that
                Parentcraft India will take measures to do account verification to ensure that the details provided
                by the User are authentic and correct. The User hereby also agree that if the User provide any
                information that is untrue, inaccurate, not current or incomplete or if we have reasonable
                grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not
                in accordance with the Terms and Conditions, we shall have the right to indefinitely suspend or
                terminate or block the User from accessing the Platform in case these Terms and Conditions
                are breached.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The mobile phone number and/or e-mail address provided is treated as the User’s primary
                identifier on the Platform. It is User’s responsibility to ensure that the mobile phone number and
                email address is up to date on the Platform at all times. The User agrees to notify or write to
                Parentcraft India promptly through <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > if mobile phone number or e-mail address changes by updating the same at Platform through a one-time password (OTP)
                verification, which will be done by sending a Short Message Service (SMS) or through call.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>User undertakes that Parentcraft India shall not be liable or responsible for the activities or
                consequences of use or misuse of any information that occurs under User display name
                especially in cases where the User have failed to update revised mobile phone number and/or
                e-mail address on the Platform or failed to protect the confidentiality of Display Name and the
                User also understands that the services may include certain communications from Parentcraft
                India as service announcements and notifications. The User understands and agrees that the
                services are provided on an "as is" basis and that Parentcraft India does not assume any
                responsibility for deletions, non delivery or failure to store any user communications or
                personalized settings.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The User confirms that the User is the original account holder used in the transactions made
                using the Parentcraft India features and services. Parentcraft India will not be responsible for
                any financial loss, inconvenience or mental agony resulting from misuse of your ID//credit card
                number/account details number for using Parentcraft India’s services.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Furthermore, the User grants Parentcraft India the right to disclose to third parties Personal
                Data to the extent necessary for the purpose of carrying out the services.</p>

            <h3>COMMUNICATIONS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>When you use the Platform or send emails or other data, information or communication to us,
                you agree and understand that you are communicating with us through electronic records and
                you consent to receive communications via electronic records from our representatives
                periodically and as and when required. We may communicate with you by email or by such
                other modes of communication as may be determined by Parentcraft India. When you send an
                email or other communication to us, we may retain those communications in order to process
                your inquiries, respond to your requests and improve our Services.</p>

            <h3>PRIVACY POLICY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Please read our Privacy Policy with respect to registration with us, the use of the Platform and
                services carefully before using the Platform. Parentcraft India reserves the right to
                modify/amend/change the Privacy Policy without providing any prior notice.</p>

            <h3>ROLES AND RESPONSIBILITY OF PARENTCRAFT INDIA</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India is only a platform by which Psychologist/Counselors are able to connect to a
                larger customer base to offer their services and the Users are able to book appointments, or
                register for events or workshops provided by the Psychologist/Counselors. Any contract of
                services shall be strictly a contract between the Psychologist/Counsellor and you. Hence,
                Parentcraft India is not liable for any non-performance or breach of any contract entered by you
                with the Psychologist/Counsellor.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India provides you the provision to book appointments for services of Professionals
                and to register for events and workshops and access to forums. All payments are required to be
                made directly Psychologist/Counsellor through the mechanisms provided by Parentcraft India
                before availing the services on the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India provides the User with the provision to access the Platform in other languages
                including but not limited to Hindi, Malayalam, Bengali, etc for ease of navigation of the Platform
                in particular the Problem Areas section.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft will provide the required notifications and auto mails for all services which includes
                forum, individual appointments, events &amp; workshops on instances of confirming the appointment
                booking or prior to the service going live or after the service has been delivered. Parentcraft
                India would not be liable for not sending out notifications due to any unforeseen circumstances.
                It is the User’s responsibility to keep a track of their registered events.</p>

            <h3>ROLES AND RESPONSIBILITY OF CUSTOMER/ USER</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You agree that you have to be a registered User to avail the services offered on the Platform.
                You hereby affirm that all the information provided by you at the time of registration on the
                Platform is true and correct. In case of any changes, you shall promptly inform Parentcraft India
                as per the guidelines mentioned in User account and registration.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>It is the responsibility of the User to ensure confirmation of the event and/or workshop booked
                through the Platform by clicking on the registration button provided. Please do note that you will
                not be allowed to register again, to a same event and/or workshop from the event and/or
                workshop listing if you have already registered to that event and/or workshop. You can filter an
                event and/or workshop based on your interest. You can also preview the details of an event
                and/or workshop before registering for an event and/or workshop.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft app provides all the details of the event posted by Psychologist/Counselors while
                booking seats for the event however, it is the responsibility of the User to clarify or verify the
                details of an event with Psychologist/Counselors through the contact details provided which is
                accessible on the Counsellor’s profile by clicking on their name provided under the
                event/workshop title.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India does not take any responsibility or has any control over the content including
                details regarding events, workshops, appointments and forum posted on the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>It is the responsibility of the User to provide the following permissions: camera, location,
                microphone, SMS, storage, telephone for before attending an individual appointment, event or
                workshop which will be requested by the hosting platform i.e. the third party service provider on
                whose platform the event or workshop is hosted. It is necessary that you enable the above
                mentioned features for attending the individual appointment, event or workshop. You agree that
                in the event such permissions are not provided, this may affect our ability to process or enable
                the services on the Platform and may therefore lead to the discontinuation of those services or
                your account for which this Personal Data was being used for, at our sole discretion. Parentcraft
                shall not be responsible for the performance of the hosting platforms as it is the choice of the
                Counsellor who handles the individual appointment, event or workshop.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India provides access to the Platform to non- users as well as to Users through the
                option of share-link. A "share link" feature is a button and/or text link appearing on the Platform
                that enables the launch of a sharing mechanism whereby you can post links to, and content
                from, the Platform onto social networking websites such as Facebook, Twitter, Instagram, etc.
                The terms and conditions of such other websites govern your use of those sites, including your
                use of shared links or other posting of our content on those web sites. Users can share a profile
                from the profile page or an event from a list of events or workshop from a list of workshops.
                Other than that the User can also share their assessment results to a Counsellor when an
                appointment is confirmed. Users can also share the platform URL / App download links. The
                provision for the user to share the app download link is provided in the App settings page. As a
                result, you are fully responsible for your own use of such web sites including your use of shared
                links to link our Platform and/or post its content. As such, you agree to defend, indemnify and
                hold Parentcraft India, its affiliates, and its and their directors, officers, employees and agents
                harmless from any and all claims (including claims that you violated the policies or terms of use
                of other sites), liabilities, costs and expenses, arising in any way from your use of such web
                sites or a share link. The potential customers would have to visit the Platform to preview the
                details. However, you acknowledge and agree that at no time is Parentcraft India making any
                representation or warranty regarding any third party's products or services, nor will Parentcraft
                India be liable to you or any third party for any claims arising from or in connection with such
                third-party products and services. You hereby disclaim and waive any rights and claims you
                may have against us with respect to third party products and services to the maximum extent
                permitted by law.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You also affirm and agree and not to sell, trade or resell or exploit for any commercial purposes,
                any portion of the Service provided by the Psychologist/Counselors. For the removal of doubt, it
                is clarified that the Platform is not for commercial use but is specifically meant for personal use
                only.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You, further agree and undertake not to reverse engineer, modify, copy, distribute, transmit,
                display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any
                information, software, or services obtained from the Platform. For the removal of doubt, it is
                clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-
                commercial purposes and unwarranted modification of data and information within the content
                of the Platform is not permitted.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>No User shall be permitted to perform any of the following prohibited activities while
                availing our Services:</b></p>
            <ul>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Stalking, intimidating and/or harassing another and/or inciting another to commit
                    violence;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Transmitting material that encourages anyone to commit a criminal offense, that results
                in civil liability or otherwise breaches any relevant laws, regulations or code of practice;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Interfering with any other person&#39;s use or enjoyment of the Platform;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Impersonate any person or entity;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Making, transmitting or storing electronic copies of materials protected by copyright
                without the permission of the owner, committing any act that amounts to the infringement
                of intellectual property or making available any material that infringes any intellectual
                property rights held by us or other proprietary rights of anyone else;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Post, transmit or make available any material that contains viruses, trojan horses,
                worms, spyware, time bombs, cancelbots, or other computer programming routines,
                code, files or such other programs that may harm the Platform, interests or rights of
                other Users or limit the functionality of any computer software, hardware or
                telecommunications, or that may harvest or collect any data or personal information
                about other Users without their consent;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Access or use the Platform in any manner that could damage, disable, overburden or
                impair any of the Platform&#39;s servers or the networks connected to any of the servers on
                which the Platform is hosted;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Intentionally or unintentionally interfere with or disrupt the services or violate any
                applicable laws related to the access to or use of the Platform/, violate any requirements,
                procedures, policies or regulations of networks connected to the Platform or engage in
                any activity prohibited by these Terms;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Disrupt or interfere with the security of, or otherwise cause harm to, the Platform,
                materials, systems resources, or gain unauthorized access to user accounts, passwords,
                servers or networks connected to or accessible through the Platform or any affiliated or
                linked sites;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Interfere with, or inhibit any User from using and enjoying access to the Platform or other
                affiliated sites, or engage in disruptive attacks such as denial of service attack on the
                Platform;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Post any comments/ reviews which are offensive in nature or hurt the sentiments of any
                community, religion, race, caste or are contrary to any law for the time being in force.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Use deep-links, page-scrape, robot, spider or other automatic device, program,
                algorithm or methodology, or any similar or equivalent manual process, to increase
                traffic to the Platform, to access, acquire, copy or monitor any portion of the Platform, or
                in any way reproduce or circumvent the navigational structure or presentation of the
                Platform, or any content, to obtain or attempt to obtain any content, documents or
                information through any means not specifically made available through the Platform;</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Shall not forge headers or otherwise manipulate identifiers in order to disguise the origin
                of any message or transmittal sent to us on or through the Platform or any services
                offered on or through this Platform.</li>
                <li style={{
                    fontSize: "16px",
                    color: "#000"
                }}>Violate any of the terms specified under the Terms for the use of the Platform.</li>
            </ul>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India reserves the right to delete the account or block the User account, in case an
            event arises where you are found indulging in any of the prohibited services while availing the
            services offered by the Platform. Do Note that if the Account of a User is removed then all the
            data pertaining to the Account will be deleted and this includes any bookings made through the
            Platform like an appointment, registration of an event and/or workshop, etc.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You hereby grant us the authorization to use the information provided by you in accordance with
            the Privacy Policy of the Platform. You hereby agree that under no circumstances you will make
            any claim against Parentcraft India for breach of contract/agreement by the
            Psychologist/Counsellor or for any deficiency of service by the Psychologist/Counsellor.</p>

            <h3>PROBLEM AREA AND FORUM</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Problem Area Section of the Platform highlights frequently asked questions or concerns
            with answers that a User might have over various categories like Selection of Schools,
            Academic Stress and Failure, etc.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>This Section can be navigated by using a language of your preference by choosing the
            language of choice from the Multi-Language tab.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India is not liable for any translation errors, if any when viewing the Section in
            languages other than English.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Forum Section allows you to post questions, post your comment to the Questions posted.
            The Forum allows the User to flag an answer for various reasons including but not limited to
            wrong information or contains information that is offensive in nature, etc</p>

            <h3>ASSESSMENT TESTS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Platform provides the option of taking part in various self-assessment tests (hereinafter
                referred as “tests”). These tests can be used by the User for an appointment. The tests are
                accessible and tailor- made to various age groups. Do note that a minor is advised to undertake
                any test in the presence of the guardian. The result of the test, feedback and recommended
                counselors are available as soon as the Test is completed. The User can choose according to
                the User’s preference. There is no limit to the number of tests that can be taken by a User.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Do note that Parentcraft India is not liable for any loss that has incurred as a result, directly or
            indirectly from the use of the self-assessment test as the tests are NOT diagnostic tools and
            cannot be substituted as a diagnostic result for treatment of any mental health condition without
            the approval of the concerned Practitioner.</p>

            <h3>FAVORITES</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The User is provided with the option of creating a list of preferred Professionals, Problem Areas
            and Forum questions by clicking on the heart button above the respective item. The User can
            view the list under My Favorites tab.</p>

            <h3>PROMOTIONS AND DISCOUNTS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Any promotion or discount contained in any electronic communication, or any communication
            that may be displayed on the Website, is made by the registered on the Platform, and not by
            the Platform itself. The offers provided to the customer can be availed by way of utilization of the
            Code (“Code”).</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Users using the promotion or discount in any manner shall be deemed to have read, understood
            and accepted these terms and conditions. The value of the Code and the mode of using E-Coupons will be specifically as mentioned in the electronic communication sent to You or
            displayed on the Website.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Code can be redeemed only for discounts for appointment bookings, events and workshops
            made through the Platform on those listings that provide for the option of using the Code will
            make payments.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Platform is not responsible for any typographical error leading to an invalid Code. Platform
            does not guarantee that any one or more listings on the would have the Code as the payment
            option. Platform makes no representation or warranties of any kind regarding any product or
            service provided by any third party in connection with any Code and shall not be responsible for
            any costs, damages, accident, delay, injury, loss, expense or inconvenience that may arise in
            connection with the use of E-Coupons. For any queries regarding the Code or the promotion
            and discount, please email at <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a ></p>

            <h3>PAYMENT AND FUNDS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You may have positive funds in your account if you have prepaid for fees or charges or for
            services to be provided to you via the Platform. Funds in your Account are held by us in our
            operating accounts. Funds in your Account are not held separately by us, and may be
            commingled with our general operating funds, and/or funds of other User's accounts.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You are not entitled to any interest, or other earnings for funds that are in your Account. We
            reserve the right to collect any funds owed to us by any other legal means. You acknowledge
            and agree that the funds shown in your Account represent our unsecured obligations to you with
            respect to your rights to direct us to make payment in relation to the purchase and sale of a
            Service or an Event through the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You also affirm that funds may only be loaded into your Account, or released from your Account,
            by us and you must only use the mechanisms available on the Platform to pay for, or receive
            funds in respect of services and or events;</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>For payment for booking of any events, appointments, workshops, etc you have to make the
            payment either through Paytm or Net Banking and /or through your Card/ (“Payment Gateway”)
            while registering for an event, appointment or workshops. Payment is debited from your Account
            upon successful registration. On the event of successful payment, the event, appointment or
            workshop booking is confirmed upon a success status which would appear under the Bookings
            tab and can be viewed by you. The invoice can be downloaded after the event is aired or after
            the appointment/workshop with the Psychologist/Counsellor from the past events section under
            the Bookings tab. Once your booking is confirmed, you can cancel/ get-refund if the
            Cancellation is done 4 hours before the scheduled time. In the event of payment
            pending/failure, you are requested to write to <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > with their name,
            service details and Parentcraft India will process a refund within a period of two (2) weeks from
            the date the service was aired. By using the services, you agree to be bound by the Payment
            Gateway Terms of Service and Privacy Policy. You hereby consent and authorize Parentcraft
            India and the Payment Gateway to share any information and payment instructions you provide
            including but not limited to bank details, service address, etc to the extent required to complete
            your transactions in accordance with the terms, including personal, financial, credit card
            payment, and transaction information.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>In case of any dispute arising between you and the Payment Gateway, send an email with the
            relevant account details to support@parentcrftindia.com, and Parentcraft India will process a
            refund within a period of 7 days from the date of the transaction. Do note that Parentcraft is not
            liable in any manner whatsoever for any issue that arises between you and the Payment
            Gateway and Parentcraft India reserves the right but has no obligation, to become involved in
            any way with these disputes.</p>

            <h3>REFUND POLICY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>No-refund policy applies to any seats purchased for an event and for workshops. In case of a
            technical issue, you can write to <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > with relevant details such as
            name, event or workshop details, etc and Parentcraft India will process the refund within a
            period of 2 weeks from the date the event was aired. The refund will be only processed at rare
            circumstances and only if it meets the criteria for a refund.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Refund policy only applies when you have booked an appointment or avail any of our other
            services provided the cancellation was done at least 4 hours before the scheduled appointment.
            In case, the booking is canceled 1 hour before the scheduled appointment, then the User is only
            eligible for partial refund. In case of a technical issue, you can write to 
            &nbsp;<a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > with relevant details such as name, appointment, etc and
            Parentcraft India will process the refund within a period of 7 days from the date of the
            appointment.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Refund policy for a Psychologist/Counsellor with no-show applies to the Users who have
            attended an event or workshop where the Psychologist/Counsellor did not turn up for the entire
            session. In such rare occurrences, a refund will be initiated on the amount received by the
            Payment Gateway to the Users, and Users are requested to write to 
            &nbsp;<a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > with their name, event details and Parentcraft India will process a
            refund within a period of 7 days from the date the event was aired.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>In case the User does not wish to go through with cancellation the User has the right to
            reschedule their appointment 4 hours before the scheduled time with no penalties. It&#39;s important
            to let us know in advance if you need help with rescheduling your appointment. This may not be
            possible if there is heavy traffic or other unforeseen circumstances.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>A no-Show policy is applicable to Users who couldn’t attend an event and /or workshop or
            missed the event and or workshop due to any unforeseen circumstances, where Parentcraft
            India won’t initiate any refund because it was the User’s fault for not turning up for the event.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Any refunds required to be processed in your favor will be returned only to the source of the
            original deposit, and cannot be redirected to any other payment source.</p>

            <h3>FORCE MAJEURE</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Platform is not liable for failure to perform any of its obligations if such failure is as a result
            of act of God (including fire, flood, earthquake, storm, hurricane, pandemic or other natural
            disaster), war, invasion, act of foreign enemies, hostilities (regardless of whether war is
            declared), civil war, rebellion, revolution, insurrection, military or usurped power or confiscation,
            terrorist activities, nationalization, government sanction, blockage, embargo, labor dispute,
            strike, epidemic, pandemic, lockout or any interruption or any failure of electricity or server,
            system, computer, internet or telephone service.</p>

            <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>All right, title and interest in usage of the terms “Parentcraft India” and www.parencraftindia.com
            including but not limited to all texts, graphics, user interfaces, visual interfaces, computer code
            and any other information associated therewith are reserved by us. Use of the Platform and the
            services offered on or through the Platform, are only for your personal, non-commercial use.
            Under no circumstances may you change or delete any copyright, trademark, or other
            proprietary notices. Any modification of the materials on the Platform or use of them for any
            other purpose will violate our intellectual property rights.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Platform and all of its content are copyrighted materials, protected by Indian and
            international copyright laws. All content contained on the Platform are the exclusive intellectual
            property of their respective owners, licensors and/or Parentcraft India such as the Problem
            Areas pertaining to mental health issues amongst school going children set out in the form of
            questions and answers has been copyrighted under the Indian copyright laws and is the sole
            intellectual property of Col Anil Kak (Retd.). Any attempt to make copies in any National,
            Regional and / or International language for any other uses by any party, any attempt to publish
            the same elsewhere or disseminate the same by any person or any organization without the
            specific and written permission of the copyright holder will amount to a violation under Indian
            copyright laws and legal proceedings against the person(s) will be initiated in the Indian courts
            of law. Therefore, the content may not be used for any purpose other than browsing and using
            the services and information provided for your own private use. Your use of the Platform does
            not grant you ownership of any content, code, data or materials you may access on or through
            the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Additionally, the names and logos of Parentcraft India are trademarks and/or service marks of
            Parentcraft India. Parentcraft India’s trademarks and service marks may not be used by any
            other party without Parentcraft India’s express written permission. To the extent that we include
            certain trademarks owned by Psychologist/Counselors on our Platform, these trademarks are
            property of their respective owners.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>By accepting the use of terms hereunder you agree that the Parentcraft India does not
            transfer/assign the title to the Platform to you, and nothing in these terms and conditions shall
            imply or be deemed or construed to mean that any right, title and interest (including but not
            limited to intellectual property rights) stands transferred/assigned to you by Parentcraft India, we
            retain the full and complete right, title and interest to the Platform, and all intellectual property,
            title and interest to the Platform, and all intellectual rights therein.</p>

            <h3>DISPUTE RESOLUTION AND GOVERNING LAW</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India shall not be held responsible for any disputes arising out of interactions
            between Users and Psychologist/Counselors. In the event of any dispute between the
            Psychologist/Counsellor and you, Parentcraft India may coordinate with you and the
            Psychologist/Counsellor to reach an amicable solution. It hereby made clear that Parentcraft
            India is only a facilitating link between you and the Psychologist/Counsellor. Parentcraft India
            reserves the right, but has no obligation, to become involved in any way with these disputes and
            also shall not be liable in any manner whatsoever. We will not be obliged to participate in any
            way in any dispute between you and any party other than us regarding the services availed
            through the Platform. You shall not name us as a party or otherwise include us in any such
            proceeding. In the event that we are named as a party in any such proceeding, we reserve the
            right to raise any and all defenses deemed appropriate, and to take any other action necessary
            to defend ourselves.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>As a User, you can raise the dispute with our Grievance Officer who will coordinate with the
            Psychologist/Counsellor and will strive to find an amicable solution for resolving the dispute. In
            order to raise the Grievance Officer, You are required either send an email to
            <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > or you can call and get in touch with Parentcraft India executive
            on Mobile at +91 928500019 and Landline: 0731 2710256. The Outcome of the dispute will be
            informed to you as early as possible. Ideally it can take about 7 days to resolve a dispute.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Platform is intended for use by Users located in India. We in no way imply that the materials
            on our Platform are appropriate or available for use outside of India. If you use our Platform
            from locations outside of India, you are responsible for compliance with any applicable local
            laws. The Terms and Conditions shall be governed by the laws of India without regard to its
            conflict of law principles.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The User agrees to submit to the personal and exclusive jurisdiction of the courts of Indore,
            India for the purpose of litigating all such claims or disputes.</p>

            <h3>SEVERABILITY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If any covenant, obligation, agreement, term or condition of these Terms and Conditions or the
            Platform thereof to any person or circumstances shall, to any extent, be invalid or unenforceable
            the remainder of these Terms and Conditions, or the application of such covenant, obligation,
            agreement, term or condition to persons or circumstances other than those in respect of which it
            is held invalid or unenforceable, shall not be affected thereby and each covenant, obligation,
            agreement, term and condition of these Terms and Conditions shall be separately valid and
            enforceable to the fullest extent permitted by law and the invalid or unenforceable covenant,
            obligation, agreement, term or condition (as the case may be) shall be modified so as to be
            enforced to the fullest extent permitted at law.</p>

            <h3>CHANGES TO THE TERMS</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India reserves for any reason and at any time, in its sole discretion, the right to
            modify, terminate, change, amend, or suspend an account or any of the services and the Terms
            and Conditions. Parentcraft India may impose limits on all or certain features of the services or
            restrict your access to part or all of the services or the account without notice or liability.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The modified Terms and Conditions will be uploaded on the Platform and shall be enforceable
            from the time of its upload on the Platform. By e-mail or SMS, you will be informed about the
            fact that “Terms and Conditions” have been modified/changed/amended.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Your access to the Platform, an account and/or the services is completely at the discretion of
            Parentcraft India, and Parentcraft India reserves the right to block, suspend or terminate your
            access to the Platform, an account and/or the services at any time for any reason including (i)
            non-compliance with these Terms and Conditions (ii) actions that may lead to liability for
            Parentcraft India or its staff, consultants and associated persons and organizations including
            affiliates, suppliers or service providers, (iii) disruption of access to other Users, and/or (iv)
            violation of applicable laws or regulations.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You agree that we may without prior consent delete your account and any related materials
            including Personal Data (as defined in our Privacy Policy), data, text, files, images and all other
            materials and that we may prevent any further access by you to any such data. In particular, it is
            our absolute policy to reject any materials, which we, at our discretion, consider to be obscene,
            in bad taste or in any other way inappropriate (whether or not to print such materials would be
            illegal or unlawful).</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You agree that we shall not be liable for any loss or damages to you or any third party resulting
            from the deletion or loss of such information or resulting in any way from the termination of your
            account and that you will keep us fully and effectively indemnified against all losses, liabilities,
            damages and costs (including legal costs on a full indemnity basis) suffered by us in connection
            with or arising from any changes to your account or the services, any deletion or loss of your
            information, or deletion of your account, any of the website content, or any products or Services.</p>

            <h3>FEEDBACK</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Any comments, suggestions, proposals or other feedback provided to us in connection with the
            operation or content of this Platform shall be provided by the submitter and received by us on a
            non-confidential basis. You represent and warrant that you have all rights necessary to submit
            the comments, suggestions, proposals or other feedback. All such comments, suggestions, and
            other information shall become our exclusive property. By submitting any such information to us,
            you agree that you are transferring and assigning, at no charge, all of your right, title and
            interest in the information, including all copyrights and other intellectual property rights. You
            agree that we shall be free to use such information on an unrestricted basis.</p>

            <h3>LIMITATION OF LIABILITY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India has endeavored to ensure that all the information on the Platform is correct,
            but Parentcraft India neither warrants nor makes any representations regarding the quality,
            accuracy or completeness of any data or information contained. Parentcraft India makes no
            warranty, express or implied, concerning the Platform and/or its contents and disclaims all
            warranties of fitness for a particular purpose and warranties of merchantability in respect of
            services, including any liability, responsibility or any other claim, whatsoever, in respect of any
            loss, whether direct or consequential, to any user or any other person, arising out of or from the
            use of the information contained in the Platform.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Since Parentcraft India acts only as a booking agent, it shall not have any liability whatsoever
            for any aspect of the arrangements between the Psychologist/Counsellor and the User as
            regards the standards of services provided by the Psychologist/Counselors. In no
            circumstances shall Parentcraft India be liable for the services provided by the
            Psychologist/Counsellor.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Although Parentcraft India makes reasonable commercial efforts to ensure that the description
            and content in the Terms and Conditions and on the Platform is correct, it does not, however,
            take responsibility for changes that occurred due to human or data entry errors or for any loss or
            damages suffered by any user due to any information contained herein. Also, Parentcraft India
            is not the Psychologist/Counsellor and cannot therefore control or prevent changes in the
            published descriptions which are based upon information provided by.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>In no event shall Parentcraft India be liable for any direct, indirect, punitive, incidental, special,
            consequential damages or any other damages resulting from: (a) the use or the inability to use
            the services; (b) the cost of procurement of services or resulting from any information or
            services purchased or obtained or messages received or transactions entered into through the
            services; (c) unauthorized access to or alteration of the user's transmissions or data; (d) any
            other matter relating to the services; including, without limitation, damages for loss of use, data
            or profits, arising out of or in any way connected with the use or performance of the Platform.
            Neither shall Parentcraft India (and our directors, officers, employees, agents or other
            representatives) be responsible for the delay or inability to use the Platform or related services,
            the provision of or failure to provide services, or for any information, software, products, services
            and related graphics obtained through the Platform, or otherwise arising out of the use of the
            Platform, whether based on contract, tort, negligence, strict liability or otherwise. Further,
            Parentcraft India shall not be held responsible for non-availability of the Platform during periodic
            maintenance operations or any unplanned suspension of access to the Platform that may occur
            due to technical reasons or for any reason beyond Parentcraft India's control.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India and its licensors, suppliers, or Psychologist/Counselors, and their respective
            officers, directors, employees, agents, or other representatives shall not be liable for any
            special, incidental, indirect, or consequential damages of any kind, or for any damages
            whatsoever resulting from loss of use, data, or profits, whether or not Parentcraft India has been
            advised of the possibility of damage, arising out of or in connection with the use or performance
            of the Platform or of failure to provide products or services that you order from Parentcraft India
            or its affiliates, including without limitation, damages arising from error, omission, virus, delay, or
            interruption of service.</p>

            <h3>INDEMNIFICATION</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>You agree that you shall indemnify, defend and hold harmless Parentcraft India and all parties
            from whom Parentcraft India has licensed portions of content on the Platform, and their
            directors, officers, and employees, against all claims, liability, damages, costs and expenses,
            including reasonable legal fees and expenses arising out of or related to: (i) your breach of
            these Terms and Conditions; (ii) your use of, or inability to use, the Platform or the services; (iii)
            any action taken by Parentcraft India as part of its investigation of a suspected violation of these
            Terms and Conditions; (iv) your violation of any third party intellectual property or other rights in
            relation to your use of the Platform or the services; or (v) any suit, claim, or demand arising from
            or relating to the Customer content.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India reserves the right, subject to indemnification by you, to assume the exclusive
            defense and control of any matter initially subject to the defense, indemnification and hold
            harmless obligations by you hereunder and you shall not in any event settle any matter without
            the prior written consent of Parentcraft India. You agree that the provisions in this section will
            survive any termination of your account, these Terms and Conditions or your access to the
            Platform and/or services.</p>

            <h3>DISCLAIMER</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>The Platform and the services are provided on an "as is" and “as available” basis without representation, warranty, or guarantee of any kind, either expressed or implied, including, but not limited to, warranties of merchantability, title, fitness for a particular purpose, or non-infringement to the maximum possible extent exclusion of such representation, warranty or guarantee is permitted under applicable law.  All such warranties, conditions and promises (other than any specific guarantee if any provided) are excluded to the full extent permitted by law and without limitation to the foregoing:</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Parentcraft India does not guarantee, represent, or warrant that your use of the Platform and/or services will be uninterrupted or error-free, and you agree that from time to time Parentcraft India may take down or remove the Platform and/or services or any functionality thereof, for indefinite periods of time, or cancel the services at any time, without notice to you; and Parentcraft India does not guarantee, represent or warrant that the Platform and/or services will be free from loss, corruption, attack, viruses, interference, hacking, or other security intrusion, and Parentcraft India disclaims any liability relating thereto.
            </p>

            <h3>NO AGENCY</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>No agency, partnership, joint venture or franchise relationship is implied, intended or created by
            this Terms and Services, the provision of the account, or the provision of any of the products or
            services.</p>

            <h3>NO WAIVER</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Our failure to insist upon or enforce your strict compliance with these Terms and Conditions will
            not constitute a waiver of any of our rights. No waiver or acquiescence by us related to any
            breach of these Terms and Conditions is valid except if given in writing. Any such waiver or
            acquiescence shall not constitute a consent to or waiver of or excuse for any other different or
            subsequent breach or act unless such waiver or consent is in writing signed by us.</p>

            <h3>REMEDIES</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>No remedy conferred upon or reserved in our favor under these Terms and Conditions will
            exclude any other remedy so conferred or reserved or existing at law or in equity but each will
            be cumulative and in addition to every other remedy given under these Terms and Conditions or
            existing at law or in equity.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>If we become aware of any possible violations by you of the Terms and Conditions, we reserve
            the right to investigate such violations. If, as a result of the investigation, we believe that
            criminal activity has occurred, we reserve the right to refer the matter to, and to cooperate with,
            any and all applicable legal authorities. Parentcraft India is entitled, except to the extent
            prohibited by applicable law, to disclose any information or materials on or in the Platform and
            the services, and any Customer content, in Parentcraft India’s possession in connection with
            your use of the Platform and the services, to (1) comply with applicable laws, legal process or
            governmental request; (2) enforce the Terms and Conditions, (3) respond to any claims that
            your content violates the rights of third parties, (4) respond to your requests for customer
            service, or (5) protect the rights, property or personal safety of Parentcraft India, its Users or the
            public, and all enforcement or other government officials, as the Parentcraft India in its sole
            discretion believes to be necessary or appropriate.</p>

            <h3>NOTICES</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>All notices shall be in writing and in English and shall deemed given if delivered personally or by
            commercial messenger or courier service, or mailed by registered or certified mail (return
            receipt requested) or sent via email/facsimile (with acknowledgment of complete transmission)
            to the following address at: <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > or at 64 Dhar Kothi, Residency Area,
            Indore, Madhya Pradesh, 452001.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Notice shall be deemed to have been served 48 hours after it has been sent, dispatched,
            displayed, as the case may be, unless, where notice has been sent by email, it comes to the
            knowledge of the sending party, that the email address is invalid.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>Legal notices from Parentcraft India will be served or to the email address you have provided
            during the registration process. Notice will be deemed given 24 hours after email is sent, unless
            the sending party is notified that the email address is invalid or that the email has not been
            delivered. Alternatively, we may give you legal notice by mail to the address provided by you
            during the registration process. In such a case, notice will be deemed given three days after the
            date of mailing.</p>

            <h3>CONTACTING US</h3>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>To contact us about these Terms and Conditions, about our services, you may write to us to the
            notice details provided above or through email or phone.</p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Last Updated Date: Dec 21, 2022</b></p>
        </section>
    </>
};

export default TermsCondition;