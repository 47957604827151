import React, { useEffect, useState } from "react";
import {
    format,
    startOfWeek,
    addDays,
    lastDayOfWeek,
    addWeeks,
    subWeeks
} from "date-fns";
import "./calendar.css";

const CustomCalendar = (props) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    let prevDisable = false;
    let startDate;
    let endDate;

    const changeWeekHandle = (btnType, isNeeded) => {
        if (btnType === "prev") {
            setCurrentMonth(subWeeks(currentMonth, 1));
            onDateClickHandle(startOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 }));
        }
        if (btnType === "next") {
            setCurrentMonth(addWeeks(currentMonth, 1));
            if (!isNeeded) {
                onDateClickHandle(startOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 }));
            }
        }
        
    };

    const onDateClickHandle = (day, dayStr) => {
        props.changeDate(day);
    };

    const checkDisable = () => {
        if (startDate && endDate) {
            const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0));
            if (today >= new Date(startDate) && today <= new Date(endDate)) {
                prevDisable = true;
            } else {
                prevDisable = false;
            }
        }
        
    }

    const renderCells = () => {
        startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
        const dateFormat = "d";
        const rows = [];
        let eachDay = "";
        let days = [];
        let day = startDate;
        let formattedDate = "";

        checkDisable();

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                eachDay = format(addDays(startDate, i), "EEE");
                const cloneDay = day;
                days.push(
                    <div
                        className={`cell text-center calendar-cell ${new Date(format(new Date(), "MM-dd-yyyy")) > new Date(format(cloneDay, "MM-dd-yyyy")) ? "disabled-date pointer-none" : ""}`}
                        key={day}
                        onClick={() => {
                            onDateClickHandle(cloneDay, props.selectedDate);
                        }}
                    >
                        <div className={`cell-inner ${format(props.selectedDate, "MM-dd-yyyy") === format(cloneDay, "MM-dd-yyyy") ? "selected" : ""}`}>
                            <div className="show-above-768">
                                <img src={require(`../../../images/${eachDay.toLowerCase()}.png`)} alt={eachDay} />
                            </div>
                            <span className="number show-above-768">{formattedDate.length === 1 ? `0` : ''}{formattedDate} {format(cloneDay, "MMM").toUpperCase()}</span>
                            <div className="hide-above-768">
                                <div className="only-date">{formattedDate.length === 1 ? `0` : ''}{formattedDate}</div>
                                <div>{format(cloneDay, "MMM").toUpperCase()}</div>
                            </div>
                            <div className="hide-above-768">
                                ({eachDay})
                            </div>
                        </div>

                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body" style={{width: "100%"}}>{rows}</div>;
    };

    useEffect(() => {
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
        if (props.selectedDate && (new Date(format(props.selectedDate, "MM-dd-yyyy")) > new Date(format(endDate, "MM-dd-yyyy")))) {
            changeWeekHandle("next");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
        if (props.selectedDate && (new Date(format(props.selectedDate, "MM-dd-yyyy")) > new Date(format(endDate, "MM-dd-yyyy")))) {
            changeWeekHandle("next", true);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedDate]);

    return (
        <div className="calendar row mr-0 ml-0">
            {renderCells()}
            <button className="prev-button" onClick={() => changeWeekHandle("prev")} disabled={prevDisable}>
                <span className='sr-only'>Left Arrow</span>
                <i className="fas fa-chevron-left"></i>
            </button>
            <button className="next-button" onClick={() => changeWeekHandle("next")}>
                <span className='sr-only'>Right Arrow</span>
                <i className="fas fa-chevron-right"></i>
            </button>
        </div>
    );
};

export default CustomCalendar;
