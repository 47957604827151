export const REQUIRED_ERROR = "This field is required";
export const INVALID_EMAIL = "Please enter valid email";
export const VALID_PHONE_ERROR = "Please enter valid phone number";
export const TIME_PASSED = "You cannot book an appointment at this duration. Please change the time";
export const WORKSHOP_TIME_PASSED = "You cannot book this workshop anymore";
export const CALL_TIME_PASSED = "You cannot join this call. It is already end";
export const CANCEL_TIME_PASSED = "You cannot cancel this appointment";
export const RESCHEDULE_TIME_PASSED = "You cannot reschedule this appointment";
export const API_ERROR = {
    "400":"API Validation error. Please add correct input.",
    "401":"Error while uploading",
    "403":"Access denied",
    "405":"You don't have access",
    "406":"Image not found",
    "501":"Invalid file type. Please upload correct file type.",
    "502":"You don't have access",
    "1000":"Session expired",
    "1001":"Invalid OTP. Please enter correct OTP",
    "1002":"OTP has been expired. Please generate another OTP",
    "1005":"Phone number not found",
    "1200":"Global config not found",
    "1301":"Professional category not found",
    "1400":"Email Id already exists",
    "1450":"Email Id already exists",
    "1401":"Professional not found",
    "1402":"Professional has been removed",
    "1453":"Customer has been removed",
    "1403":"Professional has been suspended",
    "1501":"Organizer not found",
    "1503":"Organizer has been blocked",
    "1504":"Organizer has been suspended",
    "1505":"Organizer has been deleted",
    "1600":"Service already present on same time slot",
    "1601":"Slot already booked",
    "1701":"Language not found",
    "1801":"Problem not found",
    "1901":"Problem not found",
    "2001":"FAQ not found",
    "2500":"Booking pin doesn't match. Please enter the correct pin",
    "2501":"Booking is not confirmed",
    "2502":"Booking is not pending",
    "2503": "Appointment already completed, cannot be cancelled",
    "2504": "Appointment already cancelled, cannot be cancelled",
    "2507": "Appointment cannot be cancelled now",
    "2508": "Pending appointment cannot be rescheduled",
    "2509": "Appointment already completed, cannot be rescheduled",
    "2510": "Appointment already cancelled, cannot be rescheduled",
    "2511": "Appointment cannot be rescheduled now",
    "2512": "Booking is not possible as the professional is not authorized.",
    "2505":"Location tagged with services. you cannot remove this location",
    "2506":"Location title already present. Please add a different location title.",
    "2701":"No seats available for booking",
    "2708": "Sorry! We are not taking bookings of this event anymore.",
    "3507": "Please choose reason for flagging",
    "3508": "Already flagged",
    "3509": "Not yet flagged",
    "3511": "The question cannot be edited",
    "4500": "This assessment is available for 18 years and above.",
    "4501": "This assessment is available for 3 years and above.",
    "4502": "This assessment is available for 6 years and above.",
    "4503": "This assessment is available for 4 years and above.",
    "4504": "This assessment is available for 13 years and above.",
    "4505": "This assessment is not available for above 18 years.",
    "4506": "As the users age provided is below 7 Years, please select the option Parent or Guardian from the dropdown `Take assessment as`.",
    "4507": "This assessment is available for 5 years and above.",
    "4508": "This assessment is not available for above 20 years.",
    "4509": "This assessment is available for 15 years and above",
    "5000": "You have already added a preference code",
    "5001": "Invalid preference code",
    "5002": "This preference code is expired",
    "5201": "Sorry! We are not taking bookings of this workshop anymore.",
 };