import React from 'react';
import "../pages/admins/problem-area.css";
import { toast } from 'react-toastify';

const closeImage = require('../../images/modal-close-icon.png');

const JoinSessionModal = (props) => {
    const copyToClipboard = () => {
        const tempInput = document.createElement("input");
        tempInput.value = props.callableSessionObject?.meetingLink?.trim();
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        toast.success("Link Copied");
    };
    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal fade ${props.displayJoinSessionModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Join {props.joiningType === "appointment" ? "Appointment" : "Session"}</h5>
                            <img src={closeImage} alt="close modal" onClick={() => { props.toggleJoinSessionModal(false); }} style={{ cursor: "pointer", width: "30px" }} />
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: 0 }}>
                                <h5 className="col-12 p-0 mb-3" style={{ color: "#000" }}>
                                    This {props.joiningType === "appointment" ? "appointment" : "session"} will be held on {props.callableSessionObject.meetingPlatform}. 
                                </h5>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={props.callableSessionObject.meetingLink} disabled />
                                    <div className="input-group-append">
                                    <button type="button" className="btn btn-primary" onClick={copyToClipboard}>Copy</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0 text-right mt-3 modal-buttons">
                                {props.joiningType === "appointment" && props.callableSessionObject.bookingForType && props.callableSessionObject.bookingForType !== 1 ?
                                <button type="button" className="btn btn-outline-primary mr-2" onClick={() => props.openSharePopup(true)}>Share</button> : null}
                                <button type="button" className="btn btn-primary mr-2" onClick={() => window.open(props.callableSessionObject.meetingLink)}>Open</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinSessionModal;