import React, { useEffect } from 'react';
import "./forum.css";

const FailurePayment = (props) => {
    useEffect(() => {
        const el = document.getElementsByClassName("custom-navigation-bar")[0];
        const el1 = document.getElementsByClassName("footer")[0];
        if (el) {
            el.style.visibility = "hidden";
        }
        if (el1) {
            el1.style.visibility = "hidden";
        }
    }, []);

    return (
        <div className={`forum`} style={{ margin: "0 auto", textAlign: "center", fontSize: "16px" }}>
            Failure mobile
        </div>
    )
}

export default FailurePayment;