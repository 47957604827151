import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getProfile } from "../../crud/auth.crud";
import { paytmUrl } from "../../crud/api.util";
import { getEventDetails, notification, bookEvent } from "../../crud/home.crud";
import { joinEventCall } from "../../crud/booking.crud";
import {
  timeArrConst,
  Config,
  monthArr,
  convertTwelveToZeroAM
} from "../../config/constants";
import { toast } from 'react-toastify';
import {
  API_ERROR,
  TIME_PASSED,
  CALL_TIME_PASSED
} from "../../config/error-message";
import PaymentModal from "../home/payment-modal";
import ShareToSocialModal from './shareToSocialModal';

const timeArr = [...timeArrConst];

const EventList = (props) => {
  const { isAuthorized, authData } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
      authData: auth.user
    }),
    shallowEqual
  );
  const [bookingDetails, updateBookingDetails] = useState({});
  const [disablePage, toggleDisablePage] = useState(false);
  const [loadingUpcomingEvents, toggleloadingUpcomingEvents] = useState(true);
  const [paymentInitialized, togglePaymentInitialization] = useState(false);
  const [openPaymentPopup, togglePaymentPopup] = useState(false);
  const [popupBookingDetails, updatePopupBookingDetails] = useState({});
  const [bookingFor, updateBookingFor] = useState("");
  const [displayShareModal, toggleShareModal] = useState(false);
  const [isCancellationChecked, toggleCancellation] = useState(false);

  const getTime = (timeStr, date) => {
    const time = timeStr.slice(0, timeStr.length - 3)
    const amPm = timeStr.slice(timeStr.length - 2)
    let d = new Date();
    if (date) {
      d = new Date(date);
    }
    if (amPm === "AM" && time.split(":")[0] === "12") {
      return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    }
    if (amPm === "PM" && time.split(":")[0] !== "12") {
      return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    }
    return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
  };

  const checkCurrentDay = (date) => {
    const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
    const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
    return today === day;
  };

  const checkCurrentTime = useCallback((times) => {
    const today = new Date().getTime();
    return getTime(timeArr[times[0] - 1]) > today;
  }, []);

  const getFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getStartTime = (times) => {
    times.sort((a, b) => { return a - b });
    return convertTwelveToZeroAM(timeArr[times[0] - 1]);
  };

  const getEndTime = (times) => {
    times.sort((a, b) => { return a - b });
    return convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
  };

  const getDisplayFullDateFormat = (date) => {
    const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
    const year = date.getFullYear();

    return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
  };

  const checkJoiningCallTime = useCallback((localBookingData) => {
    if (checkCurrentDay(localBookingData.slotDate)) {
      const today = new Date().getTime();
      const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
      const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
      return (startTime <= today && endTime > today) && localBookingData.isBooked;
    }
    return false;
  }, []);

  const checkStatusExpired = useCallback((localBookingData) => {
    const today = new Date().getTime();
    const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
    return (startTime <= today);
  }, []);

  const getBookingDetails = useCallback(async (id) => {
    toggleDisablePage(true);
    const reqBody = {};
    if (isAuthorized) {
      reqBody.customerRef = authData._id;
    }
    const upcomingBookingResult = await getEventDetails(id, reqBody);
    const globalConfigResult = await notification();

    toggleDisablePage(false);
    toggleloadingUpcomingEvents(false);
    if (upcomingBookingResult.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
      return;
    }
    if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
      toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
      return;
    }

    const responseBookingDetails = { ...upcomingBookingResult.data.data };
    const localBookingDetails = {
      images: responseBookingDetails.images.map(img => img.url),
      title: responseBookingDetails.title,
      description: responseBookingDetails.description,
      "date": getFullDateFormat(new Date(responseBookingDetails.date)),
      displayDate: getDisplayFullDateFormat(new Date(responseBookingDetails.date)),
      "slotDate": responseBookingDetails.date,
      startTime: getStartTime(responseBookingDetails.slots),
      endTime: getEndTime(responseBookingDetails.slots),
      vendorName: responseBookingDetails.vendorRef.personalInfo.name,
      vendorId: responseBookingDetails.vendorRef._id,
      profileVisible: responseBookingDetails.vendorRef.profileVisible !== undefined ? responseBookingDetails.vendorRef.profileVisible : true,
      totalSeats: responseBookingDetails.numberOfSeats,
      availableSeats: responseBookingDetails.availableSeats,
      fee: responseBookingDetails.eventFees,
      slots: responseBookingDetails.slots,
      _id: responseBookingDetails._id,
      isBooked: responseBookingDetails.isBooked ? responseBookingDetails.isBooked : false,
      convenienceFee: 0,
      bookingId: responseBookingDetails.bookingId,
      eventLink: responseBookingDetails.eventLink,
      gst: 0,
      total: 0,
      profilePicture: responseBookingDetails.images[0].url
    };

    if (responseBookingDetails.paymentStatus) {
      localBookingDetails.paymentStatus = responseBookingDetails.paymentStatus;
    }

    let convenienceFee = 0;
    let gst = 0;

    if (globalConfigResult.data.data) {
      convenienceFee = globalConfigResult.data.data.convenienceFee ? globalConfigResult.data.data.convenienceFee : 0;
      gst = globalConfigResult.data.data.gstPercentage ?
        (convenienceFee) * (globalConfigResult.data.data.gstPercentage / 100) : 0;
    }

    if (globalConfigResult.data.data && globalConfigResult.data.data.promoCodeGeneral && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.event && responseBookingDetails && responseBookingDetails.vendorRef && responseBookingDetails.vendorRef.promoCodeInfo && responseBookingDetails.vendorRef.promoCodeInfo.allowDiscount && authData && authData.promoCodeInfo && authData.promoCodeInfo.institutionRef) {
      localBookingDetails.discountedPrice = localBookingDetails.fee * ((100 - globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.event) / 100);
    }

    localBookingDetails.convenienceFee = parseFloat(convenienceFee);
    localBookingDetails.gst = parseFloat(gst);
    localBookingDetails.total = parseFloat((!localBookingDetails.discountedPrice ? localBookingDetails.fee : localBookingDetails.discountedPrice) + localBookingDetails.convenienceFee + localBookingDetails.gst);

    if (checkCurrentDay(responseBookingDetails.date)) {
      localBookingDetails.isExpired = checkStatusExpired(responseBookingDetails);
    } else {
      localBookingDetails.isExpired = false;
    }

    localBookingDetails.isCallJoinable = checkJoiningCallTime(localBookingDetails);

    updateBookingDetails({ ...localBookingDetails });
    setTimeout(() => {
      if (document.getElementsByClassName("carousel")[1]) {
        document.getElementsByClassName("carousel")[1].remove();
      }
      if (document.getElementsByClassName("carousel-status")[0]) {
        document.getElementsByClassName("carousel-status")[0].remove();
      }
      if (document.getElementsByClassName("control-dots")[0]) {
        document.getElementsByClassName("control-dots")[0].remove();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStatusExpired, authData, isAuthorized, checkJoiningCallTime]);

  useEffect(() => {
    if (props.match.params.eventId) {
      getBookingDetails(props.match.params.eventId);
    }
  }, [getBookingDetails, props.match.params.eventId]);

  useEffect(() => {
    if (authData && authData.personalInfo) {
      updateBookingFor(authData.personalInfo.name);
    }
  }, [authData]);

  const initiatePayment = (orderId, token, amount) => {
    var config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
        "orderId": orderId, /* update order id */
        "token": token, /* update token value */
        "tokenType": "TXN_TOKEN",
        "amount": amount.toString() /* update amount */
      },
      "handler": {
        "notifyMerchant": function (eventName, data) {
          togglePaymentPopup(true);
        }
      }
    };
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
        togglePaymentInitialization(false);
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
      });
    }
  }

  const payNow = async (paymentData) => {
    if (!isAuthorized) {
      toast.info("Please login to book an event");
      setTimeout(() => {
        const loginButton = document.getElementById("login-button");
        if (loginButton) {
          loginButton.click();
        }
      }, 1000);
      return;
    }

    if (authData && !authData.emailVerified) {
      const profileResult = await getProfile();
      if (profileResult.data.data && !profileResult.data.data.emailVerified) {
        toast.error("Please verify your email from profile");
        return;
      }
    }

    let requestBody = {};
    const myScript = document.createElement("script");
    myScript.setAttribute("src", paytmUrl);
    myScript.setAttribute("crossorigin", "anonymous");
    myScript.setAttribute("type", "application/javascript");
    document.body.appendChild(myScript);

    if (!paymentData) {
      if (checkCurrentDay(bookingDetails.date)) {
        if (!checkCurrentTime(bookingDetails.slots)) {
          toast.error(TIME_PASSED);
          return;
        }
      }
      requestBody = {
        bookingForName: bookingFor,
        bookingForType: 1
      };
    } else {
      requestBody = { ...paymentData };
    }

    togglePaymentInitialization(true);
    // initiatePayment("ORD-60a7a38ed8501ecbe8153336-1621599118809", "e4e63a8798404b13b969fd9aeaca6a821621599119840", "35.4");
    // return;

    toggleDisablePage(true);
    const bookSlotResult = await bookEvent(bookingDetails._id, requestBody);
    toggleDisablePage(false);
    if (bookSlotResult.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }
    if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
      toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
      togglePaymentInitialization(false);
      return;
    }

    if (bookSlotResult.data.data) {
      updatePopupBookingDetails({
        bookingForName: bookingFor,
        bookingForType: 1,
        bookingId: bookSlotResult.data.data._id
      });

      setTimeout(() => {
        const paytmToken = bookSlotResult.data.data.paytmTxnToken;
        const paytmOrderId = bookSlotResult.data.data.orderId;
        const paytmAmount = bookSlotResult.data.data.paymentDetails.total;
        initiatePayment(paytmOrderId, paytmToken, paytmAmount);
      }, 2000);
    }
  };

  const cancelPayment = () => {
    togglePaymentPopup(false);
    props.history.push("/book-now?categoryId=605088be5848fb3bf301e90b");
  };

  const goBack = () => {
    props.history.goBack();
  };

  const joinEventCallButtonClick = async () => {
    if (checkJoiningCallTime(bookingDetails)) {
      window.open(bookingDetails.eventLink);
      const resultData = await joinEventCall(bookingDetails.bookingId);
      if (resultData.data.errorCode) {
        toast.error(API_ERROR[resultData.data.errorCode]);
        return;
      }
      if (resultData.data.data && resultData.data.data.errorCode) {
        toast.error(API_ERROR[resultData.data.data.errorCode]);
        return;
      }
    } else {
      toast.error(CALL_TIME_PASSED);
    }
  };

  const openVendorProfile = (vendorProfile) => {
    if (vendorProfile.profileVisible) {
      props.history.push(`/professional-details/${vendorProfile.vendorId}/${vendorProfile.vendorName.split(" ").join("-")}`, { vendorId: vendorProfile.vendorId });
    }
  };

  return (
    <div className={`row problem-area booking-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      {Object.keys(bookingDetails).length ?
        <ShareToSocialModal displayShareModal={displayShareModal}
          toggleShareModal={toggleShareModal} bookingDetails={bookingDetails} /> : null}
      <div className="col-lg-12">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-6 categories-heading">
            <h4 className={`mb-0 problem-category-heading`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i>Event Details</h4>
          </div>
        </div>
        <div className="row workshop-details" style={{ margin: 0 }}>
          <div className="col-12 full-wrapper">
            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
              {Object.keys(bookingDetails).length ?
                <div className="w-100 event-list-item-wrapper pos-relative" style={{ margin: 0 }}>
                  {bookingDetails.images.length ?
                    <div className="pos-relative w-100">
                      {bookingDetails.discountedPrice ?
                        <div className="amount-image-wrapper" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: '160px', backgroundSize: 'cover', backgroundPosition: 'right', zIndex: 9 }}> <i className="fas fa-rupee-sign"></i><span className={`text-strikethrough`}>{bookingDetails.fee.toFixed(2)}</span> <> <i className="fas fa-rupee-sign ml-2"></i>{bookingDetails.discountedPrice.toFixed(2)}</> </div> : null}
                        <div className='share-button-wrapper' onClick={() => toggleShareModal(true)}>
                          <i className="fa fa-share-alt" aria-hidden="true"></i>
                        </div>
                      <Carousel autoPlay>
                        {bookingDetails.images.map((imgItem, ind) => {
                          return <div key={ind}>
                            <img src={imgItem} alt={`Event ${ind + 1}`} />
                          </div>
                        })}
                      </Carousel>
                      <div className='professional-date-time date-section'>
                        {bookingDetails.displayDate}
                      </div>
                    </div> : null}

                  <div className="col-12 description-wrapper">
                    <div className="row m-0 booking-item-side-row">
                      <div className="col-12 pr-0 pl-0">
                        <div className="row m-0">
                          <div className="col-12 professional-name pl-0">
                            {bookingDetails.title}
                          </div>
                          {/* <div className="col-md-5 col-sm-7 pr-0 pl-0 hide-less-575">
                            <div className="professional-date-time row m-0 booking-item-side-row mt-2">
                              <div className="col-6 time-slot-less-575 pl-0 text-right">
                                <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} /> {bookingDetails.displayDate}</div>
                              </div>
                              <div className="col-6 time-slot-less-575 pl-0 pr-0 text-right">
                                <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} alt="clock" /> {bookingDetails.startTime}</div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        {/* <div className="professional-name">{bookingDetails.title}</div> */}

                        <div>Created by <span className={`professional-amount text-underline ${bookingDetails.profileVisible ? 'cursor-pointer': ''}`} onClick={() => openVendorProfile(bookingDetails)}>{bookingDetails.vendorName}</span></div>

                        <hr className='w-100' />
                        <div className='about-header'>About</div>
                        <div className="professional-designation">
                        {bookingDetails.description.split('\n').map((item, key) => {
                          return <span key={key}>{item}<br /></span>
                        })}</div>
                        <hr className='w-100' />

                        <div className='row m-0 professional-date-time xhide-row-less-575'>
                          <div className='col-4 small-slot text-center bor-right-always'>
                            <div className='about-header mb-2'>Event Time</div>
                            <div>
                              <i className="fas fa-clock mr-2"></i>
                              {bookingDetails.startTime}
                            </div>
                          </div>
                          <div className='col-4 small-slot text-center bor-right-always'>
                            <div className='about-header mb-2'>Total Seats</div>
                            <div>
                              <i className="fas fa-chair mr-2"></i>
                              {bookingDetails.totalSeats}
                            </div>
                          </div>
                          <div className='col-4 small-slot text-center'>
                            <div className='about-header mb-2'>Available Seats</div>
                            <div>
                              <i className="fas fa-chair mr-2"></i>
                              {bookingDetails.availableSeats}
                            </div>
                          </div>
                        </div>
                        <hr className='w-100' />

                        {/* <div className="professional-date-time row m-0 booking-item-side-row mt-2 show-less-575">
                          <div className="event-details-time-date-wrapper time-slot-less-575 pl-0">
                            <img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} /> {bookingDetails.displayDate}
                          </div>
                          <div className="event-details-time-date-wrapper time-slot-less-575 pl-0">
                            <img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} alt="clock" /> {bookingDetails.startTime}
                          </div>
                        </div>
                        <div className="w-100 col-12 p-0">
                          <div className="fee-text seat-count bor-right pl-0 total-seats-text">Total Seats: {bookingDetails.totalSeats}</div>
                          <div className="fee-text seat-count available-seats-text">Available Seats: {bookingDetails.availableSeats}</div>
                        </div> */}
                        <div className="professional-date-time row m-0 show-less-event-details-invoice mt-4 show-less-575">
                          <div className="col-12 p-0 professional-address-title">Payment Details</div>
                          <div className="col-6 display-inline-block event-details-fees p-0 mt-1 mb-0">Service Fee</div>
                          <div className="col-6 display-inline-block event-details-fees pr-0 mt-1 mb-0 text-right"><i className="fas fa-rupee-sign"></i>{!bookingDetails.discountedPrice ? bookingDetails.fee.toFixed(2) : bookingDetails.discountedPrice.toFixed(2)}</div>
                          <div className="col-6 display-inline-block event-details-fees p-0 mt-1 mb-0">Convenience Fee:</div>
                          <div className="col-6 display-inline-block event-details-fees pr-0 mt-1 mb-0 text-right"><i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                          <div className="col-6 display-inline-block event-details-fees p-0 mt-1 mb-0">GST</div>
                          <div className="col-6 display-inline-block event-details-fees pr-0 mt-1 mb-0 text-right"><i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                          <div className="col-6 pl-0 pr-0 display-inline-block fees-invoice-event-details bor-top">Total</div>
                          <div className="col-6 pr-0 display-inline-block fees-invoice-event-details bor-top text-right"><i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}</div>
                        </div>
                        <div className="w-100 col-12 p-0 hide-less-575 text-center">
                          <div className="fee-text bor-right margin-top-5 pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{!bookingDetails.discountedPrice ? bookingDetails.fee.toFixed(2) : bookingDetails.discountedPrice.toFixed(2)}</div>
                          <div className="fee-text bor-right margin-top-5">Convenience Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                          <div className="fee-text bor-right margin-top-5">GST: <i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                          <div className="fee-text service-cost margin-top-5">Total: <i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}</div>
                          {!bookingDetails.isExpired && new Date(bookingDetails.slotDate) >= new Date(new Date().setHours(5, 30, 0, 0)) ?
                            <>
                            {(bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid) || bookingDetails.availableSeats === 0 ? null :
                                  <div className="form-check event-details-cancellation-policy-link mt-2">
                                    <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => toggleCancellation(!isCancellationChecked)} />
                                    <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                      I have read the  <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'> Cancellation Policy</a>
                                    </label>
                                  </div>}
                              <button type="button" className="btn btn-primary details-button hide-less-575 event-book-now-button" style={{margin: "10px auto 0"}} disabled={!isCancellationChecked || bookingDetails.availableSeats === 0 || bookingDetails.isBooked} onClick={!paymentInitialized ? () => payNow() : () => { }}>{paymentInitialized ? 'Initialize payment...' : bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid ? 'Already Booked' : bookingDetails.availableSeats === 0 ? 'Sold Out' : 'Pay Now'}</button>
                              <div className="col-12 pl-0 hide-less-575 mt-3">
                                {bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Pending ? <span className="hide-less-575 color-red mt-2">Please try again after 5 minutes</span> : null}
                              </div>
                            </> : bookingDetails.isBooked && bookingDetails.isCallJoinable ? <button type="button" className="btn btn-primary details-button hide-less-575 event-book-now-button" onClick={joinEventCallButtonClick} style={{margin: "10px auto 0"}}>Join</button> : null}
                          {/* {bookingDetails.isExpired && !bookingDetails.isBooked ?
                                                            <div className="w-100 fee-text seat-count pl-0 mt-2">
                                                                Event Expired
                                                            </div> : null} */}
                          {bookingDetails.isExpired && !bookingDetails.isBooked ? <>
                            <div className="col-12 pl-0 hide-less-575 mt-2">
                              <div className="form-check event-details-cancellation-policy-link ">
                                <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => { }} disabled />
                                <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                  I have read the  <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'> Cancellation Policy</a>
                                </label>
                              </div>
                            </div>
                            <button type="button" className="btn btn-primary details-button hide-less-575 event-book-now-button" disabled style={{margin: "10px auto 0"}}>Expired</button>
                            
                          </> : null}
                        </div>
                        {!bookingDetails.isExpired && new Date(bookingDetails.slotDate) >= new Date(new Date().setHours(5, 30, 0, 0)) ? <>
                          <div className="col-12 text-center show-less-575 mt-2">
                          {(bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid) || bookingDetails.availableSeats === 0 ? null :
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" onChange={() => toggleCancellation(!isCancellationChecked)} />
                                <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                  I have read the <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'>Cancellation Policy</a>
                                </label>
                              </div>}
                          <div className="col-12 p-0 mt-2 text-center show-less-575">
                            <button type="button" disabled={!isCancellationChecked || bookingDetails.availableSeats === 0 || bookingDetails.isBooked} className="btn btn-primary details-button float-none" style={{ margin: "0 auto" }} onClick={!paymentInitialized ? () => payNow() : () => { }}>{paymentInitialized ? 'Initialize payment...' : bookingDetails.isBooked && bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Paid ? 'Already Booked' : bookingDetails.availableSeats === 0 ? 'Sold Out' : 'Pay Now'}</button>
                          </div>
                          {bookingDetails.paymentStatus === Config.Transaction.NumberStatus.Pending ?
                            <div className="col-12 p-0 mt-2 text-center show-less-575 color-red">
                              Please try again after 5 minutes
                            </div>
                            : null}
                          </div>
                          </> : bookingDetails.isBooked && bookingDetails.isCallJoinable ? <div className="col-12 p-0 mt-2 text-center show-less-575">
                            <button type="button" className="btn btn-primary details-button float-none" style={{ margin: "0 auto" }} onClick={joinEventCallButtonClick}>Join</button>
                          </div> : null}
                        {bookingDetails.isExpired && !bookingDetails.isBooked ? <>
                          <div className="col-12 text-center show-less-575 mt-2">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" checked={isCancellationChecked} id="cancellationCheck" disabled onChange={() => { }} />
                              <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="cancellationCheck">
                                I have read the <a href="https://www.parentcraftindia.com/cancellation-policies" rel="noopener noreferrer" target="_blank" className='professional-amount text-underline'>Cancellation Policy</a>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 p-0 mt-2 text-center show-less-575">
                            <button type="button" disabled className="btn btn-primary details-button float-none" style={{ margin: "0 auto" }}>Expired</button>
                          </div>
                          
                        </> : null}
                      </div>
                    </div>
                  </div>
                </div>
                : <div className="text-center well col-12 color-black">
                  {!loadingUpcomingEvents ? 'No Details Available!' : 'loading...'}
                </div>}
            </div>
          </div>
        </div>
      </div>
      <PaymentModal displayModal={openPaymentPopup} paymentInitialized={paymentInitialized} payNow={payNow} popupBookingDetails={popupBookingDetails} cancelBooking={cancelPayment} />
    </div>
  )
}

export default EventList;