import React, { useState } from 'react';
import "./forum.css";
import { askQuestion } from "../../crud/qa-forum.crud";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";

const infoImage = require('../../../images/ask-question-tick.png');
const closeImage = require('../../../images/modal-close-icon.png');

const AskQuestionModal = (props) => {
  const [myQuestion, updateMyQuestion] = useState("");
  const [disablePage, toggleDisablePage] = useState(false);
  const [questionSubmitted, updateSubmission] = useState(false);

  const postQuestion = async () => {
    // return;
    toggleDisablePage(true);
    const askQuestionResult = await askQuestion({
      question: myQuestion.trim()
    });
    toggleDisablePage(false);
    if (askQuestionResult.data.errorCode) {
      toast.error(API_ERROR[askQuestionResult.data.errorCode]);
      return;
    }
    if (askQuestionResult.data.data && askQuestionResult.data.data.errorCode) {
      toast.error(API_ERROR[askQuestionResult.data.data.errorCode]);
      return;
    }

    updateMyQuestion("");
    updateSubmission(true);
    // props.toggleModal(false);
    props.getAllForumList();
  };

  return (
    <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ask Question</h5>
              <img src={closeImage} alt="close modal" onClick={() => {props.toggleModal(false); updateSubmission(false);}} style={{ cursor: "pointer" }} />
            </div>
            {!questionSubmitted ?
            <div className="modal-body">
              <div className="question-info">
                {/* <i className="fa fa-compass" aria-hidden="true"></i> */}
                <div className="info-wrapper">
                  <img src={infoImage} alt="info 1" />
                </div>

                Keep your question short and to the point, so that you can get an answer quickly.
              </div>
              <div className="question-info">
                {/* <i className="fa fa-compass" aria-hidden="true"></i> */}
                <div className="info-wrapper">
                  <img src={infoImage} alt="info 2" />
                </div>
                Check your grammer and spelling errors.
              </div>
              <div>
                <div className="form-group question-input">
                  <label htmlFor="exampleFormControlTextarea1">Ask your question here:</label>
                  <textarea className="form-control question-textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Tips: Start your question with what, why and how etc." value={myQuestion} onChange={(e) => updateMyQuestion(e.target.value)}></textarea>
                </div>
              </div>

              <div className="col-12 login-button">
                <button type="button" className="btn btn-primary" disabled={!myQuestion.trim().length} onClick={postQuestion}>Post</button>
                <button type="button" className="btn btn-default" onClick={() => {props.toggleModal(false); updateSubmission(false);}}>Cancel</button>
              </div>
            </div> :
            <div className="modal-body">
              <div className="question-info">
              Your question has been submitted and currently under review by admin. It will appear in forum once approved. You can track the status of your question in "My Q & A"
              </div>
              <div className="col-12 login-button">
                {props.from === "forum" ?
                <button type="button" className="btn btn-primary" onClick={() => {props.toggleModal(false); props.openMyQa(); updateSubmission(false);}}>Ok</button> : null}
                {props.from !== "forum" ?
                <button type="button" className="btn btn-primary" onClick={() => {props.toggleModal(false); updateSubmission(false);}}>{props.from === "forum" ? "Cancel" : "Ok"}</button> : null}
              </div>
            </div> }
          </div>
        </div>
      </div>

    </div>
  )
}

export default AskQuestionModal;