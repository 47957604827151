import React, { useEffect, useState } from 'react';
import OtherHeader from './other-page-header';
import './home.css';

const CancellationPolicy = () => {
    const [pageInitialized, updatePageInitialization] = useState(false);
    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            if (el) {
                el.style.display = "none";
            }
            if (el1) {
                el1.style.display = "none";
            }
            updatePageInitialization(true);
        }
    }, [pageInitialized]);
    return <>
        <OtherHeader header="CANCELLATION AND REFUND POLICIES" />
        <section
            class="elementor-section elementor-top-section elementor-element elementor-element-dc5bbc1 elementor-section-boxed elementor-section-height-default elementor-section-height-default other-page-wrapper"
            data-id="dc5bbc1" data-element_type="section">
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>No-refund policy applies to any seats purchased for an event and for workshops. In case of a
                technical issue, you can write to <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > with relevant details such as
                name, event or workshop details, etc and Parentcraft India will process the refund within a
                period of 2 weeks from the date the event was aired. The refund will be only processed at rare
                circumstances and only if it meets the criteria for a refund.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>
                Refund policy only applies when you have booked an appointment or avail any of our other services provided the cancellation was done at least 4 hours before the scheduled appointment. In case, the booking is canceled 1 hour before the scheduled appointment, then the User is only eligible for partial refund. In case of a technical issue, you can write to <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > with relevant details such as name, appointment, etc and Parentcraft India will process the refund within a period of  7 days from the date of the appointment.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>
                Refund policy for a Psychologist/Counsellor with no-show applies to the Users who have
                attended an event or workshop where the Psychologist/Counsellor did not turn up for the entire
                session. In such rare occurrences, a refund will be initiated on the amount received by the
                Payment Gateway to the Users, and Users are requested to write to <a
                    href="mailto:support@parentcraftindia.com"><span
                        style={{ fontSize: "11pt", color: "#677294", backgroundColor: "transparent", fontVariantNumeric: "normal", fontVariantEastAsian: "normal", textDecorationLine: "underline", textDecorationSkipInk: "none", verticalAlign: "baseline", whiteSpace: "pre-wrap" }}>support@parentcraftindia.com</span></a > with their name, event details and Parentcraft India will process a
                refund within a period of 7 days from the date the event was aired.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>
                In case the User does not wish to go through with cancellation the User has the right to
                reschedule their appointment 4 hours before the scheduled time with no penalties. It&#39;s important
                to let us know in advance if you need help with rescheduling your appointment. This may not be
                possible if there is heavy traffic or other unforeseen circumstances.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>
                A no-Show policy is applicable to Users who couldn’t attend an event and /or workshop or missed the event and or workshop due to any unforeseen circumstances, where Parentcraft India won’t initiate any refund because it was the User’s fault for not turning up for the event.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>
                Any refunds required to be processed in your favor will be returned only to the source of the original deposit, and cannot be redirected to any other payment source.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>
                No refund shall be entertained if the users fail to report any incomplete or unsatisfactory consultation within seven (7) working days.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}>
                Refunds may not include a discount on the total value of a booking.
            </p>
            <p style={{
                fontSize: "16px",
                color: "#000"
            }}><b>Last Updated Date: Dec 21, 2022</b></p>
        </section >
    </>
};

export default CancellationPolicy;