export const problemAreaMetaData = {
    "60883f8d1069d58676cb0671": {
        title: "Mental Health – Problem Area",
        description: "Mental health refers to a person&#39;s emotional, psychological, and social well-being. It affects how individuals think, feel, and act, and influences how they handle stress, relate to others, and make choices.",
    },
    "60884106f7880e6d02be3451": {
        title: "Selection of Schools for Child’s Education, Parenting",
        description: "The selection of a school is a crucial decision that impacts a child&#39;s educational journey. Factors to consider include academic programs, extracurricular activities, teaching methodologies, location, school culture, resources."
    },
    "608d9b3b5995e4fa519486cd": {
        title: "Academic Stress and Failure for a Child, Parenting",
        description: "Academic stress and failure can have a significant impact on a student&#39;s well-being and self-esteem, leading to feelings of anxiety, disappointment, and a loss of motivation."
    },
    "609e221c12b3e8febef65890": {
        title: "Selection of Subjects and Career for a Child, Parenting",
        description: "The selection of subjects and career is a critical decision that shapes an individual&#39;s educational and professional path. Factors to consider include personal interests, strengths, career prospects, future goals."
    },
    "609e2e82b0011318d8817aa7": {
        title: "Disinterest in Life, Disinterest in School, Parenting",
        description: "Disinterest in life and school can be a sign of underlying issues such as depression, anxiety, or lack of motivation. Seeking support from professionals and loved ones can help address these concerns."
    },
    "609e2e92b0011318d8817aa9": {
        title: "Tutors, Extra Classes for a Child, Common Parenting Problem",
        description: "Tutors and extra classes provide additional academic support, help students excel in their studies. They offer personalized attention, clarification of concepts, guidance to improve learning outcomes and performance."
    },
    "609e2ea420250a18e4a5f0fa": {
        title: "Social Behaviour of a Child, Common Parenting Problem",
        description: "Child social behaviour refers to how children interact, communicate, form relationships with others. It involves skills such as sharing, taking turns, empathy, cooperation, appropriate social norms and boundaries."
    },
    "609e303020250a18e4a5f0fc": {
        title: "Aggression in Children, Common Parenting Problem",
        description: "Aggression in children refers to behaviours such as physical or verbal aggression, hostility, or anger towards others. It can be a sign of underlying issues, addressing it requires understanding the root causes and implementing appropriate interventions."
    },
    "609e3048b0011318d8817aab": {
        title: "Utilising Free Time of a Child, Common Parenting Problem",
        description: "Utilizing free time involves providing a balance of activities that are both enjoyable, educational. This can include encouraging outdoor play, engaging in arts and crafts, reading together, pursuing hobbies, allowing for unstructured play to foster creativity and imagination."
    },
    "609e305b20250a18e4a5f0fe": {
        title: "How to manage Teenage Problems. Common Parenting Problem",
        description: "Teenage problems encompass a wide range of challenges including peer pressure, academic stress, identity formation, mental health issues, substance abuse, and conflict with parents."
    },
    "609e307120250a18e4a5f100": {
        title: "Child Abuse, Common Parenting Problem",
        description: "Child abuse refers to the mistreatment or neglect of a child, which can include physical, emotional, sexual abuse, or neglect. It is a serious violation of a child&#39;s rights and can cause long-lasting physical, psychological harm."
    },
    "609e3081b0011318d8817aad": {
        title: "Sex Education for a Child, Common Parenting Problem",
        description: "Sex education is the process of providing young people with knowledge, skills, and attitudes to make informed decisions about their sexual health and relationships."
    },
    "609e309620250a18e4a5f102": {
        title: "Emotional Problem of a Child, Common Parenting Problem",
        description: "Emotional problems in children can manifest as anxiety, depression, anger, or behavioural issues. These problems may stem from various factors, early identification, appropriate support are vital for addressing, promoting the child&#39;s emotional well-being."
    },
    "609e30a4b0011318d8817aaf": {
        title: "Prevent Child from Drug Abuse, Common Parenting Problem",
        description: "Drug abuse refers to the misuse or excessive use of substances such as drugs or alcohol, leading to negative physical, psychological, social consequences. Prevention, education, treatment are crucial in addressing drug abuse and promoting a healthy, drug-free lifestyle."
    }
};

export const bookNowMetaData = {
    "605088be5848fb3bf301e90b": {
        title: "Best Aptitude Testing Counsellors, Psychologist in India",
        description: "Aptitude testing helps to identify an individual&#39;s strengths and weaknesses, providing insights into their aptitude for various activities, including academic pursuits, career choices, and vocational interests.",
    },
    "6050c80e5848fb3bf301e90c": {
        title: "Talk to Best Career Counsellors, Psychologist in India",
        description: "Career counselling is a process between a trained professional and an individual seeking guidance in making informed decisions about their career path. It involves a range of assessments, discussions, and interventions.",
    },
    "6050c8225848fb3bf301e90d": {
        title: "Talk to Best Child Counsellors, Psychologist in India",
        description: "A child counsellor is a specialized professional who provides therapeutic support and guidance to children and adolescents facing emotional, behavioural, or psychological challenges.",
    },
    "6050c83e5848fb3bf301e90e": {
        title: "Talk to Best Counsellors, Psychologist in India",
        description: "A child psychologist is a specialized professional who focuses on the mental, emotional, behavioural development of children and adolescents. They are trained in assessing, diagnosing, treating a wide range of psychological issues that children may experience.",
    },
    "609e4b0f20250a18e4a5f162": {
        title: "Talk to Best Sexologist in India, Book Appointment Now",
        description: "Child sexologists are professionals who specialize in understanding and addressing sexual health and concerns in children and adolescents. They have extensive knowledge of child development, psychology, human sexuality.",
    },
    "609660b51d68e6fc9767b5ea": {
        title: "Best Counselling, Therapy, Psychologist Services in India",
        description: "Healing counselling therapy is a specialized approach that focuses on supporting individuals in their journey of emotional, psychological, spiritual healing. It aims to address past traumas, wounds, negative patterns, promoting healing, personal transformation.",
    }
};