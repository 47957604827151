import React, { useEffect, useState, useCallback } from 'react';
import "./forum.css";
import AskQuestionModal from './ask-question-modal';
import { getQAList, removeForumQuestion } from "../../crud/qa-forum.crud";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";
import RemoveModal from './remove-modal';
import { API_SUCCESS } from '../../config/success-message';
import EditAnswerModal from './answer-modal';

const qaStatus = {
  1: "Pending",
  2: "Approved",
  3: "Rejected"
};

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultImage = require('../../../images/imgAvtr.png');

const MyQA = (props) => {
  const [qaList, updateQA] = useState([]);
  const [displayModal, toggleModal] = useState(false);
  const [disablePage, toggleDisablePage] = useState(false);
  const [loadingQuestions, toggleLoadingQuestions] = useState(true);
  const { authData } = useSelector(
    ({ auth }) => ({
      authData: auth.user
    }),
    shallowEqual
  );
  const [removableQuestionId] = useState("");
  const [openRemoveConfirmationModal, toggleRemoveConfirmationModal] = useState(false);
  const [isEditable, toggleEditable] = useState(false);
  const [editableQuestion, updateSelectedQuestionForEdit] = useState({});

  const getFullDate = (date) => {
    const d = new Date(date);
    return d.getDate() + ' ' + monthArr[d.getMonth()] + ' ' + d.getFullYear();
  };

  const openQuestionModal = () => {
    toggleModal(true);
  };

  const getAllQaList = useCallback(async () => {
    toggleDisablePage(true);
    const qaResults = await getQAList();
    toggleDisablePage(false);
    toggleLoadingQuestions(false);
    if (qaResults.data.errorCode) {
      toast.error(API_ERROR[qaResults.data.errorCode]);
      return;
    }
    if (qaResults.data.data && qaResults.data.data.errorCode) {
      toast.error(API_ERROR[qaResults.data.data.errorCode]);
      return;
    }
    const qaArr = [];
    if (qaResults.data.data && qaResults.data.data.data && qaResults.data.data.data.length) {
      let qaObj = {};
      qaResults.data.data.data.forEach(each => {
        qaObj = {};
        qaObj._id = each._id;
        qaObj.questionText = each.question;
        qaObj.selected = false;
        qaObj.questionedByMe = false;
        qaObj.answerByMe = false;
        qaObj.date = getFullDate(each.createdAt);
        qaObj.status = Number(each.status); // status need from response
        if (each.customerId &&
          authData &&
          each.customerId === authData._id) {
          qaObj.questionedByMe = true;
        }
        if (!qaObj.questionedByMe) {
          qaObj.answerByMe = true;
        }
        qaObj.answers = [];
        qaArr.push({ ...qaObj });
      });
    }
    updateQA([...qaArr]);
  }, [authData]);

  useEffect(() => {
    getAllQaList();
  }, [getAllQaList]);

  const chooseAnswer = (item) => {
    props.history.push(`/myqa-details/${item._id}`);
  };

  const openMyQa = () => {
    props.history.push("/myQA");
  };

  // const removeQuestion = (questionDetails) => {
  //   toggleRemoveConfirmationModal(true);
  //   updateRemoveableQuestionId(questionDetails._id);
  // };

  const remove = async () => {
    toggleDisablePage(true);
    let removeResponse = await removeForumQuestion(removableQuestionId);

    toggleDisablePage(false);
    if (removeResponse.data.errorCode) {
      toast.error(API_ERROR[removeResponse.data.errorCode]);
      return;
    }

    toast.success(API_SUCCESS.REMOVE_QUESTION);
    toggleRemoveConfirmationModal(false);
    getAllQaList();
    
  };

  const editQuestion = (answerData) => {
    toggleEditable(true);
    const localAnswer = { ...answerData };
    localAnswer.qId = answerData._id;
    localAnswer.question = answerData.questionText;
    updateSelectedQuestionForEdit({ ...localAnswer });
  };

  return (
    <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      {isEditable ?
        <EditAnswerModal
          editType={"question"}
          displayModal={isEditable}
          toggleModal={toggleEditable}
          editableQuestion={editableQuestion}
          updateSelectedQuestionForEdit={updateSelectedQuestionForEdit}
          getQuestionDetails={getAllQaList}
        /> : null}
      {openRemoveConfirmationModal ?
        <RemoveModal
          removeType={"question"}
          displayModal={openRemoveConfirmationModal}
          toggleModal={toggleRemoveConfirmationModal}
          remove={remove}
        /> : null}
      <div className="col-lg-12 pad-0-below-500">
        <div className="row" style={{ margin: 0 }}>
          <div className={`col-md-7 col-xs-12 categories-heading`}>
            <h4 className={`mb-0 page-heading`}>My Q & A</h4>
            <button type="button" className="btn btn-link pt-0" onClick={() => props.history.push("/problem-area?category=60883f8d1069d58676cb0671")}>Back to Problem Area</button>
          </div>
          <div className={`col-md-5 col-xs-12 text-right language-dropdown-wrapper`}>
            <button type="button" className="btn btn-outline-primary" onClick={() => {
              sessionStorage.removeItem("forumFilter");
              props.history.push("/forum");}}>Forum</button>
            <button type="button" className="btn btn-outline-default ml-3" onClick={openQuestionModal}>Ask Question</button>
          </div>
        </div>
        <div className="col-12">
          <div className="row" style={{ margin: 0 }}>
            {qaList.length ?
              qaList.map((professional, index) => {
                return <div className={`col-12 professional-wrapper ${professional.selected ? 'professional-wrapper-selected' : ''}
                                `} key={index}>
                  <div className="row professional-wrapper-row pos-relative p-0" style={{ margin: 0 }}>
                    <div className="col-12 p-0">
                      <div className={`professional-designation row m-0 ${!professional.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`} onClick={professional.status === 2 ? () => chooseAnswer({ ...professional }) : () => {}}>
                        <div className="col-12 pl-0 pr-0 pb-1">
                          <div className="row m-0">
                            <div className="col-sm-9 col-xs-12 pl-0">
                              <div className="qa-date">{professional.date}</div>
                              <div className="qa-by-you">
                                {professional.answerByMe ? "Answered by you" : ""}
                                {professional.questionedByMe ? "Asked by you" : ""}
                              </div>
                            </div>
                            {professional.questionedByMe ?
                            <div className={`col-sm-3 col-xs-12 text-right pt-2 status-text ${professional.status === 2 ? 'confirmed-status' : ''}`}>
                              {qaStatus[professional.status]}
                            </div>
                            : null}
                          </div>
                          
                        </div>
                        
                        <div className="col-12 p-0">
                          {professional.selected ?
                            professional.askedByProfilePhoto ?
                              <div style={{ backgroundImage: "url(" + professional.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                              <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                              {!professional.selected ?
                                professional.questionText :
                                <div className="question-asked-details">
                                <div className="question-commentedby">{professional.askedByName}</div>
                                {professional.questionText}
                            </div>}
                        </div>
                      </div>
                    </div>
                    {professional.questionedByMe && professional.status !== 2 ?
                       <i className="fas fa-pencil-alt pull-right" style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        fontSize: "16px"
                       }} onClick={(e) => { e.stopPropagation(); editQuestion(professional); }}></i> : null}
                  </div>
                </div>
              })
              : <div className="text-center well col-12 color-black">
                {!loadingQuestions ? 
                <div className='no-data-found'>
                <img src={require("../../../images/no-data.png")} alt="no data" />
                <br />
                <div className='no-data-header'>No Result Found</div>
                <div className='no-data-desc'>You have not answered or asked any question yet!</div>
                <button className='btn btn-primary no-data-button' onClick={openQuestionModal}>Ask Question</button>
            </div>
                 : 'loading...'}
              </div>}
          </div>
        </div>
      </div>
      <AskQuestionModal displayModal={displayModal} toggleModal={toggleModal} getAllForumList={getAllQaList} openMyQa={openMyQa} from={"qa"} />
    </div>
  )
}

export default MyQA;