import React, { useEffect, useState } from "react";
import "./slot-selector.css";

const SlotSelector = (props) => {
    const [selectedSection, updateSelectedSection] = useState(0);

    const chooseSlot = (timeSlot, section) => {
        props.chooseSlot(timeSlot);
        console.log("section ", section)
        updateSelectedSection(section);
    };

    useEffect(() => {
        if (props.serviceTimeHours) {
            if (props.serviceTimeHours.some(each => each.section === 1)) {
                updateSelectedSection(1);
            } else if (props.serviceTimeHours.some(each => each.section === 2)) {
                updateSelectedSection(2);
            } else {
                updateSelectedSection(3);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        {props.serviceTimeHours.some(each => each.section === 1) ?
            <>
            <div className="col-12">
                <div className={`section-header ${selectedSection === 1 ? "selected" : ""}`}><img src={require(`../../../images/morning-dark.png`)} alt="morning" />Morning ({props.serviceTimeHours.filter(each => each.section === 1).length} slot{props.serviceTimeHours.filter(each => each.section === 1).length > 1 ? "s" : ""})</div>
                {props.serviceTimeHours.filter(each => each.section === 1).map((time) => {
                    return <div className={`each-time-slot ${time.selected ? 'active' : ''}`} key={time.text} onClick={() => chooseSlot(time, 1)}>
                        {time.text}
                    </div>
                })}
            </div>
            <hr className="w-100" />
            </>
            : null}
        {props.serviceTimeHours.some(each => each.section === 2) ?
        <>
            <div className="col-12">
                <div className={`section-header ${selectedSection === 2 ? "selected" : ""}`}><img src={require(`../../../images/noon-dark.png`)} alt="noon" />Afternoon ({props.serviceTimeHours.filter(each => each.section === 2).length} slot{props.serviceTimeHours.filter(each => each.section === 2).length > 1 ? "s" : ""})</div>
                {props.serviceTimeHours.filter(each => each.section === 2).map((time) => {
                    return <div className={`each-time-slot ${time.selected ? 'active' : ''}`} key={time.text} onClick={() => chooseSlot(time, 2)}>
                        {time.text}
                    </div>
                })}
            </div>
            <hr className="w-100" />
            </>
            : null}
        {props.serviceTimeHours.some(each => each.section === 3) ?
        <>
            <div className="col-12">
                <div className={`section-header ${selectedSection === 3 ? "selected" : ""}`}><img src={require(`../../../images/eve-dark.png`)} alt="eve" />Evening ({props.serviceTimeHours.filter(each => each.section === 3).length} slot{props.serviceTimeHours.filter(each => each.section === 3).length > 1 ? "s" : ""})</div>
                {props.serviceTimeHours.filter(each => each.section === 3).map((time) => {
                    return <div className={`each-time-slot ${time.selected ? 'active' : ''}`} key={time.text} onClick={() => chooseSlot(time, 3)}>
                        {time.text}
                    </div>
                })}
            </div>
            <hr className="w-100" />
            </>
            : null}
    </>
};

export default SlotSelector;