export const timeArrConst = ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];
export const Config = {
    Service: {
        Status: {
            Pending: 1,
            Approved: 2
        }
    },
    Booking: {
        Status: {
            Pending: 1,
            Confirmed: 2,
            Completed: 3,
            CanceledByCustomer: 4,
            CanceledByVendor: 5,
            Failed: 6,
            Timedout: 7,
            CanceledByAdmin: 8
        },
        NumberStatus : {
            1: "Pending",
            2: "Confirmed",
            3: "Completed",
            4: "Cancelled",
            5: "Cancelled",
            6: "Failed",
            7: "Timedout",
            8: "Cancelled"
        }
    },
    Event: {
        Status: {
            Pending: 1,
            Confirmed: 2,
            Completed: 3,
            Failed: 4,
            Timedout: 5
        },
        NumberStatus : {
            1: "Pending",
            2: "Confirmed",
            3: "Completed",
            4: "Failed",
            5: "Expired"
        }
    },
    Transaction: {
        Status: {
            1: "Pending",
            2: "Paid",
            3: "Failed",
            4: "Refund Initiated",
            5: "Refund Failed",
            6: "Payment Pending"
        },
        NumberStatus: {
            "Pending": 1,
            "Paid": 2,
            "Failed": 3,
            "RefundInitiated": 4,
            "RefundFailed": 5,
            "PaymentPending": 6
        }
    }
};

export const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const urls = {
    "/book-now": "/book-now",
    "/forum": "/problem-area",
    "/problem-area": "/problem-area",
    "/faq": "/faq",
    "/myQA": "/problem-area",
    "/booking-list": "/booking-list",
    "/problem-details": "/problem-area",
    "/professional-details": "/book-now",
    "/booking-details": "/booking-list",
    "/rating-review": "/booking-list",
    "/video-call": "/booking-list",
    "/session-bookings": "/session-bookings",
    "/session-booking-details": "/session-bookings",
    "/event-booking-list": "/event-booking-list",
    "/event-booking-details": "/event-booking-list",
    "/assessment-results": "/assessment-results",
    "/assessment-result-details": "/assessment-results",
    "/notifications": "/notifications",
    "/my-favourite": "/my-favourite",
    "/assessment-list": "/assessment-list",
    "/assessment-details": "/assessment-list",
    "/workshop-booking-list": "/workshop-booking-list",
    "/workshop-booking-details": "/workshop-booking-list",
};

export const notificationTypesRedirectionURLMapObj = {
    "appointmentBooked": "/booking-details/",
    "appointmentCanceledByCustomer": "/booking-details/",
    "bookingCanceledByAdmin": "/booking-details/",
    "appointmentReminderMinute": "/booking-details/",
    "vendorAddedNote": "/booking-details/",
    "ratingReminderMinute": "/booking-details/",
    "eventBooked": "/event-booking-details/",
    "eventReminderMinute": "/event-booking-details/",
    "sessionBooked": "/session-booking-details/",
    "sessionReminderMinute": "/session-booking-details/",
    "approvedForumQuestion": "/myQA",
    "rejectedForumQuestion": "/myQA",
    "answeredForumQuestion": "/myQA",
    "newSession": "/professional-details/",
    "newEvent": "/event-details/",
    "newWorkshop": "/workshop-details/",
    "workshopBooked": "/workshop-booking-details/",
    "workshopReminderMinute": "/workshop-booking-details/",
    "workshopReminderDaily": "/workshop-booking-details/",
    "workshopReminderHour": "/workshop-booking-details/",
    "testCompleted": "/assessment-result-details/",
    "linkNotAddedAfterFifteenMinute": "/booking-details/",
    "linkAddedAfterMeetingStart": "/booking-details/",
};

export const testColorScore = {
    1: "#0CBB71",
    2: "#F3A243",
    3: "#F31630",
    4: "#FFCA0D",
    5: "#265EA8"
};

export const localGlobalConfigData = {
    showWorkshop: true
};

export const convertTwelveToZeroAM = (timeStr) => {
    if (timeStr.includes('AM') && timeStr.includes('12:')) {
        let hr = timeStr.split(':')[0];
        const min = timeStr.split(':')[1];

        if (hr === '12') {
            hr = '00';
        }

        return `${hr}:${min}`;
    }

    return timeStr;
};

export const orgMetaData = {
    image: 'https://parentcraftmedia.s3.ap-south-1.amazonaws.com/PCOGImage436x228.png',
    url: 'https://www.parentcraftindia.com/',
    home: {
        title: "Mental Health India, Consult Counsellor, Psychologist, More",
        description: "Mental Health Professionals India, Consult with Counsellor, Psychologist, Psychiatrist, Mental Health Self Assessment Tests, Career Counselling, Aptitude Test",
        keywords: "Mental Health, Mental Health India, Mental Health Professionals, Mental Health Professionals India, Psychologist India, Counsellors India, Counselling Psychology India, Psychology Counsellors, Psychologist, Psychologist India, Counselling, Psychology, Counselling Psychology, Counselling Psychology India, Mental health counsellor, mental health counselling, Online Counselling, Clinical Psychologist, Clinical Psychologist India, Clinical Psychologist Counsellor, Psychiatrist, Psychiatrist India, Online Psychiatrist India, Career Counselling, Career Counselling India, Career Counselling Assessment, Aptitude Test, Aptitude Testing, Counselling Session India, Mental Health Self Assessment, Psychology Self Assessment, Child Counselling, Child Counselling India, Child Psychologist India, Child Counsellor India, Relationship Counselling, Relationship Counselling India, Parenting Counselling, Parenting Counselling India, Counsellors for Mental Health, Family Therapist, Parenting, Relationship, Anxiety, Depression, Stress, Aggression, Bipolar, Eating disorder, Hopelessness, ADHD, OCD, Grief and loss"
    },
    bookNow: {
        title: "Mental Health Professionals India -Book Appointment with Counsellor, Psychologist, Psychiatrist",
        description: "Mental Health Professionals India - Book Appointment with Psychologist / Counsellor",
        keywords: "Mental Health Professionals India, Psychologist India, Counselling Psychology India, Clinical Psychologist India, Psychiatrist India, Online Psychiatrist India, Online Counselling Session, Career Counsellors, Career Counselling India, Online Psychologist Counselling, Counselling Professionals India, Mental Health Self Assessment, Child Counsellor, Child Psychologist, Child Psychiatrist, Child Counselling, Child Self Assessment, Child Psychologist India, Child Counsellor India, Relationship Counsellor, Relationship Counselling India, Parenting Counsellor, Parenting Counselling India, Top Counsellors India, Best Cousellors India, Top Psychologist India, Best Psychologist India, Top Psychiatrist India, Best Psychiatrist India, Top Mental Health Professionals, Best Mental Health Professionals",
    },
    selfAssessment: {
        title: "Take Online Mental Health Test, Self Assessment",
        description: "Online tests for mental health self-assessment including ADHD, OCD, Anxiety, Stress, Depression, Bipolar, Sleep disorder. A quickest way to determine whether you are experiencing symptoms of a mental health condition.",
        keywords: "Online tests for mental health self-assessment including ADHD, OCD, Anxiety, Stress, Depression, Bipolar, Sleep disorder. A quickest way to determine whether you are experiencing symptoms of a mental health condition."
    },
    problemArea: {
        title: "Talk to Counsellor, Psychologist, Psychiatrist, Mental Health related issues, questions, concerns",
        description: "Common Problem related to Mental Health in India - Ask Your Question on Forums get Answer from our Counsellors / Psychologist",
        keywords: "Selection of Schools, Academic Stress, Academic Failure, Career Selection, Subject Selection, Disinterest in Life, Disinterest in School, Disinterest in Studies, Social Behaviour, Teenage Problems, Free Time, Child Abuse, Drug Abuse, Sexual Abuse,Sex Education, Emotional Problem, Emotional Stress, Heart break, Teenage Issues, Teenage Problem, Bullying, Abusive Father, Abusive Mother, Abusive Parents, Poor Grades, Addiction, Mobile Addiction, Low Self Esteem, Lack of Confidence, Parenting issues, Relationship issues, upbringing of child, good parents, good parenting, healthy relation, healthy relationship"
    }
}

export const assessmentMetaData = {
    "list": {
        title: "Take Online Mental Health Test, Self Assessment"
    },
    "623b71bc7a14c0fd9a8957f3": {
        title: "ADHD Self Assessment, Counselling Psychologist India"
    },
    "625b05192303fecac959acfc": {
        title: "Aggression Self Assessment, Counselling Psychologist India"
    },
    "624e9d0b709616216bcaf0e4": {
        title: "Anxiety Self Assessment, Counselling Psychologist India"
    },
    "627a59e669a63cdcf0aaf0d2": {
        title: "Job Satisfaction Self Assessment, Counselling India"
    },
    "627a73bd2b9c676e5a1f2b8a": {
        title: "OCD Self Assessment, Counselling Psychologist India"
    },
    "627a56237b93ee5c8a594f34": {
        title: "Organization Commitment Self Assessment, Mental Health India"
    },
    "6240ac09a8918a8f79e31b2d": {
        title: "Parenting Self Assessment, Counselling Psychologist India"
    },
    "627a523afef3c4586efb922c": {
        title: "Personal Effectiveness Self Assessment, Mental Health India"
    },
    "626402c88e2f6b5b81e9fde0": {
        title: "Adjustment Self Assessment, Counselling Psychologist India"
    },
    "625b1b8514ff66e4c3e8d347": {
        title: "Behaviour Self Assessment, Counselling Psychologist India"
    },
    "627020c16f5c794cce95c5e6": {
        title: "Bipolar Self Assessment, Counselling Psychologist India"
    },
    "62960b43a5ec9b569d34c152": {
        title: "Confidence / Self-esteem Self Assessment Mental Health India"
    },
    "62545d0a49f7a1091de6e298": {
        title: "Depression Self Assessment, Counselling Psychologist India"
    },
    "623b5d70c0eeceea83cce966": {
        title: "Eating Disorder Self Assessment, Counselling Psychologist India"
    },
    "6295ff79b448254cd367929d": {
        title: "General Well Being Self Assessment, Counselling India"
    },
    "628b074f6d5990b13de3b267": {
        title: "Grief &amp; Loss Self Assessment, Counselling Psychologist India"
    },
    "6285032d1fd429548f236d9e": {
        title: "Hopelessness Self Assessment, Counselling Psychologist India"
    },
    "62408a08aed23c6db75b7d01": {
        title: "Relationship Self Assessment, Counselling Psychologist India"
    },
    "62826a738e37f34b2d319f75": {
        title: "Sleep Self Assessment, Counselling Psychologist India"
    },
    "625462df86f068136120e36f": {
        title: "Stress Self Assessment, Counselling Psychologist India"
    },
    "64d8ae0ffb7ed3c19a04ebe3": {
        title: "Memory Assessment"
    }
};
