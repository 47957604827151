import React, { useEffect, useState, useCallback } from 'react';
import "./problem-area.css";

import { getEventsBookings } from "../../crud/booking.crud";
import { getEventList } from "../../crud/home.crud";
import {
    timeArrConst,
    Config,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

const defaultImage = require('../../../images/imgAvtr.png');
const timeArr = [...timeArrConst];

const SessionList = (props) => {
    const [upcomingBookings, updateUpcomingBooking] = useState([]);
    const [pastBookings, updatePastBooking] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [currentTab, updateCurrentTab] = useState("upcoming");
    const [loadingUpcomingBooking, toggleLoadingUpcomingBooking] = useState(true);
    const [loadingPastBooking, toggleLoadingPastBooking] = useState(true);
    const [hasEvent, updateHasEvent] = useState(false);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[0] - 1]);
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
    };

    const getAllUpcomingBookings = useCallback(async () => {
        toggleDisablePage(true);
        const upcomingBookingResult = await getEventsBookings("upcoming");

        toggleDisablePage(false);
        toggleLoadingUpcomingBooking(false);
        if (upcomingBookingResult.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
            return;
        }
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
            return;
        }

        const localUpcomingBookings = [];
        // let sortedArr = [];
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.data && upcomingBookingResult.data.data.data.length) {
            let bookingObj = {};
            upcomingBookingResult.data.data.data.forEach(each => {
                if (each.eventRef) {
                    bookingObj = {};
                    bookingObj._id = each._id;
                    bookingObj.name = each.vendorRef.personalInfo.name;
                    bookingObj.profilePicture = each.vendorRef.personalInfo.profilePicture;
                    bookingObj.designation = "";
                    bookingObj.amount = each.paymentDetails.total;
                    if (each.eventRef) {
                        bookingObj.className = each.eventRef.title;
                        bookingObj.servDesc = each.eventRef.description;
                    }
                    bookingObj.experience = "";
                    if (each.vendorRef.personalInfo.experience && (each.vendorRef.personalInfo.experience.year)) {
                        const year = each.vendorRef.personalInfo.experience.year;
                        if (year) {
                            bookingObj.experience += `${year} year${year > 1 ? 's' : ''}`;
                        }
                    }
                    bookingObj.date = getFullDateFormat(new Date(each.date));
                    bookingObj.slots = each.eventRef.slots;
                    bookingObj.profilePicture = each.eventRef.images[0].url;
                    bookingObj.startTime = getStartTime(each.eventRef.slots);
                    bookingObj.status = Config.Event.NumberStatus[each.status];
                    bookingObj.statusColor = each.status === 3 ? "#00A365" : (each.status !== 1 && each.status !== 2) ? "#E52E40" : "#185bdb";
                    bookingObj.endTime = getEndTime(each.eventRef.slots);
                    if (each.vendorRef.personalInfo.categories && each.vendorRef.personalInfo.categories.length) {
                        each.vendorRef.personalInfo.categories.forEach((item, index) => {
                            bookingObj.designation += item.title;
                            if (index !== each.vendorRef.personalInfo.categories.length - 1) {
                                bookingObj.designation += ", ";
                            }
                        });
                    }
                    localUpcomingBookings.push({ ...bookingObj });
                }
            });
        }

        updateUpcomingBooking([...localUpcomingBookings]);
        if (!localUpcomingBookings.length) {
            const upcomingEvents = await getEventList(0);
            if (upcomingEvents.data.data && upcomingEvents.data.data.data && upcomingEvents.data.data.data.length) {
                updateHasEvent(true);
            }
        }

    }, []);

    const getAllPastBookings = useCallback(async () => {
        toggleDisablePage(true);
        const pastBookingResult = await getEventsBookings("past");
        toggleLoadingPastBooking(false);
        toggleDisablePage(false);
        if (pastBookingResult.data.errorCode) {
            toast.error(API_ERROR[pastBookingResult.data.errorCode]);
            return;
        }
        if (pastBookingResult.data.data && pastBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[pastBookingResult.data.data.errorCode]);
            return;
        }

        const localPastBookings = [];
        let bookingObj = {};
        if (pastBookingResult.data.data && pastBookingResult.data.data.data && pastBookingResult.data.data.data.length) {
            // pastBookingResult.data.data.data = getSortedArray([...pastBookingResult.data.data.data], 'past');
            pastBookingResult.data.data.data.forEach(each => {
                if (each.eventRef) {
                    bookingObj = {};
                    bookingObj._id = each._id;
                    bookingObj.name = each.vendorRef.personalInfo.name;
                    bookingObj.profilePicture = each.vendorRef.personalInfo.profilePicture;
                    bookingObj.designation = "";
                    bookingObj.amount = each.paymentDetails.total;
                    bookingObj.status = Config.Event.NumberStatus[each.status];
                    bookingObj.statusColor = each.status === 3 ? "#00A365" : (each.status !== 1 && each.status !== 2) ? "#E52E40" : "#185bdb";
                    bookingObj.rating = each.ratingDetails && each.ratingDetails.rating ? each.ratingDetails.rating.toFixed(1) : 0;
                    if (each.eventRef) {
                        bookingObj.className = each.eventRef.title;
                        bookingObj.servDesc = each.eventRef.description;
                    }
                    if (each.ratingDetails) {
                        bookingObj.bookingRating = each.ratingDetails.rating;
                    }
                    bookingObj.experience = "";
                    if (each.vendorRef.personalInfo.experience && (each.vendorRef.personalInfo.experience.year)) {
                        const year = each.vendorRef.personalInfo.experience.year;
                        if (year) {
                            bookingObj.experience += `${year} year${year > 1 ? 's' : ''}`;
                        }
                    }
                    bookingObj.date = getFullDateFormat(new Date(each.date));
                    bookingObj.slots = each.eventRef.slots;
                    bookingObj.profilePicture = each.eventRef.images[0].url;
                    bookingObj.startTime = getStartTime(each.eventRef.slots);
                    bookingObj.endTime = getEndTime(each.eventRef.slots);
                    if (each.vendorRef.personalInfo.categories && each.vendorRef.personalInfo.categories.length) {
                        each.vendorRef.personalInfo.categories.forEach((item, index) => {
                            bookingObj.designation += item.title;
                            if (index !== each.vendorRef.personalInfo.categories.length - 1) {
                                bookingObj.designation += ", ";
                            }
                        });
                    }
                    localPastBookings.push({ ...bookingObj });
                }
            });
        }
        updatePastBooking([...localPastBookings]);
    }, []);

    const updateUrl = (type) => {
        const localUrl = `/event-booking-list?type=${type}`;
        props.history.push(localUrl);
    };

    useEffect(() => {
        getAllUpcomingBookings();
        getAllPastBookings();
        const urlParams = new URLSearchParams(window.location.search);
        const typeParam = urlParams.get('type');
        if (typeParam) {
            updateCurrentTab(typeParam);
        } else {
            updateUrl("upcoming");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllUpcomingBookings, getAllPastBookings]);

    return (
        <div className={`row problem-area booking-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-6 categories-heading">
                        <h4 className={`mb-0 problem-category-heading`}>Event Booking List <i className="fas fa-info-circle ml-3" aria-hidden="true" style={{ fontSize: "18px", cursor: "pointer" }} /></h4>
                    </div>
                    <div className="col-6 pr-0 upcoming-past-button-wrapper text-right pt-3">
                        <button onClick={() => { updateCurrentTab("upcoming"); updateUrl("upcoming") }} className={`btn btn-primary upcoming-past-button ${currentTab === 'upcoming' ? 'active' : ''}`}>Upcoming</button>
                        <button onClick={() => { updateCurrentTab("past"); updateUrl("past") }} className={`btn btn-primary upcoming-past-button ${currentTab === 'past' ? 'active' : ''}`}>Past</button>
                    </div>
                </div>
                <div className="row" style={{ margin: 0 }}>
                    {currentTab === "upcoming" ?
                        <div className="col-12">
                            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                                {upcomingBookings.length ?
                                    upcomingBookings.map((professional, index) => {
                                        return <div className={`col-12 professional-wrapper ${index !== upcomingBookings.length - 1 ? 'mb-15' : ''}
                                `} key={index}>
                                            <div className="row booking-item-wrapper" style={{ margin: 0 }}>
                                                <div className="booking-profile-image">
                                                    {professional.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + professional.profilePicture + ")" }} className="background-image-professional" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                    {professional.rating ? <div className="profile-rating text-center mt-2"><i className={`fa-star fas`}></i> {professional.rating} </div> : null}
                                                </div>
                                                <div className="booking-details-wrapper">
                                                    <div className="col-12 desktop-view-only">
                                                        <div className="row m-0 booking-item-side-row">
                                                            <div className="col-5 pr-20 pl-0 left-booking-item">
                                                                <div className="professional-name">{professional.name}</div>
                                                                <div className="professional-designation">{professional.designation}</div>
                                                            </div>
                                                            <div className="col-7 pl-20 pr-0">
                                                                <div className="professional-name">{professional.className}</div>
                                                                <div className="professional-service-desc">{professional.servDesc}</div>
                                                                <div className="professional-date-time row m-0 booking-item-side-row">
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Selected date</div>
                                                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(professional.date))}</div>
                                                                    </div>
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Time slot</div>
                                                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                                    <div className="col-8 pl-0 pt-3" style={{
                                                                        color: professional.statusColor
                                                                    }}>
                                                                        Status: {professional.status}
                                                                    </div>
                                                                    <div className="col-4 pr-0">
                                                                        <button className="btn btn-primary details-button" onClick={() => props.history.push(`/event-booking-details/${professional._id}`)}>View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mobile-view-only">
                                                        <div className="professional-name">{professional.name}</div>
                                                        <div className="professional-designation">{professional.designation}</div>
                                                        <div className="professional-location">{professional.className}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 mobile-view-only mt-3">
                                                    <div className="professional-date-time row m-0 booking-item-side-row">
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Selected date</div>
                                                            <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "5px" }} />{getDisplayFullDateFormat(new Date(professional.date))}</div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Time slot</div>
                                                            <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "5px" }} alt="clock" />{professional.startTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                        <div className="col-6 pl-0 pt-3" style={{
                                                            color: professional.statusColor
                                                        }}>
                                                            Status: {professional.status}
                                                        </div>
                                                        <div className="col-6 pr-0">
                                                            <button className="btn btn-primary details-button" onClick={() => props.history.push(`/event-booking-details/${professional._id}`)}>View Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : <div className="text-center well col-12 color-black">
                                        {!loadingUpcomingBooking ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Event Found</div>
                                            {hasEvent ?
                                                <button className='btn btn-primary no-data-button' onClick={() => props.history.push('/event-list')}>View Event</button> : null}
                                        </div> : 'loading...'}
                                    </div>}
                            </div>
                        </div> :
                        <div className={`col-12`}>
                            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                                {pastBookings.length ?
                                    pastBookings.map((professional, index) => {
                                        return <div className={`col-12 professional-wrapper ${index !== pastBookings.length - 1 ? 'mb-15' : ''}
                                `} key={index}>
                                            <div className="row booking-item-wrapper" style={{ margin: 0 }}>
                                                <div className="booking-profile-image pos-relative">
                                                    {professional.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + professional.profilePicture + ")" }} className="background-image-professional" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                    {/* {professional.rating ? <div className="profile-rating text-center mt-2"><i className={`fa-star fas`}></i> {professional.rating} </div> : null} */}
                                                    {professional.status === "Completed" ?
                                                        professional.bookingRating ?
                                                            <div className='rated text-center desktop-view-only'>You rated <div>{professional.bookingRating.toFixed(1)} <i className={`fa-star fas`}></i>  </div></div> : <div className='rate-button desktop-view-only'><button className='btn btn-primary' onClick={() => props.history.push(`/online-event-rating-review/${professional._id}`)}>Rate Now</button></div>
                                                        : null}
                                                </div>
                                                <div className="booking-details-wrapper">
                                                    <div className="col-12 desktop-view-only">
                                                        <div className="row m-0 booking-item-side-row">
                                                            <div className="col-5 pr-20 pl-0 left-booking-item">
                                                                <div className="professional-name">{professional.name}</div>
                                                                <div className="professional-designation">{professional.designation}</div>
                                                            </div>
                                                            <div className="col-7 pl-20 pr-0">
                                                                <div className="professional-name">{professional.className}</div>
                                                                <div className="professional-service-desc">{professional.servDesc}</div>
                                                                <div className="professional-date-time row m-0 booking-item-side-row">
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Selected date</div>
                                                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {getDisplayFullDateFormat(new Date(professional.date))}</div>
                                                                    </div>
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Time slot</div>
                                                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                                    <div className={`col-8 pl-0 pt-3`}>
                                                                        <div style={{
                                                                            color: professional.statusColor
                                                                        }}>Status: {professional.status}</div>
                                                                        
                                                                    </div>
                                                                    <div className={`col-4 pr-0`}>
                                                                        <button className="btn btn-primary details-button" onClick={() => props.history.push(`/event-booking-details/${professional._id}`)}>View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mobile-view-only">
                                                        <div className="professional-name">{professional.name}</div>
                                                        <div className="professional-designation">{professional.designation}</div>
                                                        <div className="professional-location">{professional.className}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 mobile-view-only mt-3">
                                                    <div className="professional-date-time row m-0 booking-item-side-row">
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Selected date</div>
                                                            <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "5px" }} />{getDisplayFullDateFormat(new Date(professional.date))}</div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Time slot</div>
                                                            <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "5px" }} alt="clock" />{professional.startTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                        <div className={`col-6 pl-0 ${professional.status === "Completed" ? "" : "pt-3"}`}>
                                                            <div className='pb-1' style={{
                                                                color: professional.statusColor
                                                            }}>Status: {professional.status}</div>
                                                            {professional.status === "Completed" ?
                                                                professional.bookingRating ?
                                                                    <div className='rated'>You rated <span className='rating'><span style={{verticalAlign: "middle"}}>{professional.bookingRating.toFixed(1)}</span> <i className={`fa-star fas`}></i>  </span></div> : <button className='btn btn-primary' onClick={() => props.history.push(`/online-event-rating-review/${professional._id}`)}>Rate Now</button>
                                                                : null}
                                                        </div>
                                                        <div className={`col-6 pr-0 ${professional.status === "Completed" ? "pt-3" : ""}`}>
                                                            <button className="btn btn-primary details-button" onClick={() => props.history.push(`/event-booking-details/${professional._id}`)}>View Details</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : <div className="text-center well col-12 color-black">
                                        {!loadingPastBooking ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Event Found</div>

                                        </div> : "loading..."}
                                    </div>}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default SessionList;