import React, { useEffect } from "react";
import * as MobileDetect from "mobile-detect";
const ShareComponent = () => {
  const detectDevice = () => {
    let type = new MobileDetect(window.navigator.userAgent);
    return type.os();
  };

  useEffect(() => {
    if (detectDevice() === "AndroidOS") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.parentcraftindia.customer";
    } else if (detectDevice() === "iOS") {
      window.location.href =
        "https://apps.apple.com/in/app/parentcraft-india/id1496497624";
    } else {
      window.location.href = "https://www.parentcraftindia.com";
    }
  }, []);

  return <></>;
};

export default ShareComponent;
