import React, { useEffect, useState, useCallback, useRef } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import { getProfessionalCategories, getNearbyProfessionals, getEventList, markFavouriteProfessional, getWorkshopList } from "../../crud/home.crud";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import { orgMetaData } from '../../config/constants';
import { bookNowMetaData } from '../../config/meta';
import {
    timeArrConst,
    localGlobalConfigData,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import {
    API_ERROR
} from "../../config/error-message";
import Sidebar from './sidebar';

const defaultImage = require('../../../images/imgAvtr.png');
const timeArr = [...timeArrConst];

const Dashboard = (props) => {

    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
            authData: auth.user
        }),
        shallowEqual
    );
    const [categories, updateCategory] = useState([]);
    const [professionals, updateProfessional] = useState([]);
    const [sortDown, toggleSort] = useState(null);
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [selectedCategory, updateSelectedCategory] = useState("");
    const [userLocation, updateUserLocation] = useState([]);
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [popUpOpened, openSortPopup] = useState(false);
    const [loadingProfessionals, toggleLoadingProfessionals] = useState(true);
    const [showPrevEventButtons, togglePrevEventButton] = useState(false);
    const [showNextEventButtons, toggleNextEventButton] = useState(false);
    const [showPrevWorkshopButtons, togglePrevWorkshopButton] = useState(false);
    const [showNextWorkshopButtons, toggleNextWorkshopButton] = useState(false);
    const [events, updateEvents] = useState([]);
    const [workshops, updateWorkshops] = useState([]);
    const [searchedText, updateSearchedText] = useState("");
    const [isSearchFocused, focusSearch] = useState(false);
    const [allFilterObj, updateFilterObj] = useState({});
    const [isReset, toggleReset] = useState(false);
    const [fromLower, toggleLower] = useState(false);
    const [isFilterOpened, toggleFilterOpen] = useState(false);
    const [isFilterFocused, toggleFocus] = useState(true);

    const searchRef = useRef();

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[0] - 1]);
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        return today === day;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const checkEventListDisplayTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
        return (endTime > today);
    }, []);

    const handleNextPrevButtons = (type) => {
        if (document.getElementsByClassName(type)[0]) {
            if (document.getElementsByClassName(type)[0].clientWidth < document.getElementsByClassName(type)[0].scrollWidth) {
                if (type === "categories-wrapper") {
                    togglePrevButton(true);
                    toggleNextButton(true);
                } else if (type === "events-wrapper") {
                    togglePrevEventButton(true);
                    toggleNextEventButton(true);
                } else if (type === "workshops-wrapper") {
                    togglePrevWorkshopButton(true);
                    toggleNextWorkshopButton(true);
                }

                if (document.getElementsByClassName(type)[0].scrollLeft === 0) {
                    if (type === "categories-wrapper") {
                        togglePrevButton(false);
                    } else if (type === "events-wrapper") {
                        togglePrevEventButton(false);
                    } else if (type === "workshops-wrapper") {
                        togglePrevWorkshopButton(false);
                    }
                }
                if (Math.round(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.round(document.getElementsByClassName(type)[0].scrollLeft) ||
                    Math.floor(document.getElementsByClassName(type)[0].scrollWidth - document.getElementsByClassName(type)[0].clientWidth) === Math.floor(document.getElementsByClassName(type)[0].scrollLeft)) {
                    if (type === "categories-wrapper") {
                        toggleNextButton(false);
                    } else if (type === "events-wrapper") {
                        toggleNextEventButton(false);
                    } else if (type === "workshops-wrapper") {
                        toggleNextWorkshopButton(false);
                    }
                }

            } else {
                if (type === "categories-wrapper") {
                    togglePrevButton(false);
                    toggleNextButton(false);
                } else if (type === "events-wrapper") {
                    togglePrevEventButton(false);
                    toggleNextEventButton(false);
                } else if (type === "workshops-wrapper") {
                    togglePrevWorkshopButton(false);
                    toggleNextWorkshopButton(false);
                }
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step, type) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons(type);
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'right', 25, 129, 10, type);
    };

    const prevClick = (type) => {
        var container = document.getElementsByClassName(type)[0];
        sideScroll(container, 'left', 25, 129, 10, type);
    };

    window.onresize = () => {
        handleNextPrevButtons("categories-wrapper");
        if (events.length) {
            handleNextPrevButtons("events-wrapper");
        }
        if (workshops.length) {
            handleNextPrevButtons("workshops-wrapper");
        }
    };

    window.onload = () => {
        handleNextPrevButtons("categories-wrapper");
        if (events.length) {
            handleNextPrevButtons("events-wrapper");
        }
        if (workshops.length) {
            handleNextPrevButtons("workshops-wrapper");
        }
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons("categories-wrapper");
            if (events.length) {
                handleNextPrevButtons("events-wrapper");
            }
            if (workshops.length) {
                handleNextPrevButtons("workshops-wrapper");
            }
        }
    }, [events.length, workshops.length]);

    const getAllNearbyProfessionals = useCallback(async (rating, categoryData, location, searchKey, otherFilterObj) => {
        let requestBody = {
            "coordinates": location && !rating ? location : [],
            "options": {}
        };
        if (rating) {
            requestBody.options = {
                "sortConfig": {
                    "rating": rating
                }
            }
        }
        if (categoryData) {
            requestBody.options.categoryId = categoryData;
        } else if (categoryData !== "") {
            if (selectedCategory) {
                requestBody.options.categoryId = selectedCategory;
            }
        }
        if (searchKey && searchKey.trim().length) {
            requestBody = {
                options: {
                    searchKey: searchKey === "EMPTY" ? "" : searchKey
                }
            }
        }
        if (otherFilterObj && Object.keys(otherFilterObj).length) {
            for (let item in otherFilterObj) {
                if (item !== "searchKey") {
                    requestBody.options[item] = otherFilterObj[item];
                }
            }
            if (otherFilterObj.coordinates && otherFilterObj.coordinates.length) {
                requestBody.coordinates = otherFilterObj.coordinates;
            }
        }

        toggleDisablePage(true);
        const professionalResult = await getNearbyProfessionals(requestBody);
        toggleDisablePage(false);
        toggleLoadingProfessionals(false);
        if (professionalResult.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.errorCode]);
            return;
        }
        if (professionalResult.data.data && professionalResult.data.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.data.errorCode]);
            return;
        }
        const localProfessionals = [];
        let localProfObj = {};
        const localVendorIds = [];
        if (professionalResult.data.data && professionalResult.data.data.length) {
            professionalResult.data.data.forEach(each => {
                localProfObj = {};
                localProfObj.isFavourite = authData && each.vendor && each.vendor.favouritedBy && each.vendor.favouritedBy.includes(authData._id) ? true : false;
                localProfObj.name = each.vendor.personalInfo.name;
                localProfObj.profilePicture = each.vendor.personalInfo.profilePicture;
                localProfObj.vendorId = each.vendor._id;
                localProfObj.designation = "";
                localProfObj.location = each.addressTitle;
                localProfObj.experience = "";
                localProfObj.isPromoAllowed = each.vendor.promoCodeInfo && each.vendor.promoCodeInfo.allowDiscount ? each.vendor.promoCodeInfo.allowDiscount : false;
                localProfObj.rating = each.vendor.ratingDetails && each.vendor.ratingDetails.average ? each.vendor.ratingDetails.average.toFixed(1) : 0; // need to change from response
                if (each.vendor.personalInfo.experience.year) {
                    const year = each.vendor.personalInfo.experience.year;
                    if (year) {
                        localProfObj.experience += `${year} year${year > 1 ? 's' : ''}`;
                    }
                }
                if (each.vendorCategories && each.vendorCategories.length) {
                    each.vendorCategories.forEach((item, index) => {
                        localProfObj.designation += item.title;
                        if (index !== each.vendorCategories.length - 1) {
                            localProfObj.designation += ", ";
                        }
                    });
                }

                if (otherFilterObj && otherFilterObj.coordinates && otherFilterObj.coordinates.length && each.dist) {
                    localProfObj.myDistance = each.dist.calculated ? (each.dist.calculated/1000).toFixed(1) : "0.0";
                }

                if (!localVendorIds.includes(localProfObj.vendorId)) {
                    localVendorIds.push(localProfObj.vendorId);
                    localProfessionals.push({ ...localProfObj });
                }

                
            });
        }

        updateProfessional([...localProfessionals]);
        // if (localProfessionals.length && rating === null) {
        //     getAllProfessionalCategories();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory, authData]);

    const getUserLocation = useCallback(async (localCatId) => {
        const localCat = localCatId ? localCatId : null;
        let localFilterData = {};
        let localSearchKey = null;
        if (sessionStorage.getItem("vendorFilter") &&
            JSON.parse(sessionStorage.getItem("vendorFilter")) &&
            Object.keys(JSON.parse(sessionStorage.getItem("vendorFilter"))).length) {
                localFilterData = JSON.parse(sessionStorage.getItem("vendorFilter"));
                localSearchKey = localFilterData.searchKey;
                updateFilterObj({...localFilterData});
                focusSearch(true);
                if (localSearchKey) {
                    updateSearchedText(localSearchKey);
                }
        }
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                    updateUserLocation([position.coords.longitude, position.coords.latitude]);
                    if (Object.keys(localFilterData).length) {
                        getAllNearbyProfessionals(null, null, null, localSearchKey, localFilterData);
                    } else {
                        getAllNearbyProfessionals(-1, localCat, [position.coords.longitude, position.coords.latitude]);
                    }
                    
                }
            }, (error) => {
                if (Object.keys(localFilterData).length) {
                    getAllNearbyProfessionals(null, null, null, localSearchKey, localFilterData);
                } else {
                    getAllNearbyProfessionals(-1, localCat, []);
                }
                
                if (error.code === 1) {
                }
            });
        } else {
            if (Object.keys(localFilterData).length) {
                getAllNearbyProfessionals(null, null, null, localSearchKey, localFilterData);
            } else {
                getAllNearbyProfessionals(-1, localCat, []);
            }
        }
    }, [getAllNearbyProfessionals]);

    const getAllProfessionalCategories = useCallback(async () => {
        toggleDisablePage(true);
        const categoryResult = await getProfessionalCategories();
        toggleDisablePage(false);
        if (categoryResult.data.errorCode) {
            toast.error(API_ERROR[categoryResult.data.errorCode]);
            return;
        }
        if (categoryResult.data.data && categoryResult.data.data.errorCode) {
            toast.error(API_ERROR[categoryResult.data.data.errorCode]);
            return;
        }
        const localCategories = [];
        let localCatObj = {};

        const urlParams = new URLSearchParams(window.location.search);
        const catParam = urlParams.get('categoryId');
        let localUrl = '/book-now?';
        let localCatId = null;
        let localIndex = 0;

        if (categoryResult.data.data && categoryResult.data.data.length) {
            categoryResult.data.data.forEach((each, ind) => {
                localCatObj = {};
                localCatObj.name = each.title;
                localCatObj.imageUrl = each.icon;
                localCatObj._id = each._id;
                localCatObj.selected = false;
                localCatObj.isPlural = each.isPlural;
                if (catParam && catParam === each._id) {
                    localIndex = ind;
                }
                localCategories.push({ ...localCatObj });
            });
            localCategories[localIndex].selected = true;
            updateSelectedCategory(localCategories[localIndex]._id);
            updateCategory([...localCategories]);
        }

        if (catParam) {
            localCatId = catParam;
        } else if (localCategories.length) {
            localCatId = localCategories[0]._id;
        }

        localUrl += `categoryId=${localCatId}`;

        props.history.push(localUrl);

        setTimeout(() => {
            displayNavs(true);
        }, 1000);
        getUserLocation(localCatId);
    }, [getUserLocation, displayNavs, props.history]);

    const getDisplayFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getAllEventList = useCallback(async () => {
        toggleDisablePage(true);
        const localEventList = await getEventList(10);
        toggleDisablePage(false);
        if (localEventList.data.errorCode) {
            toast.error(API_ERROR[localEventList.data.errorCode]);
            return;
        }
        if (localEventList.data.data && localEventList.data.data.errorCode) {
            toast.error(API_ERROR[localEventList.data.data.errorCode]);
            return;
        }

        let localEventObj = {};
        const localEvents = [];

        if (localEventList.data.data && localEventList.data.data.data && localEventList.data.data.data.length) {
            localEventList.data.data.data.forEach((each, ind) => {
                if (each.images.length) {
                    if (!checkCurrentDay(each.date) || (checkCurrentDay(each.date) && checkEventListDisplayTime({ slots: each.slots }))) {
                        localEventObj = {};
                        localEventObj.title = each.title;
                        localEventObj.vendorName = each.vendorRef && each.vendorRef.personalInfo ? each.vendorRef.personalInfo.name : "";
                        localEventObj.image = each.images[0].url;
                        localEventObj._id = each._id;
                        localEventObj.date = getFullDateFormat(new Date(each.date));
                        localEventObj.displayDate = getDisplayFullDateFormat(new Date(each.date));
                        localEventObj.startTime = getStartTime(each.slots);
                        localEventObj.endTime = getEndTime(each.slots);
                        localEvents.push({ ...localEventObj });
                    }
                }
            });
            updateEvents([...localEvents]);
        }

        setTimeout(() => {
            displayNavs(true);
        }, 1000);
    }, [displayNavs, checkEventListDisplayTime]);

    const getAllWorkshopsList = useCallback(async () => {
        toggleDisablePage(true);
        const localEventList = await getWorkshopList(10);
        toggleDisablePage(false);
        if (localEventList.data.errorCode) {
            toast.error(API_ERROR[localEventList.data.errorCode]);
            return;
        }
        if (localEventList.data.data && localEventList.data.data.errorCode) {
            toast.error(API_ERROR[localEventList.data.data.errorCode]);
            return;
        }

        let localEventObj = {};
        const localEvents = [];

        if (localEventList.data.data && localEventList.data.data.data && localEventList.data.data.data.length) {
            localEventList.data.data.data.forEach((each, ind) => {
                if (each.images.length) {
                    // if (!checkCurrentDay(each.date) || (checkCurrentDay(each.date) && checkEventListDisplayTime({ slots: each.slots }))) {
                    localEventObj = {};
                    localEventObj.title = each.title;
                    // localEventObj.vendorName = each.vendorRef && each.vendorRef.personalInfo ? each.vendorRef.personalInfo.name : "";
                    localEventObj.image = each.images[0].url;
                    localEventObj._id = each._id;
                    localEventObj.startDate = getFullDateFormat(new Date(each.dates[0]));
                    localEventObj.displayStartDate = getDisplayFullDateFormat(new Date(each.dates[0]));
                    localEventObj.endDate = getFullDateFormat(new Date(each.dates[each.dates.length - 1]));
                    localEventObj.displayEndDate = getDisplayFullDateFormat(new Date(each.dates[each.dates.length - 1]));
                    localEvents.push({ ...localEventObj });
                    // }
                }
            });
            updateWorkshops([...localEvents]);
        }

        setTimeout(() => {
            displayNavs(true);
        }, 1000);
    }, [displayNavs]);

    useEffect(() => {
        if (!pageInitialized) {
            getAllProfessionalCategories();
            updatePageInitialization(true);
            getAllEventList();
            getAllWorkshopsList();
        }
    }, [getAllProfessionalCategories, pageInitialized, getAllEventList, getAllWorkshopsList]);

    const changeSort = (sortConfig) => {
        toggleSort(sortConfig);
        getAllNearbyProfessionals(sortConfig === 1 ? -1 : sortConfig === 2 ? 1 : null, selectedCategory, userLocation);
    };

    const changeTags = () => {
        if (bookNowMetaData[window.location.search.split("categoryId=")[1]]) {
            document.title = bookNowMetaData[window.location.search.split("categoryId=")[1]].title;
            const metaTags = document.getElementsByTagName("meta");
            if (metaTags[1].name ===  "title") {
                metaTags[1].content = bookNowMetaData[window.location.search.split("categoryId=")[1]].title;
            }
            if (metaTags[2].name ===  "description") {
                metaTags[2].content = bookNowMetaData[window.location.search.split("categoryId=")[1]].description;
            }
            if (metaTags[3].name ===  "keywords") {
                metaTags[3].content = bookNowMetaData[window.location.search.split("categoryId=")[1]].description;
            }
            if (metaTags[4]) {
                metaTags[4].content = bookNowMetaData[window.location.search.split("categoryId=")[1]].title;
            }
            if (metaTags[5]) {
                metaTags[5].content = bookNowMetaData[window.location.search.split("categoryId=")[1]].description;
            }
            if (metaTags[6]) {
                metaTags[6].content = orgMetaData.image;
            }
            if (metaTags[7]) {
                metaTags[7].content = window.location.href;
            }
        }
    };

    useEffect(() => {
        changeTags();
        
    }, []);

    const selectCategory = (categoryData) => {
        const localCategories = [...categories];
        if (categoryData.selected) {

            return;
        } else {
            updateSelectedCategory(categoryData._id);
            getAllNearbyProfessionals(sortDown === 1 ? -1 : sortDown === 2 ? 1 : null, categoryData._id, userLocation);
            props.history.push(`/book-now?categoryId=${categoryData._id}`);
            changeTags();
        }
        localCategories.forEach(each => {
            if (each._id === categoryData._id) {
                each.selected = !each.selected;
            } else {
                each.selected = false;
            }
        });
        updateCategory([...localCategories]);
    };

    const showLocationError = () => {
        if (!userLocation.length) {
            toast.error("Not able access your current location. If you have denied the permission then please change the location access from your browser's settings and refresh the page.");
        }
    };

    useEffect(() => {
        window.onclick = (el) => {
            if (el.target &&
                el.target.className &&
                el.target.className.indexOf &&
                el.target.className.indexOf('dropdown-toggle') <= -1) {
                openSortPopup(false);
            }
        }
    });

    const clickFav = async (profData, ind) => {
        if (!isAuthorized) {
            toast.info("Please login to make the professional favourite");
            setTimeout(() => {
                const loginButton = document.getElementById("login-button");
                if (loginButton) {
                    loginButton.click();
                }
            }, 1000);
            return;
        }

        const localProfs = [...professionals];
        toggleDisablePage(true);
        let favProbResult = await markFavouriteProfessional(profData.vendorId, { favourite: !localProfs[ind].isFavourite });
        toggleDisablePage(false);
        if (favProbResult.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.errorCode]);
            return;
        }
        if (favProbResult.data.data && favProbResult.data.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.data.errorCode]);
            return;
        }
        localProfs[ind].isFavourite = !localProfs[ind].isFavourite;
        updateProfessional([...localProfs]);
    };

    const openVendorProfile = (vendorProfile) => {
        let sessionData = {};
        if (isSearchFocused) {
            sessionData.searchKey = searchedText;
        }
        if (Object.keys(allFilterObj).length) {
            const localFilter = JSON.parse(JSON.stringify(allFilterObj));
            delete localFilter.searchKey;
            sessionData = {
                ...sessionData,
                ...localFilter
            };
        }
        sessionStorage.setItem("vendorFilter", JSON.stringify(sessionData));
        props.history.push(`/professional-details/${vendorProfile.vendorId}/${vendorProfile.name.split(" ").join("-")}`, { vendorId: vendorProfile.vendorId });
    };

    const search = (e) => {
        focusSearch(true);
        updateSearchedText(e.target.value);
        if (e.target.value && e.target.value.trim().length) {
            getAllNearbyProfessionals(null, null, null, e.target.value, Object.keys(allFilterObj).length ? allFilterObj : null);
        } else if (!e.target.value) {
            getAllNearbyProfessionals(sortDown === 1 ? -1 : sortDown === 2 ? 1 : null, selectedCategory, userLocation, "EMPTY", Object.keys(allFilterObj).length ? allFilterObj : null);
        }
    };

    const openNav = () => {
        document.getElementById("mySidenav").style.width = window.innerWidth <= 500 ? `${window.innerWidth}px` : "400px";
        toggleFilterOpen(true);
        document.getElementById("overlay").style.display = "block";
        if (document.getElementsByClassName("filter-body")[0]) {
            document.getElementsByClassName("filter-body")[0].scrollTo(0, 0);
        }
    };

    const applyFilter = (filterObj) => {
        getAllNearbyProfessionals(null, selectedCategory, filterObj?.coordinates?.length ? filterObj.coordinates : userLocation, searchedText ? searchedText : "EMPTY", filterObj);
        toggleFocus(true);
        updateFilterObj({ ...filterObj });
    };

    const closeNav = () => {
        toggleFilterOpen(false);
        document.getElementById("overlay").style.display = "none";
    };

    const retrySearch = () => {
        if (isFilterFocused) {
            openNav();
        } else {
            search({ target: { value: "" } });
            if (searchRef && searchRef.current) {
                searchRef.current.focus();
            }
        }
    };

    return (
        <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <Sidebar
                    categories={categories}
                    applyFilter={applyFilter}
                    userLocation={userLocation}
                    isReset={isReset}
                    fromLower={fromLower}
                    focusSearch={focusSearch}
                    closeNav={closeNav}
                    allFilterObj={allFilterObj}
                     />
                <div id="overlay"></div>
                <div className={`row m-0 ${isFilterOpened ? "pointer-none" : ""}`}>
                    <div className="input-group mt-3 search-input-wrapper">
                        {isSearchFocused ?
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-secondary" type="button" onClick={() => {
                                    getAllNearbyProfessionals(sortDown === 1 ? -1 : sortDown === 2 ? 1 : null, selectedCategory, userLocation);
                                    focusSearch(false);
                                    updateSearchedText("");
                                    updateFilterObj({});
                                    toggleReset(true);
                                    sessionStorage.removeItem("vendorFilter");
                                }}><i className="fa fa-arrow-left"></i></button>
                            </div> : null}
                        <input type="text" ref={searchRef} className={`form-control ${!isSearchFocused ? 'bor-radius' : ''}`} placeholder="Search professionals by name" aria-label="Search professionals by name" aria-describedby="basic-addon2" value={searchedText} onChange={search} onFocus={!searchedText.length ? (e) => {search(e); toggleReset(false); toggleFocus(false);} : () => { }} />

                        <div className="input-group-append pos-relative">
                            
                                <button className="btn btn-outline-secondary" type="button"><i className='fa fa-search'></i></button>
                                {!searchedText.length ? null : <i className='fa fa-times' style={{
                                    position: "absolute",
                                    left: "-20px",
                                    top: "20px",
                                    cursor: "pointer",
                                    zIndex: 3
                                }} onClick={searchedText.length ? () => search({ target: { value: "" } }) : () => { }}></i>}
                        </div>
                    </div>
                    {isSearchFocused ?
                    <span className='pos-relative filter-icon-wrapper'>
                    <i className={`fa fa-filter pull-right ${Object.keys(allFilterObj).length && !(Object.keys(allFilterObj).length === 1 && Object.keys(allFilterObj)[0] === "searchKey") ? 'filter-applied' : ''}`} style={{fontSize: "18px", cursor: "pointer", margin: "18px 18px 0 0"}} onClick={() => {
                        openNav();
                        toggleLower(false);
                    }}></i>
                    {Object.keys(allFilterObj).length && !(Object.keys(allFilterObj).length === 1 && Object.keys(allFilterObj)[0] === "searchKey") ?
                    <div className='dot' /> : null}
                    </span>
                    : null}
                </div>
                {!isSearchFocused ? <>
                    {localGlobalConfigData.showWorkshop && workshops.length ?
                        <>
                            <div className={`row ml-0 mr-0 categories-heading pb-0`} onMouseEnter={() => { displayNavs(true) }}>
                                <div className={`col-7 pl-0 mb-0 pt-3 home-page-header display-inline-block`}>Workshops <span className="see-all-events" onClick={() => props.history.push('/workshop-list')}>View All</span></div>
                                {workshops.length ?
                                    <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                        <button className="prev-button-wrapper" onClick={() => prevClick("workshops-wrapper")} id="slideprevWorkshop" disabled={!showPrevWorkshopButtons}>
                                            <span className='sr-only'>Workshop Left Arrow</span>
                                            <i className="fas fa-arrow-left"></i>
                                        </button>
                                        <button className="next-button-wrapper" onClick={() => nextClick("workshops-wrapper")} id="slideWorkshop" disabled={!showNextWorkshopButtons}>
                                            <span className='sr-only'>Workshop Right Arrow</span>
                                            <i className="fas fa-arrow-right"></i>
                                        </button>
                                    </div> : null}
                            </div>
                            <div className="whole-categories-wrapper">
                                <div className={`workshops-wrapper pl-5`} style={{ margin: 0 }}>
                                    {workshops.length ? workshops.map((category, index) => {
                                        return <div key={index} className="full-event-list-wrapper" onClick={() => props.history.push(`/workshop-details/${category._id}`)} style={{ cursor: 'pointer' }}>
                                            <div className={`event-custom-card event-background-image-category ${index === workshops.length - 1 ? 'mr-0' : ''} ${category.selected ? 'selected-card' : ''}`} key={index} onMouseEnter={() => { displayNavs(true) }} style={{ backgroundImage: "url(" + category.image + ")" }}>

                                            </div>
                                            <div className={`col-lg-12 category-name workshop-name ${category.selected ? 'selected-card-name' : ''}`}>
                                                {category.title}
                                            </div>
                                            <div className="event-list-date-time-wrapper mt-0">
                                                <div className="event-list-date">
                                                    <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "12px", marginRight: "2px", marginBottom: "2px" }} /> {category.displayStartDate} {category.startDate !== category.endDate ? `- ${category.displayEndDate}` : ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }) : null}

                                </div>
                            </div>
                        </> : null}
                    {events.length ?
                        <>
                            <div className={`row ml-0 mr-0 categories-heading pb-0`} onMouseEnter={() => { displayNavs(true) }}>
                                <div className={`col-7 pl-0 mb-0 pt-3 home-page-header display-inline-block`}>Events <span className="see-all-events" onClick={() => props.history.push('/event-list')}>View All</span></div>
                                {events.length ?
                                    <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                        <button className="prev-button-wrapper" onClick={() => prevClick("events-wrapper")} id="slideprevEvent" disabled={!showPrevEventButtons}>
                                            <span className='sr-only'>Event Left Arrow</span>
                                            <i className="fas fa-arrow-left"></i>
                                        </button>
                                        <button className="next-button-wrapper" onClick={() => nextClick("events-wrapper")} id="slideEvent" disabled={!showNextEventButtons}>
                                            <span className='sr-only'>Event Right Arrow</span>
                                            <i className="fas fa-arrow-right"></i>
                                        </button>
                                    </div> : null}
                            </div>
                            <div className="whole-categories-wrapper">
                                <div className={`events-wrapper pl-5`} style={{ margin: 0 }}>
                                    {events.length ? events.map((category, index) => {
                                        return <div key={index} className="full-event-list-wrapper">
                                            <div className={`event-custom-card event-background-image-category ${index === events.length - 1 ? 'mr-0' : ''} ${category.selected ? 'selected-card' : ''}`} key={index} onMouseEnter={() => { displayNavs(true) }} onClick={() => props.history.push(`/event-details/${category._id}/${category.vendorName.split(" ").join("-")}/${category.title.split(" ").join("-")}`)} style={{ backgroundImage: "url(" + category.image + ")" }}>
                                                {/* <div className="col-lg-12 p-0"> */}
                                                {/* <div style={{ backgroundImage: "url(" + category.image + ")" }} className="event-background-image-category"></div> */}
                                                {/* </div> */}
                                                <div className={`col-lg-12 category-name event-name ${category.selected ? 'selected-card-name' : ''}`}>
                                                    {category.title}
                                                </div>
                                            </div>
                                            <div className="event-list-date-time-wrapper">
                                                <div className="event-list-date">
                                                    <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "12px", marginRight: "2px", marginBottom: "2px" }} /> {category.displayDate}</div>
                                                </div>
                                                <div className={`event-list-time ml-2 pull-right ${index !== events.length - 1 ? 'mr-4' : ''}`}>
                                                    <img src={require('../../../images/clock.svg')} style={{ width: "12px", marginRight: "2px", marginBottom: "2px" }} alt="clock" /> {category.startTime}
                                                </div>
                                            </div>
                                        </div>
                                    }) : null}

                                </div>
                            </div>
                        </> : null}
                </> : null}
                {/* <div className={`row m-0 ${isFilterOpened ? "pointer-none" : ""}`}>
                    <div className="input-group mt-3 search-input-wrapper">
                        {isSearchFocused ?
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-secondary" type="button" onClick={() => {
                                    getAllNearbyProfessionals(sortDown === 1 ? -1 : sortDown === 2 ? 1 : null, selectedCategory, userLocation);
                                    focusSearch(false);
                                    updateSearchedText("");
                                    updateFilterObj({});
                                    toggleReset(true);
                                    sessionStorage.removeItem("vendorFilter");
                                }}><i className="fa fa-arrow-left"></i></button>
                            </div> : null}
                        <input type="text" ref={searchRef} className={`form-control ${!isSearchFocused ? 'bor-radius' : ''}`} placeholder="Search professionals by name" aria-label="Search professionals by name" aria-describedby="basic-addon2" value={searchedText} onChange={search} onFocus={!searchedText.length ? (e) => {search(e); toggleReset(false); toggleFocus(false);} : () => { }} />

                        <div className="input-group-append pos-relative">
                            
                                <button className="btn btn-outline-secondary" type="button"><i className='fa fa-search'></i></button>
                                {!searchedText.length ? null : <i className='fa fa-times' style={{
                                    position: "absolute",
                                    left: "-20px",
                                    top: "20px",
                                    cursor: "pointer",
                                    zIndex: 3
                                }} onClick={searchedText.length ? () => search({ target: { value: "" } }) : () => { }}></i>}
                        </div>
                    </div>
                    {isSearchFocused ?
                    <span className='pos-relative filter-icon-wrapper'>
                    <i className={`fa fa-filter pull-right ${Object.keys(allFilterObj).length && !(Object.keys(allFilterObj).length === 1 && Object.keys(allFilterObj)[0] === "searchKey") ? 'filter-applied' : ''}`} style={{fontSize: "18px", cursor: "pointer", margin: "18px 18px 0 0"}} onClick={() => {
                        openNav();
                        toggleLower(false);
                    }}></i>
                    {Object.keys(allFilterObj).length && !(Object.keys(allFilterObj).length === 1 && Object.keys(allFilterObj)[0] === "searchKey") ?
                    <div className='dot' /> : null}
                    </span>
                    : null}
                </div> */}
                {!isSearchFocused ? <>
                    <div className={`row ml-0 mr-0 categories-heading pb-0`} onMouseEnter={() => { displayNavs(true) }}>
                        <div className={`col-7 pl-0 mb-0 pt-3 home-page-header display-inline-block`}>Categories</div>
                        {categories.length ?
                            <div className="col-5 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                <button className="prev-button-wrapper" onClick={() => prevClick("categories-wrapper")} id="slideprevCategory" disabled={!showPrevButtons}>
                                    <span className='sr-only'>Category Left Arrow</span>
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                                <button className="next-button-wrapper" onClick={() => nextClick("categories-wrapper")} id="slideCategory" disabled={!showNextButtons}>
                                    <span className='sr-only'>Category Right Arrow</span>
                                    <i className="fas fa-arrow-right"></i>
                                </button>
                            </div> : null}
                    </div>
                    <div className="whole-categories-wrapper">
                        <div className={`categories-wrapper pl-5`} style={{ margin: 0 }}>
                            {categories.length ? categories.map((category, index) => {
                                return <div className={`custom-card ${index === categories.length - 1 ? 'mr-0' : ''} ${category.selected ? 'selected-card' : ''}`} key={index} onMouseEnter={() => { displayNavs(true) }} onClick={() => selectCategory(category)}>
                                    <div className="col-lg-12 p-0">
                                        <div style={{ backgroundImage: "url(" + category.imageUrl + ")" }} className="background-image-category"></div>
                                    </div>
                                    <div className={`col-lg-12 pl-0 pr-0 category-name ${category.selected ? 'selected-card-name' : ''}`}>
                                        {category.name}{category.isPlural ? 's' : ''}
                                    </div>
                                </div>
                            }) : null}

                        </div>
                    </div></> : null}

                <div className="col-12 categories-heading mt-3" onMouseEnter={() => { displayNavs(true) }}>
                    <div className={`mb-0 home-page-header`}>{!isSearchFocused ? "Professionals Near You" : ""}
                        {professionals.length ?
                            <span className="professional-count">{!isSearchFocused ? "(" : ""}{professionals.length} Professional{professionals.length > 1 ? "s" : ""} Found{!isSearchFocused ? ")" : ""}</span> : null}</div>

                    {(!isSearchFocused) ?
                        <ul className="navbar-nav custom-left-nav" style={{width: "75px", flexDirection: "row"}}>
                            <li className={`nav-item dropdown ${popUpOpened ? 'show' : ''}`} style={{flex: 1, paddingTop: "15px", paddingRight: "5px"}}>
                                <img src={require('../../../images/sort.png')} className="sort-down dropdown-toggle" alt="sort down" onClick={() => openSortPopup(!popUpOpened)} />
                                <div className={`dropdown-menu ${popUpOpened ? 'show dropdown-menu-opened' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                    <span className={`dropdown-item ${sortDown === 1 ? 'active' : ''}`} onClick={() => changeSort(1)}>Sort By Rating (Desc)</span>
                                    <span className={`dropdown-item ${sortDown === 2 ? 'active' : ''}`} onClick={() => changeSort(2)}>Sort By Rating (Asc)</span>
                                    <span className={`dropdown-item ${sortDown === 3 ? 'active' : ''}`} onClick={userLocation.length ? () => changeSort(3) : () => showLocationError()}>Sort By Location</span>
                                </div>
                            </li>
                            <li className={`nav-item`} style={{flex: 1, paddingTop: "17px"}}>
                                <i className="fa fa-filter" style={{fontSize: "18px", cursor: "pointer"}} 
                                onClick={() => {
                                    search({ target: { value: "" } });
                                    openNav();
                                    toggleLower(true);
                                }}></i>
                            </li>
                        </ul> : null}

                </div>
                <div className={`col-12 ${isFilterOpened ? "pointer-none" : ""}`} onMouseEnter={() => { displayNavs(true) }}>
                    <div className="row" style={{ margin: 0 }}>
                        {professionals.length ?
                            professionals.map((professional, index) => {
                                return <div className={`col-md-6 col-xs-12 professional-wrapper
                                ${index % 2 === 0 ? 'dashboard-more-768-pr-15' : ''}
                                ${index !== professionals.length - 1 && index !== professionals.length - 2 ? 'dashboard-more-768-pb-15' : ''}
                                ${index !== professionals.length - 1 ? 'dashboard-less-768-pb-15' : ''}
                                `} key={index} onClick={() => openVendorProfile(professional)}>
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="booking-profile-image">
                                            {professional.profilePicture ?
                                                <div style={{ backgroundImage: "url(" + professional.profilePicture + ")" }} className={`background-image-professional ${professional.isPromoAllowed ? 'promo-border-professional' : ''}`} /> :
                                                <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className={`background-image-professional ${professional.isPromoAllowed ? 'promo-border-professional' : ''}`} />}
                                            
                                            {professional.rating ? <div className='text-center mt-1 profile-rating'><i className={`fa-star fas`}></i> {professional.rating} </div> : null}
                                        </div>
                                        <div className="booking-details-wrapper">
                                            <div className="professional-name">{professional.name}
                                                {professional.isFavourite ?
                                                    <img src={require('../../../images/loved.png')} className="pull-right" alt="sort down" onClick={e => { e.stopPropagation(); clickFav(professional, index) }} />
                                                    : <img src={require('../../../images/love.png')} className="pull-right" alt="sort down" onClick={e => { e.stopPropagation(); clickFav(professional, index) }} />}
                                            </div>
                                            <div className="professional-designation">{professional.designation}</div>
                                            <div className="professional-location">{professional.location}</div>
                                            <div className="professional-exp">Experience - {professional.experience}
                                            {professional.myDistance ? <span className="profile-rating">
                                                <img src={require('../../../images/location.svg')} style={{
                                                    verticalAlign: "middle"
                                                }} alt="location" /> {professional.myDistance} Km
                                            </span> : null}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingProfessionals ? <div className='no-data-found'>
                                    <img src={require("../../../images/no-data.png")} alt="no data" />
                                    <br />
                                    <div className='no-data-header'>No {allFilterObj && allFilterObj.coordinates && allFilterObj.coordinates.length ? "Nearby" : ""} Professional Found</div>
                                    <div className='no-data-desc'>Try different search name or try <br/> adjusting your filters</div>
                                    <button className='btn btn-primary no-data-button' onClick={retrySearch}>Retry</button>
                                </div> : 'loading...'}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Dashboard)
);