import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";
import { toast } from 'react-toastify';
import { markFavouriteProfessional } from "../../crud/home.crud";
import { getLanguages, addAnswer, getForumDetails, getFavForumList, getFavProblemList, getFavProfessionalList, markFavouriteQuestion, markFavouriteProblem } from "../../crud/qa-forum.crud";
import {
    API_ERROR
} from "../../config/error-message";
import UnfavModal from "./unfav-confirmation-modal";

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultImage = require('../../../images/imgAvtr.png');
const adminProfileImage = require('../../../images/logo-blue.webp');

const dummySelectedLanguage = {
    code: "",
    name: ""
};

const initialTabs = [{
    name: "Professionals",
    selected: true
}, {
    name: "Problem Area",
    selected: false
}, {
    name: "Forum",
    selected: false
}];

const Favourites = (props) => {
    const [problemAreas, updateProblemAreaList] = useState([]);
    const [menuOpened, toggleMenu] = useState(false);
    const [languages, updateLanguages] = useState([]);
    const [selectedLanguage, changeSelectedLanguage] = useState({ ...dummySelectedLanguage });
    const [pageInitialized, updateInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingProblems, toggleLoadingProblems] = useState(true);
    const [tabs, updateTabs] = useState([...initialTabs]);

    const [currentUnFavType, updateCurrentUnFavType] = useState("professional");
    const [currentUnFavObj, updateCurrentUnFavObj] = useState({});
    const [displayUnFavModal, toggleUnFavModal] = useState(false);

    // for forum
    const [forumList, updateForumList] = useState([]);
    const [myAnswer, updateMyAnswer] = useState("");
    const [selectedQuestionId, updateSelectedQuestionId] = useState("");
    const [loadingQuestions, toggleLoadingQuestions] = useState(true);
    const { authData } = useSelector(
        ({ auth }) => ({
            authData: auth.user
        }),
        shallowEqual
    );

    // for proffesional
    const [professionals, updateProfessional] = useState([]);
    const [loadingProfessionals, toggleLoadingProfessionals] = useState(true);

    const openMenu = (bool) => {
        toggleMenu(bool);
    };

    window.onclick = (el) => {
        if (el.target.className.indexOf('dropdown-toggle') <= -1) {
            openMenu(false);
        }
    }

    const selectAnswer = (item) => {
        props.history.push(`/problem-details/${item._id}/${selectedLanguage.code}`);
    };

    const getAllProblemAreaList = useCallback(async (localSelectedLang) => {
        toggleDisablePage(true);
        toggleLoadingProblems(true);
        const problemAreaResults = await getFavProblemList();
        toggleDisablePage(false);
        toggleLoadingProblems(false);
        if (problemAreaResults.data.errorCode) {
            toast.error(API_ERROR[problemAreaResults.data.errorCode]);
            return;
        }
        if (problemAreaResults.data.data && problemAreaResults.data.data.errorCode) {
            toast.error(API_ERROR[problemAreaResults.data.data.errorCode]);
            return;
        }
        const problemAreaArr = [];
        if (problemAreaResults.data.data && problemAreaResults.data.data.length) {
            let problemAreaObj = {};
            problemAreaResults.data.data.forEach(each => {
                problemAreaObj = {};
                each.hasValue = false;
                problemAreaObj.isFavourite = true;
                if (each.languageSet && each.languageSet.length) {
                    each.languageSet.forEach(each1 => {
                        if (localSelectedLang) {
                            if (each1.languageRef.code === localSelectedLang.code) {
                                problemAreaObj._id = each._id;
                                problemAreaObj.question = each1.title;
                                problemAreaObj.answer = each1.description;
                                problemAreaObj.highlightedAnswer = each1.highlightAnswer;
                                problemAreaObj.selected = false;
                                each.hasValue = true;
                            }
                        } else {
                            if (each1.languageRef.code === selectedLanguage.code) {
                                problemAreaObj._id = each._id;
                                problemAreaObj.question = each1.title;
                                problemAreaObj.answer = each1.description;
                                problemAreaObj.highlightedAnswer = each1.highlightAnswer;
                                problemAreaObj.selected = false;
                                each.hasValue = true;
                            }
                        }

                    });
                    // if selected language is not present then display eng
                    if (!each.hasValue) {
                        each.languageSet.forEach(each1 => {
                            if (each1.languageRef.name.trim().toLowerCase() === "english") {
                                problemAreaObj._id = each._id;
                                problemAreaObj.question = each1.title;
                                problemAreaObj.answer = each1.description;
                                problemAreaObj.highlightedAnswer = each1.highlightAnswer;
                                problemAreaObj.selected = false;
                            }
                        });
                    }
                }
                if (each.problemCategoryRef &&
                    each.problemCategoryRef.languageSet &&
                    each.problemCategoryRef.languageSet.length) {
                    each.problemCategoryRef.languageSet.forEach(each1 => {
                        // hasValue is used to if selected language is present or not
                        //  if (each.hasValue) {
                        if (localSelectedLang) {
                            if (each1.languageRef.code === localSelectedLang.code) {
                                problemAreaObj.categoryName = each1.title;
                                each.hasCat = true; // variable to check category lang present
                            }
                        } else {
                            if (each1.languageRef.code === selectedLanguage.code) {
                                problemAreaObj.categoryName = each1.title;
                                each.hasCat = true;
                            }
                        }
                        //  }
                    });
                    // if selected language is not present then display eng
                    if (!each.hasCat) {
                        each.problemCategoryRef.languageSet.forEach(each1 => {
                            if (each1.languageRef.name.trim().toLowerCase() === "english") {
                                problemAreaObj.categoryName = each1.title;
                            }
                        });
                    }
                }

                problemAreaArr.push({ ...problemAreaObj });
            });
        }
        updateProblemAreaList([...problemAreaArr]);
    }, [selectedLanguage]);

    const getAllLanguages = useCallback(async (localLangCode) => {
        toggleDisablePage(true);
        const languageResult = await getLanguages();
        if (languageResult.data.errorCode) {
            toast.error(API_ERROR[languageResult.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        if (languageResult.data.data && languageResult.data.data.errorCode) {
            toast.error(API_ERROR[languageResult.data.data.errorCode]);
            toggleDisablePage(false);
            return;
        }

        const localLanguages = [];
        let localLangObj = {};
        let localSelectedLang = {};
        if (languageResult.data.data && languageResult.data.data.data && languageResult.data.data.data.length) {
            languageResult.data.data.data.forEach(each => {
                localLangObj = {};
                localLangObj.name = each.name;
                localLangObj._id = each._id;
                localLangObj.code = each.code;
                localLanguages.push({ ...localLangObj });

                if (!localLangCode) {
                    if (each.name.trim().toLowerCase() === "english") {
                        changeSelectedLanguage({ ...each });
                        localSelectedLang = { ...each };
                    }
                } else {
                    if (each.code === localLangCode) {
                        changeSelectedLanguage({ ...each });
                        localSelectedLang = { ...each };
                    }
                }
            });
            updateLanguages([...localLanguages]);
            if (localLanguages.length) {
                getAllProblemAreaList(localSelectedLang);
            }
        }
    }, [getAllProblemAreaList]);

    useEffect(() => {
        if (!pageInitialized) {
            updateInitialization(true);
            let selectedLangCode = undefined;
            const urlParams = new URLSearchParams(window.location.search);
            const langParam = urlParams.get('favLanguage');
            if (langParam) {
                selectedLangCode = langParam;
            }
            getAllLanguages(selectedLangCode);
        }
    }, [getAllLanguages, pageInitialized]);

    const selectLanguage = (language) => {
        changeSelectedLanguage({ ...language });
        let localUrl = '/my-favourite?';

        localUrl += `favLanguage=${language.code}`;

        props.history.push(localUrl);
        getAllProblemAreaList({ ...language });
    };

    const updateCurrentTab = (index) => {
        const localTabs = [...tabs];
        localTabs.forEach(each => {
            each.selected = false;
        });
        localTabs[index].selected = true;
        updateTabs([...localTabs]);
    };

    // for forum

    const getFullDate = (date) => {
        const d = new Date(date);
        return d.getDate() + ' ' + monthArr[d.getMonth()] + ', ' + d.getFullYear();
    };

    const getAllForumList = async () => {
        toggleDisablePage(true);
        const forumResults = await getFavForumList();
        toggleDisablePage(false);
        toggleLoadingQuestions(false);
        if (forumResults.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.errorCode]);
            return;
        }
        if (forumResults.data.data && forumResults.data.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.data.errorCode]);
            return;
        }
        
        const forumArr = [];
        if (forumResults.data.data && forumResults.data.data.length) {
            let forumObj = {};
            forumResults.data.data.forEach(each => {
                forumObj = {};
                forumObj.isFavourite = true;
                forumObj._id = each._id;
                forumObj.question = each.question;
                forumObj.selected = false;
                forumObj.noOfComments = each.answers.length;
                forumObj.answers = [];
                forumArr.push({ ...forumObj });
            });
        }
        updateForumList([...forumArr]);
    };

    const selectForumAnswer = (id) => {
        const localProf = [...forumList];
        localProf.forEach(each => {
            if (each._id === id) {
                if (selectedQuestionId === each._id) {
                    each.selected = true;
                } else {
                    each.selected = !each.selected;
                }

            } else {
                each.selected = false;
            }

        });
        updateForumList([...localProf]);
    };

    const getQuestionDetails = async (id) => {
        toggleDisablePage(true);
        const localQuestionDetails = await getForumDetails(id);
        toggleDisablePage(false);
        if (localQuestionDetails.data.errorCode) {
            toast.error(API_ERROR[localQuestionDetails.data.errorCode]);
            return;
        }
        if (localQuestionDetails.data.data && localQuestionDetails.data.data.errorCode) {
            toast.error(API_ERROR[localQuestionDetails.data.data.errorCode]);
            return;
        }
        const localProf = [...forumList];
        localProf.forEach(each => {
            if (each._id === id) {
                let answerObj = {};
                each.askedByName = localQuestionDetails.data.data.askedBy.personalInfo.name || '';
                each.askedByProfilePhoto = localQuestionDetails.data.data.askedBy.personalInfo.profilePicture || '';
                if (localQuestionDetails.data.data && localQuestionDetails.data.data.answers && localQuestionDetails.data.data.answers.length) {
                    each.answers = [];
                    localQuestionDetails.data.data.answers.forEach(item => {
                        answerObj = {};
                        answerObj.answerText = item.answer;
                        answerObj._id = item._id;
                        answerObj.date = item.time;
                        answerObj.name = "";
                        answerObj.profilePicture = "";
                        if (item.answeredBy && item.answeredBy.personalInfo) {
                            answerObj.name = item.answeredBy.personalInfo.name;
                            answerObj.profilePicture = item.answeredBy.personalInfo.profilePicture;
                            answerObj.userType = item.answeredBy.userType;
                            answerObj.userId = item.answeredBy._id;
                        }
                        each.answers.push({ ...answerObj });
                        each.noOfComments = each.answers.length;
                    })
                }
            }
        });
        updateForumList([...localProf]);
        selectForumAnswer(id);
    };

    const chooseAnswer = (item) => {
        if (!item.selected) {
            getQuestionDetails(item._id);
            updateSelectedQuestionId(item._id);
            updateMyAnswer("");
        } else {
            updateSelectedQuestionId("");
            const localProf = [...forumList];
            localProf.forEach(each => {
                if (each._id === item._id) {
                    each.selected = false;
                }

            });
            updateForumList([...localProf]);
        }
    };

    // for professionals

    const getAllNearbyProfessionals = async () => {
        toggleDisablePage(true);
        const professionalResult = await getFavProfessionalList();
        toggleDisablePage(false);
        toggleLoadingProfessionals(false);
        if (professionalResult.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.errorCode]);
            return;
        }
        if (professionalResult.data.data && professionalResult.data.data.errorCode) {
            toast.error(API_ERROR[professionalResult.data.data.errorCode]);
            return;
        }
        const localProfessionals = [];
        let localProfObj = {};
        const localVendorIds = [];
        if (professionalResult.data.data && professionalResult.data.data.length) {
            professionalResult.data.data.forEach(each => {
                localProfObj = {};
                localProfObj.name = each.personalInfo.name;
                localProfObj.profilePicture = each.personalInfo.profilePicture;
                localProfObj.isFavourite = true;
                localProfObj.vendorId = each._id;
                localProfObj.designation = "";
                localProfObj.location = each.addressTitle;
                localProfObj.experience = "";
                localProfObj.rating = each.ratingDetails && each.ratingDetails.average ? each.ratingDetails.average.toFixed(1) : 0; // need to change from response
                if (each.personalInfo.experience.year) {
                    const year = each.personalInfo.experience.year;
                    if (year) {
                        localProfObj.experience += `${year} year${year > 1 ? 's' : ''}`;
                    }
                }
                if (each.personalInfo.categories && each.personalInfo.categories.length) {
                    each.personalInfo.categories.forEach((item, index) => {
                        localProfObj.designation += item.title;
                        if (index !== each.personalInfo.categories.length - 1) {
                            localProfObj.designation += ", ";
                        }
                    });
                }

                if (!localVendorIds.includes(localProfObj.vendorId)) {
                    localVendorIds.push(localProfObj.vendorId);
                    localProfessionals.push({ ...localProfObj });
                }
            });
        }
        updateProfessional([...localProfessionals]);
        // if (localProfessionals.length && rating === null) {
        //     getAllProfessionalCategories();
        // }
    };

    useEffect(() => {
        getAllForumList();
        getAllNearbyProfessionals();

        return () => {
            initialTabs.forEach((each, index) => {
                each.selected = index !== 0 ? false : true;
            });
            updateTabs([...initialTabs]);
        };
    }, []);

    const giveAnswer = async () => {
        if (myAnswer.trim().length) {
            toggleDisablePage(true);
            const answerResult = await addAnswer(selectedQuestionId, { answer: myAnswer.trim() });
            toggleDisablePage(false);
            if (answerResult.data.errorCode) {
                toast.error(API_ERROR[answerResult.data.errorCode]);
                return;
            }
            if (answerResult.data.data && answerResult.data.data.errorCode) {
                toast.error(API_ERROR[answerResult.data.data.errorCode]);
                return;
            }

            updateMyAnswer("");
            getQuestionDetails(selectedQuestionId);
        }
    };

    const openVendorProfile = (vendorProfile) => {
        props.history.push(`/professional-details/${vendorProfile.vendorId}/${vendorProfile.name.split(" ").join("-")}`, {vendorId: vendorProfile.vendorId});
    };

    const clickProfFav = async () => {
        toggleDisablePage(true);
        let favProbResult = await markFavouriteProfessional(currentUnFavObj.vendorId, {favourite: false});
        toggleDisablePage(false);
        if (favProbResult.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.errorCode]);
            return;
        }
        if (favProbResult.data.data && favProbResult.data.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.data.errorCode]);
            return;
        }

        getAllNearbyProfessionals();
        toggleUnFavModal(false);
    };

    const clickForumFav = async () => {
        toggleDisablePage(true);
        let favProbResult = await markFavouriteQuestion(currentUnFavObj._id, {favourite: false});
        toggleDisablePage(false);
        if (favProbResult.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.errorCode]);
            return;
        }
        if (favProbResult.data.data && favProbResult.data.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.data.errorCode]);
            return;
        }

        getAllForumList();
        toggleUnFavModal(false);
    };

    const clickProblemFav = async () => {
        toggleDisablePage(true);
        let favProbResult = await markFavouriteProblem(currentUnFavObj._id, {favourite: false});
        toggleDisablePage(false);
        if (favProbResult.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.errorCode]);
            return;
        }
        if (favProbResult.data.data && favProbResult.data.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.data.errorCode]);
            return;
        }

        updateInitialization(false);
        toggleUnFavModal(false);
    };

    return (
        <div className={`row problem-area booking-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <UnfavModal displayUnFavModal={displayUnFavModal}
                    toggleUnFavModal={toggleUnFavModal} unFav={currentUnFavType === "professional" ? clickProfFav : currentUnFavType === "problem" ? clickProblemFav : clickForumFav} />
            <div className="col-lg-12 pad-0-below-500">
                <div className="col-12 categories-heading p-0">
                    <div className="row" style={{ margin: 0 }}>
                        <div className={`col-sm-4 col-xs-12 categories-heading pb-0`}>
                            <h4 className={`mb-0 problem-category-heading`}>My Favourites</h4>
                        </div>
                        <div className={`col-sm-8 col-xs-12 text-right language-dropdown-wrapper pb-0 pr-0`}>
                            {tabs.map((tab, ind) => {
                                return <button key={tab.name} className={`btn btn-primary upcoming-past-button fav-tabs ${tab.selected ? 'active' : ''}`} onClick={() => updateCurrentTab(ind)}>{tab.name}</button>
                            })}
                        </div>
                    </div>
                </div>
                {tabs[1].selected ? <>
                    <div className="col-12 categories-heading p-0">
                        <div className="row" style={{ margin: 0 }}>
                            <div className={`col-12 text-right language-dropdown-wrapper pb-0`}>
                                <div className={`dropdown ${menuOpened ? 'show' : ''}`}>
                                    <button className="btn btn-sm btn-secondary dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => openMenu(!menuOpened)}>
                                        {selectedLanguage.name}
                                    </button>
                                    <ul className={`dropdown-menu ${menuOpened ? 'show' : ''}`} aria-labelledby="languageDropdown">
                                        {languages.length && languages.map((language, index) => {
                                            return <li key={language.code} onClick={() => selectLanguage(language)}><span className={`dropdown-item ${index !== languages.length - 1 ? 'item-border' : ''}`}>{language.name}</span></li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={`row professional-near-you-wrapper`} style={{ margin: 0 }}>
                            {problemAreas.length ?
                                problemAreas.map((professional, index) => {
                                    return <div className={`col-12 professional-wrapper
                                `} key={index}>
                                        <div className="row ml-0 mr-0" onClick={() => selectAnswer({ ...professional })}>
                                            <div className="col-12 p-0">
                                                <div className="professional-designation">{professional.categoryName}
                                                {professional.isFavourite ?
                                                    <img src={require('../../../images/loved.png')} alt="sort down" onClick={e => { e.stopPropagation(); toggleUnFavModal(true); updateCurrentUnFavObj(professional); updateCurrentUnFavType("problem") }} />
                                                    : <img src={require('../../../images/love.png')} alt="sort down" onClick={e => { e.stopPropagation(); toggleUnFavModal(true); updateCurrentUnFavObj(professional); updateCurrentUnFavType("problem") }} />}
                                                </div>
                                                <div className="professional-name">{professional.question}</div>
                                                {!professional.selected ?
                                                    <div className="professional-location">{professional.highlightedAnswer}</div> :
                                                    null}
                                            </div>
                                        </div>
                                    </div>
                                })
                                : <div className="text-center well col-12 color-black">
                                    {!loadingProblems ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Result Found</div>
                                        </div> : 'loading...'}
                                </div>}
                        </div>
                    </div>
                </> : null}
                {tabs[2].selected ? <>
                    <div className="col-12 forum-wrapper">
                        <div className={`row professional-near-you-wrapper`} style={{ margin: 0 }}>
                            {forumList.length ?
                                forumList.map((professional, index) => {
                                    return <div className={`col-12 professional-wrapper ${professional.selected ? 'professional-wrapper-selected' : ''}`} key={index}>
                                        <div className="row professional-wrapper-row p-0 no-border" style={{ margin: 0 }}>
                                            <div className="col-12 p-0">
                                                <div className={`professional-designation ${!professional.selected ? 'professional-designation-not-selected' : 'professional-designation-selected'}`} onClick={() => chooseAnswer({ ...professional })}>
                                                    {professional.selected ?
                                                        professional.askedByProfilePhoto ?
                                                            <div style={{ backgroundImage: "url(" + professional.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                                                            <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                                                    {!professional.selected ? <>
                                                        {professional.question} <span> ({professional.noOfComments} comment{professional.noOfComments > 1 ? "s" : ""}) </span> </>
                                                        : <div className="question-asked-details">
                                                            <div className="question-commentedby">{professional.askedByName}</div>
                                                            {professional.question} <span> ({professional.noOfComments} comment{professional.noOfComments > 1 ? "s" : ""}) </span>
                                                        </div>}
                                                        {professional.isFavourite ?
                                                    <img src={require('../../../images/loved.png')} alt="sort down" onClick={e => { e.stopPropagation(); toggleUnFavModal(true); updateCurrentUnFavObj(professional); updateCurrentUnFavType("forum") }} />
                                                    : <img src={require('../../../images/love.png')} alt="sort down" onClick={e => { e.stopPropagation(); toggleUnFavModal(true); updateCurrentUnFavObj(professional); updateCurrentUnFavType("forum") }} />}
                                                </div>
                                                {!professional.selected ?
                                                    null :
                                                    <div className="professional-location answer">
                                                        <div className="row answers-wrapper no-border p-0" style={{ margin: 0 }}>
                                                            <div className="col-12 p-0">
                                                                {professional.answers.length ?
                                                                    professional.answers.map((answer, index) => {
                                                                        return <div className="row no-border p-0" style={{ margin: 0 }} key={index}>
                                                                            {answer.userType !== 3 ?
                                                                                answer.profilePicture ?
                                                                                    <div style={{ backgroundImage: "url(" + answer.profilePicture + ")" }} className="background-image-professional" onClick={answer.userType === 2 ? () => openVendorProfile({vendorId: answer.userId, name: answer.name}) : () => { }} /> :
                                                                                    <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" onClick={answer.userType === 2 ? () => openVendorProfile({vendorId: answer.userId, name: answer.name}) : () => { }} /> :
                                                                                <div style={{ backgroundImage: "url(" + adminProfileImage + ")" }} className="background-image-professional" />}
                                                                            <div className=" answer-text">
                                                                                <div className="answer-commentedby">{answer.userType !== 3 ? <span className={answer.userType === 2 ? 'clickable-span' : ''} onClick={answer.userType === 2 ? () => openVendorProfile({vendorId: answer.userId, name: answer.name}) : () => { }}>{answer.name}</span> : 'Parentcraft Admin'}
                                                                                    <span className="answer-date"> {getFullDate(answer.date)} </span>
                                                                                </div>
                                                                                <div className="answer-text-span-wrapper">{answer.answerText.split('\n').map((item, key) => {
                                                                                    return <span key={key}>{item}<br /></span>
                                                                                })}</div>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                    : null}
                                                            </div>
                                                        </div>
                                                        <div className="row no-border pr-0 pl-0 pb-0" style={{ margin: 0, paddingTop: "15px" }}>
                                                            {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                                                                <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="background-image-professional" /> :
                                                                <div style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                            <div className="message-input">
                                                                <textarea className="form-control textarea" placeholder="Add your comment" value={myAnswer} onChange={(e) => updateMyAnswer(e.target.value)} rows="2"></textarea>
                                                                <button disabled={!myAnswer.trim().length} className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={giveAnswer}><img src={require("../../../images/message-send.svg")} alt="send message" /></button>

                                                            </div>

                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                })
                                : <div className="text-center well col-12 color-black">
                                    {!loadingQuestions ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Result Found</div>
                                        </div> : 'loading...'}
                                </div>}
                        </div>
                    </div>
                </> : null}
                {tabs[0].selected ? <>
                    <div className="col-12">
                        <div className="row fav-professional-wrapper pt-3" style={{ margin: 0 }}>
                            {professionals.length ?
                                professionals.map((professional, index) => {
                                    return <div className={`col-md-6 col-xs-12 professional-wrapper
                                ${index % 2 === 0 ? 'dashboard-more-768-pr-15' : ''}
                                ${index !== professionals.length - 1 && index !== professionals.length - 2 ? 'dashboard-more-768-pb-15' : ''}
                                ${index !== professionals.length - 1 ? 'dashboard-less-768-pb-15' : ''}
                                `} key={index} onClick={() => openVendorProfile(professional)}>
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="booking-profile-image">
                                                {professional.profilePicture ?
                                                    <div style={{ backgroundImage: "url(" + professional.profilePicture + ")" }} className="background-image-professional" /> :
                                                    <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                            </div>
                                            <div className="booking-details-wrapper">
                                                <div className="professional-name">{professional.name}
                                                {professional.isFavourite ? 
                                            <img src={require('../../../images/loved.png')} className="pull-right" alt="sort down" onClick={e => {e.stopPropagation(); toggleUnFavModal(true); updateCurrentUnFavObj(professional); updateCurrentUnFavType("professional")}} />
                                            : <img src={require('../../../images/love.png')} className="pull-right" alt="sort down" onClick={e => {e.stopPropagation(); toggleUnFavModal(true); updateCurrentUnFavObj(professional); updateCurrentUnFavType("professional")}} /> }
                                                </div>
                                                <div className="professional-designation">{professional.designation}</div>
                                                <div className="professional-location">{professional.location}</div>
                                                <div className="professional-exp">Experience - {professional.experience}
                                                    
                                                    {professional.rating ? <span className="profile-rating"><i className={`ml-3 fa-star fas`}></i> {professional.rating} </span> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                : <div className="text-center well col-12 color-black">
                                    {!loadingProfessionals ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Result Found</div>
                                        </div> : 'loading...'}
                                </div>}
                        </div>
                    </div>
                </> : null}
            </div>
        </div>
    )
}

export default Favourites;