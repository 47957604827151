import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const REQUEST_LOGIN_OTP = `${baseUrl}/customer/auth/request-otp`;
export const VERIFY_LOGIN_OTP = `${baseUrl}/customer/auth/verify-otp`;
export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const GET_PROFILE = `${baseUrl}/account/customer/profile`;
export const REMOVE_PHOTO = `${baseUrl}/account/customer/profile/remove-profile-picture`;
export const REQUEST_EMAIL_OTP = `${baseUrl}/account/customer/profile/verify-email-request-otp`;
export const VERIFY_EMAIL_OTP = `${baseUrl}/account/customer/profile/verify-email-confirm-otp`;
export const DELETE_ACCOUNT = `${baseUrl}/account/customer/profile/delete-account`;

export const ME_URL = "api/me";

export const requestEmailOtp = async (requestBody) => {
  const res = await axios.post(REQUEST_EMAIL_OTP, requestBody);
  return res;
}

export const confirmEmailOtp = async (requestBody) => {
  const res = await axios.post(VERIFY_EMAIL_OTP, requestBody);
  return res;
}

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export const requestLoginOtp = async (requestBody) => {
  const res = await axios.post(REQUEST_LOGIN_OTP, requestBody);
  return res;
}

export const confirmLoginOtp = async (requestBody) => {
  const res = await axios.post(VERIFY_LOGIN_OTP, requestBody);
  return res;
}

export const getProfile = async () => {
  const res = await axios.get(GET_PROFILE);
  return res;
}

export const updateProfile = async (requestBody, imageFile) => {
  const formData = new FormData();
  if (imageFile) {
    formData.append('profilePicture', imageFile);
  }
  if (requestBody) {
    for (let i in requestBody) {
      formData.append(i, requestBody[i]);
    };
  }
  const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
  };
  const res = await axios.patch(`${GET_PROFILE}/update`, formData, config);
  return res;
}

export const removePhoto = async () => {
  const res = await axios.delete(REMOVE_PHOTO);
  return res;
}

export const deleteAccountApi = async () => {
  const res = await axios.delete(DELETE_ACCOUNT);
  return res;
}