import React from "react";

const PDFViewer = () => {
    return <div className="pdf-viewer">
        <iframe title="pdf-viewer" src={`https://drive.google.com/viewerng/viewer?url=http://www.africau.edu/images/default/sample.pdf&embedded=true`} style={{
            width: "100%",
            height: "100%",
            border: "none"
        }}></iframe>
    </div>
};

export default PDFViewer;