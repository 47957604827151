import React, { useEffect, useState } from 'react';
import "./forum.css";
import { flagQuestion, flagAnswer } from "../../crud/qa-forum.crud";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";

// const closeImage = require('../../../images/modal-close-icon.png');
const initialFlagOptions = [{
  text: "Harassment or bullying",
  value: 1,
  checked: false
}, {
  text: "Sexually explicit material",
  value: 2,
  checked: false
}, {
  text: "Wrong Information",
  value: 3,
  checked: false
}, {
  text: "Duplicate content",
  value: 4,
  checked: false
}, {
  text: "Others",
  value: 5,
  checked: false
}];

const AskQuestionModal = (props) => {
  const [disablePage, toggleDisablePage] = useState(false);
  const [flagOptions, updateFlagOptions] = useState([...initialFlagOptions]);
  const [selectedFlagOption, updateSelection] = useState(0);

  const resetFlagOptions = () => {
    const localFlags = [...flagOptions];
    localFlags.forEach(each => {
      each.selected = false;
    });
    updateFlagOptions([...localFlags]);
    updateSelection(0);
  };

  const clickFlag = async () => {
    toggleDisablePage(true);
    let favProbResult = await flagQuestion(props.selectedFlaggedQuestion._id, { flagChoise: true, flagType: selectedFlagOption });
    toggleDisablePage(false);
    if (favProbResult.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.errorCode]);
      return;
    }
    if (favProbResult.data.data && favProbResult.data.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.data.errorCode]);
      return;
    }
    props.toggleFlagModal(false);
    if (props.from === "list") {
      props.getAllForumList();
    } else {
      props.getQuestionDetails(props.selectedFlaggedQuestion._id);
    }
    props.updateSelectedFlaggedQuestion({});
    resetFlagOptions();
  };

  const clickAnswerFlag = async () => {
    toggleDisablePage(true);
    let favProbResult = await flagAnswer(props.selectedFlaggedQuestion._id, props.selectedFlaggedQuestion.selectedAnswer._id, { flagChoise: true, flagType: selectedFlagOption });
    toggleDisablePage(false);
    if (favProbResult.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.errorCode]);
      return;
    }
    if (favProbResult.data.data && favProbResult.data.data.errorCode) {
      toast.error(API_ERROR[favProbResult.data.data.errorCode]);
      return;
    }
    props.toggleFlagModal(false);
    if (props.from === "list") {
      props.getAllForumList();
    } else {
      props.getQuestionDetails(props.selectedFlaggedQuestion._id);
    }
    props.updateSelectedFlaggedQuestion({});
    resetFlagOptions();
  };

  const changeFlagOption = (flagData) => {
    const localFlags = [...flagOptions];
    localFlags.forEach(each => {
      each.selected = false;
      if (each.value === flagData.value) {
        each.selected = true;
      }
    });
    updateFlagOptions([...localFlags]);
    updateSelection(flagData.value);
  };

  useEffect(() => {
    if (props.flagModalOpened) {
      resetFlagOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.flagModalOpened]);

  return (
    <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      <div className={`modal ask-question-modal fade ${props.flagModalOpened ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title">Flag Reason</h5> */}
              {/* <img src={closeImage} alt="close modal" onClick={() => { props.toggleFlagModal(false); }} style={{ cursor: "pointer" }} /> */}
            </div>
            <div className="modal-body">
              <p style={{fontSize: "17px"}}>Please choose a reason for flagging.</p>
              {flagOptions.map(option => {
                return <div className="form-check" style={{display: "block"}}>
                  <input className="form-check-input" type="radio" name="flag" id={option.text} value={option.value} checked={option.selected} onChange={() => changeFlagOption(option)} />
                  <label className="form-check-label" htmlFor={option.text}>
                    {option.text}
                  </label>
                </div>
              })}
              <div className="col-12 login-button mt-4">
                <button type="button" className="btn btn-primary" disabled={selectedFlagOption === 0} onClick={props.type === "answer" ? clickAnswerFlag : clickFlag}>Send</button>
                <button type="button" className="btn btn-default" onClick={() => { props.toggleFlagModal(false); }}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AskQuestionModal;