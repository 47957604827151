import React, { useEffect, useState } from 'react';
import "./forum.css";

const successTick = require("../../../images/green-tick.png");

const SuccessPayment = (props) => {
    const [pageInitialized, updatePageInitialization] = useState(false);

    useEffect(() => {
        if (!pageInitialized) {
            const el = document.getElementsByClassName("custom-navigation-bar")[0];
            const el1 = document.getElementsByClassName("footer")[0];
            if (el) {
                el.style.visibility = "hidden";
            }
            if (el1) {
                el1.style.visibility = "hidden";
            }
            updatePageInitialization(true);
        }
    }, [pageInitialized]);

    const openAppointmentPage = () => {
        props.history.push(`/workshop-booking-list`);
    };

    return (
        <div className="forum success-payment-outside">
            <div className={`success-payment-wrapper`}>
                <>
                    <img src={successTick} alt="success" style={{
                        width: "100px",
                        marginBottom: "15px"
                    }} />
                    <h2 className="header-1">
                        Congratulations
                    </h2>
                    <h3 className="header-1">
                        Your booking is confirmed
                    </h3>
                    <div className="button-wrapper mt-4">
                        <button className="btn btn-outline-primary" onClick={() => props.history.push("/book-now?categoryId=605088be5848fb3bf301e90b")}>Back to Home</button>
                        <button className="btn btn-primary" onClick={openAppointmentPage}>View Workshops</button>
                    </div>
                </>
            </div>
        </div>
    )
}

export default SuccessPayment;