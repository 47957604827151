import React, {useEffect} from 'react';
// const notFoundImage = require('../../../images/404.png');

const NotFound = () => {

    useEffect(() => {
        document.title = "404 Page not found";
    }, []);

    return <div style={{textAlign: "center", marginTop: "15px"}}>
        {/* <img src={notFoundImage} style={{width:"200px"}} alt="404" /> */}
        {/* <br /> */}
        <div style={{fontSize: "20px", fontWeight: 500, marginTop: "10px"}}>Unfortunately the page you are looking for has been moved or deleted</div>
        <button style={{marginTop: "10px"}} onClick={() => window.location.replace(`${window.location.origin}`)}>Move To Home</button>
    </div>
};

export default NotFound;