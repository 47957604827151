import React, { useEffect, useState, useCallback } from 'react';
import "./problem-area.css";

import { getUpcomingBookings, getPastBookings } from "../../crud/booking.crud";
import {
    timeArrConst,
    Config,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

const defaultImage = require('../../../images/imgAvtr.png');
const timeArr = [...timeArrConst];

const BookingList = (props) => {
    const [upcomingBookings, updateUpcomingBooking] = useState([]);
    const [pastBookings, updatePastBooking] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [currentTab, updateCurrentTab] = useState("upcoming");
    const [loadingUpcomingBooking, toggleLoadingUpcomingBooking] = useState(true);
    const [loadingPastBooking, toggleLoadingPastBooking] = useState(true);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[0] - 1]);
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
    };

    const getAllUpcomingBookings = useCallback(async () => {
        toggleDisablePage(true);
        const upcomingBookingResult = await getUpcomingBookings();

        toggleDisablePage(false);
        toggleLoadingUpcomingBooking(false);
        if (upcomingBookingResult.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
            return;
        }
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
            return;
        }

        const localUpcomingBookings = [];
        // let sortedArr = [];
        let bookingObj = {};
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.data && upcomingBookingResult.data.data.data.length) {

            upcomingBookingResult.data.data.data.forEach(each => {
                bookingObj = {};
                bookingObj._id = each._id;
                bookingObj.name = each.vendorRef.personalInfo.name;
                bookingObj.profilePicture = each.vendorRef.personalInfo.profilePicture;
                bookingObj.email = each.vendorRef.personalInfo.email;
                bookingObj.website = each.vendorRef.personalInfo.website;
                bookingObj.rating = each.vendorRef.ratingDetails && each.vendorRef.ratingDetails.average ? each.vendorRef.ratingDetails.average.toFixed(1) : 0;
                bookingObj.designation = "";
                bookingObj.amount = each.paymentDetails.total;
                bookingObj.appointmentBookingType = each.appointmentBookingType || 1;
                bookingObj.status = Config.Booking.NumberStatus[each.status];
                bookingObj.statusColor = each.status === 3 ? "#00A365" : (each.status !== 1 && each.status !== 2) ? "#E52E40" : "#185bdb";
                if (each.serviceRef) {
                    bookingObj.className = each.serviceRef.title;
                    bookingObj.addressTitle = each.serviceRef.addressTitle;
                    bookingObj.location = each.serviceRef.location.coordinates;
                    bookingObj.servDesc = each.serviceRef.description;
                }
                bookingObj.experience = "";
                if (each.vendorRef.personalInfo.experience && (each.vendorRef.personalInfo.experience.year)) {
                    const year = each.vendorRef.personalInfo.experience.year;
                    if (year) {
                        bookingObj.experience += `${year} year${year > 1 ? 's' : ''}`;
                    }
                }
                bookingObj.date = getFullDateFormat(new Date(each.date));
                bookingObj.slots = each.slots;
                bookingObj.startTime = getStartTime(each.slots);
                bookingObj.endTime = getEndTime(each.slots);
                if (each.vendorRef.personalInfo.categories && each.vendorRef.personalInfo.categories.length) {
                    each.vendorRef.personalInfo.categories.forEach((item, index) => {
                        bookingObj.designation += item.title;
                        if (index !== each.vendorRef.personalInfo.categories.length - 1) {
                            bookingObj.designation += ", ";
                        }
                    });
                }
                bookingObj.meetingPlatform = each.meetingDetails ? each.meetingDetails.platform : "";
                localUpcomingBookings.push({ ...bookingObj });
            });
        }

        updateUpcomingBooking([...localUpcomingBookings]);
    }, []);

    const getAllPastBookings = useCallback(async () => {
        toggleDisablePage(true);
        const pastBookingResult = await getPastBookings();
        toggleLoadingPastBooking(false);
        toggleDisablePage(false);
        if (pastBookingResult.data.errorCode) {
            toast.error(API_ERROR[pastBookingResult.data.errorCode]);
            return;
        }
        if (pastBookingResult.data.data && pastBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[pastBookingResult.data.data.errorCode]);
            return;
        }

        const localPastBookings = [];
        let bookingObj = {};
        if (pastBookingResult.data.data && pastBookingResult.data.data.data && pastBookingResult.data.data.data.length) {
            // pastBookingResult.data.data.data = getSortedArray([...pastBookingResult.data.data.data], 'past');
            pastBookingResult.data.data.data.forEach(each => {
                bookingObj = {};
                bookingObj._id = each._id;
                bookingObj.name = each.vendorRef.personalInfo.name;
                bookingObj.profilePicture = each.vendorRef.personalInfo.profilePicture;
                bookingObj.email = each.vendorRef.personalInfo.email;
                bookingObj.website = each.vendorRef.personalInfo.website;
                bookingObj.rating = each.vendorRef.ratingDetails && each.vendorRef.ratingDetails.average ? each.vendorRef.ratingDetails.average.toFixed(1) : 0;
                bookingObj.designation = "";
                bookingObj.amount = each.paymentDetails.total;
                bookingObj.status = Config.Booking.NumberStatus[each.status];
                bookingObj.statusColor = each.status === 3 ? "#00A365" : (each.status !== 1 && each.status !== 2) ? "#E52E40" : "#185bdb";
                if (each.serviceRef) {
                    bookingObj.className = each.serviceRef.title;
                    bookingObj.addressTitle = each.serviceRef.addressTitle;
                    bookingObj.location = each.serviceRef.location.coordinates;
                    bookingObj.servDesc = each.serviceRef.description;
                }
                if (each.ratingDetails) {
                    bookingObj.bookingRating = each.ratingDetails.rating;
                }
                bookingObj.experience = "";
                if (each.vendorRef.personalInfo.experience && (each.vendorRef.personalInfo.experience.year)) {
                    const year = each.vendorRef.personalInfo.experience.year;
                    if (year) {
                        bookingObj.experience += `${year} year${year > 1 ? 's' : ''}`;
                    }
                }
                bookingObj.appointmentBookingType = each.appointmentBookingType || 1;
                bookingObj.date = getFullDateFormat(new Date(each.date));
                bookingObj.slots = each.slots;
                bookingObj.startTime = getStartTime(each.slots);
                bookingObj.endTime = getEndTime(each.slots);
                if (each.vendorRef.personalInfo.categories && each.vendorRef.personalInfo.categories.length) {
                    each.vendorRef.personalInfo.categories.forEach((item, index) => {
                        bookingObj.designation += item.title;
                        if (index !== each.vendorRef.personalInfo.categories.length - 1) {
                            bookingObj.designation += ", ";
                        }
                    });
                }
                localPastBookings.push({ ...bookingObj });

                // if (each.slots && each.slots.length) {
                //     each.slots.forEach(each1 => {

                //     });
                // }


            });
        }
        updatePastBooking([...localPastBookings]);
    }, []);

    const updateUrl = (type) => {
        const localUrl = `/booking-list?type=${type}`;
        props.history.push(localUrl);
    };

    useEffect(() => {
        getAllUpcomingBookings();
        getAllPastBookings();
        const urlParams = new URLSearchParams(window.location.search);
        const typeParam = urlParams.get('type');
        if (typeParam) {
            updateCurrentTab(typeParam);
        } else {
            updateUrl("upcoming");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllUpcomingBookings, getAllPastBookings]);

    const openMapLocation = (localProf) => {
        const coordinates = localProf.location;
        window.open(`https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`);
    };

    // const copyEmailToClipboard = (localProf) => {
    //     const tempInput = document.createElement("input");
    //     tempInput.value = localProf.email;
    //     document.body.appendChild(tempInput);
    //     tempInput.select();
    //     document.execCommand("copy");
    //     document.body.removeChild(tempInput);

    //     const tooltip = document.getElementById("myTooltip");
    //     tooltip.innerHTML = "Email Copied";
    // };

    // const emailMouseOut = () => {
    //     const tooltip = document.getElementById("myTooltip");
    //     tooltip.innerHTML = "Copy to clipboard";
    // };

    return (
        <div className={`row problem-area booking-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-6 categories-heading">
                        <h4 className={`mb-0 problem-category-heading`}>Appointment List <i className="fas fa-info-circle ml-3" aria-hidden="true" style={{ fontSize: "18px", cursor: "pointer" }} /></h4>

                    </div>
                    <div className="col-6 pr-0 upcoming-past-button-wrapper text-right pt-3">
                        <button onClick={() => { updateCurrentTab("upcoming"); updateUrl("upcoming") }} className={`btn btn-primary upcoming-past-button ${currentTab === 'upcoming' ? 'active' : ''}`}>Upcoming</button>
                        <button onClick={() => { updateCurrentTab("past"); updateUrl("past") }} className={`btn btn-primary upcoming-past-button ${currentTab === 'past' ? 'active' : ''}`}>Past</button>
                    </div>
                </div>
                <div className="row" style={{ margin: 0 }}>
                    {currentTab === "upcoming" ?
                        <div className="col-12">
                            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                                {upcomingBookings.length ?
                                    upcomingBookings.map((professional, index) => {
                                        return <div className={`col-12 professional-wrapper ${index !== upcomingBookings.length - 1 ? 'mb-15' : ''}
                                `} key={index}>
                                            <div className="row booking-item-wrapper" style={{ margin: 0 }}>
                                                <div className="booking-profile-image">
                                                    {professional.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + professional.profilePicture + ")" }} className="background-image-professional" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                    {professional.rating ? <div className="profile-rating text-center mt-2"><i className={`fa-star fas`}></i> {professional.rating} </div> : null}
                                                </div>
                                                <div className="booking-details-wrapper">
                                                    <div className="col-12 desktop-view-only">
                                                        <div className="row m-0 booking-item-side-row">
                                                            <div className="col-5 pr-20 pl-0 left-booking-item">
                                                                <div className="professional-name">{professional.name}</div>
                                                                <div className="professional-designation">{professional.designation}</div>
                                                                <div className="professional-address-title">{professional.addressTitle}</div>
                                                                <div className="profile-icon-wrapper">
                                                                    {/* {professional.email ?
                                                                    <div className="tooltip">
                                                                        <i className="fa fa-envelope" aria-hidden="true" onClick={() => copyEmailToClipboard(professional)} onMouseLeave={emailMouseOut}>
                                                                        </i>
                                                                        <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                                                    </div> : null} */}
                                                                    {professional.website ?
                                                                        <img src={require('../../../images/globe.svg')} alt="website" onClick={() => window.open(professional.website)} /> : null}
                                                                    {professional && professional.location && professional.location.length ?
                                                                        <img src={require('../../../images/location.svg')} alt="location" onClick={() => openMapLocation(professional)} /> : null}
                                                                </div>
                                                                {professional.appointmentBookingType === 1 ?
                                                                    <div className="professional-amount mt-2">Video Consultation</div> :
                                                                    <div className="professional-amount mt-2">Clinic Visit</div>}
                                                            </div>
                                                            <div className="col-7 pl-20 pr-0">
                                                                <div className="professional-name">{professional.className}</div>
                                                                <div className="professional-service-desc">{professional.servDesc}</div>
                                                                <div className="professional-date-time row m-0 booking-item-side-row">
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Selected date</div>
                                                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {professional.date}</div>
                                                                    </div>
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Time slot</div>
                                                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                                    <div className="col-6 pl-0 pt-3" style={{
                                                                        color: professional.statusColor
                                                                    }}>
                                                                        Status: {professional.status}
                                                                    </div>
                                                                    <div className="col-6 pr-0">
                                                                        <button className="btn btn-primary details-button" onClick={() => props.history.push(`/booking-details/${professional._id}`)}>View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mobile-view-only">
                                                        <div className="professional-name">{professional.name}</div>
                                                        <div className="professional-designation">{professional.designation}</div>
                                                        <div className="professional-location">{professional.className}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 mobile-view-only mt-3">
                                                    <div className="professional-date-time row m-0 booking-item-side-row">
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Selected date</div>
                                                            <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "5px" }} />{professional.date}</div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Time slot</div>
                                                            <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "5px" }} alt="clock" />{professional.startTime} - {professional.endTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                        <div className="col-6 pl-0 pt-3" style={{
                                                            color: professional.statusColor
                                                        }}>
                                                            Status: {professional.status}
                                                        </div>
                                                        <div className="col-6 pr-0">
                                                            <button className="btn btn-primary details-button" onClick={() => props.history.push(`/booking-details/${professional._id}`)}>View Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : <div className="text-center well col-12 color-black">
                                        {!loadingUpcomingBooking ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Booking Found</div>
                                            <button className='btn btn-primary no-data-button' onClick={() => props.history.push('/book-now?categoryId=605088be5848fb3bf301e90b')}>Book Now</button>
                                        </div> : 'loading...'}
                                    </div>}
                            </div>
                        </div> :
                        <div className={`col-12`}>
                            <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                                {pastBookings.length ?
                                    pastBookings.map((professional, index) => {
                                        return <div className={`col-12 professional-wrapper ${index !== pastBookings.length - 1 ? 'mb-15' : ''}
                                `} key={index}>
                                            <div className="row booking-item-wrapper" style={{ margin: 0 }}>
                                                <div className="booking-profile-image pos-relative">
                                                    {professional.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + professional.profilePicture + ")" }} className="background-image-professional" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                    {/* {professional.rating ? <div className="profile-rating text-center mt-2"><i className={`fa-star fas`}></i> {professional.rating} </div> : null} */}
                                                    {professional.status === "Completed" ?
                                                        professional.bookingRating ?
                                                            <div className='rated text-center desktop-view-only'>You rated <div>{professional.bookingRating.toFixed(1)} <i className={`fa-star fas`}></i>  </div></div> : <div className='rate-button desktop-view-only'><button className='btn btn-primary' onClick={() => props.history.push(`/rating-review/${professional._id}`)}>Rate Now</button></div>
                                                        : null}
                                                </div>
                                                <div className="booking-details-wrapper">
                                                    <div className="col-12 desktop-view-only">
                                                        <div className="row m-0 booking-item-side-row">
                                                            <div className="col-5 pr-20 pl-0 left-booking-item">
                                                                <div className="professional-name">{professional.name}</div>
                                                                <div className="professional-designation">{professional.designation}</div>
                                                                <div className="professional-address-title">{professional.addressTitle}</div>
                                                                <div className="profile-icon-wrapper">
                                                                    {professional.website ?
                                                                        <img src={require('../../../images/globe.svg')} alt="website" onClick={() => window.open(professional.website)} /> : null}
                                                                    {professional && professional.location && professional.location.length ?
                                                                        <img src={require('../../../images/location.svg')} alt="location" onClick={() => openMapLocation(professional)} /> : null}
                                                                </div>
                                                                {professional.appointmentBookingType === 1 ?
                                                                    <div className="professional-amount mt-2">Video Consultation</div> :
                                                                    <div className="professional-amount mt-2">Clinic Visit</div>}

                                                            </div>
                                                            <div className="col-7 pl-20 pr-0">
                                                                <div className="professional-name">{professional.className}</div>
                                                                <div className="professional-service-desc">{professional.servDesc}</div>
                                                                <div className="professional-date-time row m-0 booking-item-side-row">
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Selected date</div>
                                                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {professional.date}</div>
                                                                    </div>
                                                                    <div className="col-6 pl-0">
                                                                        <div className="label">Time slot</div>
                                                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {professional.startTime} - {professional.endTime}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                                    <div className={`col-6 pl-0 pt-3`}>
                                                                        <div style={{
                                                                            color: professional.statusColor
                                                                        }}>Status: {professional.status}</div>

                                                                    </div>
                                                                    <div className={`col-6 pr-0`}>
                                                                        <button className="btn btn-primary details-button" onClick={() => props.history.push(`/booking-details/${professional._id}`)}>View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mobile-view-only">
                                                        <div className="professional-name">{professional.name}</div>
                                                        <div className="professional-designation">{professional.designation}</div>
                                                        <div className="professional-location">{professional.className}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 mobile-view-only mt-3">
                                                    <div className="professional-date-time row m-0 booking-item-side-row">
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Selected date</div>
                                                            <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "5px" }} />{professional.date}</div>
                                                        </div>
                                                        <div className="col-6 pl-0">
                                                            <div className="label">Time slot</div>
                                                            <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "5px" }} alt="clock" />{professional.startTime} - {professional.endTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className="professional-amount row ml-0 mr-0 booking-item-side-row">
                                                        <div className={`col-6 pl-0 ${professional.status === "Completed" ? "" : "pt-3"}`}>
                                                            <div className='pb-1' style={{
                                                                color: professional.statusColor
                                                            }}>Status: {professional.status}</div>
                                                            {professional.status === "Completed" ?
                                                                professional.bookingRating ?
                                                                    <div className='rated'>You rated <span className='rating'><span style={{verticalAlign: "middle"}}>{professional.bookingRating.toFixed(1)}</span> <i className={`fa-star fas`}></i>  </span></div> : <button className='btn btn-primary' onClick={() => props.history.push(`/rating-review/${professional._id}`)}>Rate Now</button>
                                                                : null}
                                                        </div>
                                                        <div className={`col-6 pr-0 ${professional.status === "Completed" ? "pt-3" : ""}`}>
                                                            <button className="btn btn-primary details-button" onClick={() => props.history.push(`/booking-details/${professional._id}`)}>View Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    : <div className="text-center well col-12 color-black">
                                        {!loadingPastBooking ? <div className='no-data-found'>
                                            <img src={require("../../../images/no-data.png")} alt="no data" />
                                            <br />
                                            <div className='no-data-header'>No Booking Found</div>
                                        </div> : "loading..."}
                                    </div>}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default BookingList;