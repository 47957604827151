import React, { useEffect, useState } from 'react';
import "../pages/admins/problem-area.css";
import { notification, applyPromoCode } from "../crud/home.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../config/error-message";

const preferredCust = require('../../images/preferred-customer.png');
const preferredVendor = require('../../images/preferred-vendor.png');
const promoAppliedImage = require('../../images/test-progress.png');
const closeImage = require('../../images/modal-close-icon.png');

const PromoCodeModal = (props) => {
    const [promocode, updatePromoCode] = useState('');
    const [promoOffers, updateOffers] = useState({});
    const [promoApplied, togglePromoApply] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);

    const getGlobalData = async () => {
        const globalConfigResult = await notification();
        if (globalConfigResult.data.data) {
            updateOffers({
                appointment: globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.appointment,
                event: globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.event,
                workshop: globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.workshop
            });
        }
    };

    const applyPromo = async () => {
        toggleDisablePage(true);
        const promoCodeResult = await applyPromoCode({code: promocode.trim()});
        toggleDisablePage(false);
        if (promoCodeResult.data.errorCode) {
            toast.error(API_ERROR[promoCodeResult.data.errorCode]);
            return;
        }
        if (promoCodeResult.data.data && promoCodeResult.data.data.errorCode) {
            toast.error(API_ERROR[promoCodeResult.data.data.errorCode]);
            return;
        }
        togglePromoApply(true);
        document.getElementsByClassName("scrollable-modal")[0].scrollTop = 0;
        props.updateProfileForPromo();
    };

    useEffect(() => {
        getGlobalData();
        if (props.authData && props.authData.promoCodeInfo && props.authData.promoCodeInfo.institutionRef) {
            togglePromoApply(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal profile-modal ${disablePage ? "pointer-none" : ""} fade ${props.displayPromoModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <img src={closeImage} alt="close modal" onClick={() => props.togglePromoModal(false)} className="login-modal-close" style={{right: "15px", top: "15px"}} />
                            {!promoApplied ?
                                <h5 className="" style={{ margin: "6px auto", fontSize: "20px", color: "#1B3467", fontFamily: 'Poppins Semi Bold', fontWeight: 600 }}>Preferred customer</h5> : null}
                        </div>
                        <div className="modal-body" style={{ padding: "10px 10px 25px" }}>
                            <div className='col-12 p-0'>
                                <div className='row m-0 scrollable-modal'>
                                    {promoApplied ?
                                        <>
                                            <div className="col-12 text-center category-arrows">
                                                <img src={promoAppliedImage} alt="promo applied" style={{ width: "120px" }} />
                                                <h4 style={{ color: "#185CD9", marginTop: "8px" }}>Congrats!</h4>
                                                <div style={{ color: "#1B3467", fontFamily: 'Poppins Semi Bold', fontWeight: 600, fontSize: "11px", marginBottom: "15px" }}>Preference Code applied succesfully</div>
                                            </div>
                                            <p style={{ fontSize: "14px", color: "#1B3467", fontFamily: 'Poppins Semi Bold', fontWeight: 600 }}>To avail benefits</p> </> : null}
                                    <div className='col-12 p-0' style={{ borderRadius: "15px", marginBottom: "15px", backgroundColor: "#ECF2FF" }}>
                                        <img src={!promoApplied ? preferredCust : preferredVendor} alt="preferred customer" style={{ width: "100%" }} />
                                    </div>
                                    {promoApplied ? <>
                                        <p>Our preferred professionals are marked with a golden ring.</p>
                                        <p>Click on cards with golden ring to see all the benefits.</p>
                                    </> : null}
                                    <div className='col-12 p-0'>
                                        <p style={{ fontSize: "14px", color: "#1B3467", fontFamily: 'Poppins Semi Bold', fontWeight: 600 }}>Benefits</p>
                                        <ul style={{ marginLeft: "15px" }}>
                                            <li>Get a discount of {promoOffers.appointment || '0'}% on appointment bookings</li>
                                            <li>Get a discount of {promoOffers.event || '0'}% on event bookings</li>
                                            <li>Get a discount of {promoOffers.workshop || '0'}% on workshop bookings</li>
                                        </ul>
                                    </div>
                                    {!promoApplied ? <>
                                        <div className="col-12 p-0 mb-3">
                                            <input type="text" className={`form-control profile-inputs`} id="exampleFormControlInput1" placeholder="Enter preference code" value={promocode} onChange={e => updatePromoCode(e.target.value)} />
                                        </div>
                                        <div className="col-12 p-0 mt-3 text-center login-button">
                                            <button type="button" disabled={!promocode.trim().length} className="btn btn-primary" onClick={applyPromo}>Apply</button>
                                        </div>
                                    </> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromoCodeModal;