import React, { useEffect, useState, useCallback } from 'react';
import "./problem-area.css";
import TestCategoryModal from './test-category-modal';
import TestInfoModal from './test-info-modal';

import { getTestCategories, getTests } from "../../crud/home.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";
import { assessmentMetaData, orgMetaData } from '../../config/constants';

// const defaultImage = require('../../../images/imgAvtr.png');

const TestList = (props) => {
    const [upcomingBookings, updateUpcomingBooking] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingUpcomingEvents, toggleloadingUpcomingEvents] = useState(true);
    // const [currentTab, updateCurrentTab] = useState(!window.location.pathname.includes("results") ? "" : "upcoming");
    const [displayCategoryModal, toggleCategoryModal] = useState(false);
    const [categoryList, updateCategoryList] = useState([]);
    const [openInfoModal, toggleInfoModal] = useState(false);

    const getAllUpcomingBookings = useCallback(async () => {
        toggleDisablePage(true);
        const upcomingBookingResult = await getTests();

        toggleDisablePage(false);
        toggleloadingUpcomingEvents(false);
        if (upcomingBookingResult.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
            return;
        }
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
            return;
        }

        const localUpcomingBookings = [];
        let bookingObj = {};

        if (upcomingBookingResult.data && upcomingBookingResult.data.data && upcomingBookingResult.data.data.length) {
            upcomingBookingResult.data.data.forEach(each => {
                bookingObj = {};
                bookingObj.title = each.title;
                bookingObj.description = each.description;
                bookingObj.image = each.imageLink;
                bookingObj._id = each._id;
                bookingObj.ageInfo = each.ageInfo;
                bookingObj.updatedAt = each.updatedAt;
                localUpcomingBookings.push({ ...bookingObj });

            });
        }

        updateUpcomingBooking([...localUpcomingBookings]);
    }, []);

    const getAllCategories = useCallback(async () => {
        toggleDisablePage(true);
        const localCategories = await getTestCategories();
        toggleDisablePage(false);
        if (localCategories.data.errorCode) {
            toast.error(API_ERROR[localCategories.data.errorCode]);
            return;
        }
        if (localCategories.data.data && localCategories.data.data.errorCode) {
            toast.error(API_ERROR[localCategories.data.data.errorCode]);
            return;
        }

        updateCategoryList([...localCategories.data.data]);
        // if (!window.location.search.includes("testCategoryId")) {
        //     toggleCategoryModal(true);
        // } else {
            // getAllUpcomingBookings(window.location.search.split("=")[1].split("&")[0]);
        getAllUpcomingBookings();
        // }
    }, [getAllUpcomingBookings]);

    useEffect(() => {
        // if (!window.location.pathname.includes("results")) {
            getAllCategories();
        // }
    }, [getAllCategories]);

    useEffect(() => {
        const metaTags = document.getElementsByTagName("meta");
        if (metaTags[1].name ===  "title") {
            metaTags[1].content = orgMetaData.selfAssessment.title;
        }
        if (metaTags[2].name ===  "description") {
            metaTags[2].content = orgMetaData.selfAssessment.description;
        }
        if (metaTags[3].name ===  "keywords") {
            metaTags[3].content = orgMetaData.selfAssessment.keywords;
        }
        if (metaTags[4]) {
            metaTags[4].content = orgMetaData.selfAssessment.title;
        }
        if (metaTags[5]) {
            metaTags[5].content = orgMetaData.selfAssessment.description;
        }
        if (metaTags[6]) {
            metaTags[6].content = orgMetaData.image;
        }
        if (metaTags[7]) {
            metaTags[7].content = orgMetaData.url;
        }
        document.title = assessmentMetaData.list.title;
        
    }, []);

    const chooseCategory = (catItem) => {
        props.history.push(`/assessment-list?testCategoryId=${catItem._id}&testCategory=${catItem.title.split(" ").join("_")}`);
        toggleCategoryModal(false);
        getAllUpcomingBookings(catItem._id);
    };

    const backToPrev = () => {
        toggleCategoryModal(false);
        props.history.goBack();
    };

    const openDetails = id => {
        props.history.push(`/assessment-details/${id}`);
    };

    return (
        <div className={`row problem-area booking-list test-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                {/* {!window.location.pathname.includes("results") && !window.location.search.includes("testCategoryId") ?
                    <div className='row m-0'>
                        <div className="text-center well col-12 color-black">
                            No Self Assessment Category chosen. Please choose a category to get the available self assessments.
                        </div>
                    </div>
                    : */}
                    <>
                        <div className="row" style={{ margin: 0 }}>
                            <div className="col-12 categories-heading pb-0">
                                <div className={`mb-0 problem-category-heading`}>{!window.location.pathname.includes("results") ? 'Self Assessments' : 'Self Assessment Results'}
                                {!window.location.pathname.includes("results") ? <i className="fa fa-info-circle pull-right cursor-pointer mt-2" aria-hidden="true" onClick={() => toggleInfoModal(true)}></i> : null}
                                </div>
                            </div>
                        
                            {/*
                        null :
                        <div className="col-6 pr-0 upcoming-past-button-wrapper text-right pt-1">
                            <button onClick={() => updateCurrentTab("upcoming")} className={`btn btn-primary upcoming-past-button ${currentTab === 'upcoming' ? 'active' : ''}`}>Upcoming</button>
                            <button onClick={() => updateCurrentTab("past")} className={`btn btn-primary upcoming-past-button ${currentTab === 'past' ? 'active' : ''}`}>Past</button>
                        </div>} */}
                        </div>
                        {/* {!window.location.pathname.includes("results") ?
                            <div className='row m-0 pt-2'>
                                <div className="col-12 test-category">
                                    {window.location.search.split("=")[2].split("_").join(" ")} <span className='professional-amount text-underline cursor-pointer' onClick={() => toggleCategoryModal(true)}>Change</span>
                                </div>
                            </div> : null} */}
                        <div className="row" style={{ margin: 0 }}>
                            <div className="col-12">
                                <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                                    {upcomingBookings.length ?
                                        upcomingBookings.map((professional, index) => {
                                            return <div className={`col-sm-6 col-xs-12 professional-wrapper event-list-wrapper ${index !== upcomingBookings.length - 1 ? 'mb-30' : ''} ${index % 2 === 0 ? 'pl-0' : ''}
                                `} key={index} onClick={() => { !window.location.pathname.includes("results") ? openDetails(professional._id) : props.history.push(`/test-booking-details/${professional._id}`) }}>
                                                <div className="row booking-item-wrapper event-list-item-wrapper cursor-pointer" style={{ margin: 0 }}>
                                                    <div className="event-list-image-wrapper" style={{ position: 'relative' }}>
                                                        {/* <div className="amount-image-wrapper" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")" }}> <i className="fas fa-rupee-sign"></i>{professional.fee.toFixed(2)} </div> */}
                                                        <img src={professional.image} className="event-list-img" alt={`event-${index}`} />
                                                        {!window.location.pathname.includes("results") && professional.ageInfo ?
                                                        <div className='age-info'>{professional.ageInfo}</div> : null}
                                                    </div>
                                                    <div className="col-12 p-3 event-list-item-description-wrapper">
                                                        <div className="row m-0 test-text">
                                                            <div className="col-12 pr-0 pl-0">
                                                                <div className="professional-name">{professional.title}</div>
                                                                <div className="professional-date-time test-description">{professional.description}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                        : <div className="text-center well col-12 color-black">
                                            {!loadingUpcomingEvents ? 'No Assessments Found!' : 'loading...'}
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </>
            </div>
            {displayCategoryModal ?
                <TestCategoryModal displayModal={displayCategoryModal} toggleTestModal={toggleCategoryModal} categoryList={categoryList} chooseCategory={chooseCategory} backToPrev={backToPrev} /> : null}
            {openInfoModal ?
                <TestInfoModal displayModal={openInfoModal} toggleInfoModal={toggleInfoModal} /> : null}
        </div>
    )
}

export default TestList;