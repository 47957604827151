import React from 'react';
import "../pages/admins/problem-area.css";

const OpenAppModal = (props) => {
    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal open-app-modal-container fade ${props.displaySuggestionModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog bottom-modal modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body" style={{padding: "25px"}}>
                            <div className="row" style={{ margin: 0 }}>
                                <div className="col-12 p-0 mb-1 text-center" style={{ color: "#000" }}>
                                    <div className="welcome-text">Welcome to </div>
                                    <div className="company-name">Parentcraft India</div>
                                </div>
                            </div>

                            <div className="col-12 p-0 text-center mt-3">
                                <button type="button" className="btn btn-primary open-app-primary-button mb-1" onClick={() => window.open("https://www.parentcraftindia.com/share-customer" , "_self")}>Open in App</button>
                                <div>
                                    <button type="button" className="btn btn-link" onClick={() => props.toggleSuggestionModal(false)}>Continue in Web</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenAppModal;