import React from 'react';
import "../pages/admins/problem-area.css";

const ProblemArea = (props) => {
    return (
        <div className="row problem-area" style={{ margin: 0 }}>

            <div className={`modal login-modal ask-question-modal fade ${props.displayLogoutModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{margin: "0 auto"}}>Confirm {props.type === "deleteAccount" ? "Account Delete!" : "Logout!"}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ margin: 0 }}>
                                <h5 className="col-12 p-0 mb-3" style={{ color: "#000", textAlign: "center" }}>
                                    Are you sure? <br /> {props.type === "deleteAccount" ? "you want to delete your account!" : "you want to logout from the application!" }
                                </h5>
                            </div>

                            <div className="col-12 p-0 text-right mt-3">
                                <button type="button" className="btn btn-outline-primary mr-2" onClick={() => props.toggleLogoutModal(false)}>No</button>
                                <button type="button" className="btn btn-danger" onClick={props.logout}>Yes, {props.type === "deleteAccount" ? "Delete" : "Logout"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProblemArea;