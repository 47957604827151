import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";
import { getEventList, notification } from "../../crud/home.crud";
import {
    timeArrConst,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

// const defaultImage = require('../../../images/imgAvtr.png');
const timeArr = [...timeArrConst];

const EventList = (props) => {
    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
            authData: auth.user
        }),
        shallowEqual
    );

    const [upcomingBookings, updateUpcomingBooking] = useState([]);
    const [disablePage, toggleDisablePage] = useState(false);
    const [loadingUpcomingEvents, toggleloadingUpcomingEvents] = useState(true);
    const [popUpOpened, openSortPopup] = useState(false);
    const [sortDown, toggleSort] = useState({});

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[0] - 1]);
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        return today === day;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const checkListDisplayTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
        return (endTime > today);
    }, []);

    const getAllUpcomingBookings = useCallback(async (sortConfig) => {
        toggleDisablePage(true);
        const reqBody = {};
        if (isAuthorized) {
            reqBody.customerRef = authData._id;
        }
        const upcomingBookingResult = await getEventList(0, sortConfig, reqBody);

        toggleDisablePage(false);
        toggleloadingUpcomingEvents(false);
        if (upcomingBookingResult.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.errorCode]);
            return;
        }
        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.errorCode) {
            toast.error(API_ERROR[upcomingBookingResult.data.data.errorCode]);
            return;
        }

        const globalConfigResult = await notification();

        const localUpcomingBookings = [];
        let bookingObj = {};

        if (upcomingBookingResult.data.data && upcomingBookingResult.data.data.data && upcomingBookingResult.data.data.data.length) {
            upcomingBookingResult.data.data.data.forEach(each => {
                if (each.images.length) {
                    if (!checkCurrentDay(each.date) || (checkCurrentDay(each.date) && checkListDisplayTime({slots: each.slots}))) {
                        bookingObj = {};
                        bookingObj.title = each.title;
                        bookingObj.description = each.description;
                        bookingObj.date = getFullDateFormat(new Date(each.date));
                        bookingObj.vendorId = each.vendorRef._id;
                        bookingObj.vendorName = each.vendorRef.personalInfo.name;
                        bookingObj.profileVisible = each.vendorRef.profileVisible !== undefined ? each.vendorRef.profileVisible : true;
                        bookingObj.availableSeats = each.availableSeats;
                        bookingObj.isBooked = each.isBooked ? each.isBooked : false;
                        bookingObj.startTime = getStartTime(each.slots);
                        bookingObj.endTime = getEndTime(each.slots);
                        bookingObj.images = [each.images[0].url];
                        bookingObj._id = each._id;
                        bookingObj.fee = each.eventFees;

                        if (globalConfigResult.data.data && globalConfigResult.data.data.promoCodeGeneral && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn && globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.event && each.vendorRef && each.vendorRef.promoCodeInfo && each.vendorRef.promoCodeInfo.allowDiscount && authData && authData.promoCodeInfo && authData.promoCodeInfo.institutionRef) {
                            bookingObj.discountedPrice = bookingObj.fee * ((100 - globalConfigResult.data.data.promoCodeGeneral.discountPercentageOn.event)/100);
                        }

                        localUpcomingBookings.push({...bookingObj});
                    }
                }
                
            });
        }
        
        updateUpcomingBooking([...localUpcomingBookings]);
    }, [checkListDisplayTime, isAuthorized, authData]);

    useEffect(() => {
        getAllUpcomingBookings();
    }, [getAllUpcomingBookings]);

    const changeSort = (sortConfig) => {
        toggleSort(sortConfig);
        let localSortConfig = {};
        if (sortConfig.sortValue !== 0) {
            localSortConfig[sortConfig.sortType] = sortConfig.sortValue;
        } else {
            localSortConfig = undefined;
        }
        getAllUpcomingBookings(localSortConfig);
    };

    useEffect(() => {
        window.onclick = (el) => {
            if (el.target &&
                el.target.className &&
                el.target.className.indexOf &&
                el.target.className.indexOf('dropdown-toggle') <= -1) {
                openSortPopup(false);
            }
        }
    });

    const openVendorProfile = (vendorProfile) => {
        if (vendorProfile.profileVisible) {
            props.history.push(`/professional-details/${vendorProfile.vendorId}/${vendorProfile.vendorName.split(" ").join("-")}`, {vendorId: vendorProfile.vendorId});
        }
    };

    const goBack = () => {
        props.history.goBack();
    };

    return (
        <div className={`row problem-area booking-list ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-12 categories-heading pos-relative">
                        <h4 className={`mb-0 problem-category-heading`}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: "10px", cursor: "pointer" }} onClick={goBack}></i> Events</h4>
                        {upcomingBookings.length ?
                        <ul className="navbar-nav custom-left-nav">
                            <li className={`nav-item dropdown ${popUpOpened ? 'show' : ''}`}>
                                <img src={require('../../../images/sort.png')} className="sort-down dropdown-toggle" style={{width: "40%", right: "20px", paddingRight: "0 !important"}} alt="sort down" onClick={() => openSortPopup(!popUpOpened)} />
                                <div className={`dropdown-menu ${popUpOpened ? 'show dropdown-menu-opened' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                    <span className={`dropdown-item ${sortDown.sortType === "" && sortDown.sortValue === 0 ? 'active' : ''}`} onClick={() => changeSort({ sortType: "", sortValue: 0 })}>Sort By Relevance</span>
                                    <span className={`dropdown-item ${sortDown.sortType === "fees" && sortDown.sortValue === -1 ? 'active' : ''}`} onClick={() => changeSort({sortType: "fees", sortValue: -1})}>Sort By Price (Desc)</span>
                                    <span className={`dropdown-item ${sortDown.sortType === "fees" && sortDown.sortValue === 1 ? 'active' : ''}`} onClick={() => changeSort({sortType: "fees", sortValue: 1})}>Sort By Price (Asc)</span>
                                </div>
                            </li>
                        </ul> : null}
                    </div>
                </div>
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-12">
                        <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                            {upcomingBookings.length ?
                                upcomingBookings.map((professional, index) => {
                                    return <div className={`col-sm-6 col-xs-12 professional-wrapper event-list-wrapper ${index !== upcomingBookings.length - 1 ? 'mb-30' : ''} ${index % 2 === 0 ? 'pl-0' : ''}
                                `} key={index}>
                                        <div className="row booking-item-wrapper event-list-item-wrapper" style={{ margin: 0 }}>
                                            <div className="event-list-image-wrapper">
                                                <div className="amount-image-wrapper" style={{ backgroundImage: "url(" + require('../../../images/amount-image.png') + ")", width: professional.discountedPrice ? '160px' : '110px', backgroundSize: 'cover', backgroundPosition: 'right'  }}> <i className="fas fa-rupee-sign"></i><span className={`${professional.discountedPrice ? 'text-strikethrough' : ''}`}>{professional.fee.toFixed(2)}</span> {professional.discountedPrice ? <> <i className="fas fa-rupee-sign ml-2"></i>{professional.discountedPrice.toFixed(2)}</>  : null} </div>
                                                <img src={professional.images[0]} className="event-list-img" alt={`event-${index}`} />
                                                {/* <div style={{ backgroundImage: "url(" + professional.images[1] + ")" }} className="event-list-image" /> */}
                                            </div>
                                                <div className="col-12 p-3 event-list-item-description-wrapper">
                                                    <div className="row m-0 booking-item-side-row">
                                                        <div className="col-12 pr-0 pl-0">
                                                            <div className="professional-name">{professional.title}</div>
                                                            <div>Created by <span className={`professional-amount text-underline ${professional.profileVisible ? 'cursor-pointer': ''}`} onClick={() => openVendorProfile(professional)}>{professional.vendorName}</span></div>
                                                            <div className="professional-date-time row m-0 booking-item-side-row mt-2">
                                                                <div className="col-12 pl-0">
                                                                    <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} /> {professional.date}</div>
                                                                </div>
                                                                <div className="col-6 pl-0 mt-2">
                                                                    <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px", marginBottom: "2px" }} alt="clock" /> {professional.startTime}</div>
                                                                </div>
                                                                <div className="col-6 pr-0">
                                                                    <button className="btn btn-primary details-button event-details-button" onClick={() => props.history.push(`/event-details/${professional._id}/${professional.vendorName.split(" ").join("-")}/${professional.title.split(" ").join("-")}`)}>{'View Details'}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                })
                                : <div className="text-center well col-12 color-black">
                                    {!loadingUpcomingEvents ? 'No Events Available!' : 'loading...'}
                    </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventList;