import React, { useEffect, useState } from 'react';
import "./forum.css";
import { updateForumAnswer, updateForumQuestion } from "../../crud/qa-forum.crud";
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../../config/error-message";
import { API_SUCCESS } from '../../config/success-message';

const AnswerModal = (props) => {
  const [myQuestion, updateMyQuestion] = useState("");
  const [disablePage, toggleDisablePage] = useState(false);

  const postQuestion = async () => {
    // return;
    toggleDisablePage(true);
    const askQuestionResult = props.editType === "question" ? 
    await updateForumQuestion(props.editableQuestion.qId, {
        question: myQuestion.trim()
    }) : await updateForumAnswer(props.editableAnswer.qId, props.editableAnswer._id, {
      answer: myQuestion.trim()
    });
    toggleDisablePage(false);
    if (askQuestionResult.data.errorCode) {
      toast.error(API_ERROR[askQuestionResult.data.errorCode]);
      return;
    }
    if (askQuestionResult.data.data && askQuestionResult.data.data.errorCode) {
      toast.error(API_ERROR[askQuestionResult.data.data.errorCode]);
      return;
    }

    toast.success(props.editType === "question" ? API_SUCCESS.UPDATE_QUESTION :API_SUCCESS.UPDATE_ANSWER);
    updateMyQuestion("");
    props.toggleModal(false);
    props.getQuestionDetails(props.editType === "question" ? props.editableQuestion.qId : props.editableAnswer.qId);
    if (props.editType === "question") {
        props.updateSelectedQuestionForEdit({});
    } else {
        props.updateSelectedAnswerForEdit({});
    }
    
  };

  const cancelEditAnswer = () => {
    props.toggleModal(false);
    if (props.editType === "question") {
        props.updateSelectedQuestionForEdit({});
    } else {
        props.updateSelectedAnswerForEdit({});
    }
  };

  useEffect(() => {
    if (props.displayModal) {
        updateMyQuestion(props.editType === "question" ? props.editableQuestion.question : props.editableAnswer.answerText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.displayModal]);

  return (
    <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
      <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit {props.editType === "question" ? "Question" : "Answer"}</h5>
            </div>
            <div className="modal-body pt-0">
              <div>
                <div className="form-group question-input mt-2">
                  <label htmlFor="exampleFormControlTextarea1">Update your {props.editType === "question" ? "question" : "answer"}:</label>
                  <textarea className="form-control question-textarea" id="exampleFormControlTextarea1" rows="3" placeholder={`Enter your ${props.editType === "question" ? "question" : "answer"}`} value={myQuestion} onChange={(e) => updateMyQuestion(e.target.value)}></textarea>
                </div>
              </div>

              <div className="col-12 login-button">
                <button type="button" className="btn btn-primary" disabled={!myQuestion.trim().length} onClick={postQuestion}>Save</button>
                <button type="button" className="btn btn-default" onClick={() => cancelEditAnswer()}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AnswerModal;