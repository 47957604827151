import React, { useEffect, useState } from 'react';
import "./problem-area.css";
import { getFAQList } from "../../crud/qa-forum.crud";

const FAQ = () => {
    const [faqList, updateFAQList] = useState([]);
    const [loadingQuestions, toggleLoadingQuestions] = useState(true);

    const selectAnswer = (item) => {
        const localProf = [...faqList];
        localProf.forEach(each => {

            if (each._id === item._id) {
                each.selected = !each.selected;
            } else {
                each.selected = false;
            }
        });
        updateFAQList([...localProf]);
    };


    const getAllFAQList = async () => {
        const faqResults = await getFAQList();
        toggleLoadingQuestions(false);
        if (faqResults.data.data && faqResults.data.data.data && faqResults.data.data.data.length) {
            let faqObj = {};
            const faqArr = [];
            faqResults.data.data.data.forEach(each => {
                faqObj = {};
                faqObj._id = each._id;
                faqObj.question = each.title;
                faqObj.answer = each.description;
                faqObj.selected = false;
                faqArr.push({ ...faqObj });
            });
            updateFAQList([...faqArr]);
        }
    };

    useEffect(() => {
        document.title = "Parentcraft India FAQ’s";
        getAllFAQList();
    }, []);

    return (
        <div className="row problem-area faq" style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className="col-12 categories-heading">
                    <h4 className={`mb-0 problem-category-heading`}>FAQ's</h4>
                </div>
                <div className="col-12">
                    <div className="row" style={{ margin: 0 }}>
                        {faqList.length ?
                            faqList.map((professional, index) => {
                                return <div className={`col-12 professional-wrapper
                                `} key={index}>
                                    <div className="row faq-wrapper-row" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            <div className={`faq-question ${professional.selected ? 'bor-bot' : ''}`} onClick={() => selectAnswer({ ...professional })}>
                                                {!professional.selected ?
                                                    <img style={{ width: "10px", marginRight: "10px", verticalAlign: "middle" }} src={require('../../../images/plus.png')} alt="expand row" /> :
                                                    <img style={{ width: "10px", marginRight: "10px", verticalAlign: "middle" }} src={require('../../../images/minus.png')} alt="collapse row" />}
                                                <div className="question-text">{professional.question}</div>
                                            </div>
                                            {!professional.selected ?
                                                null :
                                                <div className="professional-location answer">
                                                    <div>{professional.answer.split('\n').map((item, key) => {
                                                        return <span key={key}>{item}<br /></span>
                                                    })}</div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingQuestions ? 'No Questions Available!' : 'loading...'}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ;