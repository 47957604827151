import React, { useEffect, useState, useCallback } from 'react';
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import { getWithoutAuthBookingDetails, rateBook, getSessionDetailsWithoutAuth, rateEvent, getEventBookingDetailsWithoutAuth, rateOnlineEvent, getWorkshopBookingDetailsWithoutAuth, rateWorkshop } from "../../crud/booking.crud";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";
import {
    timeArrConst,
    Config,
    monthArr,
    convertTwelveToZeroAM
} from "../../config/constants";
import { API_SUCCESS } from '../../config/success-message';
import NoReviewConfirmation from './no-review-confirmation';

const defaultImage = require('../../../images/imgAvtr.png');

const timeArr = [...timeArrConst];

const RatingReview = (props) => {

    const [eventType, updateEventType] = useState("");
    const [selectedProfile, selectProfile] = useState({});
    const [bookingDetails, updateBookingDetails] = useState({});
    const [pageInitialized, updatePageInitialization] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [displayNoReviewModal, toggleNoReview] = useState(false);
    const [stars, updateRatingStars] = useState([{
        selected: false
    },{
        selected: false
    },{
        selected: false
    },{
        selected: false
    },{
        selected: false
    }]);
    const [ratingObj, updateRatingObj] = useState({
        rating: 0,
        review: ""
    });

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();

        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = convertTwelveToZeroAM(timeArr[times[0] - 1]);
        const endTime = convertTwelveToZeroAM(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0]);
        return `${startTime} - ${endTime}`;
    };

    const getBookingData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getWithoutAuthBookingDetails(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorRef.personalInfo.name,
            profilePicture: resultData.vendorRef.personalInfo.profilePicture,
            description: resultData.vendorRef.personalInfo.description,
            designation: "",
            bookingId: resultData._id,
            experience: "",
            email: resultData.vendorRef.personalInfo.email,
            websiteUrl: resultData.vendorRef.personalInfo.website,
            addressTitle: resultData.serviceRef.title,
            rating: resultData.ratingDetails ? resultData.ratingDetails.rating : 0, // needs to be updated with response data
            descriptionImages: [],
            services: [{
                location: resultData.serviceRef.location
            }],
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.paymentDetails.refundAmount ? resultData.paymentDetails.refundAmount : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.slots,
            status: resultData.status
        };
        localProfessionalObj.slotDuration = getDuration(resultData.slots);
        localProfessionalObj.slotDate = getFullDateFormat(new Date(resultData.date));
        if (resultData.vendorRef.personalInfo.experience.year) {
            const year = resultData.vendorRef.personalInfo.experience.year;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
        }
        if (resultData.vendorRef.personalInfo.categories && resultData.vendorRef.personalInfo.categories.length) {
            resultData.vendorRef.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorRef.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.serviceRef ? resultData.serviceRef.title : "";
        localProfessionalObj.description = resultData.serviceRef ? resultData.serviceRef.description : "";
        updateBookingDetails({ ...localProfessionalObj });
    }, []);

    const getSessionData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getSessionDetailsWithoutAuth(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorRef.personalInfo.name,
            profilePicture: resultData.vendorRef.personalInfo.profilePicture,
            description: resultData.vendorRef.personalInfo.description,
            designation: "",
            bookingId: resultData._id,
            experience: "",
            email: resultData.vendorRef.personalInfo.email,
            websiteUrl: resultData.vendorRef.personalInfo.website,
            rating: resultData.ratingDetails ? resultData.ratingDetails.rating : 0, // needs to be updated with response data
            descriptionImages: [],
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.paymentDetails.refundAmount ? resultData.paymentDetails.refundAmount : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.eventRef.slots,
            status: resultData.status
        };
        localProfessionalObj.slotDuration = getDuration(resultData.eventRef.slots);
        localProfessionalObj.slotDate = getFullDateFormat(new Date(resultData.date));
        if (resultData.vendorRef.personalInfo.experience.year) {
            const year = resultData.vendorRef.personalInfo.experience.year;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
        }
        if (resultData.vendorRef.personalInfo.categories && resultData.vendorRef.personalInfo.categories.length) {
            resultData.vendorRef.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorRef.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.eventRef ? resultData.eventRef.title : "";
        localProfessionalObj.description = resultData.eventRef ? resultData.eventRef.description : "";
        updateBookingDetails({ ...localProfessionalObj });
    }, []);

    const getEventData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getEventBookingDetailsWithoutAuth(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorRef.personalInfo.name,
            profilePicture: resultData.vendorRef.personalInfo.profilePicture,
            description: resultData.vendorRef.personalInfo.description,
            designation: "",
            bookingId: resultData._id,
            experience: "",
            email: resultData.vendorRef.personalInfo.email,
            websiteUrl: resultData.vendorRef.personalInfo.website,
            rating: resultData.ratingDetails ? resultData.ratingDetails.rating : 0, // needs to be updated with response data
            descriptionImages: [],
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.paymentDetails.refundAmount ? resultData.paymentDetails.refundAmount : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.eventRef.slots,
            status: resultData.status
        };
        localProfessionalObj.slotDuration = getDuration(resultData.eventRef.slots);
        localProfessionalObj.slotDate = getFullDateFormat(new Date(resultData.date));
        if (resultData.vendorRef.personalInfo.experience.year) {
            const year = resultData.vendorRef.personalInfo.experience.year;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
        }
        if (resultData.vendorRef.personalInfo.categories && resultData.vendorRef.personalInfo.categories.length) {
            resultData.vendorRef.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorRef.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.eventRef ? resultData.eventRef.title : "";
        localProfessionalObj.description = resultData.eventRef ? resultData.eventRef.description : "";
        updateBookingDetails({ ...localProfessionalObj });
    }, []);

    const getWorkshopData = useCallback(async (id) => {
        toggleDisablePage(true);
        const bookingResult = await getWorkshopBookingDetailsWithoutAuth(id);
        toggleDisablePage(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }
        const resultData = bookingResult.data.data;

        const localProfessionalObj = {
            name: resultData.vendorRef.personalInfo.name,
            profilePicture: resultData.vendorRef.personalInfo.profilePicture,
            description: resultData.vendorRef.personalInfo.description,
            designation: "",
            bookingId: resultData._id,
            experience: "",
            email: resultData.vendorRef.personalInfo.email,
            websiteUrl: resultData.vendorRef.personalInfo.website,
            rating: resultData.ratingDetails ? resultData.ratingDetails.rating : 0, // needs to be updated with response data
            descriptionImages: [],
            cost: resultData.paymentDetails.serviceFee,
            convenienceFee: resultData.paymentDetails.convenienceFee,
            refundAmount: resultData.paymentDetails.refundAmount ? resultData.paymentDetails.refundAmount : 0,
            gst: resultData.paymentDetails.gst,
            total: resultData.paymentDetails.total,
            slots: resultData.workshopRef.slots,
            status: resultData.status
        };
        localProfessionalObj.slotDuration = getDuration(resultData.workshopRef.slots);
        localProfessionalObj.slotDate = `${getFullDateFormat(new Date(resultData.workshopRef.dates[0]))} - ${getFullDateFormat(new Date(resultData.workshopRef.dates[resultData.workshopRef.dates.length - 1]))}`;
        if (resultData.vendorRef.personalInfo.experience.year) {
            const year = resultData.vendorRef.personalInfo.experience.year;
            if (year) {
                localProfessionalObj.experience += `${year} year${year > 1 ? 's' : ''}`;
            }
        }
        if (resultData.vendorRef.personalInfo.categories && resultData.vendorRef.personalInfo.categories.length) {
            resultData.vendorRef.personalInfo.categories.forEach((item, index) => {
                localProfessionalObj.designation += item.title;
                if (index !== resultData.vendorRef.personalInfo.categories.length - 1) {
                    localProfessionalObj.designation += ", ";
                }
            });
        }

        selectProfile({ ...localProfessionalObj });
        localProfessionalObj.name = resultData.workshopRef ? resultData.workshopRef.title : "";
        localProfessionalObj.description = resultData.workshopRef ? resultData.workshopRef.description : "";
        updateBookingDetails({ ...localProfessionalObj });
    }, []);

    useEffect(() => {
        if (!pageInitialized) {
            updatePageInitialization(true);
            if (props.match.params.bookingId) {
                if (window.location.pathname.includes("workshop")) {
                    getWorkshopData(props.match.params.bookingId);
                    updateEventType("workshop");
                } else if (window.location.pathname.includes("online-event")) {
                    getEventData(props.match.params.bookingId);
                    updateEventType("event");
                } else if (!window.location.pathname.includes("event")) {
                    getBookingData(props.match.params.bookingId);
                    updateEventType("service");
                }  else {
                    getSessionData(props.match.params.bookingId);
                    updateEventType("session");
                }
            }
        }
    }, [props.match.params, pageInitialized, getBookingData, getSessionData, getEventData, getWorkshopData]);

    // const copyEmailToClipboard = () => {
    //     const tempInput = document.createElement("input");
    //     tempInput.value = selectedProfile.email;
    //     document.body.appendChild(tempInput);
    //     tempInput.select();
    //     document.execCommand("copy");
    //     document.body.removeChild(tempInput);

    //     const tooltip = document.getElementById("myTooltip");
    //     tooltip.innerHTML = "Email Copied";
    // };

    // const emailMouseOut = () => {
    //     const tooltip = document.getElementById("myTooltip");
    //     tooltip.innerHTML = "Copy to clipboard";
    // };

    const openMapLocation = () => {
        const coordinates = selectedProfile.services[0].location.coordinates;
        window.open(`https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`);
    };

    const rate = (index) => {
        const localStars = [...stars];
        const localRatingObj = {...ratingObj};
        localStars.forEach((each, ind) => {
            if (ind <= index) {
                each.selected = true;
            } else {
                each.selected = false;
            }
        });
        localRatingObj.rating = index + 1;
        updateRatingObj({...localRatingObj});
        updateRatingStars([...localStars]);
    };

    const resetRatingReview = () => {
        const localStars = [...stars];
        const localRatingObj = {...ratingObj};
        localStars.forEach((each) => {
            each.selected = false;
        });
        localRatingObj.rating = 0;
        localRatingObj.review = "";
        updateRatingObj({...localRatingObj});
        updateRatingStars([...localStars]);
    };

    const changeReviewText = (text) => {
        const localRatingObj = {...ratingObj};
        localRatingObj.review = text;
        updateRatingObj({...localRatingObj});
    };

    const submitRatingReview = async (isFromConfirmation) => {
        const reqBody = {
            rating: ratingObj.rating,
            feedback: ratingObj.review.trim()
        };
        if (!reqBody.feedback && !isFromConfirmation) {
            toggleNoReview(true);
            return;
        }
        // return;
        toggleDisablePage(true);
        let rateResult;
        if (eventType === "service") {
            rateResult = await rateBook(props.match.params.bookingId, reqBody);
        } else if (eventType === "session") {
            rateResult = await rateEvent(props.match.params.bookingId, reqBody);
        } else if (eventType === "workshop") {
            rateResult = await rateWorkshop(props.match.params.bookingId, reqBody);
        } else {
            rateResult = await rateOnlineEvent(props.match.params.bookingId, reqBody);
        }
        
        toggleDisablePage(false);
        if (rateResult.data.errorCode) {
            toast.error(API_ERROR[rateResult.data.errorCode]);
            return;
        }
        if (rateResult.data.data && rateResult.data.data.errorCode) {
            toast.error(API_ERROR[rateResult.data.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.FEEDBACK_SUCCESS);
        if (eventType === "service") {
            getBookingData(props.match.params.bookingId);
        } else if (eventType === "session") {
            getSessionData(props.match.params.bookingId);
        } else if (eventType === "workshop") {
            getWorkshopData(props.match.params.bookingId);
        } else {
            getEventData(props.match.params.bookingId);
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {displayNoReviewModal ? 
            <NoReviewConfirmation displayNoReviewModal={displayNoReviewModal}
            toggleNoReview={toggleNoReview}
            submitReview={submitRatingReview} /> : null}
            <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                {selectedProfile.name ?
                    <div className="col-lg-12">
                        <div className="col-12 categories-heading">
                            <h4 className={`mb-0`}>Rating & Feedback</h4>
                        </div>
                        <div className="col-12">
                            <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-md-5 col-xs-12 pr-20 left-booking-item">
                                            <div className="row m-0">
                                                <div className="profile-image-wrapper p-0">
                                                    {selectedProfile.profilePicture ?
                                                        <div style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }} className="profile-image" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="profile-image" />}
                                                </div>
                                                <div className="profile-other-details profile-name-wrapper">
                                                    <div className="profile-name">{selectedProfile.name}</div>
                                                    <div className="profile-designation">{selectedProfile.designation}</div>
                                                    {eventType === "service" ?
                                                    <div className="profile-address-title">{selectedProfile.addressTitle}</div> : null}
                                                    <div className="profile-icon-wrapper">
                                                        {/* {selectedProfile.email ?
                                                            <div className="tooltip">
                                                                <i className="fa fa-envelope" aria-hidden="true" onClick={copyEmailToClipboard} onMouseLeave={emailMouseOut}>

                                                                </i>
                                                                <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                                            </div> : null} */}
                                                        {selectedProfile.websiteUrl ?
                                                            <img src={require('../../../images/globe.svg')} alt="website" onClick={() => window.open(selectedProfile.websiteUrl)} /> : null}
                                                        {selectedProfile && selectedProfile.services && selectedProfile.services.length ?
                                                            <img src={require('../../../images/location.svg')} alt="location" onClick={openMapLocation} /> : null}
                                                    </div>
                                                    <div className="profile-exp">Experience - {selectedProfile.experience}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-xs-12 pl-20 pr-0 booking-gallery">
                                            {!selectedProfile.rating ?
                                            <div className="row m-0 profile-date-time">
                                                <div className="label col-12 p-0">
                                                    How would you rate our service?
                                                </div>
                                                    <div className="profile-rating-wrapper col-12 p-0">
                                                        {stars.map((star, index) => {
                                                            return <i key={index} className={`fa-star rate-star ${!star.selected ? 'far' : 'fas'}`} onClick={() => rate(index)}></i>
                                                        })}
                                                    </div>
                                                    <div className="form-group question-input col-12 p-0">
                                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Type your review ..." value={ratingObj.review} onChange={e => changeReviewText(e.target.value)}></textarea>
                                                    </div>

                                                <div className="col-12 appointment-details-button-wrapper text-center rating-review-buttons">
                                                    <button type="button" disabled={!ratingObj.rating} className="btn btn-primary" onClick={() => submitRatingReview(false)}>Submit</button>
                                                    <button type="button" className="btn btn-outline-primary" onClick={resetRatingReview}>Reset</button>
                                                </div>
                                            </div> : <div className="text-center profile-amount well col-12 color-black pt-3">
                                                Feedback already provided!
                                            </div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0">
                                    {Object.keys(bookingDetails).length ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 pl-0 pr-0 service-details-wrapper">
                                                <div className="profile-name">{bookingDetails.name}</div>
                                                <div className="profile-service-desc">{bookingDetails.description}</div>
                                                <div className="profile-date-time row ml-0 mr-0 mb-3">
                                                    <div className="col-md-4 col-6 pl-0">
                                                        <div className="label">Selected date</div>
                                                        <div><img src={require('../../../images/calendar.png')} alt="calendar" style={{ width: "17px", marginRight: "3px" }} /> {bookingDetails.slotDate}</div>
                                                    </div>
                                                    <div className="col-md-4 col-6 pl-0">
                                                        <div className="label">Time slot</div>
                                                        <div><img src={require('../../../images/clock.svg')} style={{ width: "17px", marginRight: "3px" }} alt="clock" /> {bookingDetails.slotDuration}</div>
                                                    </div>
                                                </div>
                                                <div className="w-100">
                                                    <div className="fee-text bor-right pl-0">Service Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.cost.toFixed(2)}</div>
                                                    <div className="fee-text bor-right">Convenience Fee: <i className="fas fa-rupee-sign"></i>{bookingDetails.convenienceFee.toFixed(2)}</div>
                                                    <div className="fee-text bor-right">GST: <i className="fas fa-rupee-sign"></i>{bookingDetails.gst.toFixed(2)}</div>
                                                    <div className="fee-text">Total: <i className="fas fa-rupee-sign"></i>{bookingDetails.total.toFixed(2)}</div>
                                                </div>
                                                <div className="profile-amount row ml-0 mr-0">
                                                    {eventType === "service" ?
                                                    `Status: ${Config.Booking.NumberStatus[selectedProfile.status]}` : `Status: ${Config.Event.NumberStatus[selectedProfile.status]}`}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="text-center well col-12 color-black pt-3">
                        loading...
                    </div>}
            </div>
        </MuiPickersUtilsProvider>
    )
};

export default injectIntl(
    connect(
        null,
        auth.actions
    )(RatingReview)
);