import React, { useEffect, useState } from "react";
import { MyLocation } from '@material-ui/icons';
import { toast } from 'react-toastify';
import './dashboard.css';

let map;
let myLatLng;
let geocoder;
let marker;
let backupFilterObj = {
    location: "",
    radius: "20",
    appointmentType: [],
    daysOfWeek: [],
    categories: []
};
let backupCoordinate = [];
let isApplyClicked = false;

const Sidebar = (props) => {
    const [filterObj, updateFilterObj] = useState({
        location: "",
        radius: "20",
        appointmentType: [],
        daysOfWeek: [],
        categories: []
    });
    const [selectedCoordinates, updateCoordinate] = useState([]);
    // const [radiusLeftValue, changeLeftValue] = useState(40);

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
        updateFilterObj({
            ...backupFilterObj
        });
        console.log("backupCoordinate ", backupCoordinate, backupFilterObj)
        if (backupCoordinate.length) {
            document.getElementById("location-search-input").value = backupFilterObj.location;
        } else {
            document.getElementById("location-search-input").value = "";
        }
        updateCoordinate(backupCoordinate);
        if (props.fromLower && !isApplyClicked) {
            props.focusSearch(false);
        }
        isApplyClicked = false;
        props.closeNav();
    };

    const applyFilter = () => {
        const reqBody = {};

        if (filterObj.appointmentType.length && filterObj.appointmentType.length !== 2) {
            reqBody.serviceType = filterObj.appointmentType[0];
            reqBody.appoTypes = filterObj.appointmentType;
        }

        if (filterObj.daysOfWeek.length) {
            reqBody.daysOfWeek = filterObj.daysOfWeek;
        }

        if (filterObj.categories.length) {
            reqBody.categoryIds = filterObj.categories;
        }

        if (selectedCoordinates.length) {
            reqBody.radius = Number(filterObj.radius) * 1000;
            reqBody.coordinates = selectedCoordinates;
            reqBody.location = filterObj.location;
        }

        backupFilterObj = JSON.parse(JSON.stringify(filterObj));
        backupCoordinate = selectedCoordinates;
        isApplyClicked = true;
        props.applyFilter(reqBody);
        closeNav();
    };

    useEffect(() => {
        if (props.allFilterObj && Object.keys(props.allFilterObj).length) {

            const propsFilter = JSON.parse(JSON.stringify(props.allFilterObj));

            const localFilter = {
                location: "",
                radius: "20",
                appointmentType: [],
                daysOfWeek: [],
                categories: []
            };
            if (propsFilter.appoTypes && propsFilter.appoTypes.length) {
                localFilter.appointmentType = propsFilter.appoTypes;
            }

            if (propsFilter.daysOfWeek && propsFilter.daysOfWeek.length) {
                localFilter.daysOfWeek = propsFilter.daysOfWeek;
            }

            if (propsFilter.categoryIds && propsFilter.categoryIds.length) {
                localFilter.categories = propsFilter.categoryIds;
            }

            if (propsFilter.coordinates && propsFilter.coordinates.length) {
                localFilter.radius = (Number(propsFilter.radius) / 1000).toFixed(0);
                changeRadius({ target: { value: localFilter.radius } });
                localFilter.coordinates = propsFilter.coordinates;
                localFilter.location = propsFilter.location;
                document.getElementById("location-search-input").value = propsFilter.location;
                updateCoordinate(propsFilter.coordinates);
            }

            updateFilterObj({ ...localFilter });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.allFilterObj]);

    const initAutocomplete = () => {
        updateCoordinate([]);
        if (window.google) {
            const input = document.getElementById("location-search-input");
            const searchBox = new window.google.maps.places.Autocomplete(input);

            searchBox.setComponentRestrictions({
                country: ["in"],
            });
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });
            searchBox.addListener("place_changed", () => {
                const place = searchBox.getPlace();

                if (!place || (place && !Object.keys(place).length)) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds();
                // places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    return;
                }

                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }

                myLatLng = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                updateCoordinate([place.geometry.location.lng(), place.geometry.location.lat()]);
                updateFilterObj({
                    ...filterObj,
                    location: place.name || place.formatted_address
                });

                document.getElementById("location-search-input").value = place.name || place.formatted_address;

                map.fitBounds(bounds);
            });
        }

    };

    const setMarker = (pos) => {
        marker = new window.google.maps.Marker({
            position: pos,
            map,
            draggable: true
        });
        geocodePosition(marker.getPosition());
    };

    const initMap = (fromEffect) => {
        setTimeout(() => {
            if (window.google) {
                geocoder = new window.google.maps.Geocoder();
                myLatLng = {};
                if (props.userLocation && props.userLocation.length) {
                    myLatLng = { lat: props.userLocation[1], lng: props.userLocation[0] }
                }
                if (Object.keys(myLatLng).length) {
                    map = new window.google.maps.Map(document.getElementById("mapAddress"), {
                        center: myLatLng,
                        zoom: 18,
                        mapTypeId: "roadmap"
                    });

                    if (!fromEffect) {
                        setMarker(myLatLng);
                    }
                }
            }
        }, 100);
    };

    const chooseCurrentUserLocation = () => {
        if (props.userLocation.length) {
           initMap(); 
        } else {
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition((position) => {
                    if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                        // updateCoordinate([position.coords.longitude, position.coords.latitude]);
                        myLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
                        setMarker(myLatLng);
                    }
                }, (error) => {
                    if (error.code === 1) {
                        toast.error("Not able access your current location. If you have denied the permission then please refresh the page or Please change the location access from your browser's settings");
                    }
                });
            } else {
                toast.error("Geolocation is not supported by this browser.");
            }
        }
    };

    const setAppointmentType = (e) => {
        const localFilterObj = { ...filterObj };
        if (!localFilterObj.appointmentType.includes(Number(e.target.value))) {
            localFilterObj.appointmentType.push(Number(e.target.value));
        } else {
            localFilterObj.appointmentType.splice(localFilterObj.appointmentType.indexOf(Number(e.target.value)), 1);
        }
        updateFilterObj({ ...localFilterObj });
    };

    const setDay = (e) => {
        const localFilterObj = { ...filterObj };
        if (e.target.value === "0") {
            localFilterObj.daysOfWeek = localFilterObj.daysOfWeek.length === 7 ? [] : [1, 2, 3, 4, 5, 6, 7];
        } else {
            if (!localFilterObj.daysOfWeek.includes(Number(e.target.value))) {
                localFilterObj.daysOfWeek.push(Number(e.target.value));
            } else {
                localFilterObj.daysOfWeek.splice(localFilterObj.daysOfWeek.indexOf(Number(e.target.value)), 1);
            }
        }

        updateFilterObj({ ...localFilterObj });
    };

    const setCategories = (e) => {
        const localFilterObj = { ...filterObj };
        if (!localFilterObj.categories.includes(e.target.value)) {
            localFilterObj.categories.push(e.target.value);
        } else {
            localFilterObj.categories.splice(localFilterObj.categories.indexOf(e.target.value), 1);
        }
        updateFilterObj({ ...localFilterObj });
    };

    const changeRadius = (e) => {
        const localFilterObj = { ...filterObj };
        localFilterObj.radius = e.target.value;
        updateFilterObj({ ...localFilterObj });

        // let valueToMinus = 3;
        // if (Number(e.target.value) > 19) {
        //     valueToMinus = 5;
        // } else if (Number(e.target.value) > 31) {
        //     valueToMinus = 12;
        // }
        // changeLeftValue(Number((((Number(e.target.value) - 5)/ 35)*100).toFixed(0)) - valueToMinus);
    };

    const commonReset = () => {
        updateFilterObj({
            location: "",
            radius: "20",
            appointmentType: [],
            daysOfWeek: [],
            categories: []
        });


        // may be removed
        backupFilterObj = ({
            location: "",
            radius: "20",
            appointmentType: [],
            daysOfWeek: [],
            categories: []
        });

        document.getElementById("location-search-input").value = "";
        updateCoordinate([]);
        backupCoordinate = [];

    };

    const resetFilter = () => {
        commonReset();
        props.applyFilter({});
        closeNav();
    };

    useEffect(() => {
        if (props.isReset) {
            commonReset(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isReset]);

    const clearPlace = () => {
        document.getElementById("location-search-input").value = "";
        updateCoordinate([]);
    };

    const geocodePosition = (pos) => {
        let localPos = [pos.lng(), pos.lat()];
        let address = "";
        if (props.allFilterObj && Object.keys(props.allFilterObj).length) {
            localPos = [];
            if (props.allFilterObj.coordinates && props.allFilterObj.coordinates.length) {
                localPos = [props.allFilterObj.coordinates[1], props.allFilterObj.coordinates[0]];
                address = props.allFilterObj.location;
            };
        }

        if (localPos.length) {
            geocoder.geocode({
                latLng: pos
            }, (responses) => {
                if (responses && responses.length > 0) {
                    updateCoordinate(localPos);
                    updateFilterObj({
                        ...filterObj,
                        location: address || responses[0].formatted_address
                    });
                    // backupCoordinate = localPos;
                    // backupFilterObj.location = address || responses[0].formatted_address;
                    document.getElementById("location-search-input").value = address || responses[0].formatted_address;
                } else {
                }
            });
        } else {
            updateCoordinate(localPos);
            updateFilterObj({
                ...filterObj,
                location: ""
            });
            backupCoordinate = localPos;
            backupFilterObj.location = "";
            document.getElementById("location-search-input").value = "";
        }

    };

    useEffect(() => {
        initMap(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userLocation]);

    return <div id="mySidenav" className="sidenav row m-0">
        <div id="mapAddress" style={{
            height: "0px"
        }} />
        <div className="col-12" style={{ padding: "0 20px 0 50px" }}>
            <span className='filter-header'>Filter</span> <span className="closebtn" onClick={closeNav}><i className="fas fa-arrow-left"></i></span><span className="reset-link" onClick={resetFilter}>Reset</span>
        </div>
        <div className="col-12 filter-body" style={{ padding: "0 20px" }}>
            <div className="row m-0 common-wrapper">
                <div className="col-12 common-background-wrapper">
                    <div className="form-group">
                        <label htmlFor="location-search-input" className="section-header">Location</label>
                        <input type="text" id="location-search-input" style={{ borderColor: "#bcdcfa", paddingRight: "25px" }} className="form-control" placeholder="Search by location" onChange={e => { initAutocomplete(); }} />

                        {filterObj.location ?
                            <span className="clear-location" onClick={clearPlace}>&times;</span> : <span className="clear-location" style={{ fontSize: "16px", top: "47px" }}><i className='fa fa-search'></i></span>}
                        <div  onClick={() => { chooseCurrentUserLocation() }} className="current-loc"><MyLocation /><span className="ml-1"> Use My Current Location </span> </div>
                    </div>
                    
                    <div className="form-group radius-range mb-1">
                        <label htmlFor="radiusRange" className="section-header">Radius (KM)</label>
                        <div className="radius-val">
                            <input type="range" value={filterObj.radius} onChange={changeRadius} min="5" max="40" className="form-control-range" id="radiusRange" disabled={!selectedCoordinates.length} />
                            {/* <span className="radius-valtext" style={{
                                left: `${radiusLeftValue}%`
                            }}>{filterObj.radius}</span> */}
                        </div>

                        <span>{filterObj.radius}km</span>
                        <span className="pull-right">40km+</span>
                    </div>
                </div>
            </div>
            <div className="row m-0 common-wrapper">
                <div className="col-12 common-background-wrapper">
                    <label className="section-header">Categories</label> <br />
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            {props?.categories?.length ?
                                props.categories.map(cat => {
                                    return <div className="form-check mb-2" key={cat.name}>
                                        <input className="form-check-input" type="checkbox" name="category" id={cat.name} value={cat._id} onChange={setCategories} checked={filterObj?.categories?.includes(cat._id)} />
                                        <label className="form-check-label ml-2" htmlFor={cat.name}>{cat.name}{cat.isPlural ? 's' : ''}</label>
                                    </div>
                                }) : null}

                        </div>
                    </div>

                </div>
            </div>
            <div className="row m-0 common-wrapper">
                <div className="col-12 common-background-wrapper">
                    <label className="section-header">Availability</label> <br />
                    <div className="row m-0 checkbox-wrapper">
                        <div className="col-12 p-0">
                            <div className="form-check mb-1" style={{
                                position: "absolute",
                                top: "-25px",
                                right: 0
                            }}>
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.length === 7} id="All" value="0" />
                                <label className="form-check-label ml-1" htmlFor="All">Select All</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.includes(1)} id="Mon" value="1" />
                                <label className="form-check-label" htmlFor="Mon">Mon</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.includes(2)} id="Tue" value="2" />
                                <label className="form-check-label" htmlFor="Tue">Tue</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.includes(3)} id="Wed" value="3" />
                                <label className="form-check-label" htmlFor="Wed">Wed</label>
                            </div>
                            <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.includes(4)} id="Thu" value="4" />
                                <label className="form-check-label" htmlFor="Thu">Thu</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.includes(5)} id="Fri" value="5" />
                                <label className="form-check-label" htmlFor="Fri">Fri</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.includes(6)} id="Sat" value="6" />
                                <label className="form-check-label" htmlFor="Sat">Sat</label>
                            </div>
                            <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentDay" onChange={setDay} checked={filterObj.daysOfWeek.includes(7)} id="Sun" value="7" />
                                <label className="form-check-label" htmlFor="Sun">Sun</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row m-0 common-wrapper">
                <div className="col-12 common-background-wrapper">
                    <label className="section-header">Appointment Type</label> <br />
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentType" value="1" onChange={setAppointmentType} checked={filterObj?.appointmentType?.includes(1)} id="Video" />
                                <label className="form-check-label" htmlFor="Video">Video Consultation</label>
                            </div>
                            <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="appointmentType" value="2" onChange={setAppointmentType} checked={filterObj?.appointmentType?.includes(2)} id="Clinic" />
                                <label className="form-check-label" htmlFor="Clinic">Clinic Visit</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row m-0 apply-wrapper">
                <div className="col-12 p-0 text-center">
                    <button className="btn btn-primary" onClick={applyFilter}>APPLY</button>
                </div>
            </div>
        </div>
    </div>
};

export default Sidebar;