import React from 'react';
import "./forum.css";

const TestInfoModal = (props) => {

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="col-12 pt-3 pb-3">
                                <div className='row m-0'>
                                    <div className="col-12 category-arrows">
                                        <i className="fas fa-times close-modal pull-right mt-0" onClick={() => props.toggleInfoModal(false)}></i>
                                        <h5 className='pre-confirmation-header'>Info</h5>
                                        <div className='row m-0'>
                                            All assessments are curated according to the assessee's age. Please provide proper age to get the best result.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TestInfoModal;