import React from 'react';
import "./forum.css";

const TestCategoryModal = (props) => {

    return (
        <div className={`row forum test-modal`} style={{ margin: 0 }}>
            <div className={`modal ask-question-modal fade ${props.displayModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                            <div className="modal-body">
                                <div className="col-12 pt-3 pb-3">
                                    <div className='row m-0'>
                                        <div className="col-12 category-arrows">
                                            {window.location.search.includes("testCategoryId") ?
                                            <i className="fas fa-times close-modal pull-right mt-0" onClick={() => props.toggleTestModal(false)}></i> : <i className="fas fa-times close-modal pull-right mt-0" onClick={() => props.backToPrev()}></i>}
                                            <h5 className='pre-confirmation-header'>Self Assessment Categories</h5>
                                            <div className='row m-0 scrollable-modal'>
                                                {props.categoryList.map((category, index) => {
                                                    return <div className={`test-category-item col-12 ${index === props.categoryList.length - 1 ? 'no-border pb-0' : ''}`} key={category._id} onClick={() => props.chooseCategory(category)}>
                                                        <i className="fas fa-arrow-right"></i>{category.title}
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TestCategoryModal;