import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";
import { getProblemCategoryDetails, markFavouriteProblem } from "../../crud/qa-forum.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";
import {
    API_SUCCESS
} from "../../config/success-message";

import FontModal from './font-modal';

const questionFontSizeConfig = {
    "1": "18px",
    "2": "20px",
    "3": "22px"
};

const answerFontSizeConfig = {
    "1": "16px",
    "2": "18px",
    "3": "20px"
};

const ProblemAreaDetails = (props) => {
    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
            authData: auth.user
        }),
        shallowEqual
    );
    const [problemAreaDetails, updateDetails] = useState({});
    const [disablePage, toggleDisablePage] = useState(false);
    const [fontModal, toggleFontModal] = useState(false);
    const [currentFontSize, updateCurrentFontSize] = useState("1");

    const getAllProblemCategoryDetails = useCallback(async (id) => {
        toggleDisablePage(true);
        const problemAreaResult = await getProblemCategoryDetails(id);

        toggleDisablePage(false);
        if (problemAreaResult.data.errorCode) {
            toast.error(API_ERROR[problemAreaResult.data.errorCode]);
            return;
        }
        if (problemAreaResult.data.data && problemAreaResult.data.data.errorCode) {
            toast.error(API_ERROR[problemAreaResult.data.data.errorCode]);
            return;
        }

        const problemAreaObj = {};
        if (problemAreaResult.data.data) {
            const localRes = problemAreaResult.data.data;
            problemAreaObj.isFavourite = authData && localRes.favouritedBy && localRes.favouritedBy.includes(authData._id) ? true : false;
            if (localRes.languageSet && localRes.languageSet.length) {
                localRes.languageSet.forEach(each1 => {
                    if (each1.languageRef.code === props.match.params.lang) {
                        problemAreaObj._id = localRes._id;
                        problemAreaObj.question = each1.title;
                        problemAreaObj.answer = each1.description;
                        problemAreaObj.highlightedAnswer = each1.highlightedAnswer;
                        problemAreaObj.audio = each1.audioFile;
                    }
                    if (each1.languageRef.name.trim().toLowerCase() === "english") {
                        let problemTitle = each1.title.slice(each1.title.indexOf(". ") > -1 ? each1.title.indexOf(". ")+2 : 0).slice(0, 150);
                        document.title = localRes.problemCategoryRef && localRes.problemCategoryRef._id !== "60883f8d1069d58676cb0671" ? problemTitle : `${problemTitle} Important Definition`;
                    }
                });

                if (!problemAreaObj.question) {
                    localRes.languageSet.forEach(each1 => {
                        if (each1.languageRef.name.trim().toLowerCase() === "english") {
                            problemAreaObj._id = localRes._id;
                            problemAreaObj.question = each1.title;
                            problemAreaObj.answer = each1.description;
                            problemAreaObj.highlightedAnswer = each1.highlightedAnswer;
                            problemAreaObj.audio = each1.audioFile;
                        }
                    });
                }
            }

            if (localRes.problemCategoryRef &&
                localRes.problemCategoryRef.languageSet &&
                localRes.problemCategoryRef.languageSet.length) {
                localRes.problemCategoryRef.languageSet.forEach(each1 => {
                    if (each1.languageRef.code === props.match.params.lang) {
                        problemAreaObj.categoryName = each1.title;
                    }
                });

                if (!problemAreaObj.categoryName) {
                    localRes.problemCategoryRef.languageSet.forEach(each1 => {
                        if (each1.languageRef.name.trim().toLowerCase() === "english") {
                            problemAreaObj.categoryName = each1.title;
                        }
                    });
                }
            }
        }
        updateDetails({ ...problemAreaObj });
    }, [props.match.params, authData]);

    useEffect(() => {
        if (props.match.params.id) {
            if (sessionStorage.getItem("currentFont")) {
                updateCurrentFontSize(sessionStorage.getItem("currentFont"));
            }
            getAllProblemCategoryDetails(props.match.params.id);
        }

    }, [props.match.params, getAllProblemCategoryDetails]);

    const clickFav = async () => {
        if (!isAuthorized) {
            toast.info("Please login to make the professional favourite");
            setTimeout(() => {
                const loginButton = document.getElementById("login-button");
                if (loginButton) {
                  loginButton.click();
                }
              }, 1000);
            return;
        }
        const localProf = {...problemAreaDetails};
        toggleDisablePage(true);
        let favProbResult = await markFavouriteProblem(props.match.params.id, {favourite: !localProf.isFavourite});
        toggleDisablePage(false);
        if (favProbResult.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.errorCode]);
            return;
        }
        if (favProbResult.data.data && favProbResult.data.data.errorCode) {
            toast.error(API_ERROR[favProbResult.data.data.errorCode]);
            return;
        }
        toast.success(!localProf.isFavourite ? API_SUCCESS.FAVOURITE_SUCCESS : API_SUCCESS.UNFAVOURITE_SUCCESS);
        localProf.isFavourite = !localProf.isFavourite;
        updateDetails({...localProf});
    };

    return (
        <div className={`row problem-area problem-area-details ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0, height: "100%" }}>
            {fontModal ? 
            <FontModal 
            displayModal={fontModal}
            toggleInfoModal={toggleFontModal}
            updateCurrentFontSize={updateCurrentFontSize}
            currentFontSize={currentFontSize}
             /> : null}
            <div className="col-lg-12">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-12 pb-15`}>
                        <h4 className={`mb-0`} style={{ paddingTop: "7px" }}><i className="fa fa-arrow-left" aria-hidden="true" onClick={() => props.history.goBack()}></i>Problem Answer</h4>
                    </div>
                </div>
                <div className="row problem-details-wrapper" style={{
                    height: "100%",
                    position: "relative"
                }}>
                    {problemAreaDetails.question ?
                        <>
                            <div className="col-12" style={{height: "100%"}}>
                                <div className="problem-category">
                                    {problemAreaDetails.categoryName}
                                    <img src={require('../../../images/font-size.png')} style={{
                                        position: 'absolute',
                                        right: '35px',
                                        width: '20px'
                                    }} className="cursor-pointer" alt="font" onClick={e => { e.stopPropagation(); toggleFontModal(true) }} />
                                    {problemAreaDetails.isFavourite ?
                                        <img src={require('../../../images/loved.png')} className="cursor-pointer" alt="sort down" onClick={e => { e.stopPropagation(); clickFav() }} />
                                        : <img src={require('../../../images/love.png')} className="cursor-pointer" alt="sort down" onClick={e => { e.stopPropagation(); clickFav() }} />}
                                </div>
                                <div className="problem-question" style={{
                                    fontSize: questionFontSizeConfig[currentFontSize]
                                }}>
                                    {problemAreaDetails.question}
                                </div>
                                <div className="problem-answer" style={{
                                    fontSize: answerFontSizeConfig[currentFontSize]
                                }}>
                                    {problemAreaDetails.answer.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br /></span>
                                    })}
                                </div>
                                <div className="problem-category text-center mb-0" style={{
                                    height: "60px",
                                    width: "100%",
                                    position: "absolute",
                                    left: 0,
                                    bottom: 0 
                                }}>
                                    Copyright &#169; Parentcraft India
                                </div>
                            </div>
                            <div className="col-md-5 col-xs-12">
                                {problemAreaDetails.audio ?
                                    <div className="read-out">
                                        <div>or Listen to the Audio</div>
                                        <audio id="myAudio" controls controlsList="nodownload">
                                            <source src={problemAreaDetails.audio} type="audio/mpeg" />
                                        </audio>
                                    </div> : null}
                            </div></> :
                        <div className="text-center well col-12 color-black">
                            No Details Available!
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default ProblemAreaDetails;