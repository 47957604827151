import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./problem-area.css";

import { getTestsAccount } from "../../crud/home.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";

import moment from 'moment';

const TestResultList = (props) => {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authTokenUserWeb !== null && auth.authTokenUserWeb !== undefined,
        }),
        shallowEqual
    );

    const [upcomingBookings, updateUpcomingBooking] = useState([]);
    const [loadingUpcomingEvents, toggleloadingUpcomingEvents] = useState(true);
    useEffect(() => {
        if (isAuthorized) {
            getTestsAccount()
                .then((output) => {
                    toggleloadingUpcomingEvents(false);
                    if (output.data.errorCode) {
                        toast.error(API_ERROR[output.data.errorCode]);
                        return;
                    }
                    if (output.data.data && output.data.data.errorCode) {
                        toast.error(API_ERROR[output.data.data.errorCode]);
                        return;
                    }

                    const localUpcomingBookings = [];
                    let bookingObj = {};

                    if (output.data && output.data.data && output.data.data.length) {
                        output.data.data.forEach(each => {
                            bookingObj = {};
                            bookingObj.title = each.testRef.title;
                            bookingObj.description = each.testRef.description;
                            bookingObj.bookingForName = each.bookingForName;
                            bookingObj.updatedAt = each.updatedAt;
                            bookingObj.image = each.testRef.imageLink;
                            bookingObj._id = each._id;

                            localUpcomingBookings.push({ ...bookingObj });

                        });
                    }

                    updateUpcomingBooking([...localUpcomingBookings]);
                })
        }
    }, [isAuthorized]);


    return (
        <div className={`row problem-area booking-list test-list`} style={{ margin: 0 }}>
            <div className="col-lg-12">
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-6 categories-heading pb-0">
                        <h4 className={`mb-0 problem-category-heading`}>Self Assessment Results</h4>
                    </div>

                </div>
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-12">
                        <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                            {upcomingBookings.length ?
                                upcomingBookings.map((professional, index) => {
                                    return <div className={`col-sm-6 col-xs-12 professional-wrapper event-list-wrapper ${index !== upcomingBookings.length - 1 ? 'mb-30' : ''} ${index % 2 === 0 ? 'pl-0' : ''}
                                `} key={index} onClick={() => props.history.push(`/assessment-result-details/${professional._id}`)}>
                                        <div className="row booking-item-wrapper event-list-item-wrapper cursor-pointer" style={{ margin: 0 }}>
                                            <div className="event-list-image-wrapper" style={{ position: 'relative' }}>
                                                <img src={professional.image} className="event-list-img" alt={`event-${index}`} />
                                                <div style={{ position: 'absolute', bottom: 0, background: 'rgba(0, 0, 0, 0.8)', height: 40, padding: 12, right: 0, color: '#fff', width: "100%" }}>{moment(professional.updatedAt).format('DD MMM, YYYY')}
                                                <div style={{
                                                        backgroundColor: "#185BDB",
                                                        padding: "11px",
                                                        position: "absolute",
                                                        right: 0,
                                                        top: 0
                                                }}>See Result</div>
                                                 </div>
                                            </div>
                                            <div className="col-12 p-3 event-list-item-description-wrapper">
                                                <div className="row m-0 test-text">
                                                    <div className="col-12 pr-0 pl-0">
                                                        <div className="professional-name">{professional.title}</div>
                                                        {professional.bookingForName ? <div className="professional-date-time test-description">For: {professional.bookingForName} </div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                : <div className="text-center well col-12 color-black">
                                    {!loadingUpcomingEvents ? <div className='no-data-found'>
                                    <img src={require("../../../images/no-data.png")} alt="no data" />
                                    <br />
                                    <div className='no-data-header'>No Assessment Found</div>
                                    <button className='btn btn-primary no-data-button' onClick={() => props.history.push('/assessment-list')}>View Assessment</button>
                                </div> : 'loading...'}
                                </div>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TestResultList;