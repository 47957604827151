import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./DashboardNew";
import Forum from "../admins/forum";
import QuestionDetails from "../admins/question-details";
import Notifications from "../admins/notifications";
import MyFavourites from "../admins/favourites";
import FAQ from "../admins/faq";
import ProblemArea from "../admins/problem-area";
import BookingList from "../../pages/admins/booking-list";
import EventBookingList from "../../pages/admins/event-booking-list";
import EventList from "../../pages/admins/event-list";
import TestList from "../../pages/admins/test-list";
import WorkshopList from "../../pages/admins/workshop-list";
import WorkshopDetails from "../../pages/admins/workshop-details";
import TestResultList from "../../pages/admins/test-result-list";
import EventDetails from "../../pages/admins/event-details";
import TestDetails from "../../pages/admins/test-details";
import ProblemDetails from "../../pages/admins/problem-area-details";
import Details from "./appointment-profile-details";
import BookingDetails from "./booking-details";
import EventBookingDetails from "./event-booking-details";
import RatingReview from "./rating-review";
import MyQA from "../../pages/admins/my-qa";
import SuccessPayment from "../admins/success-payment";
import FailurePayment from "../admins/failure-payment";
import SuccessPaymentOnlineEvent from "../admins/success-payment-online-event";
import FailurePaymentOnlineEvent from "../admins/failure-payment-online-event";
import SuccessPaymentWorkshop from "../admins/success-payment-workshop";
import FailurePaymentWorkshop from "../admins/failure-payment-workshop";
import SuccessMobilePayment from "../admins/success-mobile-payment";
import FailureMobilePayment from "../admins/failure-mobile-payment";
import Home from "./home";
import PrivacyPolicy from './privacy-policy';
import TermsCondition from './terms-condition';
import CancellationPolicy from './cancellation-policy';
import Share from './share';
import NotFound from "./not-found";
import PDFViewer from '../admins/pdf-viewer';
import { LayoutSplashScreen } from "../../../_metronic";

export default function HomePage() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/book-now" component={Dashboard} />
        <Route path="/forum" component={Forum} />
        <Route path="/forum-details/:id" component={QuestionDetails} />
        <Route path="/myqa-details/:id" component={QuestionDetails} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/my-favourite" component={MyFavourites} />
        <Route path="/problem-area" component={ProblemArea} />
        <Route path="/faq/:question" component={NotFound} />
        <Route path="/faq" component={FAQ} />
        <Route path="/booking-list" component={BookingList} />
        <Route path="/event-booking-list" component={EventBookingList} />
        <Route path="/event-list" component={EventList} />
        <Route path="/event-details/:eventId/:vendorName/:eventName" component={EventDetails} />
        <Route path="/event-details/:eventId" component={EventDetails} />
        <Route path="/assessment-list" component={TestList} />
        <Route path="/assessment-details/:testId" component={TestDetails} />
        <Route path="/workshop-details/:eventId" component={WorkshopDetails} />
        <Route path="/workshop-list" component={WorkshopList} />
        <Route path="/workshop-booking-details/:eventId" component={WorkshopDetails} />
        <Route path="/workshop-booking-list" component={WorkshopList} />
        <Route path="/assessment-results" component={TestResultList} />
        <Route path="/assessment-result-details/:testId" component={TestDetails} />
        <Route path="/problem-details/:id/:lang" component={ProblemDetails} />
        <Route path="/professional-details/:id/:name" component={Details} />
        <Route path="/professional-details/:id" component={Details} />
        <Route path="/booking-details/:bookingId" component={BookingDetails} />
        <Route path="/event-booking-details/:bookingId" component={EventBookingDetails} />
        <Route path="/rating-review/:bookingId" component={RatingReview} />
        <Route path="/event-rating-review/:bookingId" component={RatingReview} />
        <Route path="/online-event-rating-review/:bookingId" component={RatingReview} />
        <Route path="/workshop-rating-review/:bookingId" component={RatingReview} />
        <Route path="/myQA" component={MyQA} />
        <Route path="/success-payment" component={SuccessPayment} />
        <Route path="/failure-payment" component={FailurePayment} />
        <Route path="/success-payment-online-event" component={SuccessPaymentOnlineEvent} />
        <Route path="/failure-payment-online-event" component={FailurePaymentOnlineEvent} />
        <Route path="/success-mobile-payment" component={SuccessMobilePayment} />
        <Route path="/failure-mobile-payment" component={FailureMobilePayment} />
        <Route path="/success-payment-workshop" component={SuccessPaymentWorkshop} />
        <Route path="/failure-payment-workshop" component={FailurePaymentWorkshop} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" component={TermsCondition} />
        <Route path="/cancellation-policies" component={CancellationPolicy} />
        <Route path="/share" component={Share} />
        <Route path="/share-customer" component={Share} />
        <Route path="/pdf-viewer" component={PDFViewer} />
        <Redirect from="/privacy" to="/privacy-policy" />
        {/* <Redirect from="/index.html" to="/" /> */}
        {/* <Route path="/404" component={NotFound} /> */}
        {/* <Redirect to="*" /> */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
}
